// Constants here should be identical to AdminUserState in the backend.
export enum AdminUserState {
  Active = 1,
  Inactive = 2,
  Invited = 11,
  Suspended = 99,
}

export const adminUserStateLabels: Record<AdminUserState, string> = {
  [AdminUserState.Active]: 'Active',
  [AdminUserState.Inactive]: 'Inactive',
  [AdminUserState.Invited]: 'Invited',
  [AdminUserState.Suspended]: 'Suspended',
};

export const adminUserStateOptions: Array<{ value: number, label: string }> = [
  { value: AdminUserState.Active, label: adminUserStateLabels[AdminUserState.Active] },
  { value: AdminUserState.Inactive, label: adminUserStateLabels[AdminUserState.Inactive] },
  { value: AdminUserState.Invited, label: adminUserStateLabels[AdminUserState.Invited] },
  { value: AdminUserState.Suspended, label: adminUserStateLabels[AdminUserState.Suspended] },
];

// Constants here should be identical to AdminUserType in the backend.
export enum AdminUserType {
  // AccountOwner = 1,
  Generic = 2,
  AAA = 9,
}

export const adminUserTypeLabels: Record<AdminUserType, string> = {
  [AdminUserType.Generic]: 'Account admin',
  [AdminUserType.AAA]: 'Team AAA',
};

export const adminUserTypeOptions: Array<{ value: AdminUserType, label: string }> = [
  { value: AdminUserType.Generic, label: adminUserTypeLabels[AdminUserType.Generic] },
  { value: AdminUserType.AAA, label: adminUserTypeLabels[AdminUserType.AAA] },
];