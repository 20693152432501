import { useToken } from '@chakra-ui/react';
import { MRT_TableOptions } from 'mantine-react-table';
import { uiStyles } from '@/lib/ui';
import { useBodySize } from '@/base/Layout/useBodySize';

// All of these generic typing thing is for us to hack some of the CSS.
export function useDataTableOptions<TData extends Record<string, any>>(): Omit<Partial<MRT_TableOptions<TData>>, 'columns' | 'data'> {
  const bodyCellColor = useToken('colors', 'text.primary');
  const bodyCellFontSize = useToken('fontSizes', 'sm');
  const headerCellFontSize = useToken('fontSizes', 'md');
  const { bodyWidth } = useBodySize();

  return {
    enableClickToCopy: false,
    mantineTableContainerProps: {
      sx: {
        maxWidth: `${bodyWidth - 129}px`,
      },
    },
    mantineCopyButtonProps: {
      // sx: { width: '100%' },
      // @ts-ignore
      // sx: { width: '100%' },
      // icon: <ContentCopy />,
    },
    mantinePaperProps: {
      // shadow: 'sm',
      withBorder: false,
      // @ts-ignore
      sx: {
        borderRadius: uiStyles.borderRadius,
        padding: 24,
        // This a hack to make the pagination div non-absolute.
        "&>div:nth-of-type(3)>div:nth-of-type(2)>div": {
          // backgroundColor: 'red !important',
          // position: 'relative',
          position: 'relative !important',
        },
      },
    },
    mantineTableProps: {
      sx: {
        '& thead>tr>th': {
          borderBottom: 'none',
        },
        '& thead>tr': {
          boxShadow: 'none !important',
        },
      },
    },
    mantineTopToolbarProps: {
      sx: {
        '& div': {
          alignItems: 'center !important',
        },
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        fontSize: `${headerCellFontSize} !important`,
      },
    },
    mantineTableBodyProps: {
      sx: {
        '& :where(*, *::before, *::after)': {
          borderStyle: 'none !important',
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        '&': {
          padding: '1.5rem 1rem 1.5rem 1rem !important',
          fontSize: `${bodyCellFontSize} !important`,
        },
        '& button': {
          fontSize: bodyCellFontSize,
          color: bodyCellColor,
        },
      },
    },
  };
}