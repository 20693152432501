import { type FC, useCallback, useState } from 'react';
import { UiHStack, UiIconPencilSimple, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { account } from '@/api';
import dayjs from 'dayjs';
import BaseButtonIconText from '@/base/Button/IconText';
import type { SubscriptionEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { useDisclosure } from '@chakra-ui/react';
import AAAFormDrawer from '@/account/component/Subscription/AAAFormDrawer';

export interface AAAFieldsProps {
  subscription: account.Subscription
  subscriptionEditor: SubscriptionEditor
}

const AAAFields: FC<AAAFieldsProps> = ({
  subscription,
  subscriptionEditor,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <UiStack spacing={4}>
      <UiHStack spacing={4}>
        <UiText variant={'title'}>Custom fields</UiText>
        <BaseButtonIconText Icon={UiIconPencilSimple} onClick={onOpen}>Edit</BaseButtonIconText>
      </UiHStack>
      <UiStack
        p={8}
        borderRadius={uiStyles.borderRadius}
        shadow={uiStyles.cardShadow}
        bgColor={'#fff'}
      >
        <UiSimpleGrid columns={4} gap={8}>
          <UiStack>
            <UiText variant={'body2'} color={'text.secondary'}>Start date</UiText>
            <UiText>
              {!!subscription.plan.from ? (
                dayjs.unix(subscription.plan.from).format('DD MMMM YYYY')
              ) : '-'}
            </UiText>
          </UiStack>
          {/*<UiStack>*/}
          {/*  <UiText variant={'body1'} color={'text.secondary'}>End date</UiText>*/}
          {/*  <UiText>*/}
          {/*    {!!subscription.plan.to ? (*/}
          {/*      dayjs.unix(subscription.plan.to).format('DD MMMM YYYY')*/}
          {/*    ) : '-'}*/}
          {/*  </UiText>*/}
          {/*</UiStack>*/}
          <UiStack>
            <UiText variant={'body1'} color={'text.secondary'}>Package discount (percentage)</UiText>
            <UiText>
              {subscription.plan.discount?.packagePercentage ?? 0}
            </UiText>
          </UiStack>
          <UiStack>
            <UiText variant={'body1'} color={'text.secondary'}>Free registrations</UiText>
            <UiText>
              {subscription.plan.discount?.registrationFreeUnits ?? 0}
            </UiText>
          </UiStack>
          <UiStack>
            <UiText variant={'body1'} color={'text.secondary'}>Free abstracts</UiText>
            <UiText>
              {subscription.plan.discount?.abstractManagementFreeUnits ?? 0}
            </UiText>
          </UiStack>
          <UiStack>
            <UiText variant={'body1'} color={'text.secondary'}>Free digital posters</UiText>
            <UiText>
              {subscription.plan.discount?.digitalPosterFreeUnits ?? 0}
            </UiText>
          </UiStack>
        </UiSimpleGrid>
      </UiStack>
      <AAAFormDrawer
        subscription={subscription}
        subscriptionEditor={subscriptionEditor}
        onClose={onClose}
        onSaveSuccess={onClose}
        isVisible={isOpen}
      />
    </UiStack>
  );
};

export default AAAFields;
