import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppWebinarCard from '@/base/App/WebinarCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface WebinarProps extends Omit<ModuleCardProps, 'children'> {
}

const Webinar: FC<WebinarProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppWebinarCard />
    </ModuleCard>
  );
};

export default Webinar;
