import { type ApiRequest } from '../globalClient';
import { type ApiResponseSingle, properCallApi } from '../tenantClient';

export interface CancelRegistrationResponse {
  id: number
}

export interface CancelRegistrationRequest {
  registrationId: number
}

export const cancelRegistration = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: CancelRegistrationRequest): Promise<ApiResponseSingle<CancelRegistrationResponse>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/admin/registrations/cancel';
    request.endpoint.query = {
      id: `${params.registrationId}`
    };
    return await properCallApi<ApiResponseSingle<CancelRegistrationResponse>>(request);
  };
};

export interface TransferRegistrationRequest {
  eventId: number
  registrationId: number
  email: string
  firstName: string
  lastName: string
}

export interface TransferRegistrationResponse {
  id: number
}

export const transferRegistration = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: TransferRegistrationRequest): Promise<ApiResponseSingle<TransferRegistrationResponse>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/admin/registrations/transfer';
    request.endpoint.query = {
      eventId: `${params.eventId}`
    };
    request.payload = {
      id: `${params.registrationId}`,
      email: `${params.email}`,
      firstName: `${params.firstName}`,
      lastName: `${params.lastName}`
    };
    return await properCallApi<ApiResponseSingle<TransferRegistrationResponse>>(request);
  };
};
