import { type PaymentOption } from '../registration';
import { type ApiRequest, type ApiResponseSingle, callApi, properCallApi } from '../tenantClient';

export const paymentOptionDefaultQueryKey = 'registration.paymentOptionDefault';

export interface EnabledCreditCardCheckRequest {
  eventId: number
}

export interface EnabledCreditCardCheckResponse {
  hasCreditCardEnabled: boolean
}

export function enabledCreditCardCheck(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: number): Promise<ApiResponseSingle<EnabledCreditCardCheckResponse>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/payment_options/enabled_credit_card_check';
    request.endpoint.query = { event_id: eventId };
    return await properCallApi<ApiResponseSingle<EnabledCreditCardCheckResponse>>(request);
  };
}

export interface PaymentOptionsDefaultRequest {
  tenantCode: string
}

export function loadPaymentOptionsDefault(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: PaymentOptionsDefaultRequest
  ) => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/payment_options/default_payment_options';
    request.endpoint.query = { tenantCode: params.tenantCode };
    return await callApi<PaymentOption>(request);
  };
}
