import { type FC } from 'react';
import { UiGrid } from '@/lib/ui';
import DataPrivay from './DataPrivacy';
import Summary from './Summary';
import Payment from './Payment';
import PaymentResult from './PaymentResult';

export interface StepsProps {}

const Steps: FC<StepsProps> = () => {
  return (
    <UiGrid
      templateColumns={{ base: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr' }}
      gap={8}
    >
      <DataPrivay />
      <Summary />
      <Payment />
      <PaymentResult />
    </UiGrid>
  );
};

export default Steps;
