import { type SubscriptionPackage, SubscriptionPaymentPlan, SubscriptionProduct } from '@/api/constant/subscription';
import { type account } from '@/api';
import { type Addon } from '@/api/account';

export function isPackageEnabled(subscription: account.Subscription, packageName: SubscriptionPackage): boolean {
  return subscription.plan.package === packageName;
}

export function getAddonsEnabled(subscription: account.Subscription): Addon[] {
  return subscription.plan.addons ?? [];
}

export function getPackageProducts(subscription: account.Subscription, pack: SubscriptionPackage): SubscriptionProduct[] {
  return subscription[pack]?.products ?? [];
}

// Check if the product is included in the package selected.
export function isProductIncluded(subscription: account.Subscription, productName: SubscriptionProduct): boolean {
  return getPackageProducts(subscription, subscription.plan.package).includes(productName);
}

// Check if the product is enabled (either included in the package os as an addon).
export function isProductEnabled(subscription: account.Subscription, productName: SubscriptionProduct): boolean {
  return getProductsEnabled(subscription).includes(productName);
}

// Get all the products that are enabled (either from the package or as an addon)
export function getProductsEnabled(subscription: account.Subscription): SubscriptionProduct[] {
  const addons = getAddonsEnabled(subscription);
  const addonProducts = [];
  for (const addon of addons) {
    addonProducts.push(addon.product);
  }
  return getPackageProducts(subscription, subscription.plan.package).concat(addonProducts);
}

export function isAddonAvailable(subscription: account.Subscription, addonName: SubscriptionProduct): boolean {
  return !subscription[subscription.plan.package]?.products?.includes(addonName);
}

// Check if the product is enabled as an addon.
export function isAddonEnabled(subscription: account.Subscription, addonName: SubscriptionProduct): boolean {
  return isAddonAvailable(subscription, addonName) && isProductEnabled(subscription, addonName);
}

export function isServicePackEnabled(subscription: account.Subscription): boolean {
  return subscription.plan.servicePack.hours > 0;
}

export type AddonPrices = {
  [M in SubscriptionProduct]?: number
}

export interface FirstYearPrice {
  total: number
  package: number
  servicePack: number
  addons: AddonPrices
  registration: number
  abstractSubmission: number
  digitalPoster: number
  liveTranslation: number
  aiLiveCaptioning: number
}

export type AddonMonths = {
  [M in SubscriptionProduct]?: number
}

export interface PriceContext {
  defaultAddonMonths: number
  addonMonths: AddonMonths
  numOfRegistrations?: number
  numOfAbstractSubmissions?: number
  numOfDigitalPosters?: number
  liveTranslationUsageCost: number
  aiLiveCaptioningUsageCost: number
}

export function calculateServicePackPrice(subscription: account.Subscription): number {
  return subscription.servicePack.hourPrice * (subscription.plan.servicePack.hours ?? 0);
}

export function calculateFirstYearPrice(subscription: account.Subscription, context: PriceContext): FirstYearPrice {
  const price: FirstYearPrice = {
    total: 0,
    package: 0,
    servicePack: 0,
    addons: {},
    registration: 0,
    abstractSubmission: 0,
    digitalPoster: 0,
    liveTranslation: 0,
    aiLiveCaptioning: 0,
  };

  // Package price
  if (subscription.plan.package) {
    price.package = subscription[subscription.plan.package].yearlyPrice;
  }

  // Service pack price
  if (subscription.plan.servicePack) {
    price.servicePack = calculateServicePackPrice(subscription);
  }

  // Addon price
  if (Array.isArray(subscription.plan.addons) && subscription.plan.addons.length > 0) {
    for (const addon of subscription.plan.addons) {
      price.addons[addon.product] = 0
      if (addon.paymentPlan === SubscriptionPaymentPlan.Quarterly && context.addonMonths) {
        price.addons[addon.product] = subscription.products[addon.product].monthlyPrice * (context.addonMonths[addon.product] ?? context.defaultAddonMonths);
      }
      if (addon.paymentPlan === SubscriptionPaymentPlan.Yearly) {
        price.addons[addon.product] = subscription.products[addon.product].yearlyPrice;
      }
      price.total += price.addons[addon.product] ?? 0;
    }
  }

  // Cost per registration in total
  if (isProductEnabled(subscription, SubscriptionProduct.Registration)) {
    // We do fix cost per abstract submission for now.
    price.registration = subscription.products[SubscriptionProduct.Registration].unitRate * (context.numOfRegistrations ?? 0);
  }

  // Cost per abstract submission in total
  if (isProductEnabled(subscription, SubscriptionProduct.AbstractManagement)) {
    // We do fix cost per abstract submission for now.
    price.abstractSubmission = subscription.products[SubscriptionProduct.AbstractManagement].unitRate * (context.numOfAbstractSubmissions ?? 0);
  }

  // Cost per digital poster upload in total
  if (isProductEnabled(subscription, SubscriptionProduct.DigitalPoster)) {
    // We do fix cost per digital poster upload for now.
    price.digitalPoster = subscription.products[SubscriptionProduct.DigitalPoster].unitRate * (context.numOfDigitalPosters ?? 0);
  }

  // Cost for live translation usage
  if (isProductEnabled(subscription, SubscriptionProduct.LiveTranslation)) {
    // We do fix cost per digital poster upload for now.
    price.liveTranslation = context.liveTranslationUsageCost;
  }

  // Cost for AI live captioning usage
  if (isProductEnabled(subscription, SubscriptionProduct.AILiveCaptioning)) {
    // We do fix cost per digital poster upload for now.
    price.aiLiveCaptioning = context.aiLiveCaptioningUsageCost;
  }

  // Calculate the total price.
  price.total += price.package + price.servicePack + price.registration + price.abstractSubmission + price.digitalPoster + price.liveTranslation + price.aiLiveCaptioning;
  return price;
}
