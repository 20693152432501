import { type FC } from 'react';
import { UiSimpleGrid } from '@/lib/ui';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonAvailable } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import MultiLingualSupport from '@/account/component/SubscriptionAddon/MultiLingualSupport';
import LiveTranslation from '@/account/component/SubscriptionAddon/LiveTranslation';
import AILiveCaptioning from '@/account/component/SubscriptionAddon/AILiveCaptioning';
import AbstractManagement from '@/account/component/SubscriptionAddon/AbstractManagement';
import DigitalPoster from '@/account/component/SubscriptionAddon/DigitalPoster';
import EventApp from '@/account/component/SubscriptionAddon/EventApp';
import AppWayfinder from '@/account/component/SubscriptionAddon/AppWayfinder';
import ContentHub from '@/account/component/SubscriptionAddon/ContentHub';
import Integration from '@/account/component/SubscriptionAddon/Integration';

export interface AddonListProps {
  subscription: account.Subscription
  toggleAddon: (addonName: SubscriptionProduct) => void
}

const AddonList: FC<AddonListProps> = ({
  subscription,
  toggleAddon
}) => {
  return (
    <>
      <UiSimpleGrid columns={{ base: 1, lg: 2, '2xl': 3 }} gap={{ base: 4, lg: 8 }}>
        {isAddonAvailable(subscription, SubscriptionProduct.AbstractManagement) && (
          <AbstractManagement
            onToggle={() => toggleAddon(SubscriptionProduct.AbstractManagement)}
            subscription={subscription}
          />
        )}
        {isAddonAvailable(subscription, SubscriptionProduct.DigitalPoster) && (
          <DigitalPoster
            onToggle={() => toggleAddon(SubscriptionProduct.DigitalPoster)}
            subscription={subscription}
          />
        )}
        {isAddonAvailable(subscription, SubscriptionProduct.MobileEventApp) && (
          <EventApp
            onToggle={() => toggleAddon(SubscriptionProduct.MobileEventApp)}
            subscription={subscription}
          />
        )}
        {/*<AppWayfinder*/}
        {/*  onToggle={() => toggleAddon(SubscriptionProduct.AppWayfinder)}*/}
        {/*  subscription={subscription}*/}
        {/*/>*/}
        <ContentHub
          onToggle={() => toggleAddon(SubscriptionProduct.ContentHub)}
          subscription={subscription}
        />
        <MultiLingualSupport
          onToggle={() => toggleAddon(SubscriptionProduct.MultiLingualSupport)}
          subscription={subscription}
        />
        <LiveTranslation
          onToggle={() => toggleAddon(SubscriptionProduct.LiveTranslation)}
          subscription={subscription}
        />
        <AILiveCaptioning
          onToggle={() => toggleAddon(SubscriptionProduct.AILiveCaptioning)}
          subscription={subscription}
        />
        <Integration
          onToggle={() => toggleAddon(SubscriptionProduct.Integration)}
          subscription={subscription}
        />
      </UiSimpleGrid>
    </>
  );
};

export default AddonList;
