import { type FC } from 'react';
import { HStack, type StackProps } from '@chakra-ui/react';
import { UiDivider } from '@/lib/ui/Divider';

export interface UiHStackProps extends StackProps {
  enableShadow?: boolean
  enableDivider?: boolean
}

export const UiHStack: FC<UiHStackProps> = ({
  enableShadow = false,
  enableDivider = false,
  ...props
}) => {
  return (
    <HStack
      divider={ enableDivider
        ? (
          <UiDivider
            height={'1px'}
            backgroundColor={'blackAlpha.300'}
          />
          )
        : undefined}
      shadow={enableShadow ? 'lg' : 'none'}
      {...props}
    />
  );
};
