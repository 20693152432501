import { getAppConfig } from '@/app/config';
import { ApiError, ApiErrorCode, createApiError } from '@/api/error';
import bugsnag from '@/bugsnag';

const appConfigApiHost = getAppConfig().api.globalHost;

export interface RequestEndpoint {
  host?: string
  path: string
  query?: Record<string, string>
}

export function getRequestUrl(requestEndpoint: RequestEndpoint): string {
  let _queryString = '';
  if (requestEndpoint.query) {
    _queryString = '?' + (new URLSearchParams(requestEndpoint.query)).toString();
  }
  const _host = requestEndpoint.host ?? appConfigApiHost;
  return `${_host}${requestEndpoint.path}${_queryString}`;
}

export interface ApiRequest {
  // url: string;
  endpoint: RequestEndpoint
  authToken?: string
  payload?: {}
  headers?: HeadersInit
  method?: string
  // transformData?: <Data>(data: object) => Data|Error;
}

const defaultFetchOptions: RequestInit = {
  method: 'POST',
  mode: 'cors',
  // credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  }
  // body: ''
};

export async function callApi<ApiData>(request: ApiRequest): Promise<ApiData> {
  // Merge all the custom headers.
  const options = {
    ...defaultFetchOptions,
    ...request.method ? { method: request.method } : {},
    headers: {
      ...defaultFetchOptions.headers,
      ...request.headers ?? {},
      ...request.authToken ? { 'access-token': request.authToken } : {}
    },
    ...request.payload ? { body: JSON.stringify(request.payload) } : {}
  };
  // Leave a breadcrumb for the API call
  bugsnag.leaveBreadcrumb('API Call', {
    requestEndpoint: request.endpoint,
    Headers: options.headers,
    method: options.method,
    payload: request.payload,
  });

  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 201) {
    // Received the successful response from the backend.
    const jsonData = await fetchResponse.json();
    return (jsonData as ApiData);
  } else {
    // Handle errors.
    switch(fetchResponse.status) {
      case 400: {
        throw createApiError('Bad request.', ApiErrorCode.BadRequest);
      }
      case 401: {
        throw createApiError('Login required.', ApiErrorCode.Unauthorized);
      }
      case 403: {
        throw createApiError('Access denied.', ApiErrorCode.AccessDenied);
      }
      case 404: {
        throw createApiError('Api not found.', ApiErrorCode.PageNotFound);
      }
      default: {
        throw createApiError('API call failed.', ApiErrorCode.ServerError);
      }
    }
  }
}
