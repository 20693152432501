// import { type TicketType } from '@/api/constant/ticket';
import {
  type ApiRequest,
  type ApiResponse,
  properCallApi
} from '@/api/tenantClient';

export const themesQueryKey = 'registration.themes';

export interface ThemeData {
  id: number
  name: string
}

export function getThemes(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string): Promise<ApiResponse<ThemeData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/delegate_types/themes';
    request.endpoint.query = { eventId };
    return await properCallApi<ApiResponse<ThemeData>>(request);
  };
}
