import { type FC } from 'react';
import { UiHStack, type UiHStackProps, UiText } from '@/lib/ui';

export interface BrandFooterProps extends UiHStackProps {
}

const BrandFooter: FC<BrandFooterProps> = (props) => {
  return (
    <UiHStack
      bgColor={'blackAlpha.50'}
      flexGrow={1}
      py={4}
      justifyContent={'space-around'}
      {...props}
    >
      <UiHStack spacing={1}>
        <UiText variant={'body2'}>Powered by</UiText>
        {/*<UiText variant={'body2'} color={'brand.500'} fontWeight={600}>evexus</UiText>*/}
      </UiHStack>
    </UiHStack>
  );
};

export default BrandFooter;
