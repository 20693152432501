import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useRecommendedDelegateTypesQuery(eventId: string, delegateTypeId: string) {
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();

  return useQuery(
    [registration.recommendedDelegateTypesQueryKey, eventId],
    async () => {
      const result = await registration.getRecommendedDelegateTypes(createTenantAdminApiRequest)(eventId, delegateTypeId);
      return result.items;
    },
    { enabled: isLoaded }
  );
}
