import {
  callApi,
  type ApiRequest,
  type ApiResponse
} from '@/api/tenantClient';
import { InfoFormResponse } from './infoFormResponse';

export interface InfoFormResponseSaveRequest {
  eventId: string
  formType: 'personal' | 'additional'
  response: Record<string, any>
  ownerId: number
  ownerType: string
  infoFormId?: number
}

export function saveInfoFormResponse(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (
    params: InfoFormResponseSaveRequest
  ): Promise<ApiResponse<InfoFormResponse>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/info_form_responses';
    request.payload = params;
    return await callApi<InfoFormResponse>(request);
  };
}
