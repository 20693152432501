import { type FC } from 'react';
import {
  ArrowSquareDownRight
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconArrowSquareDownRightProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconArrowSquareDownRight: FC<UiIconArrowSquareDownRightProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ArrowSquareDownRight}
      {...props}
    />
  );
};
