import {
  type RouteObject
} from 'react-router-dom';
import AdminAccess from '@/account/component/AdminAccess';
import AbstractGeneralLandingPage from '@/abstract/general/Landing';

// All the route names should start with Abstract to avoid conflicts with other modules (e.g. registration).
export interface AbstractRouteParams {
  AbstractGeneralLanding: {
    tenantCode: string
  }
}

export type AbstractRouteName = keyof AbstractRouteParams;

export const abstractRoutes: Record<AbstractRouteName, RouteObject> = {
  AbstractGeneralLanding: {
    path: '/abstract-management/:tenantCode/general/landing',
    element: (<AdminAccess><AbstractGeneralLandingPage /></AdminAccess>)
  },
};
