import { type FC } from 'react';
import CategoryCard from './CategoryCard';
import { UiGrid, UiStack } from '@/lib/ui';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseBlockList from '@/base/BlockList';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface CategoryListProps {}

const CategoryList: FC<CategoryListProps> = () => {
  const { eventId } = useRegisterRoute();
  const { tenant } = useTenantRoute();
  const attendeeCategoryQuery = useAttendeeCategoryQuery(eventId);

  if (tenant) {
    return (
      <UiStack
        spacing={4}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <QueryContainer query={attendeeCategoryQuery}>
          {(data) => {
            return (
              <BaseBlockList>
                { data.map((attendeeCategory) => {
                  return (
                    <CategoryCard
                      eventId={eventId}
                      tenant={tenant}
                      key={attendeeCategory.id}
                      data={attendeeCategory} />
                  );
                })}
              </BaseBlockList>
            );
          }}
        </QueryContainer>
      </UiStack>
    );
  }
};

export default CategoryList;
