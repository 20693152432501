import { type FC, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiStack,
  UiHStack,
  UiText,
  uiStyles,
  UiIconCheck, UiButton,
} from '@/lib/ui';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import PasswordForm from './PasswordForm';


export interface ManagePasswordProps extends UiHStackProps {
}

const ManagePassword: FC<ManagePasswordProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <UiStack spacing={4}>
        <UiText variant={'title'}>Password</UiText>
        <UiHStack
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          spacing={4}
          p={8}
          py={4}
          justifyContent={'space-between'}
          shadow={uiStyles.cardShadow}
        >
          <UiStack justifyContent={'flex-start'} spacing={1}>
            <UiText color={'text.secondary'}>••••••••••••••••</UiText>
          </UiStack>
          <UiButton variant={'ghost'} pr={0} onClick={onToggle}>Change</UiButton>
        </UiHStack>
      </UiStack>
      <PasswordForm onClose={onToggle} onSaveSuccess={() => {}} isVisible={isOpen}/>
    </>
  );
};

export default ManagePassword;
