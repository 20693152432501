import { useMemo, type FC } from 'react';
import {
  UiHStack,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
  UiLink
} from '@/lib/ui';
import { type IGroupRegistration } from '@/api/registration/groupRegistration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { generatePageUrl } from '@/app/pages';

export interface RegistrationInfoResponseProps {
  title: string
  registrationsInfo: IGroupRegistration[]
}

const upperFirst = (str: string) => { return `${str.charAt(0).toUpperCase()}${str.slice(1)}`; };

const RegistrationInfoResponse: FC<RegistrationInfoResponseProps> = ({ title, registrationsInfo }) => {
  const { tenantCode } = useRegisterRoute();
  const fieldMetadata = useMemo(() => {
    return Object.keys(registrationsInfo[0] || {}).filter((field) => { return field !== 'id'; });
  }, [registrationsInfo]);
  return (
    <UiStack spacing={4}>
      <UiText variant={'title'}>{title}</UiText>
      <UiStack spacing={4}>
        {
          registrationsInfo.map((registrationInfo) => {
            return (
              <UiStack
                key={registrationInfo.id}
                p={8}
                py={6}
                borderRadius={uiStyles.borderRadius}
                bgColor={'#fff'}
                flexGrow={1}
                shadow={uiStyles.cardShadow}
              >
                <UiSimpleGrid columns={3} gap={8}>
                  {
                    fieldMetadata.map((field) => {
                      return (
                        <UiStack key={field}>
                          <UiText color={'text.secondary'}>{upperFirst(field)}</UiText>
                          <UiHStack spacing={4}>
                            <UiText>{registrationInfo[field as keyof IGroupRegistration]}</UiText>
                          </UiHStack>
                        </UiStack>
                      );
                    })
                  }
                </UiSimpleGrid>
                <UiLink href={generatePageUrl('RegistrationManageInfo', { tenantCode, registrationId: registrationInfo.id.toString() })} color="primary.500" mt={4}>
                  View details
                </UiLink>
              </UiStack>
            );
          })
        }
      </UiStack>
    </UiStack>
  );
};

export default RegistrationInfoResponse;
