import { type FC, type ReactNode } from 'react';
import { ChakraBaseProvider } from '@chakra-ui/react';
import { theme as appTheme } from '@/app/theme';
import Fonts from '@/app/Fonts';
// import chakraTheme from '@chakra-ui/theme';

// const { Button } = chakraTheme.components;

// const theme = extendBaseTheme({
//   components: {
//     Button,
//   },
// });

export interface ProviderThemeProps {
  children: ReactNode
}

export const ProviderTheme: FC<ProviderThemeProps> = ({
  children
}) => {
  return (
    <ChakraBaseProvider theme={appTheme}>
      <Fonts />
      {children}
    </ChakraBaseProvider>
  );
};
