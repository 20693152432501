import { type FC } from 'react';
import {
  Eye
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconEyeProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconEye: FC<UiIconEyeProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Eye}
      {...props}
    />
  );
};
