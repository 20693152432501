import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { type UiBoxProps, UiButton, UiHStack, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { subscriptionPackageLabels, SubscriptionProduct, SubscriptionState } from '@/api/constant/subscription';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import {
  getAddonsEnabled,
  isAddonEnabled,
  isProductIncluded,
  isServicePackEnabled,
} from '@/account/subscription/utils';
import Status from '../../subscription/Plan/Status';
import Registration from '@/account/component/SubscriptionModule/Registration';
import ContentDeliveryPlatform from '@/account/component/SubscriptionModule/ContentDeliveryPlatform';
import Webinar from '@/account/component/SubscriptionModule/Webinar';
import SponsorExhibitor from '@/account/component/SubscriptionModule/SponsorExhibitor';
import LiveStreaming from '@/account/component/SubscriptionModule/LiveStreaming';
import DataInsight from '@/account/component/SubscriptionModule/DataInsight';
import MobileEventApp from '@/account/component/SubscriptionModule/MobileEventApp';
import AbstractManagement from '@/account/component/SubscriptionModule/AbstractManagement';
import DigitalPoster from '@/account/component/SubscriptionModule/DigitalPoster';
import BaseBlockList from '@/base/BlockList';
import ServicePack from '@/account/component/SubscriptionAddon/ServicePack';
import AddonAbstractManagement from '@/account/component/SubscriptionAddon/AbstractManagement';
import AddonDigitalPoster from '@/account/component/SubscriptionAddon/DigitalPoster';
import AddonEventApp from '@/account/component/SubscriptionAddon/EventApp';
import AddonAppWayfinder from '@/account/component/SubscriptionAddon/AppWayfinder';
import AddonContentHub from '@/account/component/SubscriptionAddon/ContentHub';
import AddonMultiLingualSupport from '@/account/component/SubscriptionAddon/MultiLingualSupport';
import AddonLiveTranslation from '@/account/component/SubscriptionAddon/LiveTranslation';
import AddonAILiveCaptioning from '@/account/component/SubscriptionAddon/AILiveCaptioning';
import AddonIntegration from '@/account/component/SubscriptionAddon/Integration';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
dayjs.extend(customParseFormat);

export interface SummaryProps extends UiBoxProps {
  subscription: account.Subscription
}

const addonStyles = {
  borderWidth: '1px',
  borderColor: 'gray.200',
  borderStyle: 'solid',
  // bgColor: 'blackAlpha.50',
};

const PlanInformation: FC<SummaryProps> = ({
  subscription
}) => {
  const { tenant } = useTenantRoute();
  const navigate = useNavigate();
  const toManagement = useCallback(() => {
    navigate(generatePageUrl('AccountSubscriptionPlanManagement', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);

  if (!subscription.plan) {
    return (
      <BaseMessageBarError>Failed to load the summary</BaseMessageBarError>
    );
  }

  return (
    <BaseBlockList
      bgColor={'#fff'}
      enableDivider={true}
    >
      <UiHStack spacing={4} p={8} py={6} bgColor={'#fff'} flexGrow={1} justifyContent={'space-between'}>
        <UiSimpleGrid spacing={0} columns={4} flexGrow={1}>
          <UiStack spacing={2}>
            <UiText variant={'body1'} color={'text.secondary'}>Status</UiText>
            {subscription.plan.state === SubscriptionState.Active ? (
              <Status subscriptionState={subscription.plan.state}/>
            ) : (
              <UiText fontWeight={600}>-</UiText>
            )}
          </UiStack>
          <UiStack spacing={2}>
            <UiText variant={'body1'} color={'text.secondary'}>Package</UiText>
            {subscription.plan.state === SubscriptionState.Active ? (
              <UiText fontWeight={600}>{subscriptionPackageLabels[subscription.plan.package]}</UiText>
            ) : (
              <UiText fontWeight={600}>-</UiText>
            )}
          </UiStack>
          <UiStack spacing={2}>
            <UiText variant={'body1'} color={'text.secondary'}>Add-ons</UiText>
            {subscription.plan.state === SubscriptionState.Active ? (
              <UiText fontWeight={600}>{getAddonsEnabled(subscription).length ?? 0}</UiText>
            ) : (
              <UiText fontWeight={600}>0</UiText>
            )}
          </UiStack>
          {(subscription.plan.state === SubscriptionState.Active && subscription.plan.to) && (
            <UiStack spacing={2}>
              <UiText variant={'body1'} color={'text.secondary'}>Next renew</UiText>
              <UiText fontWeight={600}>{dayjs.unix(subscription.plan.to).format('DD MMMM YYYY')}</UiText>
            </UiStack>
          )}
        </UiSimpleGrid>
        {/*{subscription.plan.state !== SubscriptionState.Active && (*/}
        {/*  <UiStack width={36} justifyContent={'flex-end'} alignItems={'flex-end'}>*/}
        {/*    <UiButton colorScheme={'primary'} onClick={toManagement}>Manage</UiButton>*/}
        {/*  </UiStack>*/}
        {/*)}*/}
      </UiHStack>
      {subscription.plan.state === SubscriptionState.Active && (
        <UiStack bgColor={'#fff'} p={8} spacing={4}>
          <UiStack>
            <UiText textColor={'text.secondary'}>Included modules</UiText>
          </UiStack>
          <UiSimpleGrid columns={{ base: 2, '2xl': 3 }} spacing={8}>
            {isProductIncluded(subscription, SubscriptionProduct.Registration) && (
              <Registration isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.EventWebsite) && (
              <ContentDeliveryPlatform isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.Webinar) && (
              <Webinar isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.SponsorExhibitor) && (
              <SponsorExhibitor isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.LiveStreaming) && (
              <LiveStreaming isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.DataInsight) && (
              <DataInsight isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.MobileEventApp) && (
              <MobileEventApp isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.AbstractManagement) && (
              <AbstractManagement isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
            {isProductIncluded(subscription, SubscriptionProduct.DigitalPoster) && (
              <DigitalPoster isSelected={true} bgColor={'#fff'} px={0} py={0}/>
            )}
          </UiSimpleGrid>
        </UiStack>
      )}
      <UiStack p={8} pb={12} spacing={4}>
        <UiText textColor={'text.secondary'}>Add-ons ({getAddonsEnabled(subscription).length})</UiText>
        <UiSimpleGrid columns={{ base: 2, '2xl': 3 }} spacing={8}>
          {isServicePackEnabled(subscription) && (
            <ServicePack
              usedHours={subscription.plan.servicePack.usedHours}
              hours={subscription.plan.servicePack.hours}
              p={6}
              {...addonStyles}
            />
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.AbstractManagement) && (
            <AddonAbstractManagement {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.DigitalPoster) && (
            <AddonDigitalPoster {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.MobileEventApp) && (
            <AddonEventApp {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.AppWayfinder) && (
            <AddonAppWayfinder {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.ContentHub) && (
            <AddonContentHub {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.MultiLingualSupport) && (
            <AddonMultiLingualSupport {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.LiveTranslation) && (
            <AddonLiveTranslation {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.AILiveCaptioning) && (
            <AddonAILiveCaptioning {...addonStyles}/>
          )}
          {isAddonEnabled(subscription, SubscriptionProduct.Integration) && (
            <AddonIntegration {...addonStyles}/>
          )}
        </UiSimpleGrid>
        {getAddonsEnabled(subscription).length === 0 && !isServicePackEnabled(subscription) && (
          <BaseMessageBarInfo {...addonStyles} borderRadius={uiStyles.borderRadius} bgColor={'#fff'}>No add-on added.</BaseMessageBarInfo>
        )}
      </UiStack>
    </BaseBlockList>
  );
};

export default PlanInformation;
