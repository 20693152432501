import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CodeCard from './CodeCard';
import { UiStack } from '@/lib/ui';
import { type DiscountCode } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseBlockList from '@/base/BlockList';

export interface CodeListProps {
  // queryParams: registration.EventListQueryRequest;
}

const CodeList: FC<CodeListProps> = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const discountCodeQuery = useQuery<DiscountCode[], Error>({
    queryKey: [registration.discountCodeQueryKey, { eventId }],
    queryFn: async () => {
      const result = await registration.loadDiscountCodes(createTenantAdminApiRequest)({ eventId });
      return result.items?.[0];
    },
    enabled: !isApiPreparing
  });

  if (tenant) {
    return (
      <UiStack
        spacing={4}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <QueryContainer query={discountCodeQuery}>
          {(data) => (
            <BaseBlockList>
              {data.map((discountCode) => (
                <CodeCard
                  key={discountCode.id}
                  discountCode={discountCode}
                  tenant={tenant}
                />
              ))
              }
            </BaseBlockList>
          )}
        </QueryContainer>
      </UiStack>
    );
  }
};

export default CodeList;
