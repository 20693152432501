import {
  type RouteObject
} from 'react-router-dom';
import AdminAccess from '@/account/component/AdminAccess';
import EventWebsiteGeneralLandingPage from '@/eventWebsite/general/Landing';

// All the route names should start with EventWebsite to avoid conflicts with other modules (e.g. registration).
export interface EventWebsiteRouteParams {
  EventWebsiteGeneralLanding: {
    tenantCode: string
  }
}

export type EventWebsiteRouteName = keyof EventWebsiteRouteParams;

export const eventWebsiteRoutes: Record<EventWebsiteRouteName, RouteObject> = {
  EventWebsiteGeneralLanding: {
    path: '/event-website/:tenantCode/general/landing',
    element: (<AdminAccess><EventWebsiteGeneralLandingPage /></AdminAccess>)
  },
};
