import { type ApiRequest } from '@/api/tenantClient';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { type UserSession } from '@/app/ProviderRegistrationAuth';

export function useGlobalApi() {
  const { adminAuth } = useAdminAuth();

  const createGlobalApiRequest = (): ApiRequest => {
    return {
      method: 'POST',
      endpoint: {
        path: ''
      },
    };
  };

  const createGlobalApiAclRequest = (session?: UserSession) => (): ApiRequest => {
    return {
      method: 'POST',
      endpoint: {
        path: ''
      },
      headers: {
        'access-token': session?.authToken ?? adminAuth.accessToken,
      }
    };
  };

  const createGlobalApiAclRequestByAuthToken = (accessToken: string) => (): ApiRequest => {
    return {
      method: 'POST',
      endpoint: {
        path: ''
      },
      headers: {
        'access-token': accessToken,
      }
    };
  };

  return {
    createGlobalApiRequest,
    createGlobalApiAclRequest,
    createGlobalApiAclRequestByAuthToken,
  }
}
