import { type FC } from 'react';
import {
  UiButton,
  UiIconCaretDown,
  UiMenu,
  UiMenuButton,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup, UiStack, uiStyles,
} from '@/lib/ui';

export interface AddonMonthsMenuProps {
  months: number
  onMonthsChange: (months: number) => void
}
const AddonMonthsMenu: FC<AddonMonthsMenuProps> = ({
  months,
  onMonthsChange,
}) => {
  return (
    <UiMenu>
      <UiMenuButton
        as={UiButton}
        borderRadius={uiStyles.formElementBorderRadius}
        rightIcon={<UiIconCaretDown color={'gray.800'} />}
        color={'text.primary'}
        bgColor={'#fff'}
        borderWidth={'1px'}
        borderColor={'gray.800'}
        borderStyle={'solid'}
        minW={240}
        maxW={240}
        textAlign={'left'}
        _expanded={{
          bgColor: 'primary.100'
        }}
      >
        {months} months
      </UiMenuButton>
      <UiMenuList zIndex={999}>
        <UiMenuOptionGroup
          value={`${months}`}
          onChange={(val) => onMonthsChange(parseInt(`${val}`, 10))}
        >
          <UiMenuItemOption value={'3'}>3 months</UiMenuItemOption>
          <UiMenuItemOption value={'6'}>6 months</UiMenuItemOption>
          <UiMenuItemOption value={'9'}>9 months</UiMenuItemOption>
          <UiMenuItemOption value={'12'}>12 months</UiMenuItemOption>
        </UiMenuOptionGroup>
      </UiMenuList>
    </UiMenu>
  );
};

export default AddonMonthsMenu;
