import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { type TicketType } from '@/api/constant';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useSharedStocksQuery(eventId: string, ticketType: TicketType) {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  return useQuery(
    [registration.sharedStockQueryKey, eventId, ticketType],
    async () => {
      const result = await registration.getSharedStocks(createTenantAdminApiRequest)(eventId, ticketType);
      return result.items;
    },
    { enabled: !isApiPreparing }
  );
}
