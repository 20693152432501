export const menuWidth = 280;
export const smartWindowVisibleWidth = 400;
// export const smartWindowWidth = 400;
export const topNavHeight = 64;
export const bodyPadding = 0;
export const topBg = 36;
// export const topOffset = 160;

export interface WindowStates {
  isMenuVisible: boolean
  isSmartWindowVisible: boolean
}

export const getMainWindowOffset = (windowStates: WindowStates): number => {
  let offset = 0;
  if (!windowStates.isMenuVisible) {
    offset += menuWidth;
  }
  if (!windowStates.isSmartWindowVisible) {
    offset += smartWindowVisibleWidth;
  }
  return offset;
};
