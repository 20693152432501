import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { transferRegistration, TransferRegistrationRequest, TransferRegistrationResponse } from '@/api/admin/registration';
import { orderIdQueryKey, registrationIdQueryKey, registrationTableQueryKey, type RegistrationInfo } from '@/api/registration';
import { ApiResponseSingle } from '@/api/tenantClient';
import BaseFormInputField from '@/base/Form/InputField';
import { BaseMessageBarError } from '@/base/MessageBar/Error';
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  UiHStack,
  UiStack,
  type UiDrawerProps, uiStyles, UiText,
} from '@/lib/ui';
import { EMAIL_REGEX } from '@/lib/util/regex';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useCallback, useState, type FC } from 'react';
import * as Yup from 'yup';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface MigrateRegistrationDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  registration: RegistrationInfo
}

export interface FormData {
  registrationId: number
  email: string
  firstName: string
  lastName: string
}

const formSchema = Yup.object().shape({
  email: Yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Email is required'),
  firstName: Yup.string()
    .required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
});

const MigrateRegistrationDrawer: FC<MigrateRegistrationDrawerProps> = ({
  isOpen,
  onClose,
  registration
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<ApiResponseSingle<TransferRegistrationResponse>, Error, TransferRegistrationRequest>({
    mutationFn: async (data: TransferRegistrationRequest) => {
      return await transferRegistration(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        void queryClient.invalidateQueries({ queryKey: [registrationIdQueryKey, { id: registration.id }] });
        void queryClient.invalidateQueries({ queryKey: [registrationTableQueryKey] });
        void queryClient.invalidateQueries({ queryKey: [orderIdQueryKey, { id: result.item.id }] });
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to transfer the registration.']);
    }
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    mutate({
      eventId: Number(registration.event.id),
      registrationId: registration.id,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName
    });
    setSubmitting(false);
  }, []);

  return (
    <UiDrawer
      placement={'right'}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <Formik<FormData>
            initialValues={{
              registrationId: registration.id,
              email: registration.email,
              firstName: registration.firstName,
              lastName: registration.lastName
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <UiStack flexGrow={1} spacing={8}>
                {errors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1}>
                    {errors.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                  </UiStack>
                )}
                <UiStack spacing={4}>
                  <UiText>
                    Transfer the registration to:
                  </UiText>
                  <BaseDividerHorizontal height={0}/>
                </UiStack>
                <BaseFormInputField
                  name={'email'}
                  label={'Email'}
                />
                <BaseFormInputField
                  name={'firstName'}
                  label={'First Name'}
                />
                <BaseFormInputField
                  name={'lastName'}
                  label={'Last Name'}
                />
                <UiHStack justifyContent={'flex-end'}>
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                    Transfer
                  </UiButton>
                </UiHStack>
              </UiStack>
            </Form>
          </Formik>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default MigrateRegistrationDrawer;
