import { UiHStack, UiIconCornersOut, UiStack, uiStyles, UiText } from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  type ChartDataset
} from 'chart.js';
import React, { type FC } from 'react';
import { Line } from 'react-chartjs-2';
import FullScreen from '../Modal/FullScreen';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement
);

interface StatisticChartProps {
  datasets: Array<ChartDataset<'line', number[]>>
  labels: string[]
  totalRegistration?: number
}

const LineChart: FC<StatisticChartProps> = ({ datasets, labels, totalRegistration }: StatisticChartProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const chartOptions = {
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          precision: 0,
        }
      },
    },
  };

  return (
    <>
      <UiHStack justifyContent={'space-between'} p={2}>
        <UiText variant={'body2'} color={'text.secondary'}>Total: {totalRegistration}</UiText>
        <UiStack onClick={onOpen} {...uiStyles.hover}>
          <UiIconCornersOut color={'primary.500'}/>
        </UiStack>
      </UiHStack>
      <Line options={chartOptions} data={{ labels, datasets }} />
      <FullScreen isOpen={isOpen} onClose={onClose}>
        <UiStack p={8}>
          <UiStack p={8} bgColor={'#fff'} borderRadius={uiStyles.borderRadius} shadow={'base'}>
            <Line options={chartOptions} data={{ labels, datasets }} />
          </UiStack>
        </UiStack>
      </FullScreen>
    </>
  );
};

export default LineChart;
