import { type FC } from 'react';
import {
  UiStack,
  UiIconClipboardText,
  UiText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface PrivacyProps {
  isActive?: boolean
}

const Privacy: FC<PrivacyProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconClipboardText}
      description={'Privacy'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventPrivacy', { tenantCode, eventId })}
      isSet={eventSettings?.privacy?.isSet}
      // stepNumber={3}
    >
      <UiStack spacing={0}>
        {!!eventSettings?.privacy.privacyPolicyUrl && (
          <UiText variant={'body2'} color={'text.secondary'}>Privacy policy url provided</UiText>
        )}
        {/* <UiText variant={'body2'} color={'text.secondary'}>23 Fields</UiText> */}
      </UiStack>
    </Step>
  );
};

export default Privacy;
