import { FC } from 'react';
import {
  Timer,
} from "@phosphor-icons/react";
import { UiIcon, UiIconProps } from "./UiIcon";

export type UiIconTimerProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconTimer: FC<UiIconTimerProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Timer}
      {...props}
    />
  );
}
