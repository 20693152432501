import { type FC } from 'react';
import { FormLabel, type FormLabelProps } from '@chakra-ui/react';

// export type UiFormLabelProps = FormLabelProps;
// export const UiFormLabel = FormLabel;

export interface UiFormLabelProps extends FormLabelProps {

}

export const UiFormLabel: FC<UiFormLabelProps> = (props) => {
  return (
    <FormLabel
      fontSize={'md'}
      color={'text.secondary'}
      // as={'legend'}
      {...props}
    />
  );
};
