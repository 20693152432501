import { type FC, type ReactNode, useMemo, type PropsWithChildren } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles,
  UiImage
} from '@/lib/ui';
import Header from './Header';
import { getAppConfig } from '@/app/config';

export interface StoreLayoutProps extends PropsWithChildren {
  showHeader?: boolean
  children: ReactNode
}

const StoreLayout: FC<StoreLayoutProps> = ({
  showHeader = true,
  children
}) => {

  const app = getAppConfig();

  return (
    <UiStack
      // p={{base: 4, md: 8}}
      minHeight={'100vh'}
      alignItems={'stretch'}
      bgColor={'gray.100'}
      borderRadius={uiStyles.borderRadius}
      spacing={0}
      flexGrow={1}
    >
      {children}
    </UiStack>
  );
};

export default StoreLayout;
