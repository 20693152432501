import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import SmartWindow from '@/base/Layout/SmartWindow';
import SideNav from '@/account/component/Layout/SideNav';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { UiBox, UiHStack, UiStack } from '@/lib/ui';
import ModuleList from './Modulelist';

const Modules: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // sideMenu={(<SideNav activeNodes={['dashboard', 'modules']} />)}
        sideMenu={undefined}
        appSwitch={(<UiHStack flexGrow={1}/>)}
      >
        <BaseLayoutBody
          isStandalone={true}
        >
          <ModuleList/>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Modules;
