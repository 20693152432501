import { type FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  type UiBoxProps,
  UiHStack,
  UiIconArrowRight,
  UiIconGear,
  UiLink,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseDigitalPosterCard from '@/base/App/DigitalPosterCard';
import BaseLiveStreamingCard from '@/base/App/LiveStreamingCard';
import BaseSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import BaseWebinarCard from '@/base/App/WebinarCard';
import BaseIntegrationCard from '@/base/App/IntegrationCard';
import BaseContentHubCard from '@/base/App/ContentHubCard';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { isProductEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import ModuleCard from './ModuleCard';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import BaseAclContainer from '@/account/component/AclContainer';
import { AdminUserType } from '@/api/constant/adminUser';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import AAAContainer from 'src/account/component/AAAContainer';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

// export interface Apps {
//   account: number;
//   registration: number;
//   abstract: number;
// }

// const apps: Apps = {
//   account: 1,
//   registration: 2,
//   abstract: 3,
// };

// export type AppName = keyof Apps;

export type AppName = 'account' | 'registration' | 'abstract' | 'eventWebsite' | 'speakerTools' | 'integrations' | 'contentHub' | 'eventApp' | 'digitalPoster' | 'sponsorExhibitor' | 'liveStreaming' | 'webinar';

export interface ModuleListProps extends UiBoxProps {
}

const ModuleList: FC<ModuleListProps> = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, error } = useQuery<account.SubscriptionLoadResponse, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (!data || !data.subscription) {
    return (
      <UiStack py={8}>
        <BaseMessageBarError>
          Failed to load your current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  if (!tenant?.code) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  const tenantCode = tenant.code;
  return (
    <UiStack spacing={0} justifyContent={'space-between'} alignItems={'stretch'}>
      <UiStack spacing={6}>
        <AAAContainer>
          <UiSimpleGrid columns={{base: 2, 'xl': 3, '2xl': 4}} gap={8}>
            <UiStack flexGrow={1} alignItems={'stretch'} shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius}>
              <UiLink href={generatePageUrl('AAAManageTenant')}>
                <UiHStack
                  py={4}
                  px={8}
                  borderRadius={uiStyles.borderRadius}
                  bgColor={'#fff'}
                  justifyContent={'space-between'}
                  // shadow={uiStyles.cardShadow}
                  {...uiStyles.hover}
                >
                  <UiText fontWeight={600}>Internal staff panel</UiText>
                  <UiIconArrowRight size={'2xl'} color={'primary.500'} />
                </UiHStack>
              </UiLink>
            </UiStack>
          </UiSimpleGrid>
          <BaseDividerHorizontal height={2}/>
        </AAAContainer>
        <UiHStack justifyContent={'flex-start'} spacing={4} alignItems={'center'}>
          <UiText variant={'title'}>Modules</UiText>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.Account, PermissionAction.BillAdmin]]}>
            {/*<BaseDividerHorizontal height={0} />*/}
            <BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode })}>
              <UiHStack justifyContent={'flex-end'} spacing={2} {...uiStyles.hover}>
                <UiIconGear size={'2xl'} color={'primary.500'}/>
                <UiText color={'primary.500'}>Manage</UiText>
              </UiHStack>
            </BaseRouterLink>
          </BaseAclContainer>
        </UiHStack>
        {/*<BaseDividerHorizontal height={4}/>*/}
        <UiSimpleGrid columns={{base: 2, 'xl': 3, '2xl': 4}} gap={8}>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.Account, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseAppAccountCard />)}
              isEnabled={true}
              navLink={generatePageUrl('AccountSetupEvent', { tenantCode })}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseAppRegistrationCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.Registration)}
              navLink={generatePageUrl('RegistrationManageEvent', { tenantCode })}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.EventWebsite, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseEventWebsiteCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.EventWebsite)}
              navLink={'/admin/events?event_active=true'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.DigitalPoster, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseDigitalPosterCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.DigitalPoster)}
              navLink={'/users/sso/auth/new'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.LiveStream, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseLiveStreamingCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.LiveStreaming)}
              navLink={'/admin/livestreams'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.SponsorExhibitor, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseSponsorExhibitorCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.SponsorExhibitor)}
              navLink={'/admin/dashboard/exhibition'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.Webinar, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseWebinarCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.Webinar)}
              navLink={'/admin/event-tools'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.AbstractManagement, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseAbstractCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.AbstractManagement)}
              navLink={'/users/sso/auth/new'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.EventApp, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseEventAppCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.MobileEventApp)}
              // navLink={generatePageUrl('MobileAppGeneralLanding', { tenantCode })}
              navLink={'/admin/events'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.ContentHub, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseContentHubCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.ContentHub)}
              navLink={'/admin/dashboard/content'}
              isExternal={true}
            />
          </BaseAclContainer>
          <BaseAclContainer tenantId={tenant.id ?? 0} permissionsRequired={[[PermissionDomain.Integration, PermissionAction.Any]]}>
            <ModuleCard
              tenant={tenant}
              module={(<BaseIntegrationCard />)}
              isEnabled={isProductEnabled(data.subscription, SubscriptionProduct.Integration)}
              isExternal={true}
              // navLink={generatePageUrl('MobileAppGeneralLanding', { tenantCode })}
            />
          </BaseAclContainer>
        </UiSimpleGrid>
      </UiStack>
    </UiStack>
  );
};

export default ModuleList;
