import { type FC } from 'react';
import {
  DotsNine
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconDotsNineProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconDotsNine: FC<UiIconDotsNineProps> = (props) => {
  return (
    <UiIcon
      IconComponent={DotsNine}
      {...props}
    />
  );
};
