import { type FC, useCallback, useState } from 'react';
import { useSubscriptionPlanEditor } from '@/account/hook/useSubscriptionPlanEditor';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps, UiGrid, UiButton,
} from '@/lib/ui';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import PlanEditor from '@/account/component/Subscription/PlanEditor';
import { account } from '@/api';
import { useWindowSize } from '@/lib/hook';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface DrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  subscription: account.Subscription
  tenantId: number
}

const EditSubscriptionDrawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  subscription,
  tenantId,
}) => {
  const subscriptionEditor = useSubscriptionPlanEditor(subscription);
  const { innerHeight: windowInnerHeight } = useWindowSize();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();
  const { mutate, isLoading } = useMutation<account.SubscriptionSaveResponse, Error, account.SubscriptionSaveRequest>({
    mutationFn: async (data: account.SubscriptionSaveRequest) => {
      console.log('----save', data);

      return account.saveSubscription(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        // onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({queryKey: [account.subscriptionQueryKey]});
        void queryClient.invalidateQueries({queryKey: [account.subscriptionNextTermQueryKey]});
        void queryClient.invalidateQueries({queryKey: [account.tenantCurrentSubscriptionQueryKey]});
        onClose();
        // toPlan(); // This is required to 'reset' the 'subscriptionEditor' to update the plan editor.
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to save the subscription.']);
    }
  });
  const onSave = useCallback(
    () => {
      if (!subscription.plan.from) {
        setSaveErrors(['Please provide the start date.']);
        return;
      }

      // console.log(subscription);
      mutate({
        tenantId,
        subscription,
      });
    },
    [
      subscription,
      tenantId,
    ]
  );

  return (
    <UiDrawer
      placement={'bottom'}
      size={'full'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody bgColor={'gray.100'}>
          {/*<UiStack alignItems={'stretch'} p={8}>*/}
          {/*  */}
          {/*</UiStack>*/}
          {/*<UiGrid templateColumns={'3fr 1fr'} gap={8}>*/}
          {/*  <UiStack overflowY={'scroll'} maxHeight={`${innerHeight - 80}px`}>*/}
          {/*    <PlanEditor subscriptionEditor={subscriptionEditor} />*/}
          {/*  </UiStack>*/}
          {/*  <UiStack></UiStack>*/}
          {/*</UiGrid>*/}
          <PlanEditor subscriptionEditor={subscriptionEditor} mode={'AAA'}/>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} p={8} pt={0}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <UiStack justifyContent={'flex-start'} alignItems={'flex-end'} px={8} pb={16}>
            {isLoading ? (
              <UiButton size={'md'} colorScheme={'green'} py={2}>
                Enabling...
              </UiButton>
            ) : (
              <UiButton size={'md'} colorScheme={'primary'} py={2} onClick={onSave}>
                Save
              </UiButton>
            )}
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default EditSubscriptionDrawer;
