import { type FC, useMemo } from 'react';
import { type UiBoxProps, UiHStack, UiStack, UiTag } from '@/lib/ui';
import { SubscriptionBillState, subscriptionBillStateLabels } from '@/api/constant/subscriptionBill';

export interface BillStatusProps extends UiBoxProps {
  billState: SubscriptionBillState
}

function getStatusDisplay(billState: SubscriptionBillState) {
  if (billState === SubscriptionBillState.Issued) {
    return {
      color: 'yellow',
      text: subscriptionBillStateLabels[SubscriptionBillState.Issued],
    };
  }
  if (billState === SubscriptionBillState.PendingPayment) {
    return {
      color: 'purple',
      text: subscriptionBillStateLabels[SubscriptionBillState.PendingPayment],
    };
  }
  if (billState === SubscriptionBillState.Overdue) {
    return {
      color: 'red',
      text: subscriptionBillStateLabels[SubscriptionBillState.Overdue],
    };
  }
  if (billState === SubscriptionBillState.Paid) {
    return {
      color: 'green',
      text: subscriptionBillStateLabels[SubscriptionBillState.Paid],
    };
  }
  return null;
}

const BillStatus: FC<BillStatusProps> = ({
  billState
}) => {
  const display = useMemo(
    () => {
      return getStatusDisplay(billState);
    },
    [billState]
  );

  if (display) {
    return (
      <UiHStack justifyContent={'flex-start'}>
        <UiTag colorScheme={display.color} size={'md'}>{display.text}</UiTag>
      </UiHStack>
    );
  }
  return null;
};

export default BillStatus;
