import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { type PaymentOption } from '@/api/registration';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import {
  UiHStack,
  UiIconGear,
  UiStack,
  UiTag,
  UiText,
  type UiHStackProps
} from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useDisclosure } from '@chakra-ui/react';
import { useMemo, type FC } from 'react';
import AboutInvoice from './AboutInvoice';
import InvoiceForm from './InvoiceForm';

export interface InvoiceProps extends UiHStackProps {
  paymentOptions: PaymentOption[]
}

const Invoice: FC<InvoiceProps> = ({ paymentOptions, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { tenant } = useRegisterRoute();
  const { isOpen: isAboutOpen, onToggle: onAboutToggle } = useDisclosure();
  const invoicePayment = useMemo(
    () => paymentOptions.find((option) => (option.name === 'invoice'))
    , [paymentOptions]);
  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'stretch'}
        spacing={4}
        p={6}
        bgColor={'#fff'}
        {...props}
        // borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'}>Invoice payments</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>Set up your bank account details</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          { invoicePayment && invoicePayment.enabled
            ? <UiTag py={2} px={4} colorScheme={'green'}>Enabled</UiTag>
            : <UiTag py={2} px={4} colorScheme={'red'}>Not enabled</UiTag>}
          {/* <UiStack {...uiStyles.hover} onClick={onAboutToggle}> */}
          {/*  <UiIconInfo size={'3xl'} color={'primary.500'} /> */}
          {/* </UiStack> */}
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiHStack
              spacing={0}
              justifyContent={'space-between'}
              flexDirection={'column'}
            >
              <BasePlainIcon
                label={'Settings'}
                Icon={UiIconGear}
                onClick={onToggle}
              />
            </UiHStack>
          </BaseAclContainer>
        </UiHStack>
      </UiHStack>
      <InvoiceForm
        onClose={onToggle}
        isVisible={isOpen}
        invoicePayment={invoicePayment}
        onSaveSuccess={() => {}}
        tenant={tenant}
      />
      <AboutInvoice
        onClose={onAboutToggle}
        isOpen={isAboutOpen}
      />
    </>
  );
};

export default Invoice;
