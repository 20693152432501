import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { UiStack } from '@/lib/ui';
import { useState, type FC } from 'react';
// import Filter from './Filter';
import SideNav from '@/account/component/Layout/SideNav';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import SmartWindow from '@/base/Layout/SmartWindow';
import EventList from './EventList';
import NewEventRow from './NewEventRow';
import BaseSearchBar from '@/base/SearchBar';

const Event: FC = () => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [name, setName] = useState('');
  const searchName = () => {
    setSearchKey(name);
  };

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<SideNav activeNodes={['setup', 'event']} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false} />} currentAppName={'account'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'}>
            <NewEventRow />
            <UiStack spacing={3} marginBottom={'10px'}>
              <BaseSearchBar
                placeHolder={'Search name'}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                enableOnClear={false}
                onSearch={searchName}
                flexGrow={1}
              />
            </UiStack>
            <EventList queryParams={{}} searchKey={searchKey} />
            {/* <RecentEvents queryParams={{tenantCode,}}/> */}
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
      {/* <Filter
        isOpen={showFilter}
        onClose={() => {setShowFilter(false)}}
      /> */}
    </>
  );
};

export default Event;
