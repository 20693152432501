/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useCallback, useEffect, useState } from 'react';
import {
  UiBox,
  UiHStack, UiSpinner,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import LoginSmartWindow from '@/account/component/Layout/LoginSmartWindow';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';
import BaseAccountTenantList from '@/base/AccountTenantList';
import AdminUser from './AdminUser';
import { AdminUserSessionState } from '@/api/constant';
import { useNavigate } from 'react-router-dom';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const ChooseTenant: FC = () => {
  const { adminAuth, isLoading } = useAdminAuth();
  const navigate = useNavigate();
  const toVerifyPasscode = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginVerifyEmailPasscode'));
    },
    [navigate, generatePageUrl]
  );

  // Ask the user to do MFA if the current session requires it.
  useEffect(() => {
    if (adminAuth.adminUserSessionState === AdminUserSessionState.PendingMfa) {
      toVerifyPasscode();
    }
  }, [
    toVerifyPasscode,
    adminAuth.adminUserSessionState
  ]);

  return (
    <BaseLayout
      smartWindow={(<LoginSmartWindow />)}
      sideMenu={null}
      smartButton={(<UiBox/>)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        {isLoading ? (
          <UiStack p={8}>
            <BaseLoadingSpinner/>
          </UiStack>
        ) : (
          <UiStack
            spacing={4}
            alignItems={'stretch'}
            flexGrow={1}
          >
            <UiHStack
              flexGrow={1}
              alignItems={'stretch'}
              spacing={0}
            >
              <UiStack
                alignItems={'center'}
                justifyContent={'center'}
                flexGrow={1}
                borderRightRadius={uiStyles.borderRadius}
                spacing={8}
                px={8}
              >
                <UiStack
                  alignItems={'stretch'}
                  minW={600}
                  maxW={800}
                  spacing={4}
                >
                  {/*<UiText variant={'title'}>*/}
                  {/*  Welcome back to evexus!*/}
                  {/*</UiText>*/}
                  <AdminUser/>
                  <UiStack height={2}/>
                  <BaseAccountTenantList/>
                </UiStack>
              </UiStack>
            </UiHStack>
          </UiStack>
        )}
      </LoginBody>
    </BaseLayout>
  );
};

export default ChooseTenant;
