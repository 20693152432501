import { type FC } from 'react';
import {
  House
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconHouseProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconHouse: FC<UiIconHouseProps> = (props) => {
  return (
    <UiIcon
      IconComponent={House}
      {...props}
    />
  );
};
