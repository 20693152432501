/* eslint-disable react/no-unescaped-entities */
import { type FC, useRef } from 'react';
import {
  UiButton,
  type UiAlertDialogProps,
  type UiButtonProps,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerCloseButton,
  UiDrawerFooter,
  UiDrawerBody,
  UiHStack,
  UiText,
  UiStack
} from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface DeletionConfirmationProps {
  isOpen: UiAlertDialogProps['isOpen']
  onClose: UiAlertDialogProps['onClose']
  onConfirm: UiButtonProps['onClick']
  name: string
  errors: string[]
}

const DeletionConfirmation: FC<DeletionConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
  name,
  errors
}) => {
  const cancelRef = useRef(null);
  return (
    <UiDrawer placement={'bottom'} size={'lg'} isOpen={isOpen} onClose={onClose}>
      <UiDrawerOverlay />
      <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
      <UiDrawerContent p={4}>
        <UiDrawerBody>
          <UiText variant={'title'} fontWeight={400}>
            Do you confirm to delete "{name}"?
          </UiText>

          {errors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {errors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
        </UiDrawerBody>
        <UiDrawerFooter>
          <UiHStack spacing={8}>
            <UiButton ref={cancelRef} onClick={onClose} colorScheme={'gray'} px={8}>
              No
            </UiButton>
            <UiButton colorScheme={'red'} ml={4} onClick={onConfirm} px={8}>
              Yes
            </UiButton>
          </UiHStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default DeletionConfirmation;
