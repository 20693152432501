
import { type FC } from 'react';
import {
  UiStack,
  uiStyles,
  UiIconArrowSquareUpLeft,
  UiIconArrowSquareDownRight,
} from '@/lib/ui';
import { useLayout } from '@/base/Layout/LayoutProvider';

export interface FullScreenToggleProps {
}

const FullScreenToggle: FC<FullScreenToggleProps> = () => {
  const { isFullScreen, toggleFullScreen } = useLayout();

  return (
    <UiStack {...uiStyles.hover} onClick={toggleFullScreen}>
      {isFullScreen ? (
        <UiIconArrowSquareDownRight color={'brand.500'} size={'3xl'}/>
      ) : (
        <UiIconArrowSquareUpLeft color={'primary.500'} size={'3xl'}/>
      )}
    </UiStack>
  );
};

export default FullScreenToggle;
