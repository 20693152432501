import { type InfoFormResponseSaveRequest, type InfoFormResponse, saveInfoFormResponse, infoFormResponseQueryKey, type Registration, type InfoForm, RegistrationInfo, infoFormResponseTableQueryKey } from '@/api/registration';
import { Formik, Form, type FormikHelpers } from 'formik';
import { useMemo, useCallback, useState } from 'react';
import { type FC } from 'react';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton,
  UiStack,
  UiHStack
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';
import { type FieldMetadataInput } from '@/base/FormBuilder/Field';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { ApiResponse } from '@/api/tenantClient';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

interface GenericInfoFormProps {
  infoForm: InfoForm | undefined
  infoFormResponse: InfoFormResponse
  registration: RegistrationInfo
  onSuccess: () => void
}

const GenericInfoForm: FC<GenericInfoFormProps> = ({ infoForm, infoFormResponse, registration, onSuccess }) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation<ApiResponse<InfoFormResponse>, Error, InfoFormResponseSaveRequest>(
    {
      mutationFn: async (data: InfoFormResponseSaveRequest) => {
        setSaveErrors([]);
        return await saveInfoFormResponse(createTenantAdminApiRequest)(data);
      },
      onSuccess: async (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          await queryClient.invalidateQueries([infoFormResponseQueryKey, { registrationId: registration?.id, type: infoForm?.formType }]);
          await queryClient.invalidateQueries([infoFormResponseTableQueryKey]);
          onSuccess();
        }
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the info form response.']);
      }
    }
  );

  const utils = useMemo(
    () => { return createUtils((infoForm?.config?.fieldsMetadata ?? [])); },
    [infoForm?.config?.fieldsMetadata]);

  const submitForm = useCallback(async (values: FormData) => {
    if (!registration?.id || !infoForm?.formType) {
      throw new Error("Can't submit Info Form, Registration ID is not found");
    }
    mutate({
      eventId: registration.event.id.toString(),
      formType: infoForm?.formType,
      response: values,
      ownerId: registration?.id,
      infoFormId: infoForm?.id,
      ownerType: 'Registration'
    });
  }, [registration.event.id.toString(), mutate, registration?.id]);

  return (
    <Formik
      initialValues={utils.getFieldsValues(infoFormResponse.response)}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData) => {
        const errors: Errors = {};
        // This is an example to check 'required' fields. We might validator others in the future.
        for (const fieldId in values) {
          const fieldMetadata = utils.getFieldMetadata(fieldId);
          if (fieldMetadata) {
            // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
            if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
              errors[fieldId] = 'The field is required';
            }
            if ((fieldMetadata as FieldMetadataInput).length && values[fieldId]) {
              if ((values[fieldId] as string)?.length > (fieldMetadata as FieldMetadataInput).length!) {
                errors[fieldId] = `The field can not have more than ${(fieldMetadata as FieldMetadataInput).length} characters.`;
              }
            }
          }
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <BaseTransitionSlideLeft>
          <UiStack flexGrow={1}>
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <FormGenerator
              fieldsLayout={infoForm?.config.fieldsLayout}
              fieldsMetadata={infoForm?.config.fieldsMetadata}
            />
          </UiStack>
        </BaseTransitionSlideLeft>
        <UiStack height={20} />
        <UiHStack justifyContent={'flex-end'} flexGrow={1}>
          <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading} >
            Save
          </UiButton>
        </UiHStack>
      </Form>
    </Formik>
  );
};

export default GenericInfoForm;
