import { type FC, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { type Options } from 'react-select';
import {
  UiButton,
  type UiHStackProps,
  UiStack,
  UiText,
  uiStyles, UiBox, UiIconEye, UiIconEyeSlash,
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import { TeamState, teamStateOptions } from '@/api/constant/team';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import type { AttendeeCategoryData, AttendeeCategorySaveRequest } from '@/api/registration';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useDisclosure } from '@chakra-ui/react';

export interface PasswordFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  password: string
}

interface Errors {
  password?: string
}

const initFormData = {
  password: '',
};

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,}$/, 'Password invalid'),
});

const PasswordForm: FC<PasswordFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { isOpen: isPasswordVisible, onToggle: onTogglePasswordVisibility } = useDisclosure();

  const { mutate, isLoading } = useMutation<account.AdminUserUpdatePasswordResponse, Error, account.AdminUserUpdatePasswordRequest>({
    mutationFn: async (data: account.AdminUserUpdatePasswordRequest) => {
      return await account.updatePassword(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        setFormData(initFormData);
        onSaveSuccess();
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to update the password.']);
    }
  });

  const submitForm = async (values: FormData) => {
    void mutate({
      password: values.password,
    });
  };

  return (
    <Formik
      initialValues={formData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Set the password'}
        size={'lg'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'password'}
            label={'Password'}
            type={isPasswordVisible ? 'text' : 'password'}
            helperText={'Password needs to include at least one number, one special character (!@#$%^&*) and no less than 12 digits.'}
            rightElement={(
              <UiBox {...uiStyles.hover} onClick={onTogglePasswordVisibility}>
                {isPasswordVisible ? (
                  <UiIconEye color={'primary.500'}/>
                ) : (
                  <UiIconEyeSlash color={'primary.500'}/>
                )}
              </UiBox>
            )}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default PasswordForm;
