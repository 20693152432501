import { useEffect, type FC, useState } from 'react';
import DiscountRulesForm, { type TicketOption, type DiscountRuleOption } from '@/registration/component/BuildEvent/DiscountRulesForm';
import {
  type UiHStackProps
} from '@/lib/ui';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { getProductType, type TicketDiscountOption } from '@/api/registration';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { type TicketType, discountTypeOptions } from '@/api/constant';
import { useTenantApi } from '@/account/hook/useTenantApi';

interface FormData {
  types: number[]
  amounts: number[]
}
export interface DiscountsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  initFormData?: FormData
  ticketId: number
  ticketType: TicketType
}

export const mapTicketDiscountToDiscountRuleOption = (ticketDiscount: TicketDiscountOption): DiscountRuleOption => {
  return {
    id: ticketDiscount.id ?? uuidv4(),
    discountCodeId: ticketDiscount.discountId,
    discountCodeName: ticketDiscount.discount.name,
    discountCodeDescription: ticketDiscount.discount.description,
    discountCodeType: ticketDiscount.discountType ? discountTypeOptions.find((option) => option.value === Number(ticketDiscount.discountType)) : undefined,
    discountCodeAmount: ticketDiscount.amount,
    discountCodeActive: ticketDiscount.active ?? undefined
  };
};

export const useLoadDiscountOptions = (eventId: string, ticketId: number, ticketType: TicketType) => {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data: ticketDiscountOptionData = [], isLoading } = useQuery<registration.TicketDiscountOption[], Error>(
    [registration.ticketDiscountOptionQueryKey, { eventId, ticketId, ticketType }],
    async () => {
      const response = await registration.loadTicketDiscountOptions(createTenantAdminApiRequest)({ eventId, ticketId, ticketType: getProductType(ticketType) });
      return (response.items as unknown) as registration.TicketDiscountOption[];
    }
  );

  const options: DiscountRuleOption[] = ticketDiscountOptionData.map(mapTicketDiscountToDiscountRuleOption);

  return { options, isLoading };
};

const DiscountsForm: FC<DiscountsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  ticketId,
  ticketType
}) => {
  const { eventId } = useRegisterRoute();
  const [ticketOptions, setTicketOptions] = useState<TicketOption[]>([]);
  const [loadingTicketOptions, setLoadingTicketOptions] = useState(true);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const { data: ticketData = [], isLoading: isTicketsLoading } = useQuery<registration.TicketListData, Error>(
    [registration.ticketListQueryKey, { eventId, ticketType }],
    async () => {
      const response = await registration.loadTicketList(createTenantAdminApiRequest)({ eventId, ticketType });
      return response.items;
    },
    {
      enabled: !isApiPreparing
    }
  );

  const { options: discountOptions, isLoading: loadingDiscountOptions } = useLoadDiscountOptions(eventId, ticketId, ticketType);

  useEffect(() => {
    if (!isTicketsLoading) {
      const options: TicketOption[] = ticketData.map((ticketDataItem: registration.TicketData) => ({
        value: ticketDataItem.id,
        label: ticketDataItem.name
      }));
      setTicketOptions(options);
      setLoadingTicketOptions(false);
    }
  }, [isTicketsLoading, ticketData]);

  return (
    <>
      {!loadingDiscountOptions && !loadingTicketOptions && (
        <DiscountRulesForm
          onClose={onClose}
          onSave={onSaveSuccess}
          isVisible={isVisible}
          discountOptions={discountOptions}
          ticketOptions={ticketOptions}
          ticketId={ticketId}
          ticketType={ticketType}
        />)}
    </>
  );
};

export default DiscountsForm;
