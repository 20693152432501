import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconDeviceMobile } from '@/lib/ui';

export interface EventAppCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
  showDescription?: boolean
}

const EventAppCard: FC<EventAppCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false,
  showDescription = true
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/app.jpeg'}
      icon={(<UiIconDeviceMobile size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      name={'Event app'}
      description={showDescription ? 'Enhance onsite event experiences' : undefined}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default EventAppCard;
