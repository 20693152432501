import { type FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  uiStyles,
  UiStack,
  UiText, UiSpinner,
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { account } from '@/api';
import AdminUser from './AdminUser';
import { AdminUserType } from '@/api/constant/adminUser';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import BaseBlockList from '@/base/BlockList';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

dayjs.extend(utc);

export interface AdminUserListProps {}

const AdminUserList: FC<AdminUserListProps> = () => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { tenant } = useTenantRoute();
  const { adminAuth } = useAdminAuth();

  const { data, isLoading, error } = useQuery<account.SearchAdminUsersResponse, Error>(
    [account.adminUserSearchQueryKey, {tenantId: tenant?.id, types: [AdminUserType.Generic]}],
    async () => {
      return await account.searchAdminUsers(createGlobalApiAclRequest())({
        tenantId: tenant?.id,
        types: [AdminUserType.Generic],
        page: 1,
        orderByField: 'email',
        isOrderedAsc: true,
        limit: 999, // Let's get all the teams at once.
      });
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (!tenant) {
    return (
      <UiStack py={8}>
        <BaseMessageBarError>
          Workspace is not found.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  if (data?.adminUserList && Array.isArray(data?.adminUserList)) {
    return (
      <>
        <BaseBlockList>
          {data.adminUserList.map((adminUser) => {
            return (
              <AdminUser
                tenant={tenant}
                adminUser={adminUser}
                key={adminUser.id}
                isCurrentUser={adminAuth?.user?.id === adminUser.id}
              />
            );
          })}
        </BaseBlockList>
      </>
    );
  }
};

export default AdminUserList;
