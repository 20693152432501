import { type FC } from 'react';
import {
  CaretRight
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCaretRightProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCaretRight: FC<UiIconCaretRightProps> = (props) => {
  return (
    <UiIcon
      IconComponent={CaretRight}
      {...props}
    />
  );
};
