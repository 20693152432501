import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useThemesQuery(eventId: string) {
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();

  return useQuery(
    [registration.themesQueryKey, eventId],
    async () => {
      const result = await registration.getThemes(createTenantAdminApiRequest)(eventId);
      return result.items;
    },
    { enabled: isLoaded }
  );
}
