import { type FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './ChatInterface.css'; // Import the custom CSS
import Markdown from 'react-markdown';
import { Box, Button, Textarea } from '@chakra-ui/react';

interface Message {
  sender: string
  message: string
}

interface AIChatHistoryProps {
  messages: Message[]
  sendMessage: (message: string) => void
  handleReset: () => void
  isResetLoading: boolean
}

const AIChatInterface: FC<AIChatHistoryProps> = ({ messages, sendMessage, handleReset, isResetLoading }) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new MutationObserver(scrollToBottom);
    if (messagesContainerRef.current) {
      observer.observe(messagesContainerRef.current, { childList: true });
    }
    return () => observer.disconnect();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Force scroll to bottom on first render after first render tick
    setTimeout(scrollToBottom, 0);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newMessage.trim()) {
        sendMessage(newMessage);
        setNewMessage('');
      }
    }
  };

  return (
    <Box className="chat-container">
      <Box className="chat-title">
        {/* Title and avatar logic here */}
      </Box>
      <Box className="messages" ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <Box key={index} px={10} py={4} className={`message ${message.sender === 'user' ? 'message-personal' : ''}`}>
            <Markdown>{message.message}</Markdown>
          </Box>
        ))}
        <Box ref={messagesEndRef} />
      </Box>
      <Box className="message-box">
        <Textarea
          className="message-input"
          placeholder="Type your message here..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Button
          className="message-submit"
          onClick={() => {
            if (newMessage.trim()) {
              sendMessage(newMessage);
              setNewMessage('');
            }
          }}
        >
          Send
        </Button>
        <Button
          className="message-submit"
          onClick={handleReset}
          isLoading={isResetLoading}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default AIChatInterface;
