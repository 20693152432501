import { type FC, useCallback, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type ApiResponse } from '@/api/tenantClient';
import { type TicketType } from '@/api/constant';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  sharedStockItem?: registration.SharedStockData
  ticketType: TicketType
}

interface FormData {
  name: string
  description: string
  stock: number
  ticketType: TicketType
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  description: Yup.string()
    .nullable(),
  stock: Yup.number()
    .required('Capacity is required.'),
});

const SharedStockForm: FC<LabelsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  sharedStockItem,
  ticketType
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<registration.SharedStockData>, Error, registration.SharedStockSaveRequest>({
    mutationFn: async (sharedStockData: registration.SharedStockSaveRequest) => {
      setSaveErrors([]);
      return await registration.saveSharedStock(createTenantAdminApiRequest)(sharedStockData);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [registration.sharedStockQueryKey, eventId, ticketType] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the shared stock.']);
    }
  });

  const onSubmit = async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    await mutateAsync({
      id: sharedStockItem?.id,
      eventId,
      name: values.name,
      description: values.description,
      stock: values.stock,
      ticketType: values.ticketType,
    });
    !sharedStockItem && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik<FormData>
      initialValues={{
        name: sharedStockItem?.name ?? '',
        description: sharedStockItem?.description ?? '',
        stock: sharedStockItem?.stock ?? 0,
        ticketType: sharedStockItem?.ticketType ?? ticketType
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
      }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Shared capacity'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'name'}
                label={'Name'}
                isRequired={true}
                type={'text'}
              />
              <BaseFormTextareaField
                name={'description'}
                label={'Description'}
                isRequired={false}
              />
              <BaseFormInputField
                name={'stock'}
                type={'number'}
                label={'Capacity'}
                min={0}
                max={2147483647}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>
        );
      }}
    </Formik>
  );
};

export default SharedStockForm;
