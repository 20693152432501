import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from './PageRow';
import BuildingPageForm from './BuildingPageForm';

export interface ManageLabelsProps extends UiHStackProps {
}

const BuildingPage: FC<ManageLabelsProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Building page'}
        description={'Manage the message that will display to an attendee visiting the registration pages when the registration mode is set to “Building”.'}
        onEdit={onToggle}
        {...props}
      />
      <BuildingPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default BuildingPage;
