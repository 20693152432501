import { type FC } from 'react';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import {
  UiBox,
  UiSpinner,
} from '@/lib/ui';
import LogoSpinner from '@/asset/lottie/logo-loading.json';
import AnimatedBrand from '@/asset/lottie/logo-loading.json';

export type Size = 'sm' | 'md' | 'lg';

export interface SpinnerProps {
  size?: Size
}

const heights = {
  'sm': 24,
  'md': 36,
  'lg': 48,
}

const getHeight = (size: Size): number => {
  return heights.hasOwnProperty(size) ? heights[size] : 32;
}

const Spinner: FC<SpinnerProps> = ({ size = 'md' }) => {
  const _sizePx = `${getHeight(size)}px`;
  return (
    <UiBox
      width={_sizePx}
      height={_sizePx}
    >
      <LottiePlayer
        autoplay={true}
        loop={true}
        // keepLastFrame={true}
        speed={6}
        src={AnimatedBrand}
        style={{
          'height': _sizePx,
          'width': _sizePx,
        }}
      />
    </UiBox>
  );
  // return (
  //   <UiSpinner
  //     thickness='4px'
  //     speed='0.8s'
  //     emptyColor='primary.50'
  //     color='primary.300'
  //     size={size ?? 'xl'}
  //   />
  // );
};

export default Spinner;
