import { type FC } from 'react';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps
} from '@/lib/ui';

export interface AboutInvoiceProps extends Omit<UiDrawerProps, 'children'> {
}

const AboutInvoice: FC<AboutInvoiceProps> = ({
  ...props
}) => {
  return (
    <UiDrawer placement={'right'} size={'lg'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={0}>
          <UiStack alignItems={'stretch'} p={8}>
            <UiText variant={'h6'} pb={8}>About pay via invoices</UiText>
          </UiStack>
        </UiDrawerBody>
        {/* <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
          </UiStack>
        </UiDrawerFooter> */}
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default AboutInvoice;
