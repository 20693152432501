import {
  type ApiRequest,
  properCallApi,
  type ApiResponseSingle,
  type ApiResponse,
} from '@/api/tenantClient';

export const groupRegistrationQueryKey = 'groupRegistration.registration';
export const registrationTableQueryKey = 'groupRegistration.table';
export const registrationIdQueryKey = 'groupRegistration.id';
export const registrationReportKey = 'groupRegistration.statistics';
export const cancelledRegistrationTableQueryKey =
  'groupRegistration.cancelledRegistrationTable';

export interface IGroupRegistration {
  id: number
  email: string
  name: string
  registrationType: string
  status: 'Approved' | 'Pending' | 'Cancelled'
}

export interface IGroupRegistrationSummary {
  name: string
  status: 'pending' | 'completed' | 'awaiting_payment' | 'cancelled'
  email: string
  eventName: string
  createdAt: string
  attendeeCategoryName: string
  orderId?: number
}

export interface IGroupRegistrationInfo {
  registrations: IGroupRegistration[]
  summary: IGroupRegistrationSummary
}

export function loadGroupRegistrationById(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (params: {
    id: number
  }): Promise<ApiResponseSingle<IGroupRegistrationInfo>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/group_registrations/' + params.id;

    return await properCallApi<ApiResponseSingle<IGroupRegistrationInfo>>(
      request
    );
  };
}

export interface IGroupRegistrationTableRow {
  id: number
  name: string
  email: string
  status: string
  createdAt: string
  confirmDelegateTypeAt: string
}

export interface IGroupRegistrationTableQueryRequest {
  page?: number
  size?: number
  filters?: string[]
  sorting?: string[]
  search?: string
  type?: string
}

export function loadGroupRegistrationsForTable(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (
    params: IGroupRegistrationTableQueryRequest
  ): Promise<ApiResponse<IGroupRegistrationTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/group_registrations/table';
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      type: params?.type,
      ...(params.search ? { search: params.search } : {}),
    };

    return await properCallApi<ApiResponse<IGroupRegistrationTableRow>>(request);
  };
}
