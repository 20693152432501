import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useRegistrationBadgesQuery(eventId: string) {
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();

  return useQuery(
    [registration.registrationBadgeQueryKey, eventId],
    async () => {
      const result = await registration.getRegistrationBadges(createTenantAdminApiRequest)(eventId);
      return result.items;
    },
    { enabled: isLoaded }
  );
}
