import { type FC } from 'react';
import {
  Headset
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconHeadsetProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconHeadset: FC<UiIconHeadsetProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Headset}
      {...props}
    />
  );
};
