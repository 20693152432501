import { type TicketType } from '@/api/constant/ticket';
import {
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const sharedStockQueryKey = 'registration.sharedStock';

export interface SharedStockData {
  id: number
  name: string
  description: string
  stock: number
  ticketType: TicketType
  createdAt: string
  updatedAt: string
}

export interface SharedStockSaveRequest {
  id?: number
  eventId: string
  name: string
  description: string
  stock: number
  ticketType: TicketType
}

export interface SharedStockDeleteRequest {
  id: string
}

export function saveSharedStock(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: SharedStockSaveRequest): Promise<ApiResponse<SharedStockData>> => {
    const path = '/api/v1/admin/ticket_shared_stocks';
    if (params.id) {
      request.method = 'PUT';
      request.endpoint.path = `${path}/${params.id}`;
    } else {
      request.method = 'POST';
      request.endpoint.path = path;
    }

    request.payload = { ticket_shared_stock: params };
    return await properCallApi<ApiResponse<SharedStockData>>(request);
  };
}

export function getSharedStocks(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string, ticketType: TicketType): Promise<ApiResponse<SharedStockData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/ticket_shared_stocks';
    request.endpoint.query = { eventId, ticket_type: ticketType };
    return await properCallApi<ApiResponse<SharedStockData>>(request);
  };
}

export function deleteSharedStock(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: SharedStockDeleteRequest): Promise<ApiResponseSingle<{}>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/ticket_shared_stocks/${params.id}`;
    return await properCallApi<ApiResponseSingle<{}>>(request);
  };
}
