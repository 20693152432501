import { type FC } from 'react';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText, UiIconPencilSimple,
} from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import TermsAndConditionForm from './Form/TermsAndConditionForm';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

export interface TermsAndConditionsProps extends UiHStackProps {}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { eventId, tenant } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  return (
    <QueryContainer query={eventQuery}>
      {(eventSettings) => (
        <>
          <UiHStack
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            // alignItems={'flex-start'}
            // justifyContent={'flex-start'}
            spacing={0}
            minWidth={'300px'}
            p={6}
            bgColor={'#fff'}
            // borderRadius={uiStyles.borderRadius}
            {...props}
          >
            <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'} flexGrow={1}>
              <UiText variant={'body1'} fontWeight={600}>Terms and conditions url</UiText>
              <UiText variant={'body2'} color={eventSettings.privacy?.termsAndConditionsUrl ? 'text.secondary' : 'red.500'}>{eventSettings.privacy?.termsAndConditionsUrl ?? 'Not provided'}</UiText>
            </UiStack>
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <BaseButtonPlainIconEdit onClick={onToggle}/>
            </BaseAclContainer>
          </UiHStack>
          <TermsAndConditionForm
            isVisible={isOpen}
            onClose={onToggle}
            privacy={eventSettings.privacy}
          />
        </>
      )}
    </QueryContainer>

  );
};

export default TermsAndConditions;
