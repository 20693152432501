import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import AdminAccess from '@/account/component/AdminAccess';

const BuildEventLayout: FC = () => {
  return (
    <AdminAccess>
      <Outlet />
    </AdminAccess>
  );
};

export default BuildEventLayout;
