import { useState, type FC } from 'react';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiText,
  uiStyles,
  UiButton,
} from '@/lib/ui';
import { useMutation } from '@tanstack/react-query';
import { type RequestDetailsChangeRequest, requestDetailsChange } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type ApiResponseSingle } from '@/api/tenantClient';
import ConfirmCard from './ConfirmCard';

export interface AttendeeDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  registrationId: number | undefined
  orderId: number
  status: string
}

const ALLOW_REQUEST_DETAILS_CHANGE_STATUSES = ['Approved', 'Paid'];

const AttendeeDrawer: FC<AttendeeDrawerProps> = ({
  isOpen,
  onClose,
  registrationId,
  orderId,
  status
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate: requestDetailsChangeMutate, isLoading: isRequestDetailsChangeMutationLoading } = useMutation<ApiResponseSingle<void>, Error, RequestDetailsChangeRequest>({
    mutationFn: async (request: RequestDetailsChangeRequest) => {
      setErrorMessage('');
      return await requestDetailsChange(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data: ApiResponseSingle<void>) => {
      if (data?.errors && (data?.errors?.length ?? 0) > 0) {
        setErrorMessage(data.errors.join(', '));
      } else {
        onClose();
      }
    },
    onError: (error: Error) => {
      reportToGlobal(error);
      setErrorMessage(`Failed to send the email (${error.message ?? ''}).`);
    }
  });

  const handleRequestDetailsChange = () => {
    void requestDetailsChangeMutate({ registrationId: registrationId! });
  };
  return (
    <UiDrawer
      placement={'right'}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <UiStack spacing={4}>
            {!!errorMessage && (
              <BaseMessageBarError borderRadius={uiStyles.borderRadius}>
                {errorMessage}
              </BaseMessageBarError>
            )}
            {(ALLOW_REQUEST_DETAILS_CHANGE_STATUSES.includes(status)) && registrationId && <UiStack
              alignItems={'flex-end'}
              justifyContent={'space-between'}
              p={8}
              bgColor={'#fff'}
              spacing={4}
              borderRadius={uiStyles.borderRadius}
              {...uiStyles.hover}
            >
              <UiStack spacing={4} justifyContent={'space-between'} flexGrow={1} enableDivider={true}>
                <UiText variant={'title'}>Attendee's information update</UiText>
                <UiText variant={'body1'}>Send an email with a link to the attendee that the attendee can click to update both of the personal information and additional information (if available).</UiText>
              </UiStack>
              <UiButton
                onClick={handleRequestDetailsChange}
                isLoading={isRequestDetailsChangeMutationLoading}
              >
                Send
              </UiButton>
            </UiStack>}
            <ConfirmCard
              orderId={orderId}
              onClose={onClose}
              title='Resend registration confirmation email'
              body={''}
              // body='Add any other emails you want to send it to. Simply type the email and click the Enter/Return key or the "Add" button to add one by one.'
              buttonLabel='Send'
            />
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default AttendeeDrawer;
