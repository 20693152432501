import { type FC, useEffect, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiButton, UiHStack, UiIconPaperPlaneTilt, UiIconPencilSimple, UiStack } from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type ManageRouteParams } from '@/registration/pages';
import OrderSummary from '@/registration/manage/OrderInfo/OrderSummary';
import Tickets from './Tickets';
import { useQuery } from '@tanstack/react-query';
import { loadOrderById, type OrderById, orderIdQueryKey } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useDisclosure } from '@chakra-ui/react';
import OrderRefundDrawer from './OrderRefundDrawer';
import RefundsSection from './RefundsSection';
import AdminNotes from '@/base/App/AdminNotes';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

const OrderInfo: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const params = useParams<ManageRouteParams['RegistrationManageOrderInfo']>();
  const { reportToGlobal } = useApiErrorHandler();
  const orderId = useMemo<number | null>(
    () => { return params.orderId ? parseInt(params.orderId, 10) : null; },
    [params.orderId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Orders', url: generatePageUrl('RegistrationManageOrder', { tenantCode }) },
        /**
         * @todo orderId here should be the order serial number, not the database table row id.
         */
        { label: `Order information (${orderId ?? ''})` }
      ];
    },
    [orderId]
  );

  const orderQuery = useQuery<OrderById, Error>({
    queryKey: [orderIdQueryKey, { id: orderId }],
    queryFn: async (): Promise<OrderById> => {
      const response = await loadOrderById(createTenantAdminApiRequest)(orderId!);
      return response.item;
    },
    enabled: !!orderId && !isTenantLoading,
  });

  useEffect(
    () => {
      if (orderQuery.error) {
        reportToGlobal(orderQuery.error);
      }
    },
    [orderQuery.error]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody breadcrumbNodes={breadcrumbNodes}>
        <QueryContainer query={orderQuery}>
          {(order) => {
            return (
              <UiStack p={8} spacing={8}>
                <OrderSummary order={order} />
                <BaseDividerHorizontal height={1} />
                <Tickets order={order} onRefundTickets={onOpen} />
                <BaseDividerHorizontal height={1} />
                {order.refunds?.length > 0 && (
                  <>
                    <RefundsSection order={order} />
                    <BaseDividerHorizontal height={1} />
                  </>
                )}
                <OrderRefundDrawer
                  order={order}
                  isOpen={isOpen}
                  onClose={onClose}
                />
                <AdminNotes profileableType="Order" profileableId={orderId} />
              </UiStack>
            );
          }}
        </QueryContainer>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default OrderInfo;
