import { useTenantApi } from '@/account/hook/useTenantApi';
import { generateOutstandingPaymentLink } from '@/api/admin/payment';
import { enabledCreditCardCheck } from '@/api/admin/paymentOption';
import { type OrderById } from '@/api/registration/order';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import {
  UiHStack, UiIconArrowsClockwise, UiIconArrowSquareOut,
  UiIconCheck,
  UiIconCopy,
  UiIconDotsThreeCircle, UiIconEnvelopeSimple,
  UiIconHourGlass, UiIconPencilSimple, UiIconPlusCircle, UiIconUsersFour,
  UiIconWarningDiamond,
  UiLink,
  UiSimpleGrid,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { UiIconDownloadSimple } from '@/lib/ui/Icon/UiDownloadSimple';
import { capitalize } from '@/lib/util';
import AttendeeDrawer from '@/registration/manage/OrderInfo/AttendeeDrawer';
import OrderStatusDrawer from '@/registration/manage/OrderInfo/OrderStatusDrawer';
import { useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import BaseButtonIconText from '@/base/Button/IconText';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const priceLocale = 'en-AU';

export interface OrderSummaryProps {
  order: OrderById
}

const ALLOWED_RESEND_CONFIRMATION_STATUSES = ['Approved', 'Paid', 'Refunded', 'Partially refunded', 'Cancelled'];

const OrderSummary: FC<OrderSummaryProps> = ({
  order
}) => {
  const { isOpen: isAttendeeDrawerOpen, onToggle: onAttendeeDrawerToggle } = useDisclosure();
  const { isOpen: isOrderStatusOpen, onToggle: onOrderStatusToggle, onClose: onOrderStatusClose } = useDisclosure();

  const { createTenantAdminApiRequest } = useTenantApi();
  const [copyStatus, setCopyStatus] = useState<'idle' | 'loading' | 'copied'>('idle');
  const [showCopyLink, setShowCopyLink] = useState(false);
  const { tenantCode } = useRegisterRoute();

  const { mutate: generateLink } = useMutation(generateOutstandingPaymentLink(createTenantAdminApiRequest), {
    onSuccess: (data) => {
      copy(data.item.paymentLink);
      setCopyStatus('copied');
      setTimeout(() => { return setCopyStatus('idle'); }, 2000);
    },
    onError: () => {
      setCopyStatus('idle');
    }
  });

  const { data: creditCardEnabled } = useQuery(
    ['enabledCreditCardCheck', order.eventId],
    async () => { return await enabledCreditCardCheck(createTenantAdminApiRequest)(order.eventId); },
    {
      enabled: order.status === 'Waiting for Payment'
    }
  );

  useEffect(() => {
    if (order.status === 'Waiting for Payment' && creditCardEnabled?.item?.hasCreditCardEnabled) {
      setShowCopyLink(true);
    } else {
      setShowCopyLink(false);
    }
  }, [creditCardEnabled, order.status]);

  const copyPaymentLink = useCallback(() => {
    setCopyStatus('loading');
    generateLink(order.id);
  }, [order.id, generateLink]);

  return (
    <>
      <UiStack spacing={4}>
        <UiHStack justifyContent={'flex-start'} spacing={4} alignItems={'center'}>
          <UiText variant={'title'}>Summary</UiText>
          {(ALLOWED_RESEND_CONFIRMATION_STATUSES.includes(order.status)) && (
            <BaseButtonIconText Icon={UiIconEnvelopeSimple} onClick={onAttendeeDrawerToggle}>Manage emails</BaseButtonIconText>
          )}
        </UiHStack>
        <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} shadow={uiStyles.cardShadow}>
          <UiSimpleGrid columns={4} gap={8}>
            <UiStack>
              <UiText color={'text.secondary'}>Status</UiText>
              <UiHStack spacing={4}>
                <Status status={order.status} />
                { order.paymentOption === 'invoice' && order.status === 'Waiting for Payment' && (
                  <BaseButtonPlainIcon
                    label={'Change status'}
                    Icon={UiIconArrowsClockwise}
                    onClick={onOrderStatusToggle}
                  />
                )}
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Email</UiText>
              <UiHStack spacing={4}>
                <UiText>{order.email}</UiText>
                {/*{(ALLOWED_RESEND_CONFIRMATION_STATUSES.includes(order.status)) && (*/}
                {/*  <UiStack {...uiStyles.hover} onClick={onAttendeeDrawerToggle}>*/}
                {/*    <BaseButtonPlainIcon*/}
                {/*      label={'Manage'}*/}
                {/*      Icon={UiIconDotsThreeCircle}*/}
                {/*      color={'primary.500'}*/}
                {/*    />*/}
                {/*  </UiStack>*/}
                {/*)}*/}
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Invoice number</UiText>
              <UiHStack spacing={4}>
                <UiText>{order.serial}</UiText>
                {order.invoiceUrl && (
                  <UiStack {...uiStyles.hover} >
                    <UiLink href={order.invoiceUrl} isExternal >
                      <BaseButtonPlainIcon
                        label={'Open invoice'}
                        Icon={UiIconArrowSquareOut}
                        color={'primary.500'}
                      />
                    </UiLink>
                  </UiStack>
                )}
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Purchased at</UiText>
              <UiText>{order.paidAt ? dayjs(order.paidAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Payment method</UiText>
              <UiHStack spacing={4}>
                <UiText>{capitalize(order.paymentOption)}</UiText>
                { showCopyLink && (
                  <UiStack {...uiStyles.hover} onClick={copyPaymentLink}>
                    {copyStatus === 'loading' && (
                      <BaseLoadingSpinner size={'sm'}/>
                    )}
                    {copyStatus === 'copied' && <UiIconCheck size={'2xl'} color={'green.500'} />}
                    {copyStatus === 'idle' && (
                      <BaseButtonPlainIcon
                        label={'Copy payment link'}
                        Icon={UiIconCopy}
                      />
                    )}
                  </UiStack>
                )}
              </UiHStack>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Currency</UiText>
              <UiText>{order.currency}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Amount</UiText>
              <UiText>{order.totalPriceFormat}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Tax ({order.taxName})</UiText>
              <UiText>{order.taxFormat}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Processing Fee</UiText>
              <UiText>{order.surchargeFormat}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Created at</UiText>
              <UiText>{order.createdAt ? dayjs(order.createdAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
            </UiStack>
            <UiStack>
              <UiText color={'text.secondary'}>Payment Option</UiText>
              <UiText>{order.paymentOption}</UiText>
            </UiStack>
            {
              order.discount && (
                <UiStack>
                  <UiText color={'text.secondary'}>Discount code</UiText>
                  <UiText>{order.discount.name} ({order.discount.code})</UiText>
                </UiStack>
              )
            }
          </UiSimpleGrid>
          {order.orderType === 'registration' && order.registrationId && (
            <UiLink href={generatePageUrl('RegistrationManageInfo', { tenantCode, registrationId: order.registrationId.toString() })} color="primary.500" mt={4}>
              View registration
            </UiLink>
          )} : {order.orderType === 'group_registration' && order.groupRegistrationId && (
            <UiLink href={generatePageUrl('GroupRegistrationManageInfo', { tenantCode, groupRegistrationId: order.groupRegistrationId.toString() })} color="primary.500" mt={4}>
              View group registration
            </UiLink>
          )}
        </UiStack>
      </UiStack>
      <AttendeeDrawer status={order.status} orderId={order.id} isOpen={isAttendeeDrawerOpen} onClose={onAttendeeDrawerToggle} registrationId={order.registrationId} />
      <OrderStatusDrawer isOpen={isOrderStatusOpen} onClose={onOrderStatusClose} orderId={order.id} currentStatus={order.status} />
    </>
  );
};

const Status: FC<{ status: string }> = ({
  status
}) => {
  if (status === 'Waiting for Payment') {
    return (
      <UiHStack>
        <UiIconHourGlass color={'orange'} size={'2xl'} />
        <UiText color={'orange'} fontWeight={'bold'}>{status}</UiText>
      </UiHStack>
    );
  } else if (status === 'Paid') {
    return (
      <UiHStack>
        <UiIconCheck color={'green.500'} size={'2xl'} />
        <UiText color={'green.500'} fontWeight={'bold'}>{status}</UiText>
      </UiHStack>
    );
  } else if (status === 'Approved') {
    return (
      <UiHStack>
        <UiIconCheck color={'green.500'} size={'2xl'} />
        <UiText color={'green.500'} fontWeight={'bold'}>{status}</UiText>
      </UiHStack>
    );
  } else if (status === 'Canceled') {
    return (
      <UiHStack>
        <UiIconWarningDiamond color={'red.500'} size={'2xl'} />
        <UiText color={'red.500'} fontWeight={'bold'}>{status}</UiText>
      </UiHStack>
    );
  } else {
    return (
      <UiHStack>
        <UiIconWarningDiamond color={'red.500'} size={'2xl'} />
        <UiText color={'red.500'} fontWeight={'bold'}>{status}</UiText>
      </UiHStack>
    );
  }
};

export default OrderSummary;
