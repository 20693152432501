import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type ClonePaymentOptionRequest } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormSelectField from '@/base/Form/SelectField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { UiStack, UiText } from '@/lib/ui';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import { useCallback, useMemo, type FC } from 'react';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  eventId: Yup.string().required('Event is required.')
});

interface FormData {
  eventId?: string
  paymentOptionId?: string
}

interface ClonePaymentOptionFormProps {
  paymentOptionId: string
  isVisible: boolean
  onClose: () => void
}

const ClonePaymentOptionForm: FC<ClonePaymentOptionFormProps> = ({
  paymentOptionId,
  isVisible,
  onClose,
}) => {
  const eventListQuery = useEventListQuery({ pagination: { pageIndex: 1, pageSize: 100 }, search: '' });
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const { eventId } = useRegisterRoute();

  const options = useMemo(
    () => {
      return eventListQuery.data?.items
        ?.filter((item) => { return Number(item.id) !== Number(eventId); })
        ?.map(item => { return { value: item.id, label: item.name }; }) ?? [];
    }
    , [eventId, eventListQuery.data?.items]);

  const { mutateAsync: clonePaymentOption, isLoading, error } = useMutation<ApiResponse<{}>, Error, ClonePaymentOptionRequest>({
    mutationFn: async (request) => {
      return await registration.clonePaymentOption(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data) => {
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors.join(', '));
      }
      void queryClient.invalidateQueries({ queryKey: [registration.paymentOptionQueryKey, { eventId }] });
    },
    onError: () => {
      // setErrors([error.message ?? 'Failed to save the attendee group.']);
      // setErrors(['Login failed (connection error).']);
    }
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    await clonePaymentOption({ id: Number(paymentOptionId), toEvent: values.eventId!.toString() });
    onClose();
    setSubmitting(false);
  }, [clonePaymentOption, paymentOptionId, onClose]);

  return (
    <Formik<FormData>
      initialValues={{}}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Choose an event to clone'}
        size={'lg'}
        isLoading={isLoading}
        buttonText={'Clone'}
        buttonLoadingText={'Cloning'}
      >
        <QueryContainer query={eventListQuery}>
          {() => {
            return (
              <UiStack flexGrow={1}>
                {error && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    <BaseMessageBarError key={error.message}>
                      {error.message}
                    </BaseMessageBarError>
                  </UiStack>
                )}
                <BaseFormSelectField
                  name={'eventId'}
                  label={'Event'}
                  layout="stack"
                  options={options}
                />
                <UiText variant={'body1'} color={'text.secondary'}>
                  This list only includes events that can be cloned, empty events with no content can not be cloned
                </UiText>
              </UiStack>
            );
          }}
        </QueryContainer>
      </BaseFormDrawer>
    </Formik>
  );
};

export default ClonePaymentOptionForm;
