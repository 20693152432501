import { ReactNode, type FC, useState, useCallback } from 'react';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerFooter,
  type UiDrawerProps, UiHStack, UiText, uiStyles, UiButton,
} from '@/lib/ui';
import { account } from '@/api';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { AdminUserType } from '@/api/constant/adminUser';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface ResendInviteConfirmation {
  adminUser: account.SearchAdminUsersResponseAdminUser,
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
}

const ResendInviteConfirmation: FC<ResendInviteConfirmation> = ({
  adminUser,
  isOpen,
  onClose,
}) => {
  const { tenant } = useTenantRoute();
  const [errors, setErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.InviteAdminUserResponse, Error, account.InviteAdminUserRequest>({
    mutationFn: async (data: account.InviteAdminUserRequest) => {
      return await account.inviteAdminUser(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to invite the user.']);
    }
  });

  const resend = useCallback(
    () => {
      if (adminUser.email && tenant?.id) {
        void mutate({
          email: adminUser.email,
          tenantId: tenant?.id,
        });
      }
    },
    [tenant?.id, adminUser.email]
  );
  
  return (
    <UiDrawer
      placement={'right'}
      size={'md'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} py={12} bgColor={'gray.100'}>
          <UiStack py={8} spacing={4} flexGrow={1}>
            {errors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {errors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <UiText variant={'title'}>Resend an invite to this user</UiText>
            <UiStack bgColor={'#fff'} p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={2}>
              <UiText>{adminUser.firstName} {adminUser.lastName}</UiText>
              <UiText color={'text.secondary'} variant={'body2'}>{adminUser.email}</UiText>
            </UiStack>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={8}>
          <UiHStack justifyContent={'flex-end'}>
            {isLoading ? (
              <UiButton colorScheme={'green'}>Resending...</UiButton>
            ) : (
              <UiButton onClick={resend}>Resend</UiButton>
            )}
          </UiHStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default ResendInviteConfirmation;
