import { type FC, useCallback, useState } from 'react';
import { Formik, type FormikHelpers, Form } from 'formik';
import {
  UiButton,
  UiDivider,
  UiHStack,
  type UiHStackProps,
  UiIconPaperPlaneTilt,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import { AdminUserType } from '@/api/constant/adminUser';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface AdminUserFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  email: string
  firstName: string
  lastName: string
}

interface Errors {
  email?: string
  firstName?: string
  lastName?: string
}

// const teamOptions: Option[] = [
//   { value: 'admin', label: 'Admin' },
//   // { value: 'Medical', label: 'Medical', isDisabled: true },
//   { value: 'regoManagement', label: 'Rego management' },
//   { value: 'finance', label: 'Finance' }
// ];

const initFormData = {
  email: '',
  firstName: '',
  lastName: '',
};

const AdminUserForm: FC<AdminUserFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { tenant } = useTenantRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.InviteAdminUserResponse, Error, account.InviteAdminUserRequest>({
    mutationFn: async (data: account.InviteAdminUserRequest) => {
      return account.inviteAdminUser(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({queryKey: [account.adminUserSearchQueryKey, {tenantId: tenant?.id, types: [AdminUserType.Generic]}]});
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to invite the user.']);
    }
  });

  const submitForm = useCallback(async (values: FormData) => {
    if (!tenant?.id) {
      setSaveErrors(['Unknown tenant.']);
    } else {
      void mutate({
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        tenantId: tenant?.id,
      });
    }
  }, [mutate, tenant?.id]);

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FormData): Errors => {
        const errors: Errors = {};
        // if (!values.name) {
        //   errors.name = 'Required';
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.name = 'Invalid email address';
        // }
        // return errors;
        return {
          // name: 'adfasdf',
        };
      }}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Invite admin user'}
        size={'lg'}
        isLoading={isLoading}
        buttonText={'Send'}
        buttonLoadingText={'Sending...'}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'email'}
            label={'Email'}
          />
          <BaseFormInputField
            name={'firstName'}
            label={'First name'}
          />
          <BaseFormInputField
            name={'lastName'}
            label={'Last name'}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default AdminUserForm;
