import { callApi, type ApiRequest, type ApiResponse, deleteAPI } from '@/api/tenantClient';

export const attendeeCategoryListQueryKey = 'registration.attendeeCategoryList';

export interface AttendeeCategoryData {
  id: number
  eventId: number
  name: string
  description: string
  registrationStrategy: RegistrationStrategyType
  isVisible: boolean
  formAttached: boolean
  minimumAttendees: number | null
  maximumAttendees: number | null
  groupRegistrationEnabled: GroupRegistrationEnabledType
}

export enum RegistrationStrategyType {
  FORMS_FIRST = 'forms_first',
  PAYMENT_FIRST = 'payment_first',
}

export enum GroupRegistrationEnabledType {
  YES = 'yes',
  NO = 'no',
}

export interface AttendeeCategoryListQueryRequest {
  eventId: string
}

export function loadAttendeeCategoryList(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: AttendeeCategoryListQueryRequest): Promise<ApiResponse<AttendeeCategoryData[]>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/attendee_categories/admin_index';
    request.endpoint.query = { eventId: params.eventId };
    return await callApi<AttendeeCategoryData[]>(request);
  };
}

export interface AttendeeCategorySaveRequestItem extends Omit<AttendeeCategoryData, 'id'> {
  id?: number | null
}

export interface AttendeeCategorySaveRequest {
  attendeeCategory: AttendeeCategorySaveRequestItem
}

export function saveAttendeeCategory(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: AttendeeCategorySaveRequest): Promise<ApiResponse<AttendeeCategoryData>> => {
    if (params.attendeeCategory.id) {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/attendee_categories/' + params.attendeeCategory.id;
      request.payload = params;
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/attendee_categories';
      request.endpoint.query = { eventId: params.attendeeCategory.eventId.toString() };
      request.payload = params;
    }
    // The backend needs to make sure the data returned matches AttendeeCategorySaveResponse.
    return await callApi<AttendeeCategoryData>(request);
  };
}

export interface AttendeeCategoryDeleteRequest {
  id: number
}

export function deleteAttendeeCategory(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: AttendeeCategoryDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/attendee_categories/' + params.id;
    return await deleteAPI(request);
  };
}

export function cloneAttendeeCategory(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (attendeeCategoryId: number): Promise<ApiResponse<AttendeeCategoryData>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/attendee_categories/clone';
    request.endpoint.query = { id: attendeeCategoryId.toString() };
    // The backend needs to make sure the data returned matches AttendeeCategorySaveResponse.
    return await callApi<AttendeeCategoryData>(request);
  };
}
