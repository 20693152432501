import { type FC } from 'react';
import PlainIcon, { PlainIconProps } from '@/base/Button/PlainIcon';
import { UiIconCopy, UiIconPencilSimple } from '@/lib/ui';

export interface PlainIconDeleteProps extends Omit<PlainIconProps, 'Icon' | 'label'> {
  label?: string
}

const PlainIconDelete: FC<PlainIconDeleteProps> = ({
  label = 'Clone',
  ...props
}) => {
  return (
    <PlainIcon
      label={label}
      Icon={UiIconCopy}
      {...props}
    />
  );
};

export default PlainIconDelete;
