import { type FC } from 'react';
import TenantList from './TenantList';

export interface AccountTenantListProps {
}

const AccountTenantList: FC<AccountTenantListProps> = () => {
  return (
    <TenantList/>
  );
};
export default AccountTenantList;
