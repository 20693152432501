import { type FC, useCallback, useEffect, useState } from 'react';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiText,
  uiStyles,
  UiDrawerFooter,
  UiButton
} from '@/lib/ui';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import ReorderList, { type Item } from '@/base/ReorderList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { type AttendeeOrderChangeRequest } from '@/api/registration/orderPositionChange';
import { registration } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface OrderChangeDrawerProps extends Omit<UiDrawerProps, 'children'> {
}

interface AttendeeCategoryItem extends Item {
  name: string
  description?: string
}

const OrderChangeDrawer: FC<OrderChangeDrawerProps> = ({
  ...props
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [items, setItems] = useState<AttendeeCategoryItem[]>([]);

  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { data } = useAttendeeCategoryQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();

  useEffect(() => data && setItems(data), [data]);

  const { mutateAsync, isLoading } = useMutation<ApiResponse<undefined>, Error, AttendeeOrderChangeRequest>({
    mutationFn: async (request: AttendeeOrderChangeRequest) =>
      await registration.attendeeOrderChange(createTenantAdminApiRequest)(request),
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId }] });
        props.onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee group Order.']);
    }
  });

  const onSave = useCallback(async () => {
    await mutateAsync({
      orderIds: items.map(item => item.id),
      eventId
    });
  }, [eventId, items, mutateAsync]);

  return (
    <UiDrawer placement={'right'} size={'xl'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <UiDrawerBody py={16} px={8} alignItems={'stretch'} bgColor={'gray.100'}>
          <UiStack flexGrow={1} spacing={1}>
            <UiText variant={'h6'}>Order attendee groups</UiText>
            <UiText variant={'body1'}>This changes the order of the attendee groups attendees will see.</UiText>
          </UiStack>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              ))}
            </UiStack>
          )}
          <BaseDividerHorizontal height={8} />
          <UiStack pt={1}>
            <ReorderList<AttendeeCategoryItem>
              data={items}
              onChange={setItems}
            >
              {({ item }) => (
                <UiStack bgColor={'#fff'} p={2} spacing={0} borderRadius={uiStyles.borderRadius}>
                  <UiText>{item.name}</UiText>
                  {!!item.description && (
                    <UiText variant={'body2'} color={'text.secondary'}>{item.description}</UiText>
                  )}
                </UiStack>
              )}
            </ReorderList>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            {isLoading ? (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'gray'}>
                Saving...
              </UiButton>
            ) : (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={onSave}>
                Save
              </UiButton>
            )}
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default OrderChangeDrawer;
