import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiButton,
  UiIconCurrencyCircleDollar,
  UiIconDotsNine,
  UiIconUsers,
  UiIconUsersFour,
  UiIconCalendarBlank, UiIconInvoice, UiIconListChecks, UiIconFolders,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useConfig } from '@/app/ProviderConfig';
import BaseModuleMenu, {MenuProps as BaseMenuProps, Menu as BaseMenu} from '@/base/Layout/ModuleMenu';
dayjs.extend(utc);

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (versionTag: string): BaseMenu[] => {
  const manageMenu = {
    name: 'manage', // The name has to be unique on the same level.
    label: 'Manage',
    subMenu: [
      {
        name: 'tenant',
        label: 'Workspaces',
        url: generatePageUrl('AAAManageTenant'),
        icon: (<UiIconFolders size={'2xl'} />)
      },
      {
        name: 'subscription',
        label: 'Subscriptions',
        url: generatePageUrl('AAAManageSubscription'),
        icon: (<UiIconListChecks size={'2xl'} />)
      },
      {
        name: 'bills',
        label: 'Bills',
        url: generatePageUrl('AAAManageBill'),
        icon: (<UiIconInvoice size={'2xl'} />)
      },
    ],
  };

  return [
    manageMenu,
  ];
};

export interface SideNavProps extends Omit<BaseMenuProps, 'menus'> {
}

const SideNav: FC<SideNavProps> = ({
  activeNodes = [],
  enableTransition = false
}) => {
  const appConfig = useConfig();
  const menus = useMemo(
    () => {
      return getMenus(appConfig.versionTag);
    }, [
      appConfig.versionTag
    ]);

  return (
    <BaseModuleMenu
      menus={menus}
      activeNodes={activeNodes}
      enableTransition={enableTransition}
    />
  );
};

export default SideNav;
