import { type FC } from 'react';
import { Text, type TextProps } from '@chakra-ui/react';

export type UiTextVariant = 'caption' | 'body2' | 'body1' | 'title' | 'h6' | 'h5' | 'h4' | 'h3';

export interface UiTextProps extends TextProps {
  color?: TextProps['color'] | 'text.primary' | 'text.secondary'
  variant?: UiTextVariant
}

interface SizeAndWeightProps {
  fontSize: TextProps['size']
  fontWeight?: TextProps['fontWeight']
}

type SizeAndWeightMapping = Record<UiTextVariant, SizeAndWeightProps>;

const sizeAndWeightMapping: SizeAndWeightMapping = {
  caption: {
    fontSize: 'xs'
  },
  body2: {
    fontSize: 'sm'
  },
  body1: {
    fontSize: 'md'
  },
  title: {
    fontSize: 'lg',
    fontWeight: 600
  },
  h6: {
    fontSize: 'xl',
    fontWeight: 600
  },
  h5: {
    fontSize: '2xl',
    fontWeight: 600
  },
  h4: {
    fontSize: '3xl',
    fontWeight: 600
  },
  h3: {
    fontSize: '4xl',
    fontWeight: 600
  }
};

export const UiText: FC<UiTextProps> = ({
  color = 'text.primary',
  variant = 'body1',
  ...props
}) => {
  return (
    <Text
      color={color}
      {...sizeAndWeightMapping[variant]}
      {...props}
    />
  );
};
