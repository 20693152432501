// Do NOT change values here since they are referenced directly in backend.

import { TeamState, teamStateLabels } from '@/api/constant/team';

export enum SubscriptionState {
  Active = 1,
  Pending = 2,
  Obsolete = 3,
  Cancelled = 4,
}

export const subscriptionStateLabels: Record<SubscriptionState, string> = {
  [SubscriptionState.Active]: 'Active',
  [SubscriptionState.Pending]: 'Not activated',
  [SubscriptionState.Obsolete]: 'Obsolete',
  [SubscriptionState.Cancelled]: 'Cancelled',
};

export const subscriptionStateOptions: Array<{ value: number, label: string }> = [
  { value: SubscriptionState.Active, label: subscriptionStateLabels[SubscriptionState.Active] },
  { value: SubscriptionState.Pending, label: subscriptionStateLabels[SubscriptionState.Pending] },
  { value: SubscriptionState.Obsolete, label: subscriptionStateLabels[SubscriptionState.Obsolete] },
  { value: SubscriptionState.Cancelled, label: subscriptionStateLabels[SubscriptionState.Cancelled] },
];

// Do NOT change values here since they are referenced directly in backend.
export enum SubscriptionPaymentPlan {
  Quarterly = 1,
  Yearly = 2,
}

export const subscriptionPlanLabels: Record<SubscriptionPaymentPlan, string> = {
  [SubscriptionPaymentPlan.Quarterly]: 'Quarterly',
  [SubscriptionPaymentPlan.Yearly]: 'Yearly'
};

export enum SubscriptionPackage {
  Pack1 = 'pack1',
  Pack2 = 'pack2'
}

export const subscriptionPackageLabels: Record<SubscriptionPackage, string> = {
  [SubscriptionPackage.Pack1]: 'Essentials',
  [SubscriptionPackage.Pack2]: 'Enterprise'
};

// export enum SubscriptionType {
//   Template = 1,
//   Instance = 2,
// }

export enum SubscriptionProduct {
  Registration = 'registration',
  AbstractManagement = 'abstractManagement',
  EventWebsite = 'eventWebsite',
  MobileEventApp = 'mobileEventApp',
  DigitalPoster = 'digitalPoster',
  LiveStreaming = 'liveStreaming',
  SponsorExhibitor = 'sponsorExhibitor',
  Webinar = 'webinar',
  DataInsight = 'dataInsight',
  MultiLingualSupport = 'multiLingualSupport',
  LiveTranslation = 'liveTranslation',
  AILiveCaptioning = 'aiLiveCaptioning',
  AppWayfinder = 'appWayfinder',
  ContentHub = 'contentHub',
  Integration = 'integration',
  // ServicePack = 'servicePack',
  // ServicePack1 = 'servicePack1',
  // ServicePack2 = 'servicePack2',
  // ServicePack3 = 'servicePack3',
}

export enum SubscriptionUnitCostType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}
