import {
  type ApiRequest,
  callApi,
} from '@/api/globalClient';

export const mfaEmailPasscodeQueryKey = 'account.mfaEmailPasscode';

export interface SendMfaEmailPasscodeRequest {}

export interface SendMfaEmailPasscodeResponse {
  errors: string[]
}

export function sendMfaEmailPasscode(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async (params: SendMfaEmailPasscodeRequest): Promise<SendMfaEmailPasscodeResponse> => {
    apiRequest.endpoint.path = '/api/auth/send-mfa-email-passcode';
    apiRequest.payload = {};
    const apiData = await callApi<SendMfaEmailPasscodeResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    return apiData;
  };
}

export interface VerifyMfaEmailPasscodeRequest {
  code: number
}

export interface VerifyMfaEmailPasscodeResponse {
  errors: string[]
}

export function verifyMfaEmailPasscode(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async (params: VerifyMfaEmailPasscodeRequest): Promise<VerifyMfaEmailPasscodeResponse> => {
    apiRequest.endpoint.path = '/api/auth/verify-mfa-email-passcode';
    apiRequest.payload = params; // This means the empty body which is required to match the backend API.
    const apiData = await callApi<VerifyMfaEmailPasscodeResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    return apiData;
  };
}