import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerBody,
  UiLink,
  UiDrawerCloseButton,
  type UiDrawerProps, UiHStack, uiStyles, UiIconArrowRight, UiIconCheck, UiIconArrowCircleRight, UiTag,
} from '@/lib/ui';
import { account } from '@/api';
import { TenantSearchTenantData } from '@/api/account';
import { generatePageUrl } from '@/app/pages';
import BaseBlockList from '@/base/BlockList';
import { TenantState } from '@/api/constant';
import BaseAccountTenantList from '@/base/AccountTenantList';

export interface TenantSwitchDrawer extends Omit<UiDrawerProps, 'children'> {
  currentTenant: account.TenantData | undefined
}

const TenantSwitchDrawer: FC<TenantSwitchDrawer> = ({
  currentTenant,
  ...props
}) => {
  const navigate = useNavigate();

  const getTenantLink = useCallback(
    (tenantCode: string) => {
      // navigate(generatePageUrl('AccountDashboardModules', {tenantCode,}));
      return generatePageUrl('AccountDashboardModules', {tenantCode,});
    },
    []
  );

  return (
    <UiDrawer placement={'right'} size={'lg'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={12}>
          <UiStack
            spacing={4}
            py={8}
          >
            {/*<UiText variant={'title'}>Switch to tenant</UiText>*/}
            <BaseAccountTenantList/>
            {/*<BaseBlockList*/}
            {/*  bgColor={'#fff'}*/}
            {/*  enableDivider={true}*/}
            {/*>*/}
            {/*  {tenantList.map((t) => {*/}
            {/*    if (t.state === TenantState.Active) {*/}
            {/*      return (*/}
            {/*        <UiHStack*/}
            {/*          bgColor={'#fff'}*/}
            {/*          px={6}*/}
            {/*          py={4}*/}
            {/*          key={t.id}*/}
            {/*          // borderRadius={uiStyles.borderRadius}*/}
            {/*          justifyContent={'space-between'}*/}
            {/*          {...uiStyles.hover}*/}
            {/*        >*/}
            {/*          /!*<UiLink key={t.id} href={currentTenant && currentTenant.id === t.id ? "#" : getTenantLink(t.code)} flexGrow={1}>*!/*/}
            {/*          <UiLink key={t.id} href={getTenantLink(t.code)} flexGrow={1}>*/}
            {/*            <UiHStack justifyContent={'space-between'}>*/}
            {/*              <UiText>{t.name}</UiText>*/}
            {/*              {currentTenant && currentTenant.id === t.id ? (*/}
            {/*                <>*/}
            {/*                  /!*<UiIconCheck size={'3xl'} color={'green.500'}/>*!/*/}
            {/*                  <UiTag colorScheme={'green'}>Current</UiTag>*/}
            {/*                </>*/}
            {/*              ) : (*/}
            {/*                <UiIconArrowRight size={'2xl'} color={'primary.500'}/>*/}
            {/*              )}*/}
            {/*            </UiHStack>*/}
            {/*          </UiLink>*/}
            {/*        </UiHStack>*/}
            {/*      );*/}
            {/*    }*/}
            {/*  })}*/}
            {/*</BaseBlockList>*/}
          </UiStack>
        </UiDrawerBody>
        </UiDrawerContent>
    </UiDrawer>
  );
};

export default TenantSwitchDrawer;
