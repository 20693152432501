import { type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiFormErrorMessage,
  UiFormHelperText,
  UiVStack,
  UiHStack,
  UiText
} from '@/lib/ui';
import { UiTextarea, type UiTextareaProps } from '@/lib/ui/Textarea/UiTextarea';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';

export interface TextareaProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  height?: UiTextareaProps['height']
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  maxLength?: UiTextareaProps['maxLength']
}

const Textarea: FC<TextareaProps> = ({
  name,
  label,
  height = '48px',
  placeholder = '',
  helperText = undefined,
  maxLength = undefined,
  isRequired = true,
  layout = 'inline'
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <UiFormControl isInvalid={!!form.errors[name]}>
          <FieldContainer
            layout={layout}
          >
            { isRequired
              ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              )
              : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
            <UiVStack alignItems={'stretch'}>
              <UiTextarea {...field} placeholder={placeholder} height={height} maxLength={maxLength} />
              {!!helperText && (
                <UiFormHelperText>
                  {helperText}
                </UiFormHelperText>
              )}
              {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
            </UiVStack>
          </FieldContainer>
        </UiFormControl>
      )}
    </Field>
  );
};

export default Textarea;
