import { type FC } from 'react';
import {
  type UiBoxProps, UiSpinner, UiStack,
} from '@/lib/ui';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseDigitalPosterCard from '@/base/App/DigitalPosterCard';
import BaseLiveStreamingCard from '@/base/App/LiveStreamingCard';
import BaseSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import BaseWebinarCard from '@/base/App/WebinarCard';
import BaseIntegrationCard from '@/base/App/IntegrationCard';
import BaseContentHubCard from '@/base/App/ContentHubCard';
import ModulePermissionCard from '@/account/access/TeamInfo/ModulePermissionCard';
import { account } from '@/api';
import { PermissionDomain } from '@/api/constant/adminUserPermission';
import { getModulePermission } from '@/account/access/TeamInfo/permissions';
import BaseBlockList from '@/base/BlockList';
import { isProductEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useQuery } from '@tanstack/react-query';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

// export const apps = {
//   ACCOUNT: 1,
//   REGISTRATION: 2,
//   ABSTRACT: 3,
// }

export interface ModuleListProps extends UiBoxProps {
  // permissionMap: account.PermissionMap
  team: account.LoadTeamResponseTeam
}


const PermissionList: FC<ModuleListProps> = ({
  team,
}) => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { data, isLoading } = useQuery<account.SubscriptionLoadResponse, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  // {isProductEnabled(data?.subscription, SubscriptionProduct.Registration) && ()}

  if (data?.subscription) {
    return (
      <BaseBlockList>
        <ModulePermissionCard
          teamId={team.id}
          moduleCard={(<BaseAppAccountCard/>)}
          module={PermissionDomain.Account}
          permission={getModulePermission(team.permissions, PermissionDomain.Account)}
        />
        {isProductEnabled(data?.subscription, SubscriptionProduct.Registration) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseAppRegistrationCard/>)}
            module={PermissionDomain.Registration}
            permission={getModulePermission(team.permissions, PermissionDomain.Registration)}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.EventWebsite) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseEventWebsiteCard/>)}
            module={PermissionDomain.EventWebsite}
            permission={getModulePermission(team.permissions, PermissionDomain.EventWebsite)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.MobileEventApp) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseEventAppCard/>)}
            module={PermissionDomain.EventApp}
            permission={getModulePermission(team.permissions, PermissionDomain.EventApp)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.AbstractManagement) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseAbstractCard/>)}
            module={PermissionDomain.AbstractManagement}
            permission={getModulePermission(team.permissions, PermissionDomain.AbstractManagement)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.DigitalPoster) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseDigitalPosterCard/>)}
            module={PermissionDomain.DigitalPoster}
            permission={getModulePermission(team.permissions, PermissionDomain.DigitalPoster)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.LiveStreaming) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseLiveStreamingCard/>)}
            module={PermissionDomain.LiveStream}
            permission={getModulePermission(team.permissions, PermissionDomain.LiveStream)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.SponsorExhibitor) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseSponsorExhibitorCard/>)}
            module={PermissionDomain.SponsorExhibitor}
            permission={getModulePermission(team.permissions, PermissionDomain.SponsorExhibitor)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.Webinar) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseWebinarCard/>)}
            module={PermissionDomain.Webinar}
            permission={getModulePermission(team.permissions, PermissionDomain.Webinar)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.ContentHub) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseContentHubCard/>)}
            module={PermissionDomain.ContentHub}
            permission={getModulePermission(team.permissions, PermissionDomain.ContentHub)}
            disableViewOnly={true}
          />
        )}
        {isProductEnabled(data?.subscription, SubscriptionProduct.Integration) && (
          <ModulePermissionCard
            teamId={team.id}
            moduleCard={(<BaseIntegrationCard/>)}
            module={PermissionDomain.Integration}
            permission={getModulePermission(team.permissions, PermissionDomain.Integration)}
            disableViewOnly={true}
          />
        )}
      </BaseBlockList>
    );
  }
};

export default PermissionList;
