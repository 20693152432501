// import logo from './logo.svg';
// import './App.css';
import { type FC } from 'react';
import { getAppConfig } from '@/app/config';
import { ProviderTheme } from '@/app/ProviderTheme';
import { ProviderQuery } from '@/app/ProviderQuery';
import { Router } from '@/app/ProviderPage';
import { ProviderConfig } from '@/app/ProviderConfig';
import { ProviderAdminAuth } from '@/app/ProviderAdminAuth';
import { ProviderGlobalMessages } from '@/app/ProviderGlobalMessages';
import bugsnag from '@/bugsnag';
import ErrorBoundaryView from '@/base/ErrorBoundary/ErrorBoundaryView';

bugsnag.start();

const App: FC = () => {
  const ErrorBoundary = bugsnag.ErrorBoundary();
  return (
    <ProviderConfig config={getAppConfig()}>
      <ProviderTheme>
        <ProviderGlobalMessages>
          <ProviderQuery>
            <ProviderAdminAuth>
              <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
                <Router />
              </ErrorBoundary>
            </ProviderAdminAuth>
          </ProviderQuery>
        </ProviderGlobalMessages>
      </ProviderTheme>
    </ProviderConfig>
  );
};

export default App;
