import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import TicketDisplayForm from './TicketDisplayForm';
import PageRow from './PageRow';

export interface ManageTicketDisplayProps extends UiHStackProps {
}

const TicketDisplayPage: FC<ManageTicketDisplayProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Ticket display settings'}
        description={'Manage the way that ticket prices, capacities and information is displayed to an attendee when registering.'}
        onEdit={onToggle}
        {...props}
      />
      <TicketDisplayForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default TicketDisplayPage;
