import {
  type ApiRequest,
  type ApiResponseSingle,
  properCallApi,
} from '../tenantClient';

export const insightQueryKey = 'registration.report.insight';

export interface IInsightRow {
  name: string
  count: number
  salesValue: string
}

export interface IInsightParams {
  eventId: number
  attendeeCategoryIds: number[]
}

export function loadInsightForTable(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (
    params: IInsightParams
  ): Promise<ApiResponseSingle<IInsightRow>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/insights/reg_order_report';
    request.payload = {
      event_id: params.eventId,
      attendee_category_ids: params.attendeeCategoryIds,
    };
    return await properCallApi<ApiResponseSingle<IInsightRow>>(request);
  };
}
