import { type ApiRequest, type ApiResponseSingle, properCallApi } from '@/api/tenantClient';

export interface PaymentLinkResponse {
  paymentLink: string
}

export function generateOutstandingPaymentLink(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (orderId: number): Promise<ApiResponseSingle<PaymentLinkResponse>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/payments/generate_outstanding_payment_link';
    request.endpoint.query = { order_id: orderId };
    return await properCallApi<ApiResponseSingle<PaymentLinkResponse>>(request);
  };
}
