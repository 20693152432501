import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconGlobe } from '@/lib/ui';

export interface MultiLingualSupportProps {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const MultiLingualSupportCard: FC<MultiLingualSupportProps> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconGlobe size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'Multi-lingual support'}
      isSelected={isSelected}
    />
  );
};
export default MultiLingualSupportCard;
