import { type FC } from 'react';
import {
  WarningDiamond
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconWarningDiamondProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconWarningDiamond: FC<UiIconWarningDiamondProps> = (props) => {
  return (
    <UiIcon
      IconComponent={WarningDiamond}
      {...props}
    />
  );
};
