import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconGauge, UiImage } from '@/lib/ui';

export interface AccountCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
  showDescription?: boolean
}

const AccountCard: FC<AccountCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false,
  showDescription = true
}) => {
  return (
    <AppCard
      // icon={<UiImage src={'/image/app/arinex-one-colour.png'} height={'32px'} borderRadius={8} />}
      icon={(<UiIconGauge size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      name={'Global dashboard'}
      description={showDescription ? 'Your end to end event solutions' : undefined}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default AccountCard;
