import { type FC } from 'react';
import { Stack, type StackProps } from '@chakra-ui/react';
import { UiDivider } from '@/lib/ui/Divider';

export interface UiStackProps extends StackProps {
  enableShadow?: boolean
  enableDivider?: boolean
}

export const UiStack: FC<UiStackProps> = ({
  enableShadow = false,
  enableDivider = false,
  ...props
}) => {
  return (
    <Stack
      divider={ enableDivider
        ? (
          <UiDivider
            height={'1px'}
            borderColor={'gray.300'}
          />
        )
        : undefined}
      shadow={enableShadow ? 'base' : 'none'}
      {...props}
    />
  );
};
