import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiHStack, UiIconPlusCircle, UiButton,
} from '@/lib/ui';
import ImporterForm from './ImporterForm';
import { UiIconUpload } from '@/lib/ui/Icon/UiIconUpload';
import BaseButtonIconText from '@/base/Button/IconText';

export interface ImporterButtonProps extends UiHStackProps {
  modelType: string
}

const ImporterButton: FC<ImporterButtonProps> = ({ modelType }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconUpload} onClick={onToggle} variant={'large'}>Upload</BaseButtonIconText>
      {/*<UiButton size={'3xl'} onClick={onToggle} variant={'ghost'}>*/}
      {/*  Upload*/}
      {/*</UiButton>*/}
      <ImporterForm
        onClose={onToggle}
        isVisible={isOpen}
        modelType={modelType}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default ImporterButton;
