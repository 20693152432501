import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppAbstractCard from '@/base/App/AbstractCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface AbstractManagementProps extends Omit<ModuleCardProps, 'children'> {
  isSelected: boolean
  bgColor?: ModuleCardProps['bgColor']
}

const AbstractManagement: FC<AbstractManagementProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppAbstractCard />
    </ModuleCard>
  );
};

export default AbstractManagement;
