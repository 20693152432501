import { type FC } from 'react';
import {
  UiStack,
  UiText,
} from '@/lib/ui';
// import BaseMessageBarError from '@/base/MessageBar/Error';
// import BaseRouterLink from '@/base/Router/Link';
// import { generatePageUrl } from '@/app/pages';

export interface ApiErrorBadRequestProps {
}

const ApiErrorBadRequest: FC<ApiErrorBadRequestProps> = () => {
  // const loginUrl = useMemo(() => generatePageUrl('AccountLoginLoginEmail'), []);

  return (
    <UiStack>
      <UiText color={'red.500'}>Bad request.</UiText>
    </UiStack>
  );
};

export default ApiErrorBadRequest;
