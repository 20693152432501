import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type OrderTableRow, loadOrdersForTable, orderTableQueryKey } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { UiSpinner, uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Datatable from './Datatable';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface SerializedOrderTableRow extends Omit<OrderTableRow, 'purchasedAt'> {
  purchasedAt: Date | null
}

interface Params {
  filters: any
  sorting: any
  pagination: any
  search?: string
}

const useGetOrders = ({ filters, sorting, pagination, search }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();

  return useQuery<ApiResponse<OrderTableRow>, Error>({
    queryKey: [orderTableQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<OrderTableRow>> => {
      const response = await loadOrdersForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
        search,
      });
      return response;
    },
    enabled: !isTenantLoading,
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const OrderTable = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [search, setSearch] = useState<string>();
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });
  const { reportToGlobal } = useApiErrorHandler();

  const { data, isLoading, error, isError, isFetching, refetch } = useGetOrders({ filters, sorting, pagination, search, setPagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <Datatable
        data={data.items.map((order) => {
          return {
            ...order,
            purchasedAt: order.purchasedAt === null ? null : new Date(order.purchasedAt)
          };
        })}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={data.pagination!.total}
        paymentOptions={data.selectOptions!.paymentOptions}
        eventSelectOptions={data.selectOptions!.eventSelectOptions}
        statusSelectOptions={data.selectOptions!.statusSelectOptions}
        workshopTicketsSelectOptions={data.selectOptions!.workshopTicketsSelectOptions}
        functionTicketsSelectOptions={data.selectOptions!.functionTicketsSelectOptions}
        tourTicketsSelectOptions={data.selectOptions!.tourTicketsSelectOptions}
        delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No orders
      </BaseMessageBarInfo>
    );
  }
};

export default OrderTable;
