import { type FC } from 'react';
import {
  uiStyles,
  UiStack,
  UiIconEye,
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDisclosure } from '@chakra-ui/react';
import BillInfoDrawer from '@/account/subscription/Payment/BillInfoDrawer';

dayjs.extend(utc);

export interface ViewBillInfoButtonProps {
  subscriptionBillId: number
}

const ViewBillInfoButton: FC<ViewBillInfoButtonProps> = ({
  subscriptionBillId,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack {...uiStyles.hover} onClick={onToggle}>
        <UiIconEye size={'3xl'} color={'primary.400'}/>
      </UiStack>
      <BillInfoDrawer subscriptionBillId={subscriptionBillId} isOpen={isOpen} onClose={onToggle}/>
    </>
  );
};

export default ViewBillInfoButton;
