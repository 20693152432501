import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiText,
  UiHStack,
  UiStack,
  UiButton,
  UiFormControl,
  UiFormErrorMessage, uiStyles,
} from '@/lib/ui';
import { type RefundRequest, type OrderById, orderIdQueryKey, type OrderItemType, orderTableQueryKey, refundTableQueryKey } from '@/api/registration';
import { MantineReactTable, type MRT_ColumnDef, type MRT_RowSelectionState, useMantineReactTable } from 'mantine-react-table';
import dayjs from 'dayjs';
import { Form, Formik, type FormikHelpers, useField } from 'formik';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import * as Yup from 'yup';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import type { SerializedOrderTableRow } from '@/registration/manage/Order/OrderTable';

const formSchema = Yup.object().shape({
  refundNote: Yup.string().required('Note is required.'),
  refundAmount: Yup.number().required('Refund amount is required.'),
  selectedItemsIds: Yup.array(Yup.number()).min(1, 'You need to select at least one items'),
});

interface FormData {
  refundNote?: string
  refundAmount?: number
  selectedItemsIds: number[]
}

export interface OrderRefundDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  order: OrderById
}

const OrderRefundDrawer: FC<OrderRefundDrawerProps> = ({
  isOpen,
  onClose,
  order
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const { adminAuth } = useAdminAuth();
  const [errors, setErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const { mutateAsync: refundMutate, isLoading } = useMutation<ApiResponseSingle<OrderById>, Error, RefundRequest>({
    mutationFn: async (request) => {
      return await registration.refunds(createTenantAdminApiRequest)(request);
    },
    onSuccess: async (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        await queryClient.invalidateQueries({ queryKey: [orderIdQueryKey, { id: order.id }] });
        void queryClient.invalidateQueries({ queryKey: [orderTableQueryKey] });
        void queryClient.invalidateQueries({ queryKey: [refundTableQueryKey] });
        onClose();
      }
    },
    onError: (error) => {
      setErrors([error.message ?? 'Failed to save the attendee group.']);
    }
  });

  const onSubmit = useCallback(async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await refundMutate({
      orderId: order.id,
      refundAmount: values.refundAmount!,
      refundNote: values.refundNote!,
      refunderEmail: adminAuth.user?.email,
      selectedItemsIds: values.selectedItemsIds,
    });
    setSubmitting(false);
  }, [adminAuth.user?.email, order.id, refundMutate]);

  return (
    <UiDrawer
      placement={'right'}
      size={'full'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <UiText variant={'title'} pb={8}>Refund tickets</UiText>
          <Formik<FormData>
            initialValues={{
              refundNote: '',
              refundAmount: undefined,
              selectedItemsIds: []
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <UiStack flexGrow={1}>
                {errors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1} pb={2}>
                    {errors.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                  </UiStack>
                )}
                <OrderItemTable order={order} />
                <UiHStack justifyContent={'flex-end'}>
                  <UiStack py={8} maxW={960}>
                    <BaseFormFieldGroup>
                      <BaseFormInputField
                        name={'refundNote'}
                        label={'Refund Note'}
                      />
                      <BaseFormInputField
                        name={'refundAmount'}
                        label={'Refund Amount'}
                        type="number"
                        helperText={'In dollars. e.g. 35.90'}
                      />
                    </BaseFormFieldGroup>
                    <UiStack flexDirection={'row-reverse'} pt={12}>
                      <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                        Refund
                      </UiButton>
                    </UiStack>
                  </UiStack>
                </UiHStack>
              </UiStack>
            </Form>
          </Formik>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

const priceLocale = 'en-AU';

const OrderItemTable: FC<{ order: OrderById }> = ({
  order
}) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [field, meta, helpers] = useField('selectedItemsIds');
  const { setValue } = helpers;

  useEffect(() => {
    void setValue(Object.keys(rowSelection).map(index => { return order.orderItems[Number(index)].id; }));
  }, [order.orderItems, rowSelection, setValue]);

  const columns = useMemo<Array<MRT_ColumnDef<OrderItemType>>>(
    () => {
      return [{
        accessorKey: 'name', // access nested data with dot notation
        header: 'Name'
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity'
      },
      {
        accessorKey: 'discountRuleType', // normal accessorKey
        header: 'Discount Type'
      },
      {
        accessorKey: 'discountRuleAmountFormat',
        header: 'Discount Amount'
      },
      {
        accessorKey: 'totalPriceFormat',
        header: 'Price',
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return <UiText>{value}</UiText>;
        }
      },
      {
        accessorKey: 'refundedAt',
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return value
            ? (<UiText color="red.500">Refunded at: {dayjs(value).format('DD/MM/YYYY')}</UiText>)
            : (<></>);
        }
      }];
    }
    , []);

  const table = useMantineReactTable({
    columns,
    data: order.orderItems,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableFilters: false,
    onRowSelectionChange: (valueOrUpdater) => {
      const value = (typeof valueOrUpdater === 'function') ? valueOrUpdater(rowSelection) : valueOrUpdater;
      // If select main ticket, select all
      const isSelectMainTicket = Object.keys(value).some((index) => { return order.orderItems[Number(index)].mainTicket; });
      setRowSelection(
        isSelectMainTicket
          ? order.orderItems
            .map((_, index) => { return index; })
            .reduce((obj, currentObj) => { return { ...obj, [currentObj]: true }; }, {})
          : valueOrUpdater
      );
    },
    state: { rowSelection },
    enableRowSelection: (row) => { return !row.original.refundedAt; },
    // positionActionsColumn: 'last',
    enableBottomToolbar: true,
    renderBottomToolbar: () => {
      return (
        <UiStack spacing={4} p={8} alignItems={'flex-end'}>
          <UiHStack>
            <UiText fontWeight={'bold'}>Discount Value:</UiText>
            <UiText>{order.discountValueFormat}</UiText>
          </UiHStack>
          <UiHStack>
            <UiText fontWeight={'bold'}>Credit card Fee:</UiText>
            <UiText>{order.creditCardFeeFormat}</UiText>
          </UiHStack>
          <UiHStack>
            <UiText fontWeight={'bold'}>Tax:</UiText>
            <UiText>{order.taxFormat}</UiText>
          </UiHStack>
          <UiHStack>
            <UiText fontWeight={'bold'}>Surcharge:</UiText>
            <UiText>{order.surchargeFormat}</UiText>
          </UiHStack>
          <UiHStack>
            <UiText fontWeight={'bold'}>Total price:</UiText>
            <UiText>{order.totalPriceFormat}</UiText>
          </UiHStack>
        </UiStack>
      );
    },
    mantinePaperProps: {
      // shadow: 'sm',
      withBorder: false,
      // @ts-ignore
      sx: {
        borderRadius: uiStyles.borderRadius,
        padding: 24,
        // This a hack to make the pagination div non-absolute.
        "&>div:nth-of-type(3)>div:nth-of-type(2)>div": {
          // backgroundColor: 'red !important',
          // position: 'relative',
          position: 'relative !important',
        },
      },
    },
  });

  return (
    <UiFormControl isInvalid={!!meta.error} flexGrow={1}>
      <MantineReactTable table={table} />
      {!!meta.error && (<UiFormErrorMessage>{meta.error}</UiFormErrorMessage>)}
    </UiFormControl>
  );
};

export default OrderRefundDrawer;
