import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles, UiBox,
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import CategoryList from './CategoryList';
import NewCategory from './NewCategory';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import OrderChange from './OrderChange';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useBuildEventStore } from '@/registration/hook/useBuildEventStore';
import { UiUnorderedList } from '@/lib/ui/Text/UiUnorderedList';
import { UiListItem } from '@/lib/ui/Text/UiListItem';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

const AttendeeTypes: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { mutate, isLoading } = useIsSetMutation();
  const { toNextStep } = useBuildEventSteps();
  const { data: event } = useEventQuery(eventId);
  const { data: eventSettings } = useEventSettingsQuery(eventId);
  const isCloneAvailable = useBuildEventStore((state) => { return state.isCloneAvailable; });

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'attendeeCategory', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set if there are fields in the personal info form.
  useEffect(
    () => {
      return () => {
        if (eventSettings &&
          eventSettings?.attendeeCategory.total > 0 &&
          !eventSettings.attendeeCategory.isSet
        ) {
          mutate({ eventId, key: 'attendeeCategory', bool: true });
        }
      };
    },
    [eventSettings?.attendeeCategory.total, eventSettings?.attendeeCategory.isSet, eventSettings, mutate, eventId]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'attendeeTypes'}
          />
        )}
        message={'Please design your badge.'}
      >
        {(!event?.eventSettings && isCloneAvailable) || event?.cloneStatus === 'in_progress' ? (
          <UiBox />
        ) : (
          <>
            <UiStack
              // pt={4}
              spacing={4}
              flexGrow={1}
              p={8}
            >
              <UiHStack justifyContent={'space-between'}>
                <StepInstruction label={'Instructions'}>
                  <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                    <UiText variant="h5">
                      Attendee groups
                    </UiText>
                    <UiText variant="body1">
                      Attendee groups enable administrators to create customised versions of the registration forms for different segments of event participants.
                    </UiText>
                    <UiText variant="body1">
                      Examples of attendee groups are: Speakers/Presenters, Sponsors & Exhibitors, Virtual Attendees, Media/Press, In-person Attendees, etc.
                    </UiText>
                    <UiText variant="body1">
                      We apply <b>Display Logic</b> to attendee groups so to ensure that each group of attendees only sees the relevant registration form pages and questions. For example:
                    </UiText>
                    <UiUnorderedList>
                      <UiListItem>
                        You can hide in-person functions and tours from your 'Virtual Attendees' registration forms.
                      </UiListItem>
                      <UiListItem>
                        You can also create customised versions of the Personal Information form pages for each attendee categories ensuring you only ask the relevant questions to each attendee group.
                      </UiListItem>
                    </UiUnorderedList>
                    <UiText variant="body1">
                      Attendee groups are created in the 'Attendee groups' step, but applied in the Personal Information, Ticket, and Additional Information steps.
                    </UiText>
                    <UiText variant="body1">
                      You can change the label for 'Attendee groups' in the 'Other settings, customisations' step, if you want to call them eg. Registration groups, Delegate types, etc.
                    </UiText>
                  </UiStack>
                </StepInstruction>
              </UiHStack>
              <BaseDividerHorizontal height={4} />
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <UiHStack justifyContent={'space-between'}>
                  <NewCategory />
                  <OrderChange />
                </UiHStack>
              </BaseAclContainer>
              <CategoryList />
            </UiStack>
            <UiHStack
              // pt={8}
              p={8}
              justifyContent={'space-between'}
            >
              <UiStack flexGrow={1} />
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <UiButton
                  size={'lg'}
                  colorScheme={'primary'}
                  type={'submit'}
                  fontSize={'lg'}
                  isLoading={isLoading}
                  onClick={onNext}
                >
                  Next
                </UiButton>
              </BaseAclContainer>
            </UiHStack>
          </>
        )}

      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default AttendeeTypes;
