import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine
} from '@/lib/ui';
// import Filter from './Filter';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseSpeakerToolsCard from '@/base/App/SpeakerToolsCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';

const Landing: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow/>)}
        sideMenu={null}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseSpeakerToolsCard/>} currentAppName={'speakerTools'}/>)}
      >
        <BaseLayoutBody
        >
          <></>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Landing;
