function encodeKey(key: string): string {
  return `AAA_WEB_${key}`;
}

export interface SaveItemRequest {
  key: string;
  item: string;
}

export function saveItem(request: SaveItemRequest) {
  localStorage.setItem(encodeKey(request.key), request.item);
}

export interface RemoveItemRequest {
  key: string;
}
export function removeItem(request: RemoveItemRequest) {
  localStorage.removeItem(encodeKey(request.key));
}

export interface LoadItemRequest {
  key: string;
}

export interface LoadItemResponse<Item> {
  item: Item|null;
  error?: string;
}

export function loadItem<Item>(request: LoadItemRequest, isJson = true): LoadItemResponse<Item> {
  const itemValue = localStorage.getItem(encodeKey(request.key));
  if (!isJson) {
    return {
      item: itemValue as Item,
    }
  } else {
    if (itemValue) {
      try {
        const item = JSON.parse(itemValue);
        return {
          item: item as Item,
        }
      } catch (e) {
        return {
          item: null,
          error: 'Failed to pase the item',
        };
      }
    }
  }
  return {
    item: null,
    error: 'Item not found',
  };
}
