/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSpinner,
  uiStyles,
  UiHStack, UiButton,
} from '@/lib/ui';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface AppListProps extends UiBoxProps {
}

const ManageNextSubscriptionTerm: FC<AppListProps> = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { data, isLoading } = useQuery<account.SubscriptionLoadNextTermResponse, Error>(
    [account.subscriptionNextTermQueryKey, {tenantId: tenant?.id}],
    async () => {
      return await account.loadNextSubscriptionTerm(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (!data?.subscription || !data?.subscriptionTermId) {
    return null;
  }

  return (
    <UiHStack
      p={8}
      py={4}
      bgColor={'yellow.50'}
      borderRadius={uiStyles.borderRadius}
      // shadow={uiStyles.cardShadow}
      // justifyContent={'flex-end'}
    >
      <UiHStack spacing={1}>
        <UiText>
          You are allowed to change your plan. All changes will be effective on your next bill, due on
        </UiText>
        <UiText color={'green.500'} fontWeight={600}>{dayjs(data.from).format('DD MMMM YY')}</UiText>
      </UiHStack>
    </UiHStack>
  );
};

export default ManageNextSubscriptionTerm;
