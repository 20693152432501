import { type FC } from 'react';
import { UiHStack, UiIconPencilSimple, UiSimpleGrid, UiStack, UiText } from '@/lib/ui';
import Ticket from './Ticket';
import { type OrderById } from '@/api/registration';
import BaseButtonGhost from '@/base/Button/Ghost';

export interface TicketsProps {
  order: OrderById
  onRefundTickets: () => void
}

const Tickets: FC<TicketsProps> = ({
  order,
  onRefundTickets,
}) => {
  const totalPrice = `${order.totalPrice}`; // API response totalPrice: '0.0' - it not number type
  const showRefundButton = ((order.status === 'Paid' || order.status === 'Approved') && totalPrice !== '0.0');
  return (
    <UiStack spacing={4}>
      <UiHStack justifyContent={'flex-start'} spacing={4} alignItems={'center'}>
        <UiText variant={'title'}>Tickets</UiText>
        {showRefundButton && (
          <BaseButtonGhost
            LeftIcon={UiIconPencilSimple}
            onClick={onRefundTickets}
          >
            Refund tickets
          </BaseButtonGhost>
        )}
      </UiHStack>
      <UiSimpleGrid columns={3} gap={8}>
        {
          order.orderItems.map((ticket) => {
            return (
              <Ticket key={ticket.name} ticket={ticket} currencyCode={order.currencyCode} />
            );
          })
        }
      </UiSimpleGrid>
    </UiStack>
  );
};

export default Tickets;
