import { useCallback, useEffect, useState } from 'react';
// import { useWindowScrollY } from '@/lib/hook';

export interface WindowDimensions {
  innerWidth: number
  innerHeight: number
  clientWidth: number
  clientHeight: number
}

export function getCurrentDimensions(): WindowDimensions {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    clientWidth: document.documentElement.clientWidth, // Excluding scrollbar
    clientHeight: document.documentElement.clientHeight // Excluding scrollbar
  };
}

// let isWindowLoaded = false;
// window.addEventListener('load', () => {
//   isWindowLoaded = true;
// });

export interface WindowSize extends WindowDimensions {
  reloadDimensions: () => void;
}

export function useWindowSize(): WindowSize {
  const [screenSize, setScreenSize] = useState(getCurrentDimensions());
  // const windowScrollY = useWindowScrollY();

  const reloadDimensions = useCallback(
    () => {
      // console.log('----', getCurrentDimensions());
      setScreenSize(getCurrentDimensions());
    },
    [setScreenSize, getCurrentDimensions]
  );

  // /**
  //  * Sometimes, pages will start loading with fewer contents which won't trigger the scrollbar.
  //  * However, as the data is loaded which might grow the page to trigger the scrollbar.
  //  * It's hard to observe the change of the page inside a hooker like this.
  //  * This will be a workaround to make sure that when a scrollbar occurred afterwards, the window dimensions will be returned again.
  //  */
  useEffect(
    () => {
      // if (windowScrollY > 0) {
      //   const {innerWidth, clientWidth} = screenSize;
      //   if (clientWidth === innerWidth) {
      //     reloadDimensions();
      //   }
      // }
      reloadDimensions();
    },
    []
  );

  useEffect(() => {
    window.addEventListener('resize', reloadDimensions);
    return () => {
      window.removeEventListener('resize', reloadDimensions);
    };
  }, []);

  return {
    ...screenSize,
    reloadDimensions,
  };
}
