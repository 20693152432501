import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiButton,
  UiIconCurrencyCircleDollar,
  UiIconDotsNine,
  UiIconUsers,
  UiIconUsersFour,
  UiIconCalendarBlank, UiIconUserCircle,
  UiIconIdentificationBadge, UiIconInvoice, UiIconListChecks,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useConfig } from '@/app/ProviderConfig';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth, type AdminAuthHook } from '@/app/ProviderAdminAuth';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseModuleMenu, {MenuProps as BaseMenuProps, Menu as BaseMenu} from '@/base/Layout/ModuleMenu';
dayjs.extend(utc);

interface MenuParams {
  tenantId: number
  tenantCode: string
  hasPermissions: AdminAuthHook['hasPermissions']
  versionTag?: string
}

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (params: MenuParams): BaseMenu[] => {
  // const dashboardMenu = {
  //   name: 'dashboard', // The name has to be unique on the same level.
  //   label: 'Dashboard',
  //   subMenu: [
  //     {
  //       name: 'modules',
  //       label: 'Modules',
  //       url: generatePageUrl('AccountDashboardModules', { tenantCode: params.tenantCode }),
  //       icon: (<UiIconDotsNine size={'2xl'} />)
  //     },
  //   ]
  // };

  const subscriptionMenu = {
    name: 'subscription', // The name has to be unique on the same level.
    label: 'Subscription',
    subMenu: [
      {
        name: 'plan',
        label: 'Plan',
        url: generatePageUrl('AccountSubscriptionPlan', { tenantCode: params.tenantCode }),
        icon: (<UiIconListChecks size={'2xl'} />),
      },
      // {
      //   name: 'billing',
      //   label: 'Billing',
      //   url: generatePageUrl('AccountModuleBill', { tenantCode: params.tenantCode }),
      //   icon: (<UiIconInvoice size={'2xl'} />)
      // }
    ]
  };
  if (!params.hasPermissions(params.tenantId, [[PermissionDomain.Account, PermissionAction.AdminUserAdmin]])) {
    subscriptionMenu.subMenu = [];
  }

  const usersAndAccessMenu = {
    name: 'userAccess', // The name has to be unique on the same level.
    label: 'Users',
    subMenu: [
      {
        name: 'user',
        label: 'Admin users',
        url: generatePageUrl('AccountAccessAdminUser', { tenantCode: params.tenantCode }),
        icon: (<UiIconUsers size={'2xl'} />)
      },
      {
        name: 'team',
        label: 'Teams',
        url: generatePageUrl('AccountAccessTeam', { tenantCode: params.tenantCode }),
        icon: (<UiIconUsersFour size={'2xl'} />)
      }
    ],
  };
  if (!params.hasPermissions(params.tenantId, [[PermissionDomain.Account, PermissionAction.AdminUserAdmin]])) {
    usersAndAccessMenu.subMenu = [];
  }

  // if (versionTag === 'future') {
  //   usersAndAccessMenu.subMenu.push({
  //     name: 'team',
  //     label: 'Teams',
  //     url: generatePageUrl('AccountAccessTeam', { tenantCode }),
  //     icon: (<UiIconUsersFour size={'2xl'} />)
  //   });
  // }

  const setupMenu = {
    name: 'setup', // The name has to be unique on the same level.
    label: 'Manage',
    subMenu: [
      {
        name: 'event',
        label: 'Events',
        url: generatePageUrl('AccountSetupEvent', { tenantCode: params.tenantCode }),
        icon: (<UiIconCalendarBlank size={'2xl'} />)
      },
      {
        name: 'accountSettings',
        label: 'My account',
        url: generatePageUrl('AccountManageAccountSettings', { tenantCode: params.tenantCode }),
        icon: (<UiIconUserCircle size={'2xl'} />)
      }
    ]
  };

  return [
    // dashboardMenu,
    setupMenu,
    subscriptionMenu,
    usersAndAccessMenu,
  ];
};

export interface SideNavProps extends Omit<BaseMenuProps, 'menus'> {
}

const SideNav: FC<SideNavProps> = ({
  activeNodes = [],
  enableTransition = false
}) => {
  const appConfig = useConfig();
  const { tenant } = useTenantRoute();
  const { hasPermissions } = useAdminAuth();
  const menus = useMemo(
    () => {
      return getMenus({
        tenantId: tenant?.id ?? 0,
        tenantCode: tenant?.code ?? '',
        hasPermissions,
        versionTag: appConfig.versionTag,
      });
    }, [
      tenant?.code,
      appConfig.versionTag,
      hasPermissions,
    ]);

  return (
    <BaseModuleMenu
      menus={menus}
      activeNodes={activeNodes}
      enableTransition={enableTransition}
    />
  );
};

export default SideNav;
