import { type FC, type ReactNode } from 'react';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface ProviderQueryProps {
  children: ReactNode
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      // refetchOnReconnect: false,
      // retry: false,
      staleTime: 10*60*1000, // milliseconds. This is how long useQuery will cache the results.
      // cacheTime: Infinity,
    },
  },
});

export const ProviderQuery: FC<ProviderQueryProps> = ({
  children
}) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
