import {
  type ApiRequest,
  type ApiResponse,
  formDataApi,
  properCallApi,
} from '../tenantClient';

export const loadImportAttemptsForTableQueryKey =
  'importer.loadImportAttemptsForTable';

export const loadImportAttemptErrorByIdQueryKey =
  'importer.loadImportAttemptErrorById';

export interface UploadCSVRequest {
  modelType: string
  uploadFile: File
}
export interface UploadCSVResponse {
  errors: string[] | undefined
}

export function uploadCSV(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: UploadCSVRequest): Promise<UploadCSVResponse> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/csv_imports/import';
    const formData = new FormData();
    formData.append('model_type', params.modelType);
    formData.append('import_file', params.uploadFile);

    const response = await formDataApi<UploadCSVResponse>(request, formData);
    return {
      errors: response.errors,
    };
  };
}

export interface ImportAttemptsTableRow {
  id: number
  status: string
  createdBy: string
  createdAt: string
}

export interface ImportAttemptsTableQueryRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search: string
  modelType: string
}

export function loadImportAttemptsForTable(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (
    params: ImportAttemptsTableQueryRequest
  ): Promise<ApiResponse<ImportAttemptsTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/import_attempts';
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      ...(params.search ? { search: params.search } : {}),
      model_type: params?.modelType,
    };

    return await properCallApi<ApiResponse<ImportAttemptsTableRow>>(request);
  };
}

export interface BaseImportAttemptErrorInfo {
  id: number
  validationErrors: Record<string, string[]>
  importErrors?: Record<string, any>
}
export interface RegistrationImportAttemptErrorInfo
  extends BaseImportAttemptErrorInfo {
  status: string
  eventName?: string
  email?: string
  firstName?: string
  lastName?: string
  attendeeCategoryName?: string
  registrationTypeName?: string
  createdAt: string
  updatedAt: string
  pronoun?: string
  tmpAvatar?: string
  bio?: string
  city?: string
  company?: string
  companyPosition?: string
  companyWebsite?: string
  country?: string
  facebook?: string
  gender?: string
  github?: string
  headline?: string
  instagram?: string
  jobTitle?: string
  linkedin?: string
  location?: string
  phone?: string
  skype?: string
  title?: string
  twitter?: string
  wechat?: string
  whatsappNumber?: string
}

export interface LoadImportAttemptErrorByIdRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search: string
  importAttemptId: string
}

export function loadRegistrationImportAttemptErrorById<T extends BaseImportAttemptErrorInfo>(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (
    params: LoadImportAttemptErrorByIdRequest
  ): Promise<ApiResponse<T>> => {
    request.method = 'GET';
    request.endpoint.path =
      '/api/v1/import_attempt_errors?import_attempt_id=' +
      params.importAttemptId;
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      ...(params.search ? { search: params.search } : {}),
    };

    return await properCallApi<ApiResponse<T>>(request);
  };
}
