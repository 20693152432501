import {
  type ApiRequest,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const registrationSetupQueryKey = 'registration.registrationSetup';

interface RegistrationSetupQueryRequest {
  eventId: string | number
}

export interface RegistrationSetup {
  id: number
  createdAt: Date
  updatedAt: Date
  customInvoiceTemplate: string
  customRefundReceiptTemplate: string
}

export function loadRegistrationSetup(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: RegistrationSetupQueryRequest): Promise<ApiResponseSingle<RegistrationSetup>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/registration_setups';
    request.endpoint.query = {
      eventId: params.eventId
    };
    return await properCallApi<ApiResponseSingle<RegistrationSetup>>(request);
  };
}

export interface RegistrationSetupParams {
  customInvoiceTemplate: string | Blob
  customRefundReceiptTemplate: string | Blob
  invoiceTemplateName: string
  refundReceiptName: string
}

export function updateRegistrationSetup(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: RegistrationSetupParams, eventId: string | number): Promise<ApiResponseSingle<RegistrationSetup>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/admin/registration_setups';
    request.endpoint.query = {
      eventId
    };

    request.payload = {
      registrationSetup: params
    };

    return await properCallApi<ApiResponseSingle<RegistrationSetup>>(request);
  };
}
