import { type FC } from 'react';
import {
  UiText,
  UiIconUsersThree,
  UiStack
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface DiscountCodesProps {
  isActive?: boolean
}

const DiscountCodes: FC<DiscountCodesProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconUsersThree}
      description={'Discount codes'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventDiscountCodes', { tenantCode, eventId })}
      isSet={eventSettings?.discountCode?.isSet}
      // stepNumber={2}
    >
      <UiStack>
        <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.discountCode?.total} total</UiText>
      </UiStack>
    </Step>
  );
};

export default DiscountCodes;
