/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UiHStack, UiStack, uiStyles } from '@/lib/ui';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import Verify from './Verify';
import { AdminUserSessionState } from '@/api/constant';
import { generatePageUrl } from '@/app/pages';

const VerifyEmailPasscode: FC = () => {
  const { adminAuth, setAccessToken } = useAdminAuth();
  const navigate = useNavigate();
  const toChooseTenant = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginChooseTenant'));
    },
    [navigate, generatePageUrl]
  );

  useEffect(() => {
    if (adminAuth.user?.id && adminAuth.adminUserSessionState === AdminUserSessionState.Active) {
      toChooseTenant();
    }
  }, [adminAuth.adminUserSessionState, adminAuth.user?.id]);

  return (
    <BaseLayout
      // smartWindow={(<LoginSmartWindow />)}
      smartWindow={(<UiStack/>)}
      sideMenu={null}
      smartButton={(<UiHStack flexGrow={1}/>)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        <UiStack
          // breadcrumbNodes={breadcrumbNodes}
          spacing={4}
          alignItems={'stretch'}
          flexGrow={1}
        >
          <UiHStack
            flexGrow={1}
            alignItems={'stretch'}
            borderRadius={uiStyles.borderRadius}
            spacing={0}
          >
            <UiStack
              alignItems={'center'}
              justifyContent={'center'}
              flexGrow={1}
              borderRightRadius={uiStyles.borderRadius}
              spacing={0}
            >
              <Verify
                onVerified={toChooseTenant}
              />
            </UiStack>
          </UiHStack>
        </UiStack>
      </LoginBody>
    </BaseLayout>
  );
};

export default VerifyEmailPasscode;
