import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppLiveStreamingCard from '@/base/App/LiveStreamingCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface LiveStreamingProps extends Omit<ModuleCardProps, 'children'> {
  // isSelected: boolean
  // bgColor?: ModuleCardProps['bgColor']
}

const LiveStreaming: FC<LiveStreamingProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppLiveStreamingCard />
    </ModuleCard>
  );
};

export default LiveStreaming;
