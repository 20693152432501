import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { type PaymentOption } from '@/api/registration';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import {
  UiHStack,
  UiIconGear,
  UiStack,
  UiTag,
  UiText,
  type UiHStackProps
} from '@/lib/ui';
import OnlinePaymentForm from './OnlinePaymentForm';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useDisclosure } from '@chakra-ui/react';
import { useMemo, type FC } from 'react';
import AboutOnline from './AboutOnline';
export interface OnlineProps extends UiHStackProps {
  paymentOptions: PaymentOption[]
}

const Online: FC<OnlineProps> = ({ paymentOptions, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { tenant } = useRegisterRoute();
  const { isOpen: isAboutOpen, onToggle: onAboutToggle } = useDisclosure();
  const onlinePayment = useMemo(
    () => { return paymentOptions.find((option) => { return ((option.name === 'stripe' || option.name === 'eWay' || option.name === 'secure_pay' || option.name === 'nab_transact')); }); }
    , [paymentOptions]);
  return (
    <>
      <UiHStack
        justifyContent={'space-between'}
        alignItems={'center'}
        // {...uiStyles.hover}
        // alignItems={'flex-start'}
        // justifyContent={'flex-start'}
        spacing={4}
        p={6}
        bgColor={'#fff'}
        {...props}
        // borderRadius={uiStyles.borderRadius}
      >
        <UiHStack spacing={4}>
          <UiStack spacing={0} alignItems={'stretch'} justifyContent={'space-between'}>
            <UiText variant={'body1'}>Credit card payments</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>Set up your own online payment gateway</UiText>
          </UiStack>
        </UiHStack>
        <UiHStack spacing={8}>
          { onlinePayment?.enabled
            ? <UiTag py={2} px={4} colorScheme={'green'}>Enabled</UiTag>
            : <UiTag py={2} px={4} colorScheme={'red'}>Not enabled</UiTag>}
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiHStack
              spacing={0}
              justifyContent={'space-between'}
              flexDirection={'column'}
            >
              <BasePlainIcon
                label={'Settings'}
                Icon={UiIconGear}
                onClick={onToggle}
              />
            </UiHStack>
          </BaseAclContainer>
        </UiHStack>
      </UiHStack>
      <OnlinePaymentForm
        onClose={onToggle}
        isVisible={isOpen}
        paymentOption={onlinePayment}
        onSaveSuccess={() => { }}
        tenant={tenant}
      />
      <AboutOnline
        onClose={onAboutToggle}
        isOpen={isAboutOpen}
      />
    </>
  );
};

export default Online;
