export enum DiscountType {
  Percentage = 1,
  FixedAmount = 2,
}

export const discountTypeLabels: Record<DiscountType, string> = {
  [DiscountType.Percentage]: 'Percentage',
  [DiscountType.FixedAmount]: 'Fixed amount'
};

export const discountTypeOptions: Array<{ value: number, label: string }> = [
  { value: DiscountType.Percentage, label: discountTypeLabels[DiscountType.Percentage] },
  { value: DiscountType.FixedAmount, label: discountTypeLabels[DiscountType.FixedAmount] }
];
