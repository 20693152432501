import { type ApiRequest } from '@/api/tenantClient';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { type UserSession, useRegistrationAuth } from '@/app/ProviderRegistrationAuth';

export function useTenantApi() {
  const { tenant, isLoading: isTenantLoading, isSuccess: isLoaded } = useTenantRoute();
  const { adminAuth } = useAdminAuth();
  const { userSession } = useRegistrationAuth();

  const createTenantApiRequest = (): ApiRequest => {
    return {
      method: 'POST',
      endpoint: {
        host: tenant?.apiEndpoint,
        path: ''
      },
      headers: {
        uid: userSession?.email ?? '',
        client: userSession?.client ?? '',
        'access-token': userSession?.authToken ?? ''
      }
    };
  };

  const createTenantApiRequestWithSession = (session: UserSession | undefined) => {
    return (): ApiRequest => {
      return {
        method: 'POST',
        endpoint: {
          host: tenant?.apiEndpoint,
          path: ''
        },
        headers: {
          uid: session?.email ?? '',
          client: session?.client ?? '',
          'access-token': session?.authToken ?? ''
        }
      };
    };
  };

  const createTenantAdminApiRequest = (): ApiRequest => {
    const request = createTenantApiRequest();
    request.headers = {
      ...request.headers,
      'access-token': adminAuth.accessToken,
    };
    return request;
  };

  return {
    createTenantApiRequest,
    createTenantAdminApiRequest,
    createTenantApiRequestWithSession,
    isLoading: isTenantLoading,
    isLoaded
  };
}
