import { type TenantAdminUserState } from '@/api/constant/tenantAdminUser';
import {
  type ApiRequest,
  properCallApi
} from '@/api/tenantClient';

export const tenantAdminUserQueryKey = 'account.tenantAdminUser';

export interface TenantAdminUserSessionUser {
  id: number
  state: TenantAdminUserState
  email: string
  name: string
  roles: string[]
  createdAt: number | null
  updatedAt: number | null
}

export interface TenantAdminUserSession {
  user: TenantAdminUserSessionUser
  authToken: string
  client: string
}

export const tenantAdminUserDefaultData: TenantAdminUserSession = {
  user: {
    id: 0,
    state: 2,
    email: '',
    name: '',
    roles: [],
    createdAt: null,
    updatedAt: null
  },
  client: '',
  authToken: ''
};

export interface TenantAdminUserLoginRequest {
  tenantApiEndpoint: string
  email: string // Admin user email.
  password: string // Admin user password.
}

const dummyTenantAdminUserSession: TenantAdminUserSession = {
  user: {
    id: 1,
    state: 1,
    email: 'superadmin@arinex.dev',
    name: 'Super admin',
    roles: [],
    createdAt: 1708645868,
    updatedAt: 1708645868
  },
  client: 'web-app',
  authToken: '__DUMMY_AUTH_TOKEN__'
};

/**
 * Always return an admin user data.
 * If the email is empty, an empty admin user data will be returned.
 */
export const loginTenantAdminUser = (createTenantRequest: () => ApiRequest) => {
  const apiRequest = createTenantRequest();
  return async (
    params: TenantAdminUserLoginRequest
  ): Promise<TenantAdminUserSession> => {
    if (params.email) {
      apiRequest.endpoint.path = '/api/v1/auth/login';
      apiRequest.payload = {
        email: params.email,
        password: params.password
      };
      // return dummyTenantAdminUserSession;
      return await properCallApi<TenantAdminUserSession>(apiRequest);
    }
    return tenantAdminUserDefaultData;
  };
};
