import { useState, type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
} from '@/lib/ui';
import { PhoneInput as ReactInternationalPhone } from 'react-international-phone';
import 'react-international-phone/style.css';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';

export interface InputProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  disabled?: boolean
  hidden?: boolean
}

const Input: FC<InputProps> = ({
  label,
  name,
  helperText = undefined,
  isRequired = true,
  placeholder = '',
  layout = 'inline',
  disabled = false,
  hidden = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Field name={name} >
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} style={{ display: hidden ? 'none' : 'block' }}>
            <FieldContainer layout={layout}>
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'} spacing={0} >
                <ReactInternationalPhone
                  name={name}
                  defaultCountry="au"
                  value={field.value}
                  hideDropdown={false}
                  placeholder={placeholder}
                  disabled={disabled}
                  onFocus={() => {
                    return setIsFocused(true);
                  }}
                  onBlur={() => {
                    return setIsFocused(false);
                  }}
                  onChange={async (value, meta) => { return await form.setFieldValue(name, value); }}
                  className={`chakra-ui-override ${isFocused ? 'focused' : ''}`}
                  inputStyle={{
                    borderRadius: '0px 8px 8px 0px',
                    width: '100%',
                    height: '40px',
                    border: isFocused ? '2px solid var(--chakra-colors-primary-400)' : '1px solid var(--chakra-colors-gray-300)',
                    backgroundColor: '#fff',
                    fontSize: 'var(--chakra-fontSizes-md)',
                    color: 'var(--chakra-colors-gray-900)',
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      paddingLeft: '4px',
                      height: '40px',
                      borderRadius: '8px 0px 0px 8px',
                      border: '1px solid var(--chakra-colors-gray-300)',
                      backgroundColor: '#fff'
                    }
                  }}
                />
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default Input;
