import { type FC } from 'react';
import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { type FieldMetadataSectionBreak } from './Field';
import { validateLabelFields } from '@/lib/util';

export interface FieldAttributes {
  label: string
  description?: string
}

export interface FieldSectionBreakFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataSectionBreak) => void
  fieldMetadata?: FieldMetadataSectionBreak
}

interface Errors {
  label?: string
  description?: string
}

const FieldSectionBreakForm: FC<FieldSectionBreakFormProps> = ({
  onSave,
  fieldMetadata = undefined
}) => {
  const initValues: FieldAttributes = { label: '', description: '' };
  if (fieldMetadata) {
    initValues.label = fieldMetadata.label;
    initValues.description = fieldMetadata.description;
  }

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        const labelError = validateLabelFields(values.label);
        if (labelError) {
          errors.label = labelError;
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        // alert(JSON.stringify(values, null, 2));
        // setSubmitting(false);
        if (fieldMetadata) {
          onSave({
            ...fieldMetadata,
            ...{
              type: 'sectionBreak',
              label: values.label,
              description: values.description,
              canDelete: true,
              canEdit: true
            }
          });
        } else {
          onSave({
            id: uuidv4(),
            type: 'sectionBreak',
            label: values.label,
            description: values.description,
            canDelete: true,
            canEdit: true
          });
        }
      }}
    >
      <Form
        style={{
          display: 'flex',
          marginTop: 0
        }}
      >
        <UiStack flexGrow={1}>
          <BaseFormFieldGroup>
            <BaseFormInputField
              name={'label'}
              label={'Label'}
            />
            <BaseFormInputField
              name={'description'}
              label={'Description'}
              isRequired={false}
            />
          </BaseFormFieldGroup>
          <UiStack flexDirection={'row-reverse'} pt={12}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
              Save
            </UiButton>
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default FieldSectionBreakForm;
