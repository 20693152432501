import { type EventSchema } from '@/registration/eventGeneration/EventDataVisualizer';
import { properCallApi, type ApiRequest, type ApiResponseSingle } from '../tenantClient';

export interface AIChatMessage {
  id: number
  sender: 'user' | 'assistant'
  message: string
  aiChatSessionId: string
  createdAt: string
  data?: string
}

export interface AIChatMessageSaveRequest {
  message: string
  aiChatSessionId: string
}

export function saveAIChatMessage(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: AIChatMessageSaveRequest): Promise<ApiResponseSingle<AIChatMessage>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/ai_chat_messages';
    request.payload = { aiChatMessage: params };
    return await properCallApi<ApiResponseSingle<AIChatMessage>>(request);
  };
}
