import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps, UiSimpleGrid,
  UiStack, uiStyles, UiText,
} from '@/lib/ui';
import BaseFormSelectField, { type TimezoneOption, type Option } from '@/base/Form/SelectField';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { eventTypeOptions } from '@/api/constant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { createEvent, type EventSaveRequest } from '@/api/account';
import { useTenantApi } from '@/account/hook/useTenantApi';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { timezoneOptionQueryKey, type Event } from '@/api/registration';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { toBase64 } from '@/lib/util';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export interface ServiceHoursFormProps extends UiHStackProps {
  tenantId: number
  subscription: account.Subscription,
  onClose: () => void
  onSaveSuccess: () => void
  isOpen: boolean
}

const formSchema = Yup.object().shape({
});

interface FormData {
  usedHours: number
}

const ServiceHoursForm: FC<ServiceHoursFormProps> = ({
  tenantId,
  subscription,
  onSaveSuccess,
  onClose,
  isOpen
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<account.UpdateSubscriptionServicePackHoursResponse, Error, account.UpdateSubscriptionServicePackHoursRequest>({
    mutationFn: async (data: account.UpdateSubscriptionServicePackHoursRequest) => {
      return await account.updateTenantSubscriptionServicePackHours(createGlobalApiAclRequest())(data);
    },
    onSuccess: async (result) => {
      if (result?.errors && result.errors?.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        await queryClient.invalidateQueries({ queryKey: [account.tenantCurrentSubscriptionQueryKey] });
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to update the service pack hours.']);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getInitValues = useCallback((subscription: account.Subscription) => {
    return {
      usedHours: subscription.plan.servicePack.usedHours ?? 0,
    };
  }, []);

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    mutate({
      usedHours: values.usedHours ?? 0,
      tenantId,
    })
    setSubmitting(false);
  }, [mutate]);

  return (
    <Formik<FormData>
      initialValues={getInitValues(subscription)}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={'Service pack hours'}
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <UiStack pb={8}>
          <UiText>Current</UiText>
          <UiStack p={6} py={4} bgColor={'blackAlpha.50'} borderRadius={uiStyles.borderRadius}>
            <UiSimpleGrid spacing={0} columns={3} flexGrow={1}>
              <UiStack spacing={2}>
                <UiText variant={'body1'} color={'text.secondary'}>Total hours</UiText>
                <UiText>{subscription.plan.servicePack.hours ?? 0}</UiText>
              </UiStack>
              <UiStack spacing={2}>
                <UiText variant={'body1'} color={'text.secondary'}>Total hours used</UiText>
                <UiText>{subscription.plan.servicePack.usedHours ?? 0}</UiText>
              </UiStack>
              <UiStack spacing={2}>
                <UiText variant={'body1'} color={'text.secondary'}>Total hours available</UiText>
                <UiText color={'green.500'}>{(subscription.plan.servicePack.hours ?? 0) - (subscription.plan.servicePack.usedHours ?? 0)}</UiText>
              </UiStack>
            </UiSimpleGrid>
          </UiStack>
        </UiStack>
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'usedHours'}
            label={'Total hours used'}
            type={'number'}
            // helperText={`Current hours: ${subscription.plan.servicePack.usedHours ?? 0}/${subscription.plan.servicePack.hours ?? 0} (used/total)`}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default ServiceHoursForm;
