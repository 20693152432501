import { type FC, useCallback, useEffect, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { UiHStack, UiSimpleGrid, UiStack, UiText } from '@/lib/ui';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import type { ActionMeta, OnChangeValue, OptionBase } from 'chakra-react-select';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import InsightTable from './InsightTable';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import SimpleMultiSelectElement, { type Option } from '@/base/Form/SimpleMultiSelectElement';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';

interface CustomOption extends OptionBase {
  label: string
  value: string
}

const InsightPage: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const [eventId, setEventId] = useState<string>('');
  const [attendeeCategoryIds, setAttendeeCategoryIds] = useState<CustomOption[]>([]);

  const [eventOptions, setEventOptions] = useState<SelectElementOption[]>([]);
  const [pagination, setPagination] = useState<{ pageIndex: number, pageSize: number }>({ pageIndex: 0, pageSize: 10 });
  const { data: eventList, isFetching: isLoadingEvent } = useEventListQuery({ pagination });
  const { data: attendeeCategories } = useAttendeeCategoryQuery(eventId);

  useEffect(() => {
    if (eventList?.items && Array.isArray(eventList?.items)) {
      const options = eventList.items.map((event: { id: string, name: string }) => {
        return {
          value: event.id,
          label: event.name,
        };
      });
      setEventOptions([...eventOptions, ...options]);
    }
  }, [eventList]);

  const attendeeCategoryOptions = (attendeeCategories?.length ? attendeeCategories : []).map(({ id: value, name: label }) => {
    return {
      value: value.toString(),
      label
    };
  });

  const handleChangeEvent = useCallback(
    (option: OnChangeValue<SelectElementOption, false>) => {
      setEventId(option?.value?.toString() ?? '');
    },
    []
  );

  const handleScrollToBottom: ((event: WheelEvent | TouchEvent) => void) | undefined = async () => {
    if (eventList?.pagination?.totalPages && pagination.pageIndex + 1 < eventList?.pagination?.totalPages) {
      setPagination((prev: { pageIndex: number, pageSize: number }) => { return { ...prev, pageIndex: prev.pageIndex + 1 }; });
    }
  };

  const handleChange = (newValue: OnChangeValue<Option, boolean>, actionMeta: ActionMeta<Option>) => {
    setAttendeeCategoryIds(newValue as CustomOption[]);
  };

  useEffect(() => {
    if (attendeeCategoryOptions.length === 0 && attendeeCategoryIds.length > 0) {
      setAttendeeCategoryIds([]);
    }
  }, [attendeeCategoryOptions, attendeeCategoryIds]);

  const categoryIds = attendeeCategoryIds.map(({ value }) => { return Number(value); });

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['report', 'insight']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody isStandalone={false} >
        <UiHStack justifyContent='flex-start' spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Insights</UiText>
        </UiHStack>
        <UiStack mt={4}>
          <UiSimpleGrid columns={4} spacing={8}>
            <UiStack flexGrow={1} >
              <BaseSimpleSelectElement
                optionValue={eventId}
                onChange={handleChangeEvent}
                isLoading={isLoadingEvent}
                options={eventOptions}
                onScrollToBottom={handleScrollToBottom}
                placeholder={'Select event'}
              />
            </UiStack>
            <UiStack flexGrow={1} gridColumn="2 / span 2">
              <SimpleMultiSelectElement
                optionValue={eventId}
                onChange={handleChange}
                options={attendeeCategoryOptions}
                value={attendeeCategoryIds}
                placeholder={'Select attendee category'}
              />
            </UiStack>
          </UiSimpleGrid>
        </UiStack>
        <BaseDividerHorizontal height={8} />
        <UiStack height={4} />
        {!!eventId && Boolean(categoryIds.length) && (
          <UiStack mt={5}>
            <InsightTable eventId={Number(eventId)} categoryIds={categoryIds} />
          </UiStack>
        )}
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default InsightPage;
