import { type FC } from 'react';
import {
  Bell
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconBellProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconBell: FC<UiIconBellProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Bell}
      {...props}
    />
  );
};
