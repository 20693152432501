import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconChalkboard } from '@/lib/ui';

export interface DigitalPosterCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
  showDescription?: boolean
}

const DigitalPosterCard: FC<DigitalPosterCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false,
  showDescription = true
}) => {
  return (
    <AppCard
      icon={(<UiIconChalkboard size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      name={'Digital poster management'}
      description={showDescription ? 'Digital poster submission, review and display' : undefined}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default DigitalPosterCard;
