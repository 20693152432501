import { type FC, ReactNode, useCallback, useState } from 'react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  UiStack,
  uiStyles,
  UiHStack,
  UiText, UiIconProhibit, UiIconNotePencil, UiIconEye, UiTag,
} from '@/lib/ui';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { ModulePermission, ModulePermissionNone } from './permissions';
import ModulePermissionEdit from '@/account/access/TeamInfo/ModulePermissionEdit';

export interface ModulePermissionCardProps extends UiHStackProps {
  teamId: number
  moduleCard: ReactNode
  module: PermissionDomain
  permission: ModulePermission
  disableViewOnly?: boolean
  // onPermissionChange: (module: PermissionDomain, permission: ModulePermission) => void
}

const ModulePermissionCard: FC<ModulePermissionCardProps> = ({
  teamId,
  moduleCard,
  module,
  permission,
  disableViewOnly = false,
  ...props
  // onPermissionChange,
}) => {

  return (
    <UiHStack
      // borderRadius={uiStyles.borderRadius}
      p={8}
      py={6}
      bgColor={'#fff'}
      // opacity={permission == "None" ? 0.5 : 1}
      justifyContent={'space-between'}
      {...props}
    >
      {moduleCard}
      {/*<BaseDividerHorizontal height={3}/>*/}
      <UiHStack justifyContent={'flex-end'} flexGrow={1}>
        <ModulePermissionEdit
          teamId={teamId}
          moduleCard={moduleCard}
          module={module}
          permission={permission}
          disableViewOnly={disableViewOnly}
          // onPermissionChange={onPermissionChange}
        />
        {/*{permission == PermissionAction.Read && (*/}
        {/*  <UiTag colorScheme={'teal'} whiteSpace={'nowrap'} p={2}>View only</UiTag>*/}
        {/*)}*/}
        {/*{permission == PermissionAction.Write && (*/}
        {/*  <UiTag colorScheme={'purple'} whiteSpace={'nowrap'} p={2}>View & edit</UiTag>*/}
        {/*)}*/}
        {/*{permission == "None" && (*/}
        {/*  <UiTag colorScheme={'gray'} whiteSpace={'nowrap'} p={2}>No access</UiTag>*/}
        {/*)}*/}
      </UiHStack>
    </UiHStack>
  );
};

export default ModulePermissionCard;
