import { type FC, useMemo, useState } from 'react';
import BaseLayout from '@/base/Layout';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { useQuery } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type RegistrationImportAttemptErrorInfo, loadRegistrationImportAttemptErrorById, loadImportAttemptErrorByIdQueryKey } from '@/api/importer';
import DataTable from './DataTable';
import { type ApiResponse } from '@/api/tenantClient';
import { useParams } from 'react-router-dom';
import { type ManageRouteParams } from '@/registration/pages';
import { UiStack } from '@/lib/ui';

const RegistrationImportAttemptErrorPage: FC = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });

  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  const params = useParams<ManageRouteParams['RegistrationImporterAttemptErrorRegistration']>();

  const importAttemptId = useMemo<number | null>(
    () => { return params.importAttemptId ? parseInt(params.importAttemptId, 10) : null; },
    [params.importAttemptId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Registration Importer', url: generatePageUrl('RegistrationImporterRegistration', { tenantCode }) },
        /**
         * @todo orderId here should be the order serial number, not the database table row id.
         */
        { label: `Import attempt errors (${importAttemptId ?? ''})` }
      ];
    },
    [importAttemptId, tenantCode]
  );

  const { data, isLoading, isError, isFetching, refetch } = useQuery<ApiResponse<RegistrationImportAttemptErrorInfo>, Error>({
    queryKey: [loadImportAttemptErrorByIdQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<RegistrationImportAttemptErrorInfo>> => {
      return await loadRegistrationImportAttemptErrorById<RegistrationImportAttemptErrorInfo>(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
        search,
        importAttemptId: `${importAttemptId}`
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: !isTenantLoading && !!importAttemptId,
  });

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody breadcrumbNodes={breadcrumbNodes} >
        <UiStack p={8}>
          <DataTable
            data={data?.items ?? []}
            isLoading={isLoading}
            isError={isError}
            isFetching={isFetching}
            refetch={refetch}
            filters={filters}
            setFilters={setFilters}
            sorting={sorting}
            setSorting={setSorting}
            search={search}
            setSearch={setSearch}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={data?.pagination?.total ?? 0}
          />
        </UiStack>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default RegistrationImportAttemptErrorPage;
