import { type FC, type ReactNode, type ReactElement } from 'react';
import {
  UiBox,
  UiHStack,
  type UiHStackProps,
  UiText,
  type UiTextProps
} from '@/lib/ui';

export interface BaseProps extends UiHStackProps {
  children: ReactNode
  icon: ReactElement
  textProps?: UiTextProps
}

export const Base: FC<BaseProps> = ({
  children,
  icon,
  textProps = {},
  ...props
}) => {
  return (
    <UiHStack
      // width={'100%'}
      // flexGrow={1}
      px={4}
      py={4}
      bgColor={'#fff'}
      alignItems={'center'}
      {...props}
    >
      <UiBox>
        {icon}
      </UiBox>
      <UiText variant={'body1'} {...textProps}>
        {children}
      </UiText>
    </UiHStack>
  );
};

export default Base;
