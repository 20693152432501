import { type FC, useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { useDisclosure } from '@chakra-ui/react';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton, UiHStack, UiIconEye, UiIconEyeSlash, UiBox, uiStyles,
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';
import { ApiError } from '@/api/error';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseFormDrawer from '@/base/Form/Drawer';
import type { SubscriptionEditor } from '@/account/hook/useSubscriptionPlanEditor';

export interface AAAFormProps extends UiHStackProps {
  subscription: account.Subscription
  subscriptionEditor: SubscriptionEditor
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  start: string // Time
  packagePercentage: number
  registrationFreeUnits: number
  abstractManagementFreeUnits: number
  digitalPosterFreeUnits: number
}

const formSchema = Yup.object().shape({
  start: Yup.date().required('Start date is empty.'),
  packagePercentage: Yup.number().integer().min(0).max(100),
  registrationFreeUnits: Yup.number().integer().min(0),
  abstractManagementFreeUnits: Yup.number().integer().min(0),
  digitalPosterFreeUnits: Yup.number().integer().min(0),
});

const AAAFormDrawer: FC<AAAFormProps> = ({
  subscription,
  subscriptionEditor,
  onClose,
  onSaveSuccess,
  isVisible,
}) => {
  // const [errors, setErrors] = useState<string[]>([]);
  const { updateSubscription } = subscriptionEditor;

  const initValues: FormData = useMemo(() => {
    return {
      start: `${dayjs(subscription.plan.from).utc()}`,
      end: `${dayjs(subscription.plan.from).utc()}`,
      packagePercentage: subscription.plan.discount?.packagePercentage ?? 0,
      registrationFreeUnits: subscription.plan.discount?.registrationFreeUnits ?? 0,
      abstractManagementFreeUnits: subscription.plan.discount?.abstractManagementFreeUnits ?? 0,
      digitalPosterFreeUnits: subscription.plan.discount?.digitalPosterFreeUnits ?? 0,
    };
  }, [
    subscription.plan.from,
    subscription.plan.to,
    subscription.plan.discount?.packagePercentage,
    subscription.plan.discount?.registrationFreeUnits,
    subscription.plan.discount?.abstractManagementFreeUnits,
    subscription.plan.discount?.digitalPosterFreeUnits,
  ]);

  const submitForm = useCallback((values: FormData) => {
    const _newSubscription = {...subscription};
    _newSubscription.plan.from = dayjs(values.start).unix();
    _newSubscription.plan.discount = {
      packagePercentage: values.packagePercentage ?? 0,
      registrationFreeUnits: values.registrationFreeUnits ?? 0,
      abstractManagementFreeUnits: values.abstractManagementFreeUnits ?? 0,
      digitalPosterFreeUnits: values.digitalPosterFreeUnits ?? 0,
    };
    updateSubscription(subscription);
    onSaveSuccess();
  }, [
    subscription,
    updateSubscription,
  ]);

  return (
    <>
      <Formik
        initialValues={initValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={formSchema}
        onSubmit={async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
          setSubmitting(true);
          await submitForm(values);
          setSubmitting(false);
        }}
      >
        <BaseFormDrawer
          isOpen={isVisible}
          onClose={onClose}
          title={'Subscription'}
          size={'xl'}
          // isLoading={isLoading}
        >
          <UiStack alignItems={'flex-start'} spacing={4}>
            {/*{errors.length > 0 && (*/}
            {/*  <UiStack spacing={4} flexGrow={1} pb={2}>*/}
            {/*    {errors.map((error, index) => {*/}
            {/*      return (*/}
            {/*        <BaseMessageBarError key={index} width={inputWidth}>*/}
            {/*          {error}*/}
            {/*        </BaseMessageBarError>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </UiStack>*/}
            {/*)}*/}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'start'}
                type={'date'}
                label={'Start date'}
                // layout={'stack'}
              />
              <BaseFormInputField
                name={'packagePercentage'}
                type={'number'}
                label={'Package discount'}
                helperText={'This should be the percentage of the discount of the page. Value of 15 means 15% off'}
                // layout={'stack'}
              />
              <BaseFormInputField
                name={'registrationFreeUnits'}
                type={'number'}
                label={'Total free registration (12 months)'}
                helperText={'E.g. value of 500 here means no registration fee charged for the first 500 registrations per 12 months.'}
                // layout={'stack'}
              />
              <BaseFormInputField
                name={'abstractManagementFreeUnits'}
                type={'number'}
                label={'Total free abstracts (12 months)'}
                helperText={'E.g. value of 500 here means no submission fee charged for the first 500 abstracts per 12 months.'}
                // layout={'stack'}
              />
              <BaseFormInputField
                name={'digitalPosterFreeUnits'}
                type={'number'}
                label={'Total free digital posters (12 months)'}
                helperText={'E.g. value of 500 here means no uploading fee charged for the first 500 digital posters per 12 months.'}
                // layout={'stack'}
              />
            </BaseFormFieldGroup>
            <UiStack py={4} spacing={4}>
              {/*{isLoading*/}
              {/*  ? (*/}
              {/*    <UiButton px={8} size={'lg'} colorScheme={'green'} width={inputWidth}>*/}
              {/*      Signing in...*/}
              {/*    </UiButton>*/}
              {/*  ) : (*/}
              {/*    <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'} width={inputWidth}>*/}
              {/*      Sign in*/}
              {/*    </UiButton>*/}
              {/*  )}*/}
              {/*<UiHStack justifyContent={'flex-end'}>*/}
              {/*  <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'}>*/}
              {/*    Save*/}
              {/*  </UiButton>*/}
              {/*</UiHStack>*/}
            </UiStack>
          </UiStack>
        </BaseFormDrawer>
      </Formik>
    </>
  );
};

export default AAAFormDrawer;
