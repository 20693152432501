import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppEventAppCard from '@/base/App/EventAppCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface MobileEventAppProps extends Omit<ModuleCardProps, 'children'> {
}

const MobileEventApp: FC<MobileEventAppProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppEventAppCard />
    </ModuleCard>
  );
};

export default MobileEventApp;
