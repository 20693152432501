export enum TenantState {
  Active = 1,
  Inactive = 2,
}

export const tenantStateLabels: Record<TenantState, string> = {
  [TenantState.Active]: 'Active',
  [TenantState.Inactive]: 'Inactive'
};

export const tenantStateOptions: Array<{ value: number, label: string }> = [
  { value: TenantState.Active, label: tenantStateLabels[TenantState.Active] },
  { value: TenantState.Inactive, label: tenantStateLabels[TenantState.Inactive] }
];
