import { type FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { generatePageUrl } from '@/app/pages';
import { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import PlanEditor from '@/account/component/Subscription/PlanEditor';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import PlanSummary from '@/account/subscription/PlanManagement/PlanSummary';
import { SubscriptionEditor, useSubscriptionPlanEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { UiSpinner, UiStack } from '@/lib/ui';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { account } from '@/api';
import {
  SubscriptionState,
} from '@/api/constant/subscription';
import Plan from './Plan';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const PlanManagement: FC = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const [subscription, setSubscription] = useState<account.Subscription>();
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Subscription' },
        { label: 'Plan', url: generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant?.code ?? '' }) },
        { label: 'Manage your plan' }
      ];
    },
    [tenant?.code]
  );

  const { createGlobalApiAclRequest } = useGlobalApi();
  const { data: currentSubscription, isLoading } = useQuery<account.SubscriptionLoadResponse, Error>(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  const { data: nextSubscriptionTerm, isLoading: isNextSubscriptionTermLoading } = useQuery<account.SubscriptionLoadNextTermResponse, Error>(
    [account.subscriptionNextTermQueryKey, {tenantId: tenant?.id}],
    async () => {
      return await account.loadNextSubscriptionTerm(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  useEffect(
    () => {
      if (currentSubscription) {
        // console.log('-------set subscription', currentSubscription);
        if (currentSubscription.subscription.plan.state === SubscriptionState.Active && nextSubscriptionTerm && nextSubscriptionTerm.subscriptionTermId) {
          // If the current subscription is active. The editor should always load the subscription from the next subscription term.
          setSubscription({ ...nextSubscriptionTerm.subscription });
        } else {
          setSubscription({ ...currentSubscription.subscription });
        }
      }
    },
    [ currentSubscription,
      nextSubscriptionTerm,
    ]
  );

  if (isTenantLoading || isLoading || isNextSubscriptionTermLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (subscription && tenant) {
    return (
      <Plan
        tenant={tenant}
        subscription={subscription}
      />
    );
  } else {
    return (
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'} />)}
        MainTransition={null}
      >
        <BreadcrumbBody
          breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack p={8}>
            <BaseMessageBarError>
              Failed to load the subscription editor.
            </BaseMessageBarError>
          </UiStack>
        </BreadcrumbBody>
      </BaseLayout>
    )
  }
};

export default PlanManagement;
