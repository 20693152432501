import { type FC } from 'react';
import {
  ListNumbers
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconListNumbersProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconListNumbers: FC<UiIconListNumbersProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ListNumbers}
      {...props}
    />
  );
};
