import { type FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type UiHStackProps,
  UiStack,
  UiButton,
  UiHStack,
  UiIconUser,
  UiText,
  uiStyles,
  UiIconArrowsClockwise,
  UiIconUserCircle,
  UiIconWarningCircle,
  UiIconSignIn, UiLink,
} from '@/lib/ui';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';

export interface AdminUserProps extends UiHStackProps {
}

const AdminUser: FC<AdminUserProps> = ({}) => {
  const { adminAuth } = useAdminAuth();
  const navigate = useNavigate();
  const loginUrl = useMemo(() => generatePageUrl('AccountLoginLoginEmail'), []);

  const switchAccount = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginLoginEmail'));
    },
    [navigate, generatePageUrl]
  );

  if (adminAuth.user && adminAuth.user.id > 0) {
    return (
      <UiHStack
        // spacing={4}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        spacing={0}
        p={0}
        shadow={uiStyles.cardShadow}
      >
        <UiStack
          // px={8}
          px={6}
          py={4}
          borderLeftRadius={uiStyles.borderRadius}
          borderRightWidth={'1px'}
          borderRightColor={'gray.200'}
          borderRightStyle={'solid'}
          // bgColor={'primary.500'}
        >
          <UiIconUserCircle size={'3xl'} color={'text.primary'} />
        </UiStack>
        <UiHStack px={8} py={4} pl={6} spacing={4} flexGrow={1}>
          <UiText flexGrow={1}>
            {adminAuth.user.email}
          </UiText>
          <UiStack {...uiStyles.hover} onClick={switchAccount}>
            <UiIconArrowsClockwise color={'primary.500'} size={'2xl'} />
          </UiStack>
        </UiHStack>
      </UiHStack>
    );
  } else {
    return (
      <UiHStack p={4} bgColor={'red.50'} borderRadius={uiStyles.borderRadius} justifyContent={'space-between'}>
        <UiHStack>
          <UiIconWarningCircle size={'2xl'} color={'red.500'}/>
          <UiText color={'red.500'}>Login required.</UiText>
        </UiHStack>
        <UiHStack spacing={0}>
          <UiLink href={loginUrl}>
            <UiStack
              {...uiStyles.hover}
              borderRadius={uiStyles.borderRadius}
              p={2}
              // px={4}
            >
              <UiText
                color={'primary.500'}
                // variant={'title'}
                // p={4}
              >
                Back to login
              </UiText>
            </UiStack>
          </UiLink>
          <UiIconSignIn size={'2xl'} color={'primary.500'}/>
        </UiHStack>
      </UiHStack>
    );
  }
};

export default AdminUser;
