import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  bookingComUrl: string
}

const formSchema = Yup.object().shape({
  bookingComUrl: Yup.string()
    .url('Invalid URL')
    .nullable(),
});

const BookingComPageForm: FC<LabelsFormProps> = ({
  onClose,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee group labels.']);
    }
  });

  const submitForm = async (values: FormData) => {
    await mutateEventSettings({
      eventId,
      config: {
        otherSettings: {
          ...eventSettingQuery.data!.otherSettings,
          bookingCom: {
            bookingComUrl: values.bookingComUrl,
          }
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => {
        return (
          <Formik
            initialValues={{
              bookingComUrl: eventSetting.otherSettings?.bookingCom?.bookingComUrl ?? '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={async (
              values: FormData,
              { setSubmitting }: FormikHelpers<FormData>
            ) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
            }}
          >
            <BaseFormDrawer
              isOpen={isVisible}
              onClose={onClose}
              title={'Attendee group page'}
              size={'xl'}
              isLoading={isLoading}
            >
              {saveErrors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {saveErrors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <BaseFormFieldGroup>
                <BaseFormInputField
                  name={'bookingComUrl'}
                  label={'Booking.com Affiliate URL'}
                  isRequired={false}
                  helperText={'This URL will be used to generate links which can be attached to registration emails.'} />
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          </Formik>
        );
      }}
    </QueryContainer>

  );
};

export default BookingComPageForm;
