import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppDataInsightCard from '@/base/App/DataInsightCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface DataInsightProps extends Omit<ModuleCardProps, 'children'> {
}

const DataInsight: FC<DataInsightProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppDataInsightCard />
    </ModuleCard>
  );
};

export default DataInsight;
