import { type FC, type ReactNode } from 'react';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { AdminUserType } from '@/api/constant/adminUser';

// import Filter from './Filter';

export interface AAAContainerProps {
  children: ReactNode
}

const AAAContainer: FC<AAAContainerProps> = ({
  children,
}) => {
  const { adminAuth } = useAdminAuth();
  // Only AAA admin users have access to the AAA pages.
  if (adminAuth.accessToken && adminAuth.user?.id && adminAuth.user.type === AdminUserType.AAA) {
    return children;
  }
  return null;
};

export default AAAContainer;
