import { type FC, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  HStack,
  useDisclosure
} from '@chakra-ui/react';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText,
  UiTag,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter, UiGrid
} from '@/lib/ui';
import { text } from '@/lib/util';
import {
  registration
} from '@/api';
import TicketForm from './TicketForm';
import ManageDiscounts from './ManageDiscounts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ActiveTicketDiscount, type TicketDiscount, type TicketDeleteRequest, type TicketData, type TicketCloneRequest } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DeletionConfirmation from './DeletionConfirmation';
import timezone from 'dayjs/plugin/timezone';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useSharedStocksQuery } from '@/registration/hook/useSharedStocksQuery';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface TicketCardProps extends UiHStackProps {
  ticket: registration.TicketData
  ticketType: TicketType
}

const TicketCard: FC<TicketCardProps> = ({
  ticket,
  ticketType,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { eventId, tenant } = useRegisterRoute();
  const { data: event } = useEventQuery(eventId);
  const { isOpen: isDiscountsOpen, onToggle: onDiscountsToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data: sharedStockData } = useSharedStocksQuery(eventId, ticketType);

  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate, isLoading } = useMutation<void, Error, TicketDeleteRequest>({
    mutationFn: async (request: TicketDeleteRequest) => {
      await registration.deleteTicket(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.recommendedDelegateTypesQueryKey, eventId] });
      // NOTE: handle error
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<TicketData>, Error, TicketCloneRequest>({
    mutationFn: async (ticketData: TicketCloneRequest) => {
      return await registration.cloneTicket(createTenantAdminApiRequest)(ticketData);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(
    () => { return mutate({ id: ticket.id.toString(), ticketType }); }
    , [ticket.id, mutate, ticketType]);

  const onConfirm = useCallback(
    () => { return mutateClone({ id: ticket.id.toString(), ticketType }); }
    , [ticket.id, mutateClone, ticketType]);

  const getValidDateFormat = useCallback(
    (date: string) => {
      if (event?.tzInfoName) {
        return dayjs(date).tz(event?.tzInfoName).format('DD MMM YYYY HH:mm');
      }
      return dayjs(date).format('DD MMM YYYY HH:mm');
    }
    , [event?.tzInfoName]);

  const stockDisplay = useMemo(
    () => {
      if (ticket.ticketSharedStockId && Array.isArray(sharedStockData)) {
        for (const sharedStock of sharedStockData) {
          if (sharedStock.id === ticket.ticketSharedStockId) {
            return (
              <UiTag>
                {sharedStock.name} ({sharedStock.stock})
              </UiTag>
            );
          }
        }
        return (
          <UiText>-</UiText>
        );
      }
      return (
        <UiText variant={'body1'}>{ticket.stock}</UiText>
      );
    },
    [
      ticket,
      sharedStockData,
    ]
  );

  return (
    <>
      <UiStack
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        spacing={0}
        py={6}
        shadow={uiStyles.cardShadow}
      >
        <UiHStack spacing={6} px={6} alignItems={'stretch'}>
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} flexGrow={1} px={0} spacing={4}>
            <UiGrid
              templateColumns={'3fr 1fr'}
              gap={0}
              flexGrow={1}
              // alignItems={'flex-start'}
            >
              <UiHStack
                alignItems={'stretch'}
                spacing={4}
                p={6}
                // px={6}
                justifyContent={'space-between'}
                borderLeftRadius={uiStyles.borderRadius}
                borderWidth={'1px'}
                borderColor={'gray.100'}
                borderStyle={'solid'}
              >
                <UiGrid
                  templateColumns={'1fr 1fr 1fr'}
                  gap={6}
                  flexGrow={1}
                >
                  <UiStack spacing={4}>
                    <UiStack>
                      <UiText fontWeight={'bold'}>{ticket.name}</UiText>
                      <UiText variant={'body2'}>{ticket.description ? text.trimText(ticket.description, 50) : ''}</UiText>
                    </UiStack>
                    <UiStack>
                      <UiText variant={'body2'} color={'text.secondary'}>Price</UiText>
                      <UiText variant={'body1'}>{ticket.priceFormat}</UiText>
                    </UiStack>
                  </UiStack>
                  <UiStack spacing={4}>
                    <UiStack>
                      <UiText variant={'body2'} color={'text.secondary'}>Capacity</UiText>
                      <UiText variant={'body1'}>{stockDisplay}</UiText>
                    </UiStack>
                    <UiStack>
                      <UiHStack>
                        <UiText variant={'body2'} color={'text.secondary'}>Valid from/to</UiText>
                        {dayjs(ticket.validTo).isBefore(dayjs.utc()) && <UiTag colorScheme={'red'}>Expired</UiTag>}
                      </UiHStack>
                      <UiText variant={'body1'}>
                        {ticket.validFrom
                          ? `${getValidDateFormat(ticket.validFrom)} - ${getValidDateFormat(ticket.validTo)}`
                          : 'All times'}
                      </UiText>
                    </UiStack>
                  </UiStack>
                  <UiStack spacing={2}>
                    <UiText variant={'body2'} color={'text.secondary'}>Attendee categories</UiText>
                    <UiStack>
                      {Array.isArray(ticket.attendeeCategories) && ticket.attendeeCategories.length > 0 ? (
                        <UiHStack flexWrap={'wrap'}>
                          {ticket.attendeeCategories.map((attendeeCategory) => {
                            return (
                              <UiTag key={attendeeCategory.id} p={2} colorScheme={'gray'}>{attendeeCategory.name}</UiTag>
                            );
                          })}
                        </UiHStack>
                      ) : (
                        <UiText color={'red.500'}>Unavailable to any attendees. Please choose attendee groups.</UiText>
                      )}
                    </UiStack>
                  </UiStack>
                </UiGrid>
                <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                  <UiStack alignItems={'flex-start'}>
                    <BaseButtonPlainIconEdit onClick={onToggle} />
                  </UiStack>
                </BaseAclContainer>
              </UiHStack>
              <UiHStack
                alignItems={'stretch'}
                spacing={6}
                p={6}
                // px={6}
                justifyContent={'space-between'}
                borderRightRadius={uiStyles.borderRadius}
                borderWidth={'1px'}
                borderColor={'gray.100'}
                borderStyle={'solid'}
                borderLeftWidth={0}
              >
                <UiStack spacing={2}>
                  <UiText variant={'body2'} color={'text.secondary'}>Discounts</UiText>
                  {(Array.isArray(ticket.ticketDiscounts) && ticket.ticketDiscounts.length > 0)
                    ? (
                      <UiHStack spacing={2} flexWrap={'wrap'}>
                        {(ticket.ticketDiscounts as ActiveTicketDiscount[])
                          .map((discountDelegateType) => {
                            return (
                              <UiTag key={discountDelegateType.id} colorScheme={'gray'} opacity={discountDelegateType.active ? 1 : 0.6}>
                                {discountDelegateType.discount.name}
                              </UiTag>
                            );
                          })}
                      </UiHStack>
                    )
                    : (
                      <UiTag colorScheme={'red'}>None</UiTag>
                    )}
                </UiStack>
                <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                  <UiStack>
                    <BaseButtonPlainIconEdit onClick={onDiscountsToggle} />
                  </UiStack>
                </BaseAclContainer>
              </UiHStack>
            </UiGrid>
          </UiStack>
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiStack
              alignItems={'flex-end'}
              spacing={4}
            >
              <BaseButtonPlainIconClone onClick={onToggleConfirmClone} />
              <BaseButtonPlainIconDelete onClick={onToggleConfirmDeletion} isLoading={isLoading} />
            </UiStack>
          </BaseAclContainer>
        </UiHStack>
      </UiStack>
      <TicketForm
        onClose={onClose}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        ticket={ticket}
        ticketType={ticketType}
      />
      <ManageDiscounts
        onClose={onDiscountsToggle}
        isVisible={isDiscountsOpen}
        onSaveSuccess={() => {}}
        ticketId={ticket.id}
        ticketType={ticketType}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this ticket?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={4}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'primary'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={ticket.name} />
    </>
  );
};

export default TicketCard;
