import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiButton,
  UiHStack,
  UiHStackProps,
  UiIconGear,
  UiIconPlusCircle, UiIconX,
  UiStack,
  uiStyles,
  UiTag,
  UiText,
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { account } from '@/api';
import { AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import RemoveMemberConfirmation from '@/account/access/TeamInfo/RemoveMemberConformation';
import BasePlainIcon from '@/base/Button/PlainIcon';

dayjs.extend(utc);

export interface TeamMemberProps extends UiHStackProps {
  teamId: number
  member: account.LoadTeamResponseUser
}

const TeamMember: FC<TeamMemberProps> = ({
  teamId,
  member,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const onConfirm = () => {};

  return (
    <>
      <UiHStack key={member.id} p={8} py={6} justifyContent={'space-between'} bgColor={'#fff'} {...props}>
        <UiStack>
          <UiText>{member.email}</UiText>
        </UiStack>
        <UiStack>
          <BasePlainIcon
            label={'Remove'}
            Icon={UiIconX}
            color={'red.500'}
            onClick={onToggle}
          />
          {/*<UiButton variant={'ghost'} py={0} onClick={onToggle}>Remove</UiButton>*/}
        </UiStack>
      </UiHStack>
      <RemoveMemberConfirmation
        teamId={teamId}
        member={member}
        isOpen={isOpen}
        onClose={onToggle}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default TeamMember;
