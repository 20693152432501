import {
  SubscriptionPackage,
  SubscriptionPaymentPlan,
  SubscriptionProduct,
  SubscriptionState,
  SubscriptionUnitCostType,
} from '@/api/constant/subscription';
import { type ApiRequest, callApi } from '@/api/globalClient';
import { TenantData } from '@/api/account/tenant';
import { SubscriptionTermState } from '@/api/constant/subscriptionTerm';

export const subscriptionQueryKey = 'account.subscription';
export const subscriptionSearchQueryKey = 'account.subscriptionSearch';
export const subscriptionNextTermQueryKey = 'account.subscriptionNextTerm';
export const tenantCurrentSubscriptionQueryKey = 'account.tenantCurrentSubscription';

export interface SubscriptionProductBase {
  monthlyPrice: number
  yearlyPrice: number
}

export interface SubscriptionServicePack {
  hourPrice: number
}

// Modules
export interface SubscriptionRegistration extends SubscriptionProductBase {
  unitRate: number // Cost per registration
  unitType: SubscriptionUnitCostType
}
export interface SubscriptionAbstractManagement extends SubscriptionProductBase {
  unitRate: number // Cost per submission
  unitType: SubscriptionUnitCostType
}
export interface SubscriptionContentDeliveryPlatform extends SubscriptionProductBase {
}
export interface SubscriptionMobileEventApp extends SubscriptionProductBase {
}
export interface SubscriptionDigitalPoster extends SubscriptionProductBase {
  unitRate: number // // Cost per poster
  unitType: SubscriptionUnitCostType
}
export interface SubscriptionLiveStreaming extends SubscriptionProductBase {
}
export interface SubscriptionSponsorExhibitor extends SubscriptionProductBase {
}
export interface SubscriptionWebinar extends SubscriptionProductBase {
}
export interface SubscriptionDataInsight extends SubscriptionProductBase {
}
export interface SubscriptionMultiLingualSupport extends SubscriptionProductBase {
}
export interface SubscriptionLiveTranslation extends SubscriptionProductBase {
  usagePrice: number
}
export interface SubscriptionAICaptioning extends SubscriptionProductBase {
  usagePrice: number
}
export interface SubscriptionAppWayfinder extends SubscriptionProductBase {
}
export interface SubscriptionContentHub extends SubscriptionProductBase {
}
export interface SubscriptionIntegration extends SubscriptionProductBase {
}

/**
 * DO NOT change the keys here since the backend will use the same keys to locate the products.
 */
export interface SubscriptionProducts {
  [SubscriptionProduct.Registration]: SubscriptionRegistration
  [SubscriptionProduct.AbstractManagement]: SubscriptionAbstractManagement
  [SubscriptionProduct.EventWebsite]: SubscriptionContentDeliveryPlatform
  [SubscriptionProduct.MobileEventApp]: SubscriptionMobileEventApp
  [SubscriptionProduct.DigitalPoster]: SubscriptionDigitalPoster
  [SubscriptionProduct.LiveStreaming]: SubscriptionLiveStreaming
  [SubscriptionProduct.SponsorExhibitor]: SubscriptionSponsorExhibitor
  [SubscriptionProduct.Webinar]: SubscriptionWebinar
  [SubscriptionProduct.DataInsight]: SubscriptionDataInsight
  [SubscriptionProduct.MultiLingualSupport]: SubscriptionMultiLingualSupport
  [SubscriptionProduct.LiveTranslation]: SubscriptionLiveTranslation
  [SubscriptionProduct.AILiveCaptioning]: SubscriptionAICaptioning
  [SubscriptionProduct.AppWayfinder]: SubscriptionAppWayfinder
  [SubscriptionProduct.ContentHub]: SubscriptionContentHub
  [SubscriptionProduct.Integration]: SubscriptionIntegration
}

export interface Addon {
  product: SubscriptionProduct
  paymentPlan: SubscriptionPaymentPlan
}

export interface Discount {
  packagePercentage: number // This should be the percentage of the discount. A value of 15 means it's a 15% off.
  registrationFreeUnits: number // A value of 500 here means free registration fee (unit fee) for the first 500 registrations every 12 months.
  abstractManagementFreeUnits: number // A value of 500 here means free abstract submission fee (unit fee) for the first 500 registrations every 12 months.
  digitalPosterFreeUnits: number // A value of 500 here means free digital poster submission fee (unit fee) for the first 500 registrations every 12 months.
}

export interface Subscription {
  id: number
  products: SubscriptionProducts
  [SubscriptionPackage.Pack1]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  [SubscriptionPackage.Pack2]: {
    yearlyPrice: number
    products: SubscriptionProduct[]
  }
  servicePack: SubscriptionServicePack
  plan: {
    state: SubscriptionState
    // type: SubscriptionType
    from: number // timestamp
    to: number // timestamp
    currency: string
    package: SubscriptionPackage
    servicePack: {
      hours: number
      usedHours: number
    }
    addons?: Addon[] | undefined
    discount?: Discount
  }
}

// export const defaultSubscription: Subscription = {
//   id: 0,
//   products: {
//     registration: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140,
//       unitRate: 3,
//       unitType: SubscriptionUnitCostType.Fixed,
//     },
//     abstractManagement: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140,
//       unitRate: 6,
//       unitType: SubscriptionUnitCostType.Fixed,
//     },
//     eventWebsite: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     mobileEventApp: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     digitalPoster: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140,
//       unitRate: 6,
//       unitType: SubscriptionUnitCostType.Fixed,
//     },
//     liveStreaming: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     sponsorExhibitor: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     webinar: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     dataInsight: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     multiLingualSupport: {
//       monthlyPrice: 295,
//       yearlyPrice: 2950
//     },
//     liveTranslation: {
//       monthlyPrice: 95,
//       yearlyPrice: 950,
//       usagePrice: 0
//     },
//     aiLiveCaptioning: {
//       monthlyPrice: 95,
//       yearlyPrice: 950,
//       usagePrice: 0
//     },
//     appWayfinder: {
//       monthlyPrice: 715,
//       yearlyPrice: 7140
//     },
//     contentHub: {
//       monthlyPrice: 595,
//       yearlyPrice: 6300
//     }
//   },
//   pack1: {
//     yearlyPrice: 28355,
//     products: [
//       SubscriptionProduct.Registration,
//       SubscriptionProduct.EventWebsite,
//       SubscriptionProduct.Webinar,
//       SubscriptionProduct.SponsorExhibitor,
//       SubscriptionProduct.LiveStreaming,
//       SubscriptionProduct.DataInsight
//     ]
//   },
//   pack2: {
//     yearlyPrice: 39500,
//     products: [
//       SubscriptionProduct.Registration,
//       SubscriptionProduct.EventWebsite,
//       SubscriptionProduct.Webinar,
//       SubscriptionProduct.SponsorExhibitor,
//       SubscriptionProduct.LiveStreaming,
//       SubscriptionProduct.DataInsight,
//       SubscriptionProduct.AbstractManagement,
//       SubscriptionProduct.DigitalPoster,
//       SubscriptionProduct.MobileEventApp
//     ]
//   },
//   servicePack: {
//     hourPrice: 170,
//   },
//   plan: {
//     state: SubscriptionState.InReview,
//     type: SubscriptionType.Template,
//     package: SubscriptionPackage.Pack2,
//     from: 1705792147,
//     to: 1716250147,
//     currency: 'AUD',
//     servicePack: {
//       hours: 0,
//       usedHours: 0,
//     },
//     addons: [],
//   },
// };

export interface SubscriptionLoadRequest {
  tenantId: number
}

export interface SubscriptionLoadResponse {
  subscription: Subscription
  subscriptionId: number
  errors: string[]
}

export const loadSubscription = (createGlobalApiAclRequest: () => ApiRequest) => {
  const apiRequest = createGlobalApiAclRequest();
  return async (
    params: SubscriptionLoadRequest
  ): Promise<SubscriptionLoadResponse> => {
    apiRequest.endpoint.path = '/api/subscription/get-tenant-current-subscription';
    apiRequest.payload = params;
    // return defaultSubscription;
    return await callApi<SubscriptionLoadResponse>(apiRequest);
  };
};

export interface SubscriptionLoadNextTermRequest {
  tenantId: number
}

export interface SubscriptionLoadNextTermResponse {
  subscription: Subscription
  subscriptionTermId: number
  from: string
  to: string
  errors: string[]
}

export const loadNextSubscriptionTerm = (createGlobalApiAclRequest: () => ApiRequest) => {
  const apiRequest = createGlobalApiAclRequest();
  return async (
    params: SubscriptionLoadNextTermRequest
  ): Promise<SubscriptionLoadNextTermResponse> => {
    apiRequest.endpoint.path = '/api/subscription/load-tenant-next-subscription-term';
    apiRequest.payload = params;
    // return defaultSubscription;
    return await callApi<SubscriptionLoadNextTermResponse>(apiRequest);
  };
};

export interface SubscriptionSaveRequest {
  tenantId: number
  subscription: Subscription
}

export interface SubscriptionSaveResponse {
  subscriptionId: number
  errors: string[]
}

export const saveSubscription = (createGlobalApiAclRequest: () => ApiRequest) => {
  const apiRequest = createGlobalApiAclRequest();
  return async (
    params: SubscriptionSaveRequest
  ): Promise<SubscriptionSaveResponse> => {
    apiRequest.endpoint.path = '/api/subscription/update-tenant-subscription';
    apiRequest.payload = params;
    // return defaultSubscription;
    return await callApi<SubscriptionSaveResponse>(apiRequest);
  };
};


export interface SubscriptionRecord {
  id: number
  state: SubscriptionState
  package: SubscriptionPackage
  tenant: TenantData
  from: string
  to: string
  modelTemplate: string
  model: Subscription | null
  createdAt: string
  updatedAt: string
}

export interface SubscriptionSearchRequest {
  state?: number
  tenantId?: number
  tenantName?: string
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}

export interface SubscriptionSearchResponse {
  subscriptionList: SubscriptionRecord[]
  page: number
  total: number
  errors: string[]
}

export function searchSubscriptions(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: SubscriptionSearchRequest): Promise<SubscriptionSearchResponse> {
    apiRequest.endpoint.path = '/api/subscription/search-subscriptions';
    apiRequest.payload = params;
    return await callApi<SubscriptionSearchResponse>(apiRequest);
  }
}

export interface SubscriptionTermRecord {
  id: number
  state: SubscriptionTermState
  months: number
  number: number
  maturedAt: string
  from: string
  to: string
  createdAt: string
  updatedAt: string
  subscriptionId: number
  modelInstance: string
  model: Subscription | null
}

export interface GetTenantCurrentSubscriptionRequest {
  tenantId: number
}

export interface GetTenantCurrentSubscriptionResponse {
  subscription: SubscriptionRecord
  subscriptionTerms: SubscriptionTermRecord[]
  errors: string[]
}

export function getTenantCurrentSubscription(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: GetTenantCurrentSubscriptionRequest): Promise<GetTenantCurrentSubscriptionResponse> {
    apiRequest.endpoint.path = '/api/subscription/get-tenant-current-subscription-record';
    apiRequest.payload = params;
    const response = await callApi<GetTenantCurrentSubscriptionResponse>(apiRequest);
    if (response?.subscription?.modelTemplate) {
      try {
        response.subscription.model = JSON.parse(response.subscription.modelTemplate);
      } catch {
        response.subscription.model = null;
      }
    }
    if (Array.isArray(response?.subscriptionTerms) && response?.subscriptionTerms.length > 0) {
      response.subscriptionTerms.forEach((term, index) => {
        try {
          response.subscriptionTerms[index].model = JSON.parse(term.modelInstance);
        } catch {
          response.subscriptionTerms[index].model = null;
        }
      })
    }
    return response;
  }
}

export interface UpdateSubscriptionServicePackHoursRequest {
  usedHours: number
  tenantId: number
}

export interface UpdateSubscriptionServicePackHoursResponse {
  errors: string[]
}

export function updateTenantSubscriptionServicePackHours(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: UpdateSubscriptionServicePackHoursRequest): Promise<UpdateSubscriptionServicePackHoursResponse> {
    apiRequest.endpoint.path = '/api/subscription/update-tenant-subscription-service-pack-hours';
    apiRequest.payload = params;
    return await callApi<UpdateSubscriptionServicePackHoursResponse>(apiRequest);
  }
}
