import { useCallback, useState, type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiText,
  UiStack,
  UiHStack,
  uiStyles,
  UiButton, UiIconHourglassHigh, UiIconCopy,
} from '@/lib/ui';
import CloneEventForm from './CloneEventForm';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useBuildEventStore } from '@/registration/hook/useBuildEventStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type EventSetting, type EventSettingsCreateRequest, createEventSettings } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface CloneEventProps {
  eventId: string
  tenantCode: string
}

const CloneEvent: FC<CloneEventProps> = ({
  eventId,
  tenantCode,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { data: event } = useEventQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsCreateRequest>(
    {
      mutationFn: async (data: EventSettingsCreateRequest) => {
        return await createEventSettings(createTenantAdminApiRequest)(data);
      },
      onSuccess: (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          setSaveErrors([]);
          void queryClient.invalidateQueries({ queryKey: [registration.eventQueryKey, { eventId }] });
          void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
          setCloneAvailable(false);
        }
      },
      onError: (error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to clone event']);
      }
    }
  );

  const setCloneAvailable = useBuildEventStore(state => { return state.setCloneAvailable; });
  const onNo = useCallback(() => {
    mutate({ eventId });
  }, [eventId, mutate]);

  return (
    <>
      {event?.cloneStatus === 'in_progress' ? (
        <UiStack p={8}>
          <UiHStack bgColor={'#fff'} px={8} py={6} borderRadius={uiStyles.borderRadius} justifyContent={'flex-start'} spacing={2}>
            <UiIconHourglassHigh size={'3xl'} color={'green.500'} />
            <UiText>
              The event clone is currently in progress. It will take a few minutes. Please come back and check later.
            </UiText>
          </UiHStack>
        </UiStack>
      ) : (
        <UiStack p={8}>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <UiHStack bgColor={'#fff'} px={8} py={4} borderRadius={uiStyles.borderRadius} justifyContent={'space-between'}>
            <UiHStack justifyContent={'flex-start'} spacing={2}>
              <UiIconCopy size={'2xl'} />
              <UiText>
                Clone a previous event to start with?
              </UiText>
            </UiHStack>
            <UiHStack spacing={8}>
              <UiButton colorScheme={'red'} variant={'ghost'} onClick={onNo} isLoading={isLoading}>No</UiButton>
              <UiButton colorScheme={'primary'} variant={'ghost'} onClick={onToggle}>Yes</UiButton>
            </UiHStack>
          </UiHStack>
        </UiStack>
      )}
      <CloneEventForm
        eventId={eventId}
        tenantCode={tenantCode}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default CloneEvent;
