import { useParams, type Params } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useEffect } from 'react';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';

interface RouteParams extends Params {
  tenantCode: string
}

export function useTenantRoute() {
  const params = useParams<RouteParams>();
  const tenantCode = params.tenantCode ?? '';
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, error, isSuccess } = useQuery<account.TenantLoadByCodeResponse, Error>(
    [account.tenantQueryKey],
    async () => {
      return await account.loadTenantByCode(createGlobalApiRequest)({ code: tenantCode });
    },
    {
      enabled: !!tenantCode,
      cacheTime: Infinity,
    }
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  return {
    ...params,
    isLoading,
    isSuccess,
    tenant: data?.tenant,
  };
}
