/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode } from 'react';
import {
  UiHStack,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalOverlay,
} from '@/lib/ui';

export interface FullScreenProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

const FullScreen: FC<FullScreenProps> = ({
  children,
  isOpen,
  onClose
}) => {
  return (
    <>
      <UiModal
        onClose={onClose}
        size={'full'}
        isOpen={isOpen}
        motionPreset={'scale'}
        scrollBehavior={'inside'}
      >
        <UiModalOverlay />
        <UiModalContent bgColor={'gray.100'}>
          <UiHStack
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            p={8}
            borderBottomWidth={'1px'}
            borderBottomStyle={'solid'}
            borderBottomColor={'gray.300'}
            // shadow={'base'}
          >
            <UiModalCloseButton alignSelf={'flex-end'} p={4} size={'lg'} />
          </UiHStack>
          <UiModalBody bgColor={'gray.100'}>
            {children}
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default FullScreen;
