import { type FC } from 'react';
import {
  Link
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconLinkProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconLink: FC<UiIconLinkProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Link}
      {...props}
    />
  );
};
