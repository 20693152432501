/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UiBox,
  UiHStack,
  UiStack,
  uiStyles, UiSwitch,
} from '@/lib/ui';
import LoginEmailForm from './LoginEmailForm';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';
import CurrentUserSession from '@/account/login/LoginEmail/CurrentUserSession';

const LoginEmail: FC = () => {
  const { adminAuth, setAccessToken } = useAdminAuth();
  const navigate = useNavigate();

  const onLoggedIn = (accessToken: string) => {
    // console.log('----log in token', accessToken);
    setAccessToken(accessToken);
    navigate(generatePageUrl('AccountLoginChooseTenant'));
  };

  return (
    <BaseLayout
      // smartWindow={(<LoginSmartWindow />)}
      smartWindow={(<UiStack/>)}
      sideMenu={null}
      smartButton={(<UiHStack flexGrow={1}/>)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        <UiStack
          // breadcrumbNodes={breadcrumbNodes}
          spacing={4}
          alignItems={'stretch'}
          flexGrow={1}
        >
          <UiHStack
            flexGrow={1}
            alignItems={'stretch'}
            borderRadius={uiStyles.borderRadius}
            spacing={0}
          >
            <UiStack
              alignItems={'center'}
              justifyContent={'center'}
              flexGrow={1}
              borderRightRadius={uiStyles.borderRadius}
              spacing={0}
            >
              <CurrentUserSession/>
              <LoginEmailForm
                onLoggedIn={onLoggedIn}
              />
            </UiStack>
          </UiHStack>
        </UiStack>
      </LoginBody>
    </BaseLayout>
  );

  // return (
  //   <LoginLayout
  //     smartWindow={<LoginSmartWindow/>}
  //     smartButton={<LoginSupportSmartButton/>}
  //   >
  //     <UiStack
  //       // breadcrumbNodes={breadcrumbNodes}
  //       spacing={4}
  //       alignItems={'stretch'}
  //       flexGrow={1}
  //     >
  //       <UiHStack
  //         flexGrow={1}
  //         alignItems={'stretch'}
  //         borderRadius={uiStyles.borderRadius}
  //         spacing={0}
  //       >
  //         <UiStack
  //           borderLeftRadius={uiStyles.borderRadius}
  //           spacing={0}
  //           // alignItems={'center'}
  //           // p={8}
  //           // justifyContent={'center'}
  //           bgColor={'primary.400'}
  //           bgImg={'/image/bg/login-bg.jpg'}
  //           bgSize={'cover'}
  //           bgPos={'center'}
  //           // minW={'600px'}
  //           // maxW={'800px'}
  //           width={'40%'}
  //         >
  //           {/* <UiImage
  //             src={'/image/bg/login-bg.jpg'}
  //             borderLeftRadius={uiStyles.borderRadius}
  //             minH={innerHeight - 160}
  //             maxH={innerHeight - 160}
  //           /> */}
  //         </UiStack>
  //         <UiStack
  //           alignItems={'center'}
  //           justifyContent={'center'}
  //           flexGrow={1}
  //           bgColor={'gray.100'}
  //           borderRightRadius={uiStyles.borderRadius}
  //           spacing={4}
  //         >
  //           {/* <UiText
  //             variant={'h5'}
  //           >
  //             Admin login
  //           </UiText> */}
  //           {!adminUser?.id ? (
  //             <LoginEmailForm
  //               onLoggedIn={onLoggedIn}
  //             />
  //           ) : (
  //             <LoginPasswordForm
  //               onLoginSuccess={() => {}}
  //               adminUser={adminUser}
  //               changeEmail={() => setAminUser(null)}
  //             />
  //           )}
  //         </UiStack>
  //       </UiHStack>
  //     </UiStack>
  //   </LoginLayout>
  // );
};

export default LoginEmail;
