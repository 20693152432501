/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode, useCallback, useState } from 'react';
import {
  UiButton,
  UiHStack, UiIconEnvelopeSimple, UiIconUser, UiIconUserCircle, UiIconUsersFour,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalOverlay, UiSpinner, UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface DeleteTeamConfirmationProps {
  team: account.TeamData
  isOpen: boolean
  onClose: () => void
  onDeleteSuccess: () => void
}

const DeleteTeamConfirmation: FC<DeleteTeamConfirmationProps> = ({
  team,
  isOpen,
  onClose,
  onDeleteSuccess,
}) => {
  const [ errors, setErrors ] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();
  const { tenant } = useTenantRoute();

  const { mutate, isLoading } = useMutation<account.DeleteTeamResponse, Error, account.DeleteTeamRequest>({
    mutationFn: async (data: account.DeleteTeamRequest) =>
      await account.deleteTeam(createGlobalApiAclRequest())(data),
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        // Reload the team.
        // void queryClient.invalidateQueries({ queryKey: [account.teamQueryKey, {id: team?.id}]});
        void queryClient.invalidateQueries({ queryKey: [account.teamSearchQueryKey, {tenantId: tenant?.id}]});
        onClose();
        onDeleteSuccess();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to delete the team.']);
    }
  });

  const onYes = useCallback(
    () => {
      if (team.id) {
        mutate({
          teamId: team.id,
        });
      }
    },
    [team?.id]
  );

  return (
    <>
      <UiModal
        onClose={onClose}
        size={'xs'}
        isOpen={isOpen}
        motionPreset={'scale'}
        // scrollBehavior={'inside'}
        isCentered={true}
      >
        <UiModalOverlay />
        <UiModalContent minW={480}>
          {/*<UiModalCloseButton alignSelf={'flex-end'} p={4} size={'lg'} />*/}
          <UiModalBody>
            <UiStack
              px={2}
              py={6}
              flexGrow={1}
              alignItems={'stretch'}
              // enableDivider={true}
              spacing={4}
            >
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <UiText>
                Continue to delete this team?
              </UiText>
              <UiHStack bgColor={'blackAlpha.50'} borderRadius={uiStyles.borderRadius} spacing={2} p={4} alignItems={'center'}>
                {/*<UiIconUsersFour size={'3xl'}/>*/}
                <UiText fontWeight={600}>{team.name}</UiText>
              </UiHStack>
              <UiHStack flexGrow={1} justifyContent={'space-between'}>
                <UiButton variant={'ghost'} color={'red.500'} pl={0} py={0} onClick={onClose}>No</UiButton>
                {isLoading ? (
                  <BaseLoadingSpinner size={'sm'}/>
                ) : (
                  <UiButton onClick={onYes}>Yes</UiButton>
                )}
              </UiHStack>
            </UiStack>
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default DeleteTeamConfirmation;
