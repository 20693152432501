import {
  UiButton,
  UiStack,
  UiText,
  UiHStack,
  uiStyles
} from '@/lib/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useState } from 'react';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import PasscodeInput from '@/account/login/VerifyEmailPasscode/PasscodeInput';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

interface VerifyProps {
  onVerified: () => void
}

const Verify: FC<VerifyProps> = ({
  onVerified,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { adminAuth, isLoading: isAdminAuthLoading } = useAdminAuth();
  const queryClient = useQueryClient();

  const { mutate: sendPasscode, isLoading: isSendPasscodeLoading } = useMutation<account.SendMfaEmailPasscodeResponse, Error, account.SendMfaEmailPasscodeRequest>({
    mutationFn: async (data: account.SendMfaEmailPasscodeRequest) => {
      return await account.sendMfaEmailPasscode(createGlobalApiAclRequest())({});
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to send the passcode.']);
    }
  });

  const { mutate: verifyPasscode, isLoading: isVerifyPasscodeLoading } = useMutation<account.VerifyMfaEmailPasscodeResponse, Error, account.VerifyMfaEmailPasscodeRequest>({
    mutationFn: async (data: account.VerifyMfaEmailPasscodeRequest) => {
      return await account.verifyMfaEmailPasscode(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        onVerified();
        // Reload the admin user session once the passcode is successfully verified.
        void queryClient.invalidateQueries({ queryKey: [account.adminUserSessionQueryKey, {accessToken: adminAuth.accessToken}] });
        setErrors([]);
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to verify the passcode.']);
    }
  });

  const onSendPasscode = useCallback(
    () => {
      sendPasscode({});
    },
    [sendPasscode]
  );

  const onVerifyPasscode = useCallback(
    (code: number) => {
      verifyPasscode({
        code,
      });
    },
    [verifyPasscode]
  );
  
  return (
    <UiStack
      alignItems={'stretch'}
      spacing={4}
      pt={8}
      pb={64}
      minW={360}
      maxW={600}
      // bgColor={'red'}
    >
      <UiStack>
        <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
          A passcode has been sent to your email address. Please also check your spam or junk folder.
        </BaseMessageBarInfo>
      </UiStack>
      {errors.length > 0 && (
        <UiStack spacing={4} flexGrow={1}>
          {errors.map((error, index) => {
            return (
              <BaseMessageBarError key={index}>
                {error}
              </BaseMessageBarError>
            );
          })}
        </UiStack>
      )}
      <BaseDividerHorizontal height={4} />
      <PasscodeInput
        onSendPasscode={onSendPasscode}
        onVerifyPasscode={(code) => {
          onVerifyPasscode(code);
        }}
      />
    </UiStack>
  );
};

export default Verify;
