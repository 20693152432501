import {
  UiButton, UiModal, UiModalBody, UiModalContent, UiModalOverlay, UiStack, UiText,
  UiHStack,
  uiStyles
} from '@/lib/ui';
import { useMutation } from '@tanstack/react-query';
import { type FC, useState, useCallback } from 'react';

import BaseMessageBarError from '@/base/MessageBar/Error';

import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type RemoveNoteRequest, removeNote, type AdminNote } from '@/api/admin/note';

interface RemoveNoteConfirmationProps {
  profileableType: string
  profileableId: number | null
  note: AdminNote
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const RemoveNoteConfirmation: FC<RemoveNoteConfirmationProps> = ({ profileableType, profileableId, note, isOpen, onClose, onConfirm }) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<ApiResponseSingle<AdminNote>, Error, RemoveNoteRequest>({
    mutationFn: async (data: RemoveNoteRequest) => {
      return await removeNote(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        onConfirm();
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to remove the note.']);
    }
  });

  const onYes = useCallback(
    () => {
      mutate({
        profileableType,
        profileableId,
        note,
      });
    },
    [profileableType, profileableId, note]
  );

  return (
    <>
      <UiModal
        onClose={onClose}
        size={'xs'}
        isOpen={isOpen}
        motionPreset={'scale'}
        isCentered={true}
      >
        <UiModalOverlay />
        <UiModalContent minW={480}>
          <UiModalBody>
            <UiStack
              px={2}
              py={6}
              flexGrow={1}
              alignItems={'stretch'}
              spacing={4}
            >
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <UiText>
                Continue to remove this note?
              </UiText>
              <UiHStack bgColor={'blackAlpha.50'} borderRadius={uiStyles.borderRadius} spacing={2} p={4} alignItems={'center'}>
                <UiText fontWeight={600}>{note.content}</UiText>
              </UiHStack>
              <UiHStack flexGrow={1} justifyContent={'space-between'}>
                <UiButton variant={'ghost'} color={'red.500'} pl={0} py={0} onClick={onClose}>No</UiButton>
                <UiButton isLoading={isLoading} onClick={onYes}>Yes</UiButton>
              </UiHStack>
            </UiStack>
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default RemoveNoteConfirmation;
