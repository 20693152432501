import { type FC } from 'react';
import {
  IdentificationBadge
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconIdentificationBadgeProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconIdentificationBadge: FC<UiIconIdentificationBadgeProps> = (props) => {
  return (
    <UiIcon
      IconComponent={IdentificationBadge}
      {...props}
    />
  );
};
