import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/PageRow';
import AdditionalInformationPageForm from './AdditionalInformationPageForm';

export interface ManageLabelsProps extends UiHStackProps {
}

const AdditionalInformationPage: FC<ManageLabelsProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Additional information page'}
        description={'Manage labels and descriptions on the page where we ask the attendees to fill the additional information form.'}
        onEdit={onToggle}
        {...props}
      />
      <AdditionalInformationPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default AdditionalInformationPage;
