import { Global } from '@emotion/react';

// This is currently NOT used. Poppins is used by including Google font links directly in index.html
const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'ao-app-font';
        src: url('./font/hk-grotesk/HKGrotesk-Regular.otf') format('otf');
      }
      `}
  />
);

export default Fonts;
