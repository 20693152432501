import {
  type ApiRequest,
  callApi,
} from '@/api/globalClient';
import { AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import { PermissionDomain, PermissionAction } from '@/api/constant/adminUserPermission';
import { AdminUserSessionState } from '@/api/constant/adminUserSession';

export const adminUserSessionQueryKey = 'account.adminUserSession';

// export interface AdminUserSessionTenant {
//   id: number
//   state: number
//   name: string
//   code: string
//   apiEndpoint: string
//   createdAt: number // Timestamp
//   updatedAt: number // Timestamp
// }

export interface AdminUserSessionUser {
  id: number
  state: AdminUserState
  type: AdminUserType
  email: string
  firstName: string
  lastName: string
  // tenants: AdminUserSessionTenant[]
  createdAt: number // Timestamp
  updatedAt: number // Timestamp
}

export interface LoadCurrentAdminUserSessionRequest {}

export type PermissionMap = Partial<Record<PermissionDomain, PermissionAction[]>>;

export interface TenantPermissionMap {
  tenantId: number
  permissionMap: PermissionMap
}

// export interface LoadCurrentAdminUserSessionTeam {
//   id: number
//   state: TeamState
//   type: TeamType
//   name: string
//   tenantId: number
// }

export interface LoadCurrentAdminUserSessionResponse {
  adminUser: AdminUserSessionUser
  adminUserSessionState: AdminUserSessionState
  permissionMaps: TenantPermissionMap[]
  // teams: LoadCurrentAdminUserSessionTeam[]
  errors: string[]
}

export function loadCurrentSession(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async (request: LoadCurrentAdminUserSessionRequest): Promise<LoadCurrentAdminUserSessionResponse> => {
    apiRequest.endpoint.path = '/api/auth/load-admin-user';
    apiRequest.payload = {}; // This means the empty body which is required to match the backend API.
    const apiData = await callApi<LoadCurrentAdminUserSessionResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    apiData.permissionMaps = apiData.permissionMaps ?? [];
    // apiData.teams = apiData.teams ?? [];
    return apiData;
  };
}

export interface SignOutRequest {

}
export interface SignOutResponse {
  errors: string[]
}
export function signOut(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async (request: SignOutRequest): Promise<SignOutResponse> => {
    apiRequest.endpoint.path = '/api/auth/sign-out';
    apiRequest.payload = {}; // This means the empty body which is required to match the backend API.
    const apiData = await callApi<SignOutResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    return apiData;
  };
}

// export interface AdminUserSessionNonceRequest {}

export interface AdminUserSessionNonceResponse {
  nonce: string
  errors: string[]
}

export function generateAdminUserSessionNonce(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async (): Promise<AdminUserSessionNonceResponse> => {
    apiRequest.endpoint.path = '/api/auth/generate-admin-user-session-nonce';
    apiRequest.payload = {};
    const apiData = await callApi<AdminUserSessionNonceResponse>(apiRequest);
    apiData.errors = apiData.errors ?? [];
    return apiData;
  };
}
