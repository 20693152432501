import {
  type ApiRequest,
  callApi
} from '@/api/globalClient';
import { TenantState } from '@/api/constant';

export const tenantQueryKey = 'account.tenant';
export const tenantSearchQueryKey = 'tenant.tenantSearch';
export const tenantListQueryKey = 'tenant.tenantList';

export interface TenantData {
  id: number
  state: number
  name: string
  code: string
  apiEndpoint: string
  createdAt: number
  updatedAt: number
}

export interface TenantLoadByCodeRequest {
  code: string // The tenant code.
}

export interface TenantLoadByCodeResponse {
  tenant: TenantData
  errors: string[]
}

const emptyTenant: TenantData = {
  id: 0,
  state: TenantState.Active,
  name: '',
  code: '',
  apiEndpoint: '',
  createdAt: 0,
  updatedAt: 0
};

export function loadTenantByCode(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function(params: TenantLoadByCodeRequest): Promise<TenantLoadByCodeResponse> {
    if (params.code) {
      apiRequest.endpoint.path = '/api/tenant/load-tenant-by-code';
      apiRequest.payload = {
        code: params.code
      };

      const response = await callApi<TenantLoadByCodeResponse>(apiRequest);
      return {
        tenant: response.tenant,
        errors: response.errors
      };
    }
    return {
      tenant: emptyTenant,
      errors: []
    };
  }
}

export interface TenantSearchTenantData extends TenantData {
}

export interface TenantSearchRequest {
  name?: string
  state?: number
  code?: string
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}

export interface TenantSearchResponse {
  tenantList: TenantSearchTenantData[]
  page: number
  total: number
  errors: string[]
}

export function searchTenants(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: TenantSearchRequest): Promise<TenantSearchResponse> {
    apiRequest.endpoint.path = '/api/tenant/search-tenants';
    apiRequest.payload = params;
    const response = await callApi<TenantSearchResponse>(apiRequest);
    response.tenantList = response.tenantList ?? [];
    return response
  }
}

export interface TenantSaveRequest {
  tenant: {
    id?: number
    name: string
    code: string
    state: number
    apiEndpoint: string
  }
}

export interface TenantSaveResponse {
  id: number
  errors: string[]
}

export function saveTenant(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: TenantSaveRequest): Promise<TenantSaveResponse> {
    apiRequest.endpoint.path = '/api/tenant/save-tenant';
    apiRequest.payload = params;
    return await callApi<TenantSaveResponse>(apiRequest);
  }
}

export interface TenantLoadRequest {
  id: number
}

export interface TenantLoadResponse {
  tenant: TenantData
  errors: string[]
}

export function loadTenant(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: TenantLoadRequest): Promise<TenantLoadResponse> {
    apiRequest.endpoint.path = '/api/tenant/load-tenant';
    apiRequest.payload = params;
    return await callApi<TenantLoadResponse>(apiRequest);
  }
}
