import { type FC, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  UiStack,
  UiText,
} from '@/lib/ui';
import { GlobalMessage } from '@/app/ProviderGlobalMessages';
import { isMessageApiError } from '@/account/hook/useApiErrorHandler';
import { ApiErrorCode } from '@/api/error';
import ApiErrorUnauthorized from '@/base/GlobalMessages/ApiErrorUnauthorized';
import ApiErrorBadRequest from '@/base/GlobalMessages/ApiErrorBadRequest';
import ApiErrorServerError from '@/base/GlobalMessages/ApiErrorServerError';
import ApiErrorAccessDenied from '@/base/GlobalMessages/ApiErrorAccessDenied';

export interface ErrorsDisplayProps {
  messages: GlobalMessage[];
}

const MessagesDisplay: FC<ErrorsDisplayProps> = ({
  messages,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(
    () => {
      if (messages.length > 0) {
        onOpen();
      }
    },
    [messages]
  )

  return (
    <UiDrawer
      placement={'bottom'}
      size={'sm'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        {/*<UiDrawerCloseButton size={'lg'} color={'primary.500'}/>*/}
        <UiDrawerBody px={12} py={8} bgColor={'gray.100'}>
          <UiStack spacing={4}>
            {messages.map((message, index) => {
              if (isMessageApiError(message, ApiErrorCode.Unauthorized)) {
                return (
                  <ApiErrorUnauthorized key={index}/>
                );
              }
              if (isMessageApiError(message, ApiErrorCode.AccessDenied)) {
                return (
                  <ApiErrorAccessDenied key={index}/>
                );
              }
              if (isMessageApiError(message, ApiErrorCode.ServerError)) {
                return (
                  <ApiErrorServerError key={index}/>
                );
              }
              if (isMessageApiError(message, ApiErrorCode.BadRequest)) {
                return (
                  <ApiErrorBadRequest key={index}/>
                );
              }
              return (
                <UiText key={index}>{message.body}</UiText>
              )
            })}
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default MessagesDisplay;
