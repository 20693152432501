import { type UiHStackProps } from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { type FC } from 'react';
import ClosedPageForm from './ClosedPageForm';
import PageRow from './PageRow';

export interface ManageLabelsProps extends UiHStackProps {
}

const ClosedPage: FC<ManageLabelsProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Closed page'}
        description={'Manage the message that will display to an attendee visiting the registration pages when the registration mode is set to “Closed”.'}
        onEdit={onToggle}
        {...props}
      />
      <ClosedPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default ClosedPage;
