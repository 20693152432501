import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';

const Landing: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={null}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseEventWebsiteCard showDescription={false}/>} currentAppName={'eventWebsite'} />)}
      >
        <BaseLayoutBody
          isStandalone={true}
        >
          <></>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Landing;
