import { type FC } from 'react';
import {
  UiText,
  UiSimpleGrid,
  UiIconUsersThree,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface AttendeeCategoriesProps {
  isActive?: boolean
}

const AttendeeCategories: FC<AttendeeCategoriesProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconUsersThree}
      description={'Attendee groups'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId })}
      isSet={eventSettings?.attendeeCategory?.isSet}
      // stepNumber={1}
    >
      <UiSimpleGrid flexGrow={1} columns={2} gap={4}>
        <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.attendeeCategory?.total ?? 0} total</UiText>
      </UiSimpleGrid>
    </Step>
  );
};

export default AttendeeCategories;
