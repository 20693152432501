import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiButton,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
dayjs.extend(utc);

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export type ActiveNodes = Array<Menu['name']>;

export interface MenuProps {
  menus: Menu[]
  activeNodes?: ActiveNodes
  enableTransition?: boolean
}

const ModuleMenu: FC<MenuProps> = ({
  menus,
  activeNodes = [],
  enableTransition = false
}) => {
  const transitionProps = enableTransition
    ? {
      transform: 'width, opacity',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out'
    }
    : {};

  return (
    <UiVStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flexGrow={1}
      p={8}
      borderLeftRadius={uiStyles.bodyRadius}
      overflowY={'scroll'}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
          display: 'none'
        }
      }}
      {...uiStyles.glass}
      {...transitionProps}
      // bgColor={'red'}
      minH={800}
    >
      <UiStack width={'100%'}>
        {menus.map((menu) => {
          return (
            <UiStack key={menu.name}>
              <SubMenu menu={menu} activeNodes={activeNodes} />
              {/* <UiBox height={4}/> */}
            </UiStack>
          );
        })}
      </UiStack>
    </UiVStack>
  );
};

interface SubMenuProps {
  menu: Menu
  activeNodes?: ActiveNodes
}

const SubMenu: FC<SubMenuProps> = ({
  menu,
  activeNodes = []
}) => {
  const _activeNodePath = useMemo<string>(
    () => {
      return activeNodes.join('-');
    },
    [activeNodes]
  );

  if (menu.subMenu && Array.isArray(menu.subMenu) && menu.subMenu.length > 0) {
    return (
      <UiStack pb={4}>
        <UiHStack justifyContent={'flex-start'} flexGrow={1} width={'100%'}>
          <UiText variant='title'>
            {menu.label}
          </UiText>
        </UiHStack>
        <UiVStack
          spacing={0}
          alignItems={'stretch'}
          // pl={8}
        >
          {menu.subMenu?.map((subMenu) => {
            const _nodePath = `${menu.name}-${subMenu.name}`;
            return (
              <BaseRouterLink key={subMenu.url} to={subMenu.url ?? ''}>
                <UiStack
                  key={_nodePath}
                  {...uiStyles.hover}
                  bgColor={_activeNodePath === _nodePath ? 'primary.50' : 'transparent'}
                  borderRadius={uiStyles.borderRadius}
                  flexGrow={1}
                  px={4}
                >
                  <UiButton
                    leftIcon={subMenu.icon ?? undefined}
                    variant={'unstyled'}
                    flexGrow={1}
                    bgColor={'transparent'}
                  >
                    <UiText variant={'body1'} flex={1} display={'flex'}>{subMenu.label}</UiText>
                  </UiButton>
                </UiStack>
              </BaseRouterLink>
            );
          })}
        </UiVStack>
      </UiStack>
    );
  }
};

export default ModuleMenu;
