import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type UiHStackProps,
  UiStack,
  UiHStack,
  UiText,
  uiStyles,
  UiIconUserCircle,
  UiIconArrowRight,
} from '@/lib/ui';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface CurrentUserSessionProps extends UiHStackProps {
}

const boxWidth = '420px';

const CurrentUserSession: FC<CurrentUserSessionProps> = ({}) => {
  const { adminAuth } = useAdminAuth();
  const navigate = useNavigate();
  // const chooseTenantUrl = useMemo(() => generatePageUrl('AccountLoginChooseTenant'), []);

  const toChooseTenant = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginChooseTenant'));
    },
    [navigate, generatePageUrl]
  );

  if (adminAuth.user && adminAuth.user.id > 0) {
    return (
      <UiStack
        minW={boxWidth}
        maxW={boxWidth}
        alignItems={'stretch'}
        spacing={0}
      >
        <UiText>Log into this user</UiText>
        <UiStack height={4}/>
        <UiHStack
          // spacing={4}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          spacing={0}
          p={0}
          shadow={uiStyles.cardShadow}
        >
          <UiStack
            // px={8}
            px={6}
            py={4}
            borderLeftRadius={uiStyles.borderRadius}
            borderRightWidth={'1px'}
            borderRightColor={'gray.200'}
            borderRightStyle={'solid'}
          >
            <UiIconUserCircle size={'3xl'} color={'text.primary'} />
          </UiStack>
          <UiHStack px={6} py={4} spacing={4} flexGrow={1}  onClick={toChooseTenant} {...uiStyles.hover}>
            <UiText flexGrow={1}>
              {adminAuth.user.email}
            </UiText>
            <UiStack>
              <UiIconArrowRight color={'primary.500'} size={'2xl'} />
            </UiStack>
          </UiHStack>
        </UiHStack>
        <BaseDividerHorizontal height={8} />
      </UiStack>
    );
  }
};

export default CurrentUserSession;
