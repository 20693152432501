export const eventTypeOptions: Array<{ value: string, label: string }> = [
  { value: 'hybrid', label: 'Hybrid' },
  { value: 'in_person', label: 'In Person' },
  { value: 'virtual', label: 'Virtual' },
  { value: 'webinar', label: 'Webinar' }
];

export enum EventState {
  Published = 1,
  Preview = 2,
  Closed = 3,
}

export const eventStateLabels: Record<EventState, string> = {
  [EventState.Published]: 'Published',
  [EventState.Preview]: 'Preview',
  [EventState.Closed]: 'Closed'
};

export const eventStateOptions: Array<{ value: number, label: string }> = [
  { value: EventState.Published, label: eventStateLabels[EventState.Published] },
  { value: EventState.Preview, label: eventStateLabels[EventState.Preview] },
  { value: EventState.Closed, label: eventStateLabels[EventState.Closed] }
];
