import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconClosedCaptioning } from '@/lib/ui';

export interface AILiveCaptioningProps {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const AILiveCaptioningCard: FC<AILiveCaptioningProps> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconClosedCaptioning size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'AI live captioning'}
      isSelected={isSelected}
    />
  );
};
export default AILiveCaptioningCard;
