import { useMemo, type FC } from 'react';
import {
  UiHStack,
  UiStack,
  UiText,
  uiStyles, UiIconWarningCircle, UiIconSignIn, UiLink,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseLayout from '@/base/Layout';

export interface AccessDeniedProps {
}

const AccessDenied: FC<AccessDeniedProps> = () => {
  const loginUrl = useMemo(() => generatePageUrl('AccountLoginLoginEmail'), []);

  return (
    <BaseLayout
      // smartWindow={(<LoginSmartWindow />)}
      smartWindow={null}
      sideMenu={null}
      // smartButton={(<LoginSupportSmartButton />)}
      smartButton={(<UiHStack flexGrow={1}/>)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <UiStack p={8} spacing={4} bgColor={'gray.100'} flexGrow={1} borderRadius={uiStyles.borderRadius}>
        <UiHStack p={4} bgColor={'red.50'} borderRadius={uiStyles.borderRadius} justifyContent={'space-between'}>
          <UiHStack>
            <UiIconWarningCircle size={'2xl'} color={'red.500'}/>
            <UiText color={'red.500'}>Access denied.</UiText>
          </UiHStack>
          <UiHStack spacing={0}>
            <UiLink href={loginUrl}>
              <UiStack
                {...uiStyles.hover}
                borderRadius={uiStyles.borderRadius}
                p={2}
                // px={4}
              >
                <UiText
                  color={'primary.500'}
                  // variant={'title'}
                  // p={4}
                >
                  Back to login
                </UiText>
              </UiStack>
            </UiLink>
            <UiIconSignIn size={'2xl'} color={'primary.500'}/>
          </UiHStack>
        </UiHStack>
        {/*<BaseMessageBarError>*/}
        {/*  Access denied.*/}
        {/*</BaseMessageBarError>*/}

      </UiStack>
    </BaseLayout>
  );
};

export default AccessDenied;
