import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  type ChartDataset
} from 'chart.js';
import React, { type FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { UiHStack, UiIconCornersOut, UiStack, uiStyles, UiText } from '@/lib/ui';
import FullScreen from '@/base/Modal/FullScreen';
import { useDisclosure } from '@chakra-ui/react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PointElement
);

interface StatisticChartProps {
  datasets: Array<ChartDataset<'bar', number[]>>
  labels: string[]
  title: string
  plugins?: Plugin[]
  formatLabel?: (amount: number) => string
}

const BarChart: FC<StatisticChartProps> = ({ datasets, labels, title, plugins, formatLabel }: StatisticChartProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const barOptions = useMemo(
    () => {
      return {
        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: any) => {
                const value: number = Number(tooltipItem.raw);
                if (value && formatLabel) {
                  return formatLabel(value);
                }
              }
            }
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            text: title,
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              precision: 0,
            }
          },
        },
      };
    },
    [title]
  );

  const barData = {
    labels,
    datasets
  };

  return (
    <>
      <UiHStack justifyContent={'flex-end'}>
        <UiStack onClick={onOpen} {...uiStyles.hover}>
          <UiIconCornersOut color={'primary.500'}/>
        </UiStack>
      </UiHStack>
      <Bar options={barOptions} data={barData}/>
      <FullScreen isOpen={isOpen} onClose={onClose}>
        <UiStack p={8}>
          <UiStack p={8} bgColor={'#fff'} borderRadius={uiStyles.borderRadius} shadow={'base'}>
            <Bar options={barOptions} data={barData}/>
          </UiStack>
        </UiStack>
      </FullScreen>
    </>
  );
};

export default BarChart;
