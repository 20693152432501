import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { type UiBoxProps, UiButton, UiHStack, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { subscriptionPackageLabels, SubscriptionProduct, SubscriptionState } from '@/api/constant/subscription';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import {
  getAddonsEnabled,
  isAddonEnabled,
  isProductIncluded,
  isServicePackEnabled,
} from '@/account/subscription/utils';
import Status from '../../subscription/Plan/Status';
import Registration from '@/account/component/SubscriptionModule/Registration';
import ContentDeliveryPlatform from '@/account/component/SubscriptionModule/ContentDeliveryPlatform';
import Webinar from '@/account/component/SubscriptionModule/Webinar';
import SponsorExhibitor from '@/account/component/SubscriptionModule/SponsorExhibitor';
import LiveStreaming from '@/account/component/SubscriptionModule/LiveStreaming';
import DataInsight from '@/account/component/SubscriptionModule/DataInsight';
import MobileEventApp from '@/account/component/SubscriptionModule/MobileEventApp';
import AbstractManagement from '@/account/component/SubscriptionModule/AbstractManagement';
import DigitalPoster from '@/account/component/SubscriptionModule/DigitalPoster';
import BaseBlockList from '@/base/BlockList';
import ServicePack from '@/account/component/SubscriptionAddon/ServicePack';
import AddonAbstractManagement from '@/account/component/SubscriptionAddon/AbstractManagement';
import AddonDigitalPoster from '@/account/component/SubscriptionAddon/DigitalPoster';
import AddonEventApp from '@/account/component/SubscriptionAddon/EventApp';
import AddonAppWayfinder from '@/account/component/SubscriptionAddon/AppWayfinder';
import AddonContentHub from '@/account/component/SubscriptionAddon/ContentHub';
import AddonMultiLingualSupport from '@/account/component/SubscriptionAddon/MultiLingualSupport';
import AddonLiveTranslation from '@/account/component/SubscriptionAddon/LiveTranslation';
import AddonAILiveCaptioning from '@/account/component/SubscriptionAddon/AILiveCaptioning';
import AddonIntegration from '@/account/component/SubscriptionAddon/Integration';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
dayjs.extend(customParseFormat);

export interface SummaryProps extends UiBoxProps {
}

const ManageSubscription: FC<SummaryProps> = ({
}) => {
  const { tenant } = useTenantRoute();
  const navigate = useNavigate();
  const toManagement = useCallback(() => {
    navigate(generatePageUrl('AccountSubscriptionPlanManagement', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);

  return (
    <UiHStack
      p={8}
      py={4}
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      justifyContent={'space-between'}
    >
      <UiStack>
        <UiHStack spacing={1}>
          <UiText>
            No subscription found in your account.
          </UiText>
        </UiHStack>
      </UiStack>
      <UiButton variant={'ghost'} px={0} onClick={toManagement}>Choose a plan</UiButton>
    </UiHStack>
  );
};

export default ManageSubscription;
