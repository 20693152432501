/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { InfoFormType } from '@/api/registration';
import FormList from './FormList';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

const PersonalInfoForm: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'personalInformation', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set if there are fields in the personal info form.
  useEffect(
    () => {
      return () => {
        if (eventSettings &&
          eventSettings?.personalInformation.totalFields > 0 &&
          !eventSettings.personalInformation.isSet
        ) {
          mutate({ eventId, key: 'personalInformation', bool: true });
        }
      };
    },
    [
      eventSettings?.personalInformation.totalFields,
      eventSettings?.personalInformation.isSet
    ]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'personalInfo'}
          />
        )}
        message={'Group your input fields.'}
      >
        <UiStack
          // pt={4}
          spacing={4}
          flexGrow={1}
          p={8}
        >
          <UiHStack>
            <StepInstruction label={'Instructions'}>
              <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                <UiText variant="h5">
                  Personal information
                </UiText>
                <UiText variant="body1">
                  The 'Personal information' page enables you to create form fields for relevant personal and contact details that you want to collect from registrants. This personal information is collected so registrants can be identified or contacted by the Event Organiser.
                </UiText>
                <UiText variant="body1">
                  Examples of personal information form elements are: Title, First name/given name, Last name/family name, Position/job title, Organisation name, Phone number, Address, State, Suburb/city, Country.
                </UiText>
                <UiText variant="body1">
                  You can create as many different versions of the 'Personal information' page as you want. You need to assign at least one Attendee group to each personal information page version that you create.
                </UiText>
                <UiText variant="body1">
                  Creating different versions of the 'Personal information' page allows you to customise the page for different Attendee groups, eg. you might want to ask in-person attendees for their dietary or access requirements on this page, but you won't need to include this question on a version of the Personal information page for a virtual attendee group.
                </UiText>
                <UiText variant="body1">
                  You can change the label for the 'Personal information' page in the 'Other settings, customisations' step, eg if you want to title the page 'Contact details' instead of 'Personal information'.
                </UiText>
              </UiStack>
            </StepInstruction>
          </UiHStack>
          <BaseDividerHorizontal height={4} />
          <FormList formType={InfoFormType.PERSONAL} />
        </UiStack>
        <UiHStack
          p={8}
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiText px={4} color={'text.secondary'}>
              Please make sure you've designed the form to collect the attendee's personal information.
            </UiText>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
              Next
            </UiButton>
          </BaseAclContainer>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default PersonalInfoForm;
