import { type FC, ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UiStack,
  UiHStack,
  type UiStackProps,
  UiBreadcrumb,
  UiBreadcrumbItem,
  UiBreadcrumbLink,
  UiIconArrowRight,
  uiStyles,
  UiText, UiIconCaretRight,
  // UiBox,
  // UiTag,
  // UiText,
} from '@/lib/ui';
import Menu from '@/base/Menu';

export interface BaseBreadcrumbBarNode {
  label: string
  url?: string
  onClick?: () => void
}

export interface BaseBreadcrumbBarProps extends UiStackProps {
  nodes: BaseBreadcrumbBarNode[]
  // infoBar?: ReactElement;
}

export const UiBreadcrumbBar: FC<BaseBreadcrumbBarProps> = ({
  nodes
  // infoBar = undefined,
}) => {
  const navigate = useNavigate();

  if (nodes.length === 0) {
    return null;
  }

  return (
    <UiStack borderRadius={uiStyles.borderRadius}>
      <UiBreadcrumb separator={(<UiIconCaretRight size={'2xl'}/>)}>
        {nodes.map((node) => {
          if (!!node.url || !!node.onClick) {
            return (
              <UiBreadcrumbItem key={node.label}>
                <UiBreadcrumbLink
                  onClick={node.onClick ? node.onClick : () => { navigate(node.url ?? ''); }}
                  fontSize={'body1'}
                  // textDecoration={'underline'}
                  color={'primary.500'}
                  {...uiStyles.hover}
                  fontWeight={600}
                >
                  {node.label}
                </UiBreadcrumbLink>
              </UiBreadcrumbItem>
            );
          } else {
            return (
              <UiBreadcrumbItem key={node.label} isCurrentPage>
                <UiBreadcrumbLink fontWeight={600} href='' fontSize={'body1'}>{node.label}</UiBreadcrumbLink>
              </UiBreadcrumbItem>
            );
          }
        })}
      </UiBreadcrumb>
    </UiStack>
  );
};

export default UiBreadcrumbBar;
