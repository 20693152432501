import { type FC } from 'react';
import {
  UiIconWarningCircle,
  uiStyles,
} from '@/lib/ui';
import Base, { type BaseProps } from './Base';

export type MessageBarInfoProps = Omit<BaseProps, 'icon'>;

export const BaseMessageBarError: FC<MessageBarInfoProps> = (props) => {
  return (
    <Base
      textProps={{
        variant: 'body1',
        color: 'red.500'
      }}
      icon={<UiIconWarningCircle size={'3xl'} color={'red.500'} />}
      bgColor={'red.50'}
      borderRadius={uiStyles.borderRadius}
      {...props}
    />
  );
};

export default BaseMessageBarError;
