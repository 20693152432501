import { registration } from '@/api';
import {
  UiButton,
  UiHStack,
  UiIconArrowRight,
  UiIconStar,
  UiImage, UiStack,
  UiText,
  uiStyles,
  type UiHStackProps, UiGrid, UiSimpleGrid,
} from '@/lib/ui';
import {
  useDisclosure
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, type FC, useMemo } from 'react';
import EventForm from './EventForm';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { eventListQueryKey } from '@/api/registration';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useTenantAcl } from '@/account/hook/useTenantAcl';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';

dayjs.extend(utc);

export interface EventRowProps extends UiHStackProps {
  event: registration.Event
  isSelected?: boolean
}

export interface ToggleFavouriteRequest {
  eventId: string
}

const EventRow: FC<EventRowProps> = ({
  event,
  isSelected = false,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { tenant } = useTenantRoute();
  const { hasTenantPermissions } = useTenantAcl({tenantId: tenant?.id ?? 0});
  const { mutate: mutateToggleFavourite } = useMutation<ApiResponseSingle<any>, Error, ToggleFavouriteRequest>({
    mutationFn: async (dataParams: ToggleFavouriteRequest) => {
      return await registration.toggleFavourite(createTenantAdminApiRequest)(dataParams);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [eventListQueryKey] });
    }
  });
  const canEdit = useMemo(
    () => {
      return hasTenantPermissions([[PermissionDomain.Account, PermissionAction.Write]]);
    },
    [hasTenantPermissions]
  );

  const handleToggleFavourite = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      void mutateToggleFavourite({
        eventId: event.id
      });
    },
    [event.id, mutateToggleFavourite]
  );

  return (
    <>
      <UiHStack
        p={4}
        px={8}
        // px={8}
        flexGrow={1}
        spacing={4}
        bgColor={'#fff'}
        justifyContent={'space-between'}
        // bgColor={isSelected ? 'primary.50' : 'transparent'}
        {...uiStyles.hoverFlex({ bgColor: 'gray.50' })}
        {...props}
      >
        <UiSimpleGrid columns={2} spacing={4} flexGrow={1}>
          <UiHStack>
            {!!event.menuLogo && (
              <UiImage
                src={event.menuLogo}
                maxWidth={'48px'}
              />
            )}
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Event ID: {event.id}</UiText>
              <UiText>{event.name}</UiText>
            </UiStack>
          </UiHStack>
          <UiStack spacing={1}>
            <UiText variant={'body2'} color={'text.secondary'}>When</UiText>
            {event.startDate && event.endDate ? (
              <UiText variant={'body1'}>
                {dayjs(event.startDate).format('DD MMM YYYY')} - {dayjs(event.endDate).format('DD MMM YYYY')}
              </UiText>
            ) : (
              <UiText variant={'body1'}>-</UiText>
            )}
          </UiStack>
        </UiSimpleGrid>
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Account, PermissionAction.Write]]}>
          {/*<UiIconArrowRight color={'primary.500'} size={'2xl'} />*/}
          <BaseButtonPlainIconEdit
            onClick={canEdit ? onToggle : () => {}}
          />
        </BaseAclContainer>
        {/* <EventStateTag state={data.state}/> */}
        {/* {isSelected && (<UiIconCheck size={'3xl'} weight={'bold'} color={'green.500'}/>)} */}
      </UiHStack>
      <EventForm
        event={event}
        isVisible={isOpen}
        onClose={onToggle}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default EventRow;
