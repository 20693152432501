import { type FC, useMemo, useCallback } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import dayjs, { type Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { createFormatPrice } from '@/lib/util/locale';
import { UiBadge, UiButton, UiHStack, UiIconArrowRight, UiIconCopy, UiStack, uiStyles, UiTag } from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { account } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { TenantState, tenantStateLabels, tenantStateOptions } from '@/api/constant';
import {
  SubscriptionPackage,
  subscriptionPackageLabels,
  SubscriptionState,
  subscriptionStateLabels,
  subscriptionStateOptions,
} from '@/api/constant/subscription';

dayjs.extend(advancedFormat);

interface DataTableProps {
  data: account.SubscriptionRecord[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: any
  setFilters: (filters: any) => void
  sorting: any
  setSorting: (sorting: any) => void
  pagination: any
  setPagination: (pagination: any) => void
  rowCount: number
}

const DataTable: FC<DataTableProps> = ({
  data,
  isLoading,
  isError,
  isFetching,
  refetch,
  filters,
  setFilters,
  sorting,
  setSorting,
  pagination,
  setPagination,
  rowCount,
}) => {
  const navigate = useNavigate();
  const priceLocale = 'en-AU';
  const priceCurrency = 'AUD';
  const { tenantCode } = useRegisterRoute();
  const dataTableOptions = useDataTableOptions<account.SubscriptionRecord>();
  const formatPrice = useMemo(
    () => { return createFormatPrice({ locale: priceLocale, currency: priceCurrency }); },
    []
  );

  const toTenantInfoPage = useCallback(
    (tenantId: string) => {
      navigate(generatePageUrl('AAAManageTenantInfo', { tenantId, }));
    },
    []
  );

  const columns = useMemo<Array<MRT_ColumnDef<account.SubscriptionRecord>>>(
    () => {
      return [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnFilter: false,
          size: 50,
        },
        {
          accessorKey: 'state',
          header: 'State',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: subscriptionStateOptions.map((stateOption) => {
              return {
                label: stateOption.label,
                value: `${stateOption.value}`,
              };
            }),
          },
          Cell: ({ cell }) => {
            if (cell.getValue()) {
              if (cell.getValue() === SubscriptionState.Active) {
                return (
                  <UiTag colorScheme={'green'}>
                    {subscriptionStateLabels[cell.getValue() as SubscriptionState]}
                  </UiTag>
                );
              }
              if (cell.getValue() === SubscriptionState.Pending) {
                return (
                  <UiTag colorScheme={'yellow'}>
                    {subscriptionStateLabels[cell.getValue() as SubscriptionState]}
                  </UiTag>
                );
              }
              if (cell.getValue() === SubscriptionState.Obsolete || cell.getValue() === SubscriptionState.Cancelled) {
                return (
                  <UiTag colorScheme={'gray'}>
                    {subscriptionStateLabels[cell.getValue() as SubscriptionState]}
                  </UiTag>
                );
              }
            }
            return null;
          },
        },
        {
          accessorKey: 'package',
          header: 'Package',
          Cell: ({ cell }) => {
            return subscriptionPackageLabels[cell.getValue() as SubscriptionPackage];
          },
        },
        {
          accessorKey: 'tenant',
          header: 'Tenant',
          Cell: ({ cell }) => {
            return (cell.getValue() as account.TenantData)?.name ?? '';
          },
        },
        {
          accessorKey: 'from',
          header: 'From',
          Cell: ({ cell }) => {
            return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format("DD/MM/YYYY") : null;
          },
          enableColumnFilter: false,
        },
        {
          accessorKey: 'to',
          header: 'To',
          Cell: ({ cell }) => {
            return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format("DD/MM/YYYY") : null;
          },
          enableColumnFilter: false,
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at',
          Cell: ({ cell }) => {
            return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format("DD/MM/YYYY HH:mm:ss") : null;
          },
          enableColumnFilter: false,
        },
        {
          accessorKey: 'updatedAt',
          header: 'Updated at',
          Cell: ({ cell }) => {
            return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format("DD/MM/YYYY HH:mm:ss") : null;
          },
          enableColumnFilter: false,
        },
      ];
    },
    [tenantStateOptions, formatPrice]
  );

  const handleApplyFilter = () => {
    refetch();
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: false,
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableHiding: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      pagination,
    },
    // displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    // globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={4}
          // pt={2}
        >
          <UiButton
            size={'sm'}
            variant={'outline'}
            colorScheme={'primary'}
            onClick={handleApplyFilter}
          >
            Apply filter
          </UiButton>
        </UiHStack>
      );
    },
    renderRowActions: ({ row }) => {
      return (
        <UiHStack
          px={0}
          justifyContent={'flex-start'}
          onClick={() => { return toTenantInfoPage(`${row.original?.tenant?.id}`); }}
          {...uiStyles.hover}
        >
          <UiIconArrowRight size={'2xl'} color={'primary.500'}/>
        </UiHStack>
      );
    },
    ...dataTableOptions,
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;
