import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles,
  UiIconCurrencyCircleDollar
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import TicketList from '../MainEventTickets/TicketList';
import { TicketType } from '@/api/constant';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import { UiUnorderedList } from '@/lib/ui/Text/UiUnorderedList';
import { UiListItem } from '@/lib/ui/Text/UiListItem';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import SharedStock from '@/registration/component/BuildEvent/SharedStock';

const WorkshopTickets: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'workshopTicket', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Assume the step is set once the step is visited.
  useEffect(
    () => {
      mutate({ eventId, key: 'workshopTicket', bool: true });
    },
    [mutate, eventId]
  );

  return (
    <BaseLayout
      smartWindow={<SmartWindow />}
      // disableTopNav={true}
      appSwitch={<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={
          <ManageEventSteps
            eventId={eventId}
            activeStep={'workshopTickets'}
          />
        }
        message={'Please design your badge.'}
      >
        {/* <UiHStack p={8}> */}
        {/*  {!eventSettings?.workshopTicket?.isEnabled */}
        {/*    ? ( */}
        {/*      <UiText variant={'title'} color={'text.secondary'}> */}
        {/*        Step skipped */}
        {/*      </UiText> */}
        {/*    ) */}
        {/*    : ( */}
        {/*      <UiText variant={'title'} color={'primary.500'}> */}
        {/*        Step included */}
        {/*      </UiText> */}
        {/*    )} */}
        {/*  <UiSwitch */}
        {/*    colorScheme={'primary'} */}
        {/*    onChange={onToggle} */}
        {/*    isChecked={eventSettings?.workshopTicket?.isEnabled} */}
        {/*    size={'lg'} /> */}
        {/* </UiHStack> */}
        {/* {!eventSettings?.workshopTicket?.isEnabled */}
        {/*  ? ( */}
        {/*    <UiStack pb={8} px={8}> */}
        {/*      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>There are no function tickets for this event.</BaseMessageBarInfo> */}
        {/*    </UiStack> */}
        {/*  ) */}
        {/*  : ( */}
        <>
          <UiStack
            spacing={4}
            flexGrow={1}
            p={8}
          >
            <UiHStack alignItems={'flex-start'} justifyContent={'flex-start'} spacing={4}>
              {/* <UiHStack bgColor={'#fff'} py={4} px={8} borderRadius={uiStyles.borderRadius}>
                <UiIconCurrencyCircleDollar />
                <UiText>AUD</UiText>
                <UiText color={'text.secondary'}>(you can change the currency by editing the event)</UiText>
              </UiHStack> */}
              <UiHStack>
                <StepInstruction label={'Instructions'}>
                  <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                    <UiText variant="h5">
                      Workshop tickets
                    </UiText>
                    <UiText variant='body1'>
                      <b>Ticket types</b> refer to a specific classification of tickets that are offered for attendees to purchase or obtain entry to (components of) the event.
                    </UiText>
                    <UiText variant='body1'>
                      You can create up to <b>4 different ticket type pages</b> on your registration form:
                    </UiText>
                    <UiUnorderedList>
                      <UiListItem>
                        <b>Registration types:</b> eg full Conference admission ticket, Conference day tickets, Webinar ticket
                      </UiListItem>
                      <UiListItem>
                        <b>Function tickets:</b> eg. Welcome reception ticket, Gala dinner ticket
                      </UiListItem>
                      <UiListItem>
                        <b>Workshop ticket:</b> eg Pre-event workshop ticket, Morning workshop ticket
                      </UiListItem>
                      <UiListItem>
                        <b>Tours tickets:</b> eg Site visit tickets
                      </UiListItem>
                    </UiUnorderedList>
                    <UiText variant='body1'>
                      You can create as many different tickets under each ticket type page as you want. You need to assign at least one Attendee group to each ticket that you create. You also have the option to apply the Discount codes and set the discount amount or percentage for each ticket.
                    </UiText>
                    <UiText variant='body1'>
                      You can change the label for the ticket types in the 'Other settings, customisations' step, eg if you want to replace 'Registration types' to 'Conference tickets; or 'Workshop' tickets to 'Masterclasses'
                    </UiText>
                  </UiStack>
                </StepInstruction>
              </UiHStack>
            </UiHStack>
            <BaseDividerHorizontal height={4} />
            <SharedStock ticketType={TicketType.Workshop} />
            <BaseDividerHorizontal height={4} />
            <TicketList ticketType={TicketType.Workshop} />
          </UiStack>
          <UiHStack
            justifyContent={'space-between'}
            p={8}
          >
          </UiHStack>
        </>
        {/*  ) */}
        {/* } */}
        <UiHStack
          justifyContent={'space-between'}
          p={8}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
              Next
            </UiButton>
          </BaseAclContainer>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default WorkshopTickets;
