import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
} from '@/lib/ui';
import EventForm from './EventForm';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewEventRowProps extends UiHStackProps {
}

const NewEventRow: FC<NewEventRowProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle} variant={'large'}>Add event</BaseButtonIconText>
      <EventForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewEventRow;
