import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  functionTicketPageTitle: string
  functionTicketPageDescription: string
}

const formSchema = Yup.object().shape({
  functionTicketPageTitle: Yup.string()
    .nullable(),
  functionTicketPageDescription: Yup.string()
    .max(500, 'Description can not have more than 500 charactors.')
    .nullable()
});

const FunctionTicketPageForm: FC<LabelsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the function ticket labels.']);
    }
  });

  const submitForm = async (values: FormData) => {
    await mutateEventSettings({
      eventId,
      config: {
        pageMetadata: {
          ...eventSettingQuery.data!.pageMetadata,
          functionTicket: {
            title: values.functionTicketPageTitle,
            description: values.functionTicketPageDescription
          }
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => (
        <Formik
          initialValues={{
            functionTicketPageTitle: eventSetting.pageMetadata?.functionTicket?.title ?? '',
            functionTicketPageDescription: eventSetting.pageMetadata?.functionTicket?.description ?? ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={formSchema}
          onSubmit={async (
            values: FormData,
            { setSubmitting }: FormikHelpers<FormData>
          ) => {
            setSubmitting(true);
            await submitForm(values);
            setSubmitting(false);
          }}
        >
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Function tickets page'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                ))}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'functionTicketPageTitle'}
                label={'Page title'}
                isRequired={false}
                helperText={'The title for the page. E.g. Functions'}
              />
              <BaseFormTextareaField
                name={'functionTicketPageDescription'}
                label={'Description/instruction for the page'}
                isRequired={false}
                maxLength={500}
                helperText={'The description or instruction you want to show on the top of the page.'}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>
        </Formik>
      )}
    </QueryContainer>
  );
};

export default FunctionTicketPageForm;
