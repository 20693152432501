import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
// import FormCard from './FormCard';
import { UiHStack, UiText, UiSimpleGrid } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type InfoForm, type InfoFormType } from '@/api/registration';
import NewForm from './NewForm';
import FormCard from './FormCard';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

export interface FormListProps {
  formType: InfoFormType
}

const FormList: FC<FormListProps> = ({ formType }) => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const infoFormsQuery = useQuery(
    [registration.infoFormsQueryKey, { eventId, formType }],
    async () => {
      const result = await registration.loadInfoForms(createTenantAdminApiRequest)({ formType, eventId });
      return result.items;
    },
    {
      enabled: !isApiPreparing
    }
  );

  return (
    <QueryContainer query={infoFormsQuery}>
      {(infoForms) => {
        return (
          <>
            <UiHStack justifyContent={'space-between'}>
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <NewForm />
              </BaseAclContainer>
              <UiText>Total: {infoForms.length}</UiText>
            </UiHStack>
            {infoForms && infoForms.length > 0 && (
              <UiSimpleGrid spacing={4} columns={1}>
                {infoForms.map((formItem) => {
                  return (
                    <FormCard key={formItem.id} infoForm={formItem} />
                  );
                })}
              </UiSimpleGrid>
            )}
          </>
        );
      }}
    </QueryContainer>
  );
};

export default FormList;
