import { type Attributes, Children, cloneElement, type FC, isValidElement, type ReactNode, useMemo } from 'react';
import {
  UiDivider,
  UiVStack,
  type UiStackProps,
  uiStyles, UiHStack, type UiBoxProps,
} from '@/lib/ui';

export interface BlockListProps extends UiStackProps {
  enableSeparator?: boolean
  enableDivider?: boolean
  header?: ReactNode
  footer?: ReactNode
  spacing?: number
}

export const BlockList: FC<BlockListProps> = ({
  enableSeparator = true,
  enableDivider = false,
  header = undefined,
  footer = undefined,
  children,
  spacing = 0.5,
  ...props
}) => {
  const numOfChildren = useMemo(
    () => {
      return Children.toArray(children).length;
    },
    [children]
  );

  return (
    <UiVStack
      borderRadius={uiStyles.listRadius}
      alignItems={'stretch'}
      shadow={uiStyles.cardShadow}
      // bgColor={'gray.100'}
      {...props}
    >
      {!!header && header}
      <UiVStack
        p={0}
        spacing={enableSeparator ? spacing : 0}
        // spacing={spacing}
        alignItems={'stretch'}
        enableDivider={enableDivider}
        divider={enableDivider ? (
          <UiHStack flexGrow={1} bgColor={'gray.100'} height={spacing} borderStyle={'none'} />
        ) : undefined
        }
      >
        {Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            const _props: UiBoxProps = {};
            if (index === 0) {
              _props.borderTopRadius = uiStyles.listRadius;
            }
            if (index === numOfChildren - 1) {
              _props.borderBottomRadius = uiStyles.listRadius;
            }
            return cloneElement(child, _props as Attributes);
          }
          return child;
        })}
      </UiVStack>
      {!!footer && footer}
    </UiVStack>
  );
};

export default BlockList;
