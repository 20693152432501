import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  UiBox,
  type UiHStackProps,
  UiStack, UiText,
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormSelectField from '@/base/Form/SelectField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  label: string
  invoiceType: 'fixed' | 'percentage'
  invoiceAmount: number
  creditCardType: 'fixed' | 'percentage'
  creditCardAmount: number
}

const formSchema = Yup.object().shape({
  label: Yup.string()
    .required('Surcharge label is required'),
  invoiceType: Yup.string()
    .required('Invoice type is required'),
  invoiceAmount: Yup.number().when('invoiceType', {
    is: 'percentage',
    then: (schema) => { return schema.min(0, 'Invoice surcharge must be no less than 0'); },
    otherwise: (schema) => { return schema.min(0, 'Invoice surcharge must be no less than 0'); }
  }),
  creditCardType: Yup.string()
    .required('Credit card type is required'),
  creditCardAmount: Yup.number().when('creditCardType', {
    is: 'percentage',
    then: (schema) => { return schema.min(0, 'Credit card surcharge must be no less than 0'); },
    otherwise: (schema) => { return schema.min(0, 'Credit card surcharge must be no less than 0'); }
  })
});

const SurchargePageForm: FC<LabelsFormProps> = ({
  onClose,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee group labels.']);
    }
  });

  const submitForm = async (values: FormData) => {
    await mutateEventSettings({
      eventId,
      config: {
        surcharge: {
          label: values.label,
          invoice: {
            type: values.invoiceType,
            value: values.invoiceAmount
          },
          creditCard: {
            type: values.creditCardType,
            value: values.creditCardAmount
          }
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => {
        return (
          <Formik<FormData>
            initialValues={{
              label: eventSetting.surcharge?.label ?? 'Processing fee',
              invoiceType: eventSetting.surcharge?.invoice?.type ?? 'fixed',
              invoiceAmount: eventSetting.surcharge?.invoice?.type === 'fixed' ? eventSetting.surcharge?.invoice?.value : eventSetting.surcharge?.invoice?.value,
              creditCardType: eventSetting.surcharge?.creditCard?.type ?? 'fixed',
              creditCardAmount: eventSetting.surcharge?.creditCard?.type === 'fixed' ? eventSetting.surcharge?.creditCard?.value : eventSetting.surcharge?.creditCard?.value
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={async (
              values: FormData,
              { setSubmitting }: FormikHelpers<FormData>
            ) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
            }) => (
              <BaseFormDrawer
                isOpen={isVisible}
                onClose={onClose}
                title={'Surcharge'}
                size={'xl'}
                isLoading={isLoading}
              >
                {saveErrors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    {saveErrors.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                  </UiStack>
                )}
                <BaseFormFieldGroup>
                  <BaseFormInputField
                    name={'label'}
                    label={'Label'}
                    isRequired={true}
                    type={'text'}
                    helperText={'The label of the surcharge on the frontend. E.g. Processing fee'}
                  />
                  <UiText variant={'body1'} fontWeight={600}>Invoice payments</UiText>
                  <BaseFormSelectField
                    name={'invoiceType'}
                    label={'Type'}
                    isRequired={true}
                    options={[
                      { value: 'percentage', label: 'Percentage' },
                      { value: 'fixed', label: 'Fixed amount' }
                    ]}
                  />
                  <BaseFormInputField
                    name={'invoiceAmount'}
                    label={'Amount'}
                    isRequired={true}
                    type="number"
                    helperText={values.invoiceType === 'fixed' ? 'E.g. a value of 20 means $20.' : 'E.g. a value of 20 means 20% surcharge.' }
                  />
                  <UiText variant={'body1'} fontWeight={600}>Credit card payments</UiText>
                  <BaseFormSelectField
                    name={'creditCardType'}
                    label={'Type'}
                    isRequired={true}
                    options={[
                      { value: 'percentage', label: 'Percentage' },
                      { value: 'fixed', label: 'Fixed amount' }
                    ]}
                  />
                  <BaseFormInputField
                    name={'creditCardAmount'}
                    label={'Amount'}
                    isRequired={true}
                    type="number"
                    helperText={values.creditCardType === 'fixed' ? 'E.g. a value of 20 means $20.' : 'E.g. a value of 20 means 20% surcharge.' }
                  />
                </BaseFormFieldGroup>
              </BaseFormDrawer>
            )}
          </Formik>
        );
      }}
    </QueryContainer>

  );
};

export default SurchargePageForm;
