import { type FC } from 'react';
import {
  Info
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconInfoProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconInfo: FC<UiIconInfoProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Info}
      {...props}
    />
  );
};
