import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  type User
} from '@firebase/auth';
import app from './app';

const auth = getAuth(app);

export interface AuthSignInByEmailPasswordRequest {
  email: string
  password: string
}

export interface AuthSignInByEmailPasswordResponse {
  user?: User
  error?: string
}

export async function signInByEmailPassword(request: AuthSignInByEmailPasswordRequest): Promise<AuthSignInByEmailPasswordResponse> {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, request.email, request.password);
    return {
      user: userCredential.user,
    };
  } catch (error) {
    return {
      error: (error as Error).message ?? '',
    };
  }
}

// export interface AuthUpdatePasswordRequest {
//   // email: string
//   password: string
// }
//
// export interface AuthUpdatePasswordResponse {
//   // user?: User
//   error?: string
// }
//
// export async function updateAdminUserPassword(request: AuthUpdatePasswordRequest): Promise<AuthUpdatePasswordResponse> {
//   try {
//     console.log(auth.currentUser);
//     // const userCredential = await signInWithEmailAndPassword(auth, request.email, request.password);
//     // return {
//     //   user: userCredential.user,
//     // };
//   } catch (error) {
//     return {
//       error: (error as Error).message ?? '',
//     };
//   }
// }