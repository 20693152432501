import { useMemo, type FC, type ReactNode, useEffect, useState } from 'react';
import {
  UiHStack,
  UiHStackProps,
  UiStack,
  uiStyles,
} from '@/lib/ui';
import BaseBreadcrumbBar, { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import { topNavHeight } from '@/base/Layout/viewport';
// import { useWindowScrollY, useWindowSize } from '@/lib/hook';
import BaseFloatingContainerTop from '@/base/FloatingContainer/Top';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface BreadcrumbBodyProps {
  children: ReactNode
  breadcrumbNodes: BaseBreadcrumbBarNode[]
  message?: string
}

const BreadcrumbBody: FC<BreadcrumbBodyProps> = ({
  children,
  breadcrumbNodes,
  message = undefined
}) => {
  // const windowScrollY = useWindowScrollY();
  // const [ isBreadcrumbBarFixed, setBreadcrumbBarFixed ] = useState(false);
  //
  // useEffect(() => {
  //   if (windowScrollY >= topNavHeight) {
  //     setBreadcrumbBarFixed(true);
  //   } else {
  //     setBreadcrumbBarFixed(false);
  //   }
  // }, [windowScrollY]);
  
  return (
    <UiStack
      spacing={0}
      alignItems={'stretch'}
      flexGrow={1}
      // p={8}
    >
      <BaseFloatingContainerTop heightOffset={topNavHeight}>
        <UiStack
          spacing={0}
          borderTopRadius={uiStyles.bodyRadius}
          {...uiStyles.glass}
        >
          <UiStack
            px={8}
            py={6}
            // borderBottomWidth={'1px'}
            // borderBottomStyle={'solid'}
            // borderBottomColor={'gray.300'}
          >
            <BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes} />
          </UiStack>
        </UiStack>
      </BaseFloatingContainerTop>
      <BaseTransitionSlideLeft>
        <UiStack
          alignItems={'stretch'}
          flexGrow={1}
          spacing={0}
          borderBottomRadius={uiStyles.bodyRadius}
          {...uiStyles.glass}
        >
          <BaseDividerHorizontal height={0} widthOffset={8}/>
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default BreadcrumbBody;
