import { type FC, SyntheticEvent, useCallback, KeyboardEvent } from 'react';
import {
  UiStack,
  UiHStack,
  uiStyles,
  UiInput,
  UiInputProps,
  UiIconMagnifyingGlass, UiHStackProps, UiIconX,
} from '@/lib/ui';

export interface SearchBarProps extends UiHStackProps {
  value: UiInputProps['value']
  onChange: UiInputProps['onChange']
  onSearch: () => void
  onClear?: () => void
  enableOnClear?: boolean
  placeHolder?: UiInputProps['placeholder']
  inputProps?: UiInputProps
}

const SearchBar: FC<SearchBarProps> = ({
  value,
  onChange,
  onSearch,
  onClear,
  enableOnClear = true,
  placeHolder = '',
  inputProps = {},
  ...props
}) => {
  const onEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e?.key === "Enter") {
        onSearch();
      }
    },
    [onSearch]
  );

  return (
    <UiHStack
      spacing={4}
      bgColor={'#fff'}
      px={4}
      pr={8}
      borderRadius={uiStyles.formElementBorderRadius}
      {...props}
    >
      <UiInput
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
        onKeyDown={onEnter}
        type={'text'}
        height={'48px'}
        borderColor={'#fff'}
        focusBorderColor={'#fff'}
        _hover={{
          borderColor: '#fff',
        }}
        {...inputProps}
      />
      {!!value && enableOnClear && (
        <UiStack
          {...uiStyles.hover}
          onClick={onClear}
        >
          <UiIconX size={'2xl'} color={'text.secondary'}/>
        </UiStack>
      )}
      <UiStack
        {...uiStyles.hover}
        onClick={onSearch}
      >
        <UiIconMagnifyingGlass size={'2xl'} color={'primary.500'}/>
      </UiStack>
    </UiHStack>
  );
};

export default SearchBar;
