import { type FC } from 'react';
import BaseMessageBarError from '@/base/MessageBar/Error';

interface ErrorBoundaryViewProps {
  clearError: () => void
}

const ErrorBoundaryView: FC<ErrorBoundaryViewProps> = ({ clearError }) => {
  return (
    <div>
      <BaseMessageBarError>
        {'Something went wrong'}
      </BaseMessageBarError>
      <button onClick={clearError}>Clear Error</button>
    </div>
  );
};

export default ErrorBoundaryView;
