import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { PermissionMap } from '@/api/account';

export const ModulePermissionNone = 'None';

export type ModulePermission = PermissionAction.Read | PermissionAction.Write | typeof ModulePermissionNone;

export function getModulePermission(permissionMap: PermissionMap, module: PermissionDomain): ModulePermission {
  if (!permissionMap || !permissionMap.hasOwnProperty(module) || !Array.isArray(permissionMap[module]) || permissionMap[module]?.length === 0) {
    return ModulePermissionNone;
  }
  if (permissionMap[module]?.includes(PermissionAction.Write)) {
    return PermissionAction.Write;
  }
  if (permissionMap[module]?.includes(PermissionAction.Read)) {
    return PermissionAction.Read;
  }
  return ModulePermissionNone;
}
