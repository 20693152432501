export enum TicketType {
  Main = 1,
  Function = 2,
  Workshop = 3,
  Tour = 4,
}

export const ticketTypeLabels: Record<TicketType, string> = {
  [TicketType.Main]: 'Registration type',
  [TicketType.Function]: 'Function',
  [TicketType.Workshop]: 'Workshop',
  [TicketType.Tour]: 'Tour'
};

export const ticketTypeButtonLabels: Record<TicketType, string> = {
  [TicketType.Main]: 'Registration type',
  [TicketType.Function]: 'Function ticket',
  [TicketType.Workshop]: 'Workshop ticket',
  [TicketType.Tour]: 'Tour ticket'
};

export const ticketTypeOptions: Array<{ value: number, label: string }> = [
  { value: TicketType.Main, label: ticketTypeLabels[TicketType.Main] },
  { value: TicketType.Function, label: ticketTypeLabels[TicketType.Function] },
  { value: TicketType.Workshop, label: ticketTypeLabels[TicketType.Workshop] },
  { value: TicketType.Tour, label: ticketTypeLabels[TicketType.Tour] }
];

export enum TicketState {
  Valid = 1,
  Deleted = 2,
}

export const ticketStateLabels: Record<TicketState, string> = {
  [TicketState.Valid]: 'Valid',
  [TicketState.Deleted]: 'Deleted'
};

export const ticketStateOptions: Array<{ value: number, label: string }> = [
  { value: TicketState.Valid, label: ticketStateLabels[TicketState.Valid] },
  { value: TicketState.Deleted, label: ticketStateLabels[TicketState.Deleted] }
];

export enum StockType {
  Exclusive = 1, // The ticket owns the whole stock
  Shared = 2, // The ticket shares the stock with other tickets.
}

export const stockTypeLabels: Record<StockType, string> = {
  [StockType.Exclusive]: 'Exclusive',
  [StockType.Shared]: 'Shared',
};

export const stockTypeOptions: Array<{ value: number, label: string }> = [
  { value: StockType.Exclusive, label: stockTypeLabels[StockType.Exclusive] },
  { value: StockType.Shared, label: stockTypeLabels[StockType.Shared] }
];
