import { type FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerBody,
  UiButton,
  UiDrawerCloseButton,
  type UiDrawerProps, UiHStack, uiStyles,
} from '@/lib/ui';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';
import { getAppConfig } from '@/app/config';

const appHost = getAppConfig().host;

export interface ModuleRedirectionProps extends Omit<UiDrawerProps, 'children'> {
  module: ReactNode
  navLink: string
}

const ModuleRedirection: FC<ModuleRedirectionProps> = ({
  module,
  navLink,
  ...props
}) => {
  const [ errors, setErrors ] = useState<string[]>([]);
  const { tenant } = useTenantRoute();
  const { reportToGlobal } = useApiErrorHandler();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const navigate = useNavigate();
  const { refetch } = useQuery<account.AdminUserSessionNonceResponse, Error>(
    [],
    async () => {
      return await account.generateAdminUserSessionNonce(createGlobalApiAclRequest())();
    },
    {
      enabled: false,
      cacheTime: Infinity
    }
  );

  const redirect = useCallback(
    async () => {
      if (tenant?.apiEndpoint) {
        setErrors([]);
        const redirectUrl = `${tenant?.apiEndpoint}${navLink}`;
        const {data, error} = await refetch();
        const errorRedirectUrl = `${appHost}${generatePageUrl('AccountDashboardModuleRedirectionError', { tenantCode: tenant?.code ?? '' })}`;
        if (error) {
          reportToGlobal(error);
          setErrors(['Failed to connect to the API.']);
        }
        if (data?.nonce) {
          // @todo create the error redirect url to handle rediretion error.
          const query = {
            nonce: data?.nonce,
            'redirect_url': redirectUrl,
            'error_redirect_url': errorRedirectUrl,
          };
          const queryString = (new URLSearchParams(query)).toString();
          const landingUrl = `${tenant?.apiEndpoint}/api/v1/auth/dashboard_redirect?${queryString}`;
          // @ts-ignore
          window.open(landingUrl, '_blank').focus();
        }
      }
    },
    [tenant?.apiEndpoint, setErrors, tenant?.code]
  );

  return (
    <UiDrawer placement={'right'} size={'lg'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'xl'} color={'primary.500'} />
        <UiDrawerBody py={12} px={8}>
          <UiStack spacing={4}>
            <UiText variant={'body1'}>This module will launch in a new window.</UiText>
            <UiHStack p={8} py={6} bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
              {module}
            </UiHStack>
            {/*<UiText color={'text.secondary'}>You will be redirected to the above module in a new window.</UiText>*/}
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            {/*{isLoading*/}
            {/*  ? (*/}
            {/*    <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'gray'}>*/}
            {/*      {buttonLoadingText}*/}
            {/*    </UiButton>*/}
            {/*  )*/}
            {/*  : (*/}
            {/*    <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>*/}
            {/*      {buttonText}*/}
            {/*    </UiButton>*/}
            {/*  )}*/}
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} onClick={redirect}>
              Launch
            </UiButton>
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default ModuleRedirection;
