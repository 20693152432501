import { type FC } from 'react';
import {
  UiStack,
  UiText,
  UiHStack,
  type UiStackProps,
  uiStyles,
  UiBox
} from '@/lib/ui';
import { text, locale } from '@/lib/util';
// import FieldInputForm from './FieldInputForm';

export interface DescriptionBlockProps extends UiStackProps {
  description: string
  onClick?: () => void
  isRequired?: boolean
}

const DescriptionBlock: FC<DescriptionBlockProps> = ({
  description,
  onClick,
  isRequired = false,
  ...props
}) => {
  return (
    <UiStack
      borderRadius={uiStyles.borderRadius}
      spacing={2}
      onClick={onClick}
      {...uiStyles.hover}
      {...props}
    >
      <UiBox
        height={'80px'}
        // width={'320px'}
        borderRadius={uiStyles.formElementBorderRadius}
        borderWidth={'1px'}
        borderStyle={'solid'}
        borderColor={'gray.400'}
        bgColor={'indigo.50'}
        p={2}
      >
        <UiText noOfLines={3} textOverflow={'ellipsis'} variant={'body2'} color={'text.secondary'}>
          {description ?? ''}
        </UiText>
      </UiBox>
      {/*<UiText variant={'body2'} color={'text.secondary'}>{description}</UiText>*/}
    </UiStack>
  );
};

export default DescriptionBlock;
