import { type FC, useMemo, useState } from 'react';
import { UiHStack, UiStack, UiTag, UiText } from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { SubscriptionPackage, subscriptionPackageLabels } from '@/api/constant/subscription';
import Card from './Card';
import ModuleList from '../../SubscriptionModule/ModuleList';
import { getPackageProducts, isPackageEnabled } from '@/account/subscription/utils';

export interface Pack2Props {
  subscription: account.Subscription
  onSelect: () => void
}

const Pack2: FC<Pack2Props> = ({
  subscription,
  onSelect
}) => {
  const products = useMemo(
    () => {
      return getPackageProducts(subscription, SubscriptionPackage.Pack2);
    },
    [subscription]
  );

  return (
    <>
      <Card
        isSelected={isPackageEnabled(subscription, SubscriptionPackage.Pack2)}
        onSelect={onSelect}
      >
        <UiStack>
          <UiHStack spacing={0} justifyContent={'space-between'}>
            <UiHStack>
              <UiHStack spacing={4}>
                <UiText variant={'h6'} flexGrow={1}>{subscriptionPackageLabels[SubscriptionPackage.Pack2]}</UiText>
                <UiTag colorScheme={'green'} p={2} px={4}>Better value</UiTag>
              </UiHStack>
            </UiHStack>
            <UiStack>
              <UiTag px={4} py={2} bgColor={'blackAlpha.50'}>Yearly plan</UiTag>
            </UiStack>
          </UiHStack>
          <BaseDividerHorizontal height={4} />
          <UiStack>
            <UiText>Everything in Essential</UiText>
            <UiText>Extra modules to elevate your events</UiText>
          </UiStack>
        </UiStack>
        <BaseDividerHorizontal height={4} />
        <UiStack spacing={8}>
          <UiText>Modules included ({products.length})</UiText>
          <ModuleList
            modules={products}
          />
        </UiStack>
      </Card>
    </>
  );
};

export default Pack2;
