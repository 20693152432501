import { useCallback, type FC, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  UiGrid,
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText,
} from '@/lib/ui';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DeletionConfirmation from '@/registration/component/BuildEvent/SharedStock/DeletionConfirmation';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import SharedStockForm from '@/registration/component/BuildEvent/SharedStock/SharedStockForm';
import { type TicketType } from '@/api/constant';
import { type SharedStockDeleteRequest } from '@/api/registration/sharedStock';
import { type ApiResponseSingle } from '@/api/tenantClient';

export interface ShareStockListItemProps extends UiHStackProps {
  eventId: string
  tenant: any // Update the type here.
  sharedStockItem: registration.SharedStockData
  ticketType: TicketType
}

const ShareStockListItem: FC<ShareStockListItemProps> = ({
  eventId,
  tenant,
  ticketType,
  sharedStockItem,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate, isLoading } = useMutation<ApiResponseSingle<{}>, Error, SharedStockDeleteRequest>({
    mutationFn: async (request: SharedStockDeleteRequest) => {
      return await registration.deleteSharedStock(createTenantAdminApiRequest)(request);
    },
    onSuccess: (result) => {
      if (result.errors) {
        setSaveErrors(result.errors);
      } else {
        setSaveErrors([]);
        onToggleConfirmDeletion();
        void queryClient.invalidateQueries({ queryKey: [registration.sharedStockQueryKey, eventId, ticketType] });
      }
    },

    onError: () => {
      // NOTE: handle error (e.g., show an error message)
    }
  });

  const openDeleteConfirmation = useCallback(
    () => {
      setSaveErrors([]);
      onToggleConfirmDeletion();
    },
    [onToggleConfirmDeletion]
  );

  const onDelete = useCallback(
    () => { return mutate({ id: sharedStockItem.id.toString() }); }
    , [sharedStockItem.id]);

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={0}
        p={6}
        bgColor={'#fff'}
        {...props}
        // borderRadius={uiStyles.borderRadius}
      >
        <UiGrid
          templateColumns={'1fr 1fr'}
          gap={6}
          flexGrow={1}
        >
          <UiStack alignItems={'flex-start'} spacing={0} justifyContent={'flex-start'} flexGrow={1}>
            <UiText variant={'body1'} fontWeight={600}>{sharedStockItem.name}</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>{sharedStockItem.description ?? ' '}</UiText>
          </UiStack>
          <UiStack alignItems={'flex-start'} spacing={0} justifyContent={'flex-start'} flexGrow={1}>
            <UiText variant={'body2'} color={'text.secondary'}>Stock</UiText>
            <UiText variant={'body1'}>{sharedStockItem.stock ?? 0}</UiText>
          </UiStack>
        </UiGrid>
        <UiHStack
          spacing={8}
          justifyContent={'space-between'}
        >
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <BaseButtonPlainIconEdit onClick={onToggle} />
            <BaseButtonPlainIconDelete onClick={openDeleteConfirmation} isLoading={isLoading} />
          </BaseAclContainer>
        </UiHStack>
      </UiHStack>
      <SharedStockForm
        isVisible={isOpen}
        onClose={onToggle}
        sharedStockItem={sharedStockItem}
        onSaveSuccess={() => {}}
        ticketType={ticketType}
      />
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={sharedStockItem.name} errors={saveErrors} />
    </>
  );
};

export default ShareStockListItem;
