export enum RegistrationMode {
  Building = 'Building',
  Preview = 'Preview',
  Live = 'Live',
  Closed = 'Closed',
}

export const registrationModeOptions: Array<{ value: string, label: string }> = [
  { value: RegistrationMode.Building, label: RegistrationMode.Building },
  { value: RegistrationMode.Preview, label: RegistrationMode.Preview },
  { value: RegistrationMode.Live, label: RegistrationMode.Live },
  { value: RegistrationMode.Closed, label: RegistrationMode.Closed }
];
