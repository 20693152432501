import { type FC, type ReactNode } from 'react';
import {
  UiStack,
  UiStackProps,
  uiStyles
} from '@/lib/ui';

export interface CardProps {
  children: ReactNode
  isSelected: boolean
  onSelect: () => void
}

const Card: FC<CardProps> = ({
  children,
  isSelected,
  onSelect
}) => {
  return (
    <>
      <UiStack
        flexGrow={1}
        alignItems={'stretch'}
        bgColor={'#fff'}
        p={8}
        borderWidth={'2px'}
        borderStyle={'solid'}
        borderColor={isSelected ? 'green.500' : 'whiteAlpha.700'}
        borderRadius={uiStyles.borderRadius}
        onClick={onSelect}
        {...uiStyles.hoverFlex({bgColor: '#fff'})}
        shadow={uiStyles.cardShadow}
      >
        <UiStack
          transform={`scale(${isSelected ? 0.98 : 1})`}
          transition={'all .2s ease-in-out'}
        >
          {children}
        </UiStack>
      </UiStack>
    </>
  );
};

export default Card;
