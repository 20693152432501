import { type FC } from 'react';
import {
  SignOut
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconSignOutProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconSignOut: FC<UiIconSignOutProps> = (props) => {
  return (
    <UiIcon
      IconComponent={SignOut}
      {...props}
    />
  );
};
