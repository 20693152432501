import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import GroupRegistrationTable from './GroupRegistrationTable';
import { UiFlex, UiHStack, UiStack, UiText } from '@/lib/ui';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const GroupRegistrations: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['manage', 'groupRegistrations']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
      >
        <UiHStack justifyContent={'space-between'} spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Group Registrations</UiText>
          <UiFlex flexGrow={1} justifyContent={'flex-end'}>
            <SubscribeButton reportName={reportSubscriptionName.registrationReport} />
          </UiFlex>
        </UiHStack>
        <UiStack height={4} />
        <GroupRegistrationTable />
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default GroupRegistrations;
