import { type FC } from 'react';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps, UiSpinner,
} from '@/lib/ui';
import BillInfo from '@/account/subscription/Payment/BillInfo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export interface DrawerProps {
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
  subscriptionBillId: number
}

const BillInfoDrawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  subscriptionBillId,
}) => {
  return (
    <UiDrawer
      placement={'right'}
      size={'xl'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody py={8} px={0} bgColor={'gray.100'}>
          <UiStack alignItems={'stretch'} p={8}>
            <BillInfo subscriptionBillId={subscriptionBillId}/>
          </UiStack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default BillInfoDrawer;
