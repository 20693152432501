import { type FC, useMemo, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/BreadcrumbBody';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine
} from '@/lib/ui';
import { RouteParams, generatePageUrl } from '@/app/pages';
import { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import TeamManagement from './TeamManagement';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useParams } from 'react-router-dom';
import { AccountRouteParams } from '@/account/pages';

const TeamInfo: FC = () => {
  const { tenant } = useTenantRoute();
  const params = useParams<AccountRouteParams['AccountAccessTeamInfo']>();
  const teamId = params.teamId ? parseInt(params.teamId, 10) : 0;

  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Users and teams' },
        { label: 'Teams', url: generatePageUrl('AccountAccessTeam', { tenantCode: tenant?.code ?? '' }) },
        { label: 'Manage team' }
      ];
    },
    [tenant?.code]
  );

  return (
    <>
      <BaseLayout
        MainTransition={null}
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        // sideMenu={(<SideNav activeNodes={['manage', 'team']}/>)}
        // breadcrumb={(<BaseBreadcrumbBar title={'Sell'} nodes={breadcrumbNodes}/>)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'} />)}
      >
        <BaseLayoutBody
          breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'} p={8}>
            {/*<NewPermission />*/}
            {/* <UiText variant={'title'}>Permissions</UiText> */}
            <TeamManagement teamId={teamId}/>
          </UiStack>
          <UiStack height={16}/>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default TeamInfo;
