import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconBrowser } from '@/lib/ui/Icon/UiIconBrowser';

export interface EventWebsiteCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
  showDescription?: boolean
}

const EventWebsiteCard: FC<EventWebsiteCardProps> = ({
  isSelected = false,
  isEnabled = false,
  navLink = '',
  showDescription = true,
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/event-web.jpeg'}
      icon={(<UiIconBrowser size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'Event website'}
      description={showDescription ? 'Central event content and engagement hub' : undefined}
      isSelected={isSelected}
      navLink={navLink}
      flexGrow={1}
    />
  );
};
export default EventWebsiteCard;
