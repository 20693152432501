import { type FC } from 'react';
import PlainIcon, { PlainIconProps } from '@/base/Button/PlainIcon';
import { UiIconPencilSimple } from '@/lib/ui';

export interface PlainIconEditProps extends Omit<PlainIconProps, 'Icon' | 'label'> {
  label?: string
}

const PlainIconEdit: FC<PlainIconEditProps> = ({
  label = 'Edit',
  ...props
}) => {
  return (
    <PlainIcon
      label={label}
      Icon={UiIconPencilSimple}
      {...props}
    />
  );
};

export default PlainIconEdit;
