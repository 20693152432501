import { useEffect, useState } from 'react';
import { type Layout } from 'react-grid-layout';
import { type FieldMetadata } from './Field';

export default function useFields(initFieldsLayout: Layout[], initFieldsMetadata: FieldMetadata[]) {
  const [fieldsLayout, setFieldsLayout] = useState(initFieldsLayout);
  const [fieldsMetadata, setFieldsMetadata] = useState(initFieldsMetadata);

  useEffect(() => {
    setFieldsLayout(initFieldsLayout);
    setFieldsMetadata(initFieldsMetadata);
  }, [initFieldsLayout, initFieldsMetadata]);

  const addField = (fieldLayout: Layout, metadata: FieldMetadata) => {
    setFieldsLayout([...fieldsLayout, fieldLayout]);
    setFieldsMetadata([...fieldsMetadata, metadata]);
  };

  const deleteFieldLayout = (fieldId: string) => {
    const newFieldsLayout = [...fieldsLayout];
    newFieldsLayout.splice(fieldsLayout.findIndex((layout: Layout) => {
      return layout.i === fieldId;
    }), 1);
    setFieldsLayout(newFieldsLayout);
  };
  const deleteFieldMetadata = (fieldId: string) => {
    const newFieldsMetadata = [...fieldsMetadata];
    newFieldsMetadata.splice(fieldsMetadata.findIndex((metadata: FieldMetadata) => {
      return metadata.id === fieldId;
    }), 1);
    setFieldsMetadata(newFieldsMetadata);
  };
  const deleteField = (fieldId: string) => {
    deleteFieldLayout(fieldId);
    deleteFieldMetadata(fieldId);
  };
  const updateFieldMetadata = (fieldMetadata: FieldMetadata) => {
    const newFieldsMetadata = [...fieldsMetadata];
    newFieldsMetadata.splice(fieldsMetadata.findIndex((metadata: FieldMetadata) => {
      return metadata.id === fieldMetadata.id;
    }), 1);
    setFieldsMetadata([...newFieldsMetadata, fieldMetadata]);
  };
  return {
    fieldsLayout,
    setFieldsLayout,
    fieldsMetadata,
    setFieldsMetadata,
    addField,
    deleteField,
    updateFieldMetadata
  };
}
