import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormSelectField, { type TimezoneOption, type Option } from '@/base/Form/SelectField';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { eventTypeOptions } from '@/api/constant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { createEvent, type EventSaveRequest } from '@/api/account';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import ImageSelectField from '@/base/Form/ImageSelectField';
import { timezoneOptionQueryKey, type Event } from '@/api/registration';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { toBase64 } from '@/lib/util';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { getAppConfig } from '@/app/config';
import QueryContainer from '@/base/QueryContainer/QueryContainer';

interface ICheckDemensions { file: File, limitWidth: number, limitHeight: number }

const MAX_SIZE_IMAGE = 10485760; // 10MB

const checkDimensions = async ({ file, limitWidth, limitHeight }: ICheckDemensions): Promise<boolean> => {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  return await new Promise((resolve) => {
    img.onload = () => {
      resolve(img.width <= limitWidth && img.height <= limitHeight);
    };
  });
};

const validateFile = async (value: File[], limitWidth: number, limitHeight: number, fieldName: string) => {
  if (!value) return true;

  const [file] = value;
  if (!(file instanceof File)) return true;

  const isCheckedImg = await checkDimensions({ file, limitWidth, limitHeight });
  if (!isCheckedImg) {
    return new Yup.ValidationError(`Size limit (${limitWidth}x${limitHeight})`, value, fieldName);
  }

  return file.size <= MAX_SIZE_IMAGE;
};

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export interface EventFormProps extends UiHStackProps {
  event?: Event
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const isValidTime = (value: string) => { return dayjs(value, 'HH:mm').isValid(); };
const defaultTimezone = {
  value: 'Melbourne',
  label: '(+10:00) Melbourne',
  offset: '+10:00'
};

const formSchema = Yup.object().shape({
  eventType: Yup.string().required('Event type is required'),
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name is too short (minimum is 3 characters)'),
  location: Yup.string()
    .required('Venue is required.')
    .min(3, 'Venue is too short (minimum is 3 characters)')
    .max(100, 'Venue can not have more than 100 characters.'),
  // shortName: Yup.string()
  //   .required('Name is required.')
  //   .min(3, 'Name is too short (minimum is 3 characters)'),
  // tagline: Yup.string()
  //   .required('This field is required.')
  //   .min(3, 'Name is too short (minimum is 3 characters)'),
  websiteUrl: Yup.string()
    .required('Website URL is required.')
    .matches(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Invalid URL'),
  mapUrl: Yup.string()
    .url('Invalid URL'),
  // primaryColour: Yup.string()
  //   .required('Primary Color is required.'),
  // secondaryColour: Yup.string()
  //   .required('Secondary Color is required.'),
  timeZone: Yup.string()
    .required('Time zone is required'),
  startDate: Yup.date()
    .required('From Date is required'),
  endDate: Yup.date()
    .required('To Date is required')
    .test('is-after', 'End date must be later than or the same as start date', function (value: Date) {
      const startDate: Date = this.parent.startDate;
      return dayjs(value).isSameOrAfter(dayjs(startDate));
    }),
  tmpCoverImage: Yup.mixed().test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpCoverImage'); }
  ).notRequired(),
  tmpRegistrationBanner: Yup.mixed().test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpRegistrationBanner'); }
  ).notRequired(),
  tmpMenuLogo: Yup.mixed().test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 400, 400, 'tmpMenuLogo'); }
  ).notRequired(),
  startTime: Yup.string()
    .required('Start time is required')
    .test('is-date', 'Invalid time format (HH:mm)', isValidTime),
  endTime: Yup.string()
    .required('End time is required')
    .test('is-date', 'Invalid time format (HH:mm)', isValidTime)
    .test('is-after', 'End time must be later than start time', function (value: string) {
      const { startTime, startDate, endDate } = this.parent;
      if (dayjs(startDate).isSame(dayjs(endDate))) {
        return dayjs(value, 'HH:mm').isAfter(dayjs(startTime, 'HH:mm'));
      }
      return true;
    })
});

interface FormData {
  eventType: string
  name: string
  location: string
  shortName: string
  tagline: string
  websiteUrl: string
  mapUrl: string
  // primaryColour: string
  // secondaryColour: string
  tmpCoverImage: FileOrUrl[]
  tmpRegistrationBanner: FileOrUrl[]
  tmpMenuLogo: FileOrUrl[]
  tmpEmailSignature: FileOrUrl[]
  timeZone: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  financeCode: string
  financeCode2: string
}

const EventForm: FC<EventFormProps> = ({
  event,
  onClose,
  isVisible
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();
  const appConfig = getAppConfig();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<Event>, Error, EventSaveRequest>({
    mutationFn: async (data: EventSaveRequest) => {
      return await createEvent(createTenantAdminApiRequest)(data);
    },
    onSuccess: async (result) => {
      if (result?.errors && result.errors?.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        await queryClient.invalidateQueries({ queryKey: [registration.eventListQueryKey] });
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to save the event.']);
    }
  });

  const timezoneOptionsQuery = useQuery<TimezoneOption[], Error>({
    queryKey: [timezoneOptionQueryKey],
    queryFn: async () => {
      const response = await registration.loadEventTimezoneOptions(createTenantAdminApiRequest)();
      return response.items;
    },
    enabled: !isApiPreparing
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getInitValues = useCallback((event: Event | undefined) => {
    const timezoneOptions = timezoneOptionsQuery?.data;
    const timeZone: TimezoneOption = timezoneOptions?.find((option) => { return option.value === event?.timeZone; }) ?? defaultTimezone;
    const startDate = (event?.startDate ? dayjs(event?.startDate) : dayjs()).format('YYYY-MM-DD');
    const endDate = (event?.endDate ? dayjs(event?.endDate) : dayjs()).format('YYYY-MM-DD');
    const startTime = (event?.startTime ? dayjs(event?.startTime).utcOffset(timeZone.offset ?? defaultTimezone.offset) : dayjs()).format('HH:mm');
    const endTime = (event?.endTime ? dayjs(event?.endTime).utcOffset(timeZone.offset ?? defaultTimezone.offset) : dayjs()).format('HH:mm');
    return {
      eventType: event?.eventType ?? '',
      name: event?.name ?? '',
      location: event?.location ?? '',
      shortName: event?.shortName ?? '',
      tagline: event?.tagline ?? '',
      websiteUrl: event?.websiteUrl ?? appConfig.api.tenantHost,
      mapUrl: event?.mapUrl ?? '',
      tmpCoverImage: event?.coverImage ? [event.coverImage] : [],
      tmpRegistrationBanner: event?.registrationBanner ? [event.registrationBanner] : [],
      tmpMenuLogo: event?.menuLogo ? [event.menuLogo] : [],
      tmpEmailSignature: event?.emailSignature ? [event.emailSignature] : [],
      startDate,
      endDate,
      startTime,
      endTime,
      timeZone: timeZone.value.toString(),
      financeCode: event?.financeCode ?? '',
      financeCode2: event?.financeCode2 ?? ''
    } satisfies FormData;
  }, [appConfig.api.tenantHost, timezoneOptionsQuery?.data]);

  const initialValues = useMemo(() => { return getInitValues(event); }, [event, getInitValues]);

  const disableEventTypeField: boolean = initialValues.eventType === 'webinar';
  const typeOptions: Option[] = initialValues.eventType === 'webinar' ? eventTypeOptions : eventTypeOptions.filter((option) => { return option.value !== 'webinar'; });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);

    const timezoneOptions = timezoneOptionsQuery?.data;
    const timezone: TimezoneOption = timezoneOptions?.find((option) => { return option.value === event?.timeZone; }) ?? defaultTimezone;
    const formatString = `YYYY-MM-DDTHH:mm:ss${timezone.offset ?? defaultTimezone.offset}`;
    const startDate = dayjs(values.startDate).format(formatString);
    const endDate = dayjs(values.endDate).format(formatString);
    const startTime = dayjs(`${values.startDate} ${values.startTime}`).format(formatString);
    const endTime = dayjs(`${values.endDate} ${values.endTime}`).format(formatString);

    const eventData = {
      id: event?.id,
      eventType: values.eventType,
      name: values.name,
      location: values.location,
      // shortName: values.shortName,
      // tagline: values.tagline,
      websiteUrl: values.websiteUrl,
      mapUrl: values.mapUrl,
      tmpCoverImage: values.tmpCoverImage[0] instanceof File ? await toBase64(values.tmpCoverImage[0]) : values.tmpCoverImage[0],
      tmpRegistrationBanner: values.tmpRegistrationBanner[0] instanceof File ? await toBase64(values.tmpRegistrationBanner[0]) : values.tmpRegistrationBanner[0],
      tmpMenuLogo: values.tmpMenuLogo[0] instanceof File ? await toBase64(values.tmpMenuLogo[0]) : values.tmpMenuLogo[0],
      tmpEmailSignature: values.tmpEmailSignature[0] instanceof File ? await toBase64(values.tmpEmailSignature[0]) : values.tmpEmailSignature[0],
      timeZone: values.timeZone,
      startDate,
      endDate,
      startTime,
      endTime,
      financeCode: values.financeCode,
      financeCode2: values.financeCode2
    };
    const response = await mutateAsync({ event: eventData });
    resetForm({ values: getInitValues(response.items[0]) }); // update form init values
    setSubmitting(false);
  }, [event?.id, event?.timeZone, getInitValues, mutateAsync]);

  return (
    <QueryContainer query={timezoneOptionsQuery} disableLoadingEffect={true}>
      {(timezoneOptions) => {
        return (
          <Formik<FormData>
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            <BaseFormDrawer
              isOpen={isVisible}
              onClose={onClose}
              title={'Event'}
              size={'xl'}
              isLoading={isLoading}
            >
              {saveErrors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {saveErrors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <BaseFormFieldGroup>
                <BaseFormSelectField
                  name={'eventType'}
                  label={'Event type'}
                  options={typeOptions}
                  isDisabled={disableEventTypeField}
                />
                <BaseFormInputField
                  name={'name'}
                  label={'Event name'}
                />
                <BaseFormInputField
                  name={'location'}
                  label={'Location'}
                />
                <BaseFormInputField
                  name={'websiteUrl'}
                  label={'Event website'}
                  helperText={`Please include https:// in your URL, for example: ${process.env.REACT_APP_API_TENANT_HOST!}`}
                />
                <BaseFormInputField
                  name={'mapUrl'}
                  label={'Google Maps URL'}
                  isRequired={false}
                />
                {/* <BaseFormInputField
            name={'primaryColour'}
            label={'Primary Color'}
          />
          <BaseFormInputField
            name={'secondaryColour'}
            label={'Secondary Color'}
          /> */}
                <ImageSelectField
                  name="tmpCoverImage"
                  label="Event website banner"
                  isRequired={false}
                  helperText={'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'}
                  hintText={'The Event website banner uploaded will display in hero section of the event website'}
                />
                <ImageSelectField
                  name="tmpRegistrationBanner"
                  label="Registration banner"
                  isRequired={false}
                  helperText={'Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'}
                  hintText={'The Cover image uploaded will display as the top banner on the registration form'}
                />
                <ImageSelectField
                  name="tmpMenuLogo"
                  label="Menu logo"
                  isRequired={false}
                  helperText={'Please upload file under format: jpg, jpeg, png, gif. Size limit: 400x400 10MB'}
                  hintText={'The Menu logo uploaded will display in the event listing'}
                />
                <ImageSelectField
                  name="tmpEmailSignature"
                  label="Email signature"
                  isRequired={false}
                  helperText={'Please ensure to upload an image with a transparent background for best results.'}
                />
                <BaseFormSelectField
                  name={'timeZone'}
                  label={'Time Zone'}
                  helperText={'This is the time zone that will be used when displaying all dates and times relevant to the event.'}
                  options={timezoneOptions}
                  defaultValue={defaultTimezone}
                />
                <BaseFormInputField
                  name={'startDate'}
                  label={'From date'}
                  type={'date'}
                  helperText="The provided date should be in the event's time zone."
                />
                <BaseFormInputField
                  name={'endDate'}
                  label={'To date'}
                  type={'date'}
                  helperText="The provided date should be in the event's time zone."
                />
                <BaseFormInputField
                  name={'startTime'}
                  label={'Start time'}
                  type={'time'}
                  helperText="The provided time should be in the event's time zone."
                />
                <BaseFormInputField
                  name={'endTime'}
                  label={'End time'}
                  type={'time'}
                  helperText="The provided time should be in the event's time zone."
                />
                <BaseFormSelectField
                  name={'currency'}
                  label={'Currency'}
                  helperText={'We only support AUD currently. Support for more currencies is on the way.'}
                  options={[{ value: 'AUD', label: 'AUD' }]}
                  defaultValue={{ value: 'AUD', label: 'AUD' }}
                  isDisabled={true}
                />
                <BaseFormInputField
                  name={'financeCode'}
                  label={'Finance code'}
                  helperText="This code will be included in payment metadata for accounting purposes."
                  isRequired={false}
                />
                <BaseFormInputField
                  name={'financeCode2'}
                  label={'Finance code 2'}
                  helperText="This code will be included in payment metadata for accounting purposes."
                  isRequired={false}
                />
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          </Formik>
        );
      }}
    </QueryContainer>
  );
};

export default EventForm;
