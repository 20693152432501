import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ListActivatedChart, loadOrderStatistics, orderListActivated, orderStatistics, type OrderReport, registrationReportKey, type EventLocale, retrieveLocale } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import SmartWindow from '@/base/Layout/SmartWindow';
import { UiHStack, UiIconCoins, UiIconNote, UiStack, UiText } from '@/lib/ui';
import { createFormatPrice } from '@/lib/util/locale';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { useEventListQuery } from '@/registration/hook/useEventListQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { SimpleGrid } from '@chakra-ui/react';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import OrderChartReport from './OrderChartReport';
import OrderActivatedChartReport from './OrderActivatedChartReport';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const groupOptions = [
  { label: 'Attendee Category', value: 'byAttendeeCategory' },
  // { label: 'Main Ticket Type', value: 'byMainTicketType' },
  { label: 'Country', value: 'byCountry' },
  { label: 'Discount code', value: 'byDiscountCode' },
  // { label: 'Function Ticket Type', value: 'byFunctionTicket' },
  // { label: 'Workshop Ticket Type', value: 'byWorkshopTicket' },
  // { label: 'Tour Ticket Type', value: 'byTourTicket' },
  { label: 'All Ticket Type', value: 'byTotalTicket' }
];

const groupDateOptions = [
  { label: '7 Day', value: 'last_7_days' },
  { label: '1 Month (28) days)', value: 'last_4_weeks' },
  { label: '6 Month', value: 'last_6_months' },
  { label: '1 Year', value: 'year' }
];
interface Params {
  eventId: string
  type: string
}

interface LocaleParams {
  eventId: string
}

export type useGetOrderStatisticsType = ({ eventId, type }: Params) => UseQueryResult<ApiResponseSingle<OrderReport>, Error>;

const useGetOrderStatistics = ({ eventId, type }: Params) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponseSingle<OrderReport>, Error>({
    queryKey: [orderStatistics, eventId, type],
    queryFn: async (): Promise<ApiResponseSingle<OrderReport>> => {
      const response = await loadOrderStatistics(createTenantAdminApiRequest)({
        eventId,
        type
      });

      return response;
    },
    enabled: !!eventId && !!type,
    keepPreviousData: true,
    staleTime: 30_000
  });
};

export type useGetLocaleEventType = ({ eventId }: LocaleParams) => UseQueryResult<ApiResponseSingle<EventLocale>, Error>;

const useGetLocaleEvent = ({ eventId }: LocaleParams) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponseSingle<EventLocale>, Error>({
    queryKey: ['localeEvent', eventId],
    queryFn: async (): Promise<ApiResponseSingle<EventLocale>> => {
      const response = await retrieveLocale(createTenantAdminApiRequest)({
        eventId
      });

      return response;
    },
    enabled: !!eventId,
    staleTime: 30_000
  });
};

interface CompletedParamsChart {
  groupName: string
  eventId: string
}

export type useGetListActivatedChartType = ({ eventId, groupName }: CompletedParamsChart) => UseQueryResult<ApiResponseSingle<ListActivatedChart>, Error>;

const useGetListActivatedChart = ({ groupName, eventId }: CompletedParamsChart) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponseSingle<ListActivatedChart>, Error>({
    queryKey: [registrationReportKey, groupName, eventId],
    queryFn: async (): Promise<ApiResponseSingle<ListActivatedChart>> => {
      const response = await orderListActivated(createTenantAdminApiRequest)({
        groupName,
        eventId
      });

      return response;
    },
    enabled: !!groupName && !!eventId,
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const Registration: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const [pagination, setPagination] = useState<{ pageIndex: number, pageSize: number }>({ pageIndex: 0, pageSize: 10 });
  const [eventOptions, setEventOptions] = useState<SelectElementOption[]>([]);
  const { data: eventList } = useEventListQuery({ pagination });

  useEffect(() => {
    if (eventList?.items && Array.isArray(eventList?.items)) {
      const options = (eventList?.items ?? []).map((event: { id: string, name: string }) => { return { value: event.id, label: event.name }; });
      setEventOptions([...eventOptions, ...options]);
    }
  }, [eventList]);

  const handleScrollToBottom: ((event: WheelEvent | TouchEvent) => void) = async () => {
    if (setPagination && eventList?.pagination?.totalPages && pagination.pageIndex + 1 < eventList?.pagination?.totalPages) {
      setPagination((prev: { pageIndex: number, pageSize: number }) => { return { ...prev, pageIndex: prev.pageIndex + 1 }; });
    }
  };

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['report', 'orders']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
      >
        <UiHStack justifyContent={'flex-start'} spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Orders report</UiText>
        </UiHStack>
        {/* <BaseDividerHorizontal height={6} /> */}
        <UiStack height={6} />
        <SimpleGrid columns={{ '2xl': 2, base: 1 }} spacing={8}>
          <OrderChartReport
            selectOptions={groupOptions}
            eventList={eventList}
            useGetOrderStatistics={useGetOrderStatistics}
            title={'Total sales count'}
            type={'total_single_sale'}
            eventOptions={eventOptions}
            handleScrollToBottom={handleScrollToBottom}
            icon={(<UiIconNote color={'text.primary'} />)}
            reportName={reportSubscriptionName.orderTotalSingleSaleReport}
          />
          <OrderChartReport
            selectOptions={groupOptions}
            eventList={eventList}
            useGetOrderStatistics={useGetOrderStatistics}
            useGetLocaleEvent={useGetLocaleEvent}
            title={'Total sales in dollars'}
            type={'total_single_sales_in_dollars'}
            eventOptions={eventOptions}
            handleScrollToBottom={handleScrollToBottom}
            icon={(<UiIconCoins color={'text.primary'} />)}
            reportName={reportSubscriptionName.orderTotalSingleSaleInDollarReport}
          />
          <OrderActivatedChartReport
            selectOptions={groupDateOptions}
            type=''
            handleScrollToBottom={handleScrollToBottom}
            eventOptions={eventOptions}
            setPagination={setPagination}
            useGetListActivatedChart={useGetListActivatedChart}
            title='Orders Activated' />
        </SimpleGrid>
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default Registration;
