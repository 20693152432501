import React, { type PropsWithChildren } from 'react';

type PropsType = PropsWithChildren<{ FallbackComponent: React.FC<{ clearError: () => void }> }>;

export class AppErrorBoundary extends React.Component<PropsType, { hasError: boolean }> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    return this.state.hasError ? <this.props.FallbackComponent clearError={this.clearError} /> : this.props.children;
  }
}
