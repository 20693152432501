import { useCallback, type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  uiStyles,
  UiText,
  UiHStack,
  UiIconListNumbers
} from '@/lib/ui';
import OrderChangeDrawer from './OrderChangeDrawer';
import { type TicketType } from '@/api/constant';
import { type TicketOrderChangeRequest, type TicketData } from '@/api/registration';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseButtonIconText from '@/base/Button/IconText';

export interface OrderChangeProps extends UiHStackProps {
  tickets: TicketData[]
  ticketType: TicketType
}

const OrderChange: FC<OrderChangeProps> = ({ tickets, ticketType }) => {
  const { isOpen, onToggle } = useDisclosure();
  const queryClient = useQueryClient();
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<undefined>, Error, TicketOrderChangeRequest>({
    mutationFn: async (request: TicketOrderChangeRequest) => {
      const result = await registration.ticketOrderChange(createTenantAdminApiRequest)(request);
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw result?.errors;
      }
      return result;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      onToggle();
    }
  });

  const onSubmit = useCallback(async (orderIds: Array<string | number>) => {
    await mutateAsync({
      ticketType,
      orderIds,
      eventId
    });
  }, [eventId, mutateAsync, ticketType]);
  return (
    <>
      <BaseButtonIconText Icon={UiIconListNumbers} onClick={onToggle}>Reorder</BaseButtonIconText>
      <OrderChangeDrawer<TicketData>
        items={tickets}
        onSubmit={onSubmit}
        isSubmitting={isLoading}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default OrderChange;
