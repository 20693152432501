import { type FC } from 'react';
import {
  UiStack,
  uiStyles,
  UiText,
  UiBox,
  type UiStackProps,
  UiHStack,
  UiInput
} from '@/lib/ui';

export interface FieldPhoneProps extends UiStackProps {
  label: string
  type: string
  onClick: () => void
  isRequired?: boolean
}

const FieldPhone: FC<FieldPhoneProps> = ({
  label,
  onClick,
  isRequired = false,
  ...props
}) => {
  return (
    <UiStack
      borderRadius={uiStyles.borderRadius}
      spacing={2}
      onClick={onClick}
      {...uiStyles.hover}
      {...props}
    >
      <UiHStack justifyContent={'space-between'}>
        <UiText color={'text.secondary'}>{label}{isRequired ? ' *' : ''}</UiText>
      </UiHStack>
      <UiInput
        bgColor={'indigo.50'}
        placeholder={'+1 (234) 567-890'}
        cursor="auto"
        isReadOnly
        height={'40px'}
        borderRadius={uiStyles.formElementBorderRadius}
        borderWidth={'1px'}
        borderStyle={'solid'}
        borderColor={'gray.400'}
      />
    </UiStack>
  );
};

export default FieldPhone;
