import { type FC, useState } from 'react';
import { Formik, type FormikHelpers, Form } from 'formik';
import { type Options } from 'react-select';
import {
  UiButton,
  type UiHStackProps,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import { TeamState, teamStateOptions } from '@/api/constant/team';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import type { AttendeeCategoryData, AttendeeCategorySaveRequest } from '@/api/registration';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarInfo from '@/base/MessageBar/Info';

export interface ForgotPasswordFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  email: string
}

interface Errors {
  email?: string
}

const initFormData = {
  email: '',
};

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required.'),
});

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.AdminUserSendPasswordResetEmailResponse, Error, account.AdminUserSendPasswordResetEmailRequest>({
    mutationFn: async (data: account.AdminUserSendPasswordResetEmailRequest) => {
      return account.sendPasswordResetEmail(createGlobalApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors as string[]);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to send the password reset email.']);
    }
  });

  const submitForm = async (values: FormData) => {
    void mutate({
      email: values.email,
    });
  };

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Forgot password?'}
        size={'lg'}
        isLoading={isLoading}
        buttonText={'Send'}
        buttonLoadingText={'Sending...'}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'email'}
            label={'Email'}
          />
          <UiStack>
            <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
              Once you've clicked the "Send" button, you will receive an email in a few minutes. Please check your spam or junk folder if it has not arrived in your inbox within a few minutes.
            </BaseMessageBarInfo>
          </UiStack>
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default ForgotPasswordForm;
