import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface PaymentProps extends UiHStackProps {
}

const Payment: FC<PaymentProps> = () => {
  return (
    <UiVStack
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      // alignItems={'flex-start'}
      // justifyContent={'flex-start'}
      minWidth={'300px'}
      // bgColor={'gray.50'}
      // p={8}
      bgColor={'#fff'}
      // divider={(<BaseDividerHorizontal/>)}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiStack flexGrow={1}>
        <UiText variant={'title'}>Payment</UiText>
        <UiText>
          During the checkout, the attendee will be asked to make the payment.
        </UiText>
      </UiStack>
      <BaseDividerHorizontal height={2} />
      <UiHStack flexGrow={1}>
        <UiText variant={'body1'} color={'text.secondary'}>
          Step required
        </UiText>
      </UiHStack>
    </UiVStack>
  );
};

export default Payment;
