import { type FC } from 'react';
import {
  Coins
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCoinsProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCoins: FC<UiIconCoinsProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Coins}
      {...props}
    />
  );
};
