/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode, useRef } from 'react';
import {
  UiHStack, UiIconArrowRight,
  UiIconQuestion,
  UiStack, uiStyles,
  UiText
} from '@/lib/ui';
import {
  useDisclosure
} from '@chakra-ui/react';
import InstructionDrawer from '@/registration/component/BuildEvent/StepInstruction/InstructionDrawer';

export interface StepInstructionProps {
  label: string
  children: ReactNode
}

const StepInstruction: FC<StepInstructionProps> = ({
  label,
  children
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack>
        <UiHStack
          p={6}
          py={4}
          borderRadius={uiStyles.borderRadius}
          bgColor={'#fff'}
          {...uiStyles.hover}
          onClick={onToggle}
          spacing={4}
          justifyContent={'space-between'}
        >
          <UiHStack>
            <UiIconQuestion color={'primary.500'} size={'2xl'} />
            <UiText>{label}</UiText>
          </UiHStack>
          <UiIconArrowRight color={'primary.500'} size={'2xl'} />
        </UiHStack>
      </UiStack>
      <InstructionDrawer isOpen={isOpen} onClose={onToggle}>
        {children}
      </InstructionDrawer>
    </>
  );
};

export default StepInstruction;
