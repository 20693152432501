import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppEventWebsiteCard from '@/base/App/EventWebsiteCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface ContentDeliveryPlatformProps extends Omit<ModuleCardProps, 'children'> {
}

const ContentDeliveryPlatform: FC<ContentDeliveryPlatformProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppEventWebsiteCard />
    </ModuleCard>
  );
};

export default ContentDeliveryPlatform;
