/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import { type FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { account, registration } from '@/api';
import { paymentOptionDefaultQueryKey } from '@/api/admin/paymentOption';
import { type CreatePaymentRequest, type PaymentOption, type paymentOptionType } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormSecretField from '@/base/Form/SecretField';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerOverlay,
  UiHStack,
  type UiHStackProps,
  UiIconArrowSquareOut,
  UiLink,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface OnlinePaymentFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  paymentOption?: PaymentOption
  tenant?: account.TenantData
}

interface FormData {
  paymentType: paymentOptionType
  isEnabled?: boolean
  default?: boolean
  stripeSecretKey?: string
  stripePublicKey?: string
  apiKey?: string
  password?: string
  securePayMerchantCode?: string
  securePayClientId?: string
  securePayClientSecret?: string
  nabTransactMerchantId?: string
  nabTransactPassword?: string
}

const formSchema = Yup.object().shape({
  paymentType: Yup.string().required('Payment Gateway is required.'),
  isEnabled: Yup.boolean().required('This field is required'),
  stripeSecretKey: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'stripe' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  stripePublicKey: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'stripe' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  endpointSecret: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'stripe' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  apiKey: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'eWay' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  password: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'eWay' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  securePayMerchantCode: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'secure_pay' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  securePayClientId: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'secure_pay' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  securePayClientSecret: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'secure_pay' && isEnabled; },
    then: (schema) => { return schema.required('This key is required'); }
  }),
  nabTransactMerchantId: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'nab_transact' && isEnabled; },
    then: (schema) => { return schema.required('This field is required'); }
  }),
  nabTransactPassword: Yup.string().when(['paymentType', 'isEnabled'], {
    is: (paymentType: string, isEnabled: boolean) => { return paymentType === 'nab_transact' && isEnabled; },
    then: (schema) => { return schema.required('This field is required'); }
  }),
  // default: Yup.boolean().required('This field is required')
});

const isEnabledOptions: Option[] = [
  { value: true, label: 'Enable' },
  { value: false, label: 'Disable' }
];

const isDefaultOptions: Option[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const OnlinePaymentForm: FC<OnlinePaymentFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  paymentOption,
  tenant,
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { eventId, tenantCode } = useRegisterRoute();

  const { mutateAsync: testConnection, isLoading: testConnectionLoading } = useMutation<ApiResponse<{}>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.testConnection(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const { mutateAsync, isLoading } = useMutation<ApiResponse<PaymentOption>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.addPaymentOptions(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [paymentOptionDefaultQueryKey] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const onTestConnection = useCallback(async (values: FormData) => {
    const { paymentType, isEnabled, default: defaultPayment, ...config } = values;
    await testConnection({
      paymentOptionType: paymentType,
      config,
      eventId
    });
  }, [testConnection]);

  const submitForm = useCallback(async (values: FormData) => {
    const { paymentType, isEnabled, default: defaultPayment, ...config } = values;
    void mutateAsync({
      id: paymentOption?.id,
      paymentOptionType: paymentType,
      isEnabled,
      config,
      eventId,
      tenantCode,
      default: defaultPayment
    });
  }, [mutateAsync, paymentOption?.id]);

  return (
    <Formik
      initialValues={{
        paymentType: paymentOption?.name ?? 'stripe',
        isEnabled: paymentOption?.enabled,
        stripeSecretKey: paymentOption?.config?.stripeSecretKey ?? '',
        stripePublicKey: paymentOption?.config?.stripePublicKey ?? '',
        endpointSecret: paymentOption?.config?.endpointSecret ?? '',
        apiKey: paymentOption?.config?.apiKey ?? '',
        password: paymentOption?.config?.password ?? '',
        securePayMerchantCode: paymentOption?.config?.securePayMerchantCode ?? '',
        securePayClientId: paymentOption?.config?.securePayClientId ?? '',
        securePayClientSecret: paymentOption?.config?.securePayClientSecret ?? '',
        nabTransactMerchantId: paymentOption?.config?.nabTransactMerchantId ?? '',
        nabTransactPassword: paymentOption?.config?.nabTransactPassword ?? '',
        default: true,
        tenantCode
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      {({ values, validateForm }) => {
        return (
          <UiDrawer placement={'right'} size={'xl'} isOpen={isVisible} onClose={onClose}>
            <Form style={{ display: 'flex' }}>
              <UiDrawerOverlay />
              <UiDrawerContent>
                <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
                <UiDrawerBody p={0}>
                  <UiStack alignItems={'stretch'} p={8}>
                    <UiText variant={'h6'} pb={8}>Set up your payment gateway</UiText>
                    {saveErrors.length > 0 && (
                      <UiStack spacing={4} flexGrow={1} py={4}>
                        {saveErrors.map((error, index) => {
                          return (
                            <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                          );
                        })}
                      </UiStack>
                    )}
                    <BaseFormFieldGroup>
                      <BaseFormSelectField
                        name={'paymentType'}
                        label={'Payment gateway'}
                        layout="stack"
                        options={[
                          { value: 'stripe', label: 'Stripe' },
                          { value: 'secure_pay', label: 'Secure Pay' },
                          { value: 'eWay', label: 'eWay' },
                          { value: 'nab_transact', label: 'NAB Transact' }
                        ]}
                      />
                      {
                        values.paymentType === 'stripe' && (
                          <>
                            <UiLink href={'https://support.stripe.com/questions/locate-api-keys-in-the-dashboard'} target={'_blank'} {...uiStyles.hover}>
                              <UiHStack>
                                <UiText color={'primary.500'}>
                                Find keys in your stripe account dashboard.
                                </UiText>
                                <UiIconArrowSquareOut color={'primary.500'} />
                              </UiHStack>
                            </UiLink>
                            <BaseFormSelectField
                              name={'isEnabled'}
                              label={'Enable this method?'}
                              options={isEnabledOptions}
                              layout="stack"
                            />
                            <BaseFormSecretField
                              name="stripeSecretKey"
                              label="Live mode secret key (server-side key)"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="stripePublicKey"
                              label="Live mode publishable key (client-side key)"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="endpointSecret"
                              label="Webhook secret"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                          </>
                        )
                      }
                      {
                        values.paymentType === 'eWay' && (
                          <>
                            <UiLink href={'https://eway.io/api-v3/#authentication'} target={'_blank'} {...uiStyles.hover}>
                              <UiHStack>
                                <UiText color={'primary.500'}>
                                Find keys in your eWay account dashboard.
                                </UiText>
                                <UiIconArrowSquareOut color={'primary.500'} />
                              </UiHStack>
                            </UiLink>
                            <BaseFormSelectField
                              name={'isEnabled'}
                              label={'Enable this method?'}
                              options={isEnabledOptions}
                              layout="stack"
                            />
                            <BaseFormSecretField
                              name="apiKey"
                              label="Rapid api key"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="password"
                              label="Rapid password"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                          </>
                        )
                      }
                      {
                        values.paymentType === 'secure_pay' && (
                          <>
                            <UiLink href={'https://auspost.com.au/payments/dashboard/?context=pay'} target={'_blank'} {...uiStyles.hover}>
                              <UiHStack>
                                <UiText color={'primary.500'}>
                                Find keys in your secure pay account dashboard.
                                </UiText>
                                <UiIconArrowSquareOut color={'primary.500'} />
                              </UiHStack>
                            </UiLink>
                            <BaseFormSelectField
                              name={'isEnabled'}
                              label={'Enable this method?'}
                              options={isEnabledOptions}
                              layout="stack"
                            />
                            <BaseFormSecretField
                              name="securePayMerchantCode"
                              label="Secure pay merchant code"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="securePayClientId"
                              label="Secure pay client id"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="securePayClientSecret"
                              label="Secure pay client secret"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                          </>
                        )
                      }
                      {
                        values.paymentType === 'nab_transact' && (
                          <>
                            <UiLink href={'https://nabgateway-developer.nab.com.au/'} target={'_blank'} {...uiStyles.hover}>
                              <UiHStack>
                                <UiText color={'primary.500'}>
                                  Find keys in your NAB Transact account dashboard.
                                </UiText>
                                <UiIconArrowSquareOut color={'primary.500'} />
                              </UiHStack>
                            </UiLink>
                            <BaseFormSelectField
                              name={'isEnabled'}
                              label={'Enable this method?'}
                              options={isEnabledOptions}
                              layout="stack"
                            />
                            <BaseFormSecretField
                              name="nabTransactMerchantId"
                              label="NAB Transact merchant ID"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                            <BaseFormSecretField
                              name="nabTransactPassword"
                              label="NAB Transact password"
                              layout="stack"
                              tenantId={tenant?.id}
                            />
                          </>
                        )
                      }
                      {/*<BaseFormSelectField*/}
                      {/*  name={'default'}*/}
                      {/*  label={'Set as default'}*/}
                      {/*  helperText={'If set as default, this payment method will be pre-set for any new events.'}*/}
                      {/*  options={isDefaultOptions}*/}
                      {/*  isRequired={false}*/}
                      {/*  layout="stack"*/}
                      {/*/>*/}
                    </BaseFormFieldGroup>
                  </UiStack>
                </UiDrawerBody>
                <UiDrawerFooter p={0}>
                  <UiHStack p={8} justifyContent={'space-between'} flexGrow={1}>
                    {
                      values.paymentType === 'stripe' ? (
                        <UiButton
                          px={8}
                          size={'lg'}
                          variant={'ghost'}
                          colorScheme={'primary'}
                          isLoading={testConnectionLoading}
                          onClick={async () => {
                            const errors = await validateForm();
                            if (Object.keys(errors).length === 0) {
                              void onTestConnection(values);
                            }
                          }}
                        >
                          Test Connection
                        </UiButton>
                      ) : (<UiText />)
                    }
                    <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                      Save
                    </UiButton>
                  </UiHStack>
                </UiDrawerFooter>
              </UiDrawerContent>
            </Form>
          </UiDrawer>
        );
      }}
    </Formik>
  );
};

export default OnlinePaymentForm;
