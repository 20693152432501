import { type FC, useCallback, useMemo } from 'react';
import { Field, type FieldProps } from 'formik';
import { type OptionBase, Select, type OnChangeValue } from 'chakra-react-select';
import {
  UiFormControl,
  UiFormLabel,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
  uiStyles
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';
// import { SelectProps } from '@chakra-ui/react';

export interface Option extends OptionBase {
  value: string | number | boolean
  label: string
}
export interface TimezoneOption extends Option {
  value: string | number | boolean
  label: string
  offset: string
}

export interface SelectFieldProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  defaultValue?: Option
  // defaultInputValue?: Option['value'];
  isMultiple?: boolean
  maximumOptionsSelectable?: number
  options: Option[]
  helperText?: string
  isRequired?: boolean
  isDisabled?: boolean
}

const SelectField: FC<SelectFieldProps> = ({
  label,
  name,
  options = [],
  defaultValue = undefined,
  isMultiple = false,
  maximumOptionsSelectable = undefined,
  helperText = undefined,
  isRequired = true,
  layout = 'inline',
  isDisabled = false
}) => {
  // const onChange = (option: OnChangeValue<Option, true>) => {
  //   form.setFieldValue(
  //     field.name,
  //     (option).map((item: Option) => item.value)
  //   );
  // };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1}>
            <FieldContainer
              layout={layout}
            >
              { isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'} spacing={0}>
                {isMultiple ? (
                  <MultipleSelect
                    form={form}
                    field={field}
                    name={name}
                    options={options}
                    defaultValue={defaultValue}
                    isDisabled={isDisabled}
                    maximumOptionsSelectable={maximumOptionsSelectable}
                  />
                ) : (
                  <SingleSelect
                    form={form}
                    field={field}
                    name={name}
                    options={options}
                    defaultValue={defaultValue}
                    isDisabled={isDisabled}
                  />
                )}
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export interface MultipleSelectProps extends Omit<SelectFieldProps, 'label' | 'layout'> {
  form: FieldProps['form']
  field: FieldProps['field']
}

const MultipleSelect: FC<MultipleSelectProps> = ({
  form,
  field,
  options,
  maximumOptionsSelectable,
  ...props
}) => {
  const onChange = useCallback(
    (option: OnChangeValue<Option, true>) => {
      void form.setFieldValue(
        field.name,
        (option).map((item: Option) => { return item.value; })
      );
    },
    [form, field]
  );

  const value = useMemo(() => { return options.filter((option) => { return (field.value as number[]).includes(option.value as number); }); }
    , [field.value, options]);

  return (
    <Select
      // isMulti
      // name={name}
      value={value}
      options={options}
      size={'md'}
      focusBorderColor={'primary.500'}
      closeMenuOnSelect={false}
      onChange={onChange}
      chakraStyles={{
        container: (provided) => { return { ...provided, borderColor: 'gray.300', bgColor: '#fff', borderRadius: uiStyles.formElementBorderRadius }; },
        control: (provided) => { return { ...provided, borderRadius: uiStyles.formElementBorderRadius }; },
        menuList: (provided) => { return { ...provided, borderColor: 'gray.600', borderWidth: '1px', borderStyle: 'solid' }; },
        dropdownIndicator: (provided) => { return { ...provided, ...uiStyles.hover }; }
      }}
      {...props}
      isMulti={true}
      isDisabled={props.isDisabled}
      isOptionDisabled={() => {
        const max = maximumOptionsSelectable ?? 0;
        return max > 0 && value.length >= max;
      }}
    />
  );
};

export interface SingleSelectProps extends Omit<SelectFieldProps, 'label' | 'layout'> {
  form: FieldProps['form']
  field: FieldProps['field']
}

const SingleSelect: FC<SingleSelectProps> = ({
  form,
  field,
  ...props
}) => {
  const onChange = useCallback(
    (option: OnChangeValue<Option, false>) => {
      if (option === undefined || option === null || option?.value === '') {
        void form.setFieldValue(field.name, undefined);
      } else {
        void form.setFieldValue(field.name, option.value);
      }
    },
    [form, field]
  );

  const value = useMemo(() => {
    return field.value === null || field.value === undefined
      ? undefined
      : props.options.find((i) => { return i.value === field.value; });
  }
  , [field.value, props.options]);
  return (
    <Select
      size={'md'}
      focusBorderColor={'primary.500'}
      closeMenuOnSelect={true}
      onChange={onChange}
      value={value}
      chakraStyles={{
        container: (provided) => { return { ...provided, borderColor: 'gray.300', bgColor: '#fff', borderRadius: uiStyles.formElementBorderRadius }; },
        control: (provided) => { return { ...provided, borderRadius: uiStyles.formElementBorderRadius }; },
        menuList: (provided) => { return { ...provided, borderColor: 'gray.500', borderWidth: '1px', borderStyle: 'solid', borderRadius: uiStyles.formElementBorderRadius }; },
        dropdownIndicator: (provided) => { return { ...provided, ...uiStyles.hover }; }
      }}
      {...props}
      isMulti={false}
      isDisabled={props.isDisabled}
    />
  );
};

export default SelectField;
