import { getAppConfig } from '@/app/config';
// eslint-disable-next-line @typescript-eslint/no-var-requires

export function isError(errors: Array<string | Error> | undefined | null) {
  return Array.isArray(errors) && errors.length > 0;
}

export function generateRegisterAbsoluteUrl(relativePath: string): string {
  const appConfig = getAppConfig();
  return appConfig.registerHost + '/' + relativePath.replace(/^\/|\/$/g, '');
}

export function dynamicColor(colors: string[]): string {
  if (colors.length > 0) {
    const randomIndex = Math.floor(Math.random() * colors.length);

    return colors[randomIndex];
  } else {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    return `rgb(${r},${g},${b})`;
  }
}

export function capitalize(string: string): string {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateLabelFields = (label: string) => {
  if (!label) {
    return 'Label is required';
  } else if (label.includes('.')) {
    return 'Label should not contain a period (.) character';
  } else if (label.length > 200) {
    return 'Label can not be more than 200 characters.';
  }
  return null;
};
