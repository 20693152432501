
import { type FC, useEffect } from 'react';
import {
  UiHStack, UiIconEye, UiIconHourglassHigh,
  UiIconPencilSimple, UiIconPlusCircle, UiIconTimer,
  UiSimpleGrid, UiSpinner,
  UiStack,
  uiStyles, UiTag,
  UiText,
} from '@/lib/ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import { useDisclosure } from '@chakra-ui/react';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import dayjs from 'dayjs';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import {
  SubscriptionState,
} from '@/api/constant/subscription';
import PlanInformation from '@/account/component/Subscription/PlanInformation';
import EditSubscriptionDrawer from '@/aaa/manage/TenantInfo/EditSubscriptionDrawer';
import {
  isServicePackEnabled,
} from '@/account/subscription/utils';
import BaseButtonIconText from '@/base/Button/IconText';
import ServiceHoursForm from './ServiceHoursForm';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface CurrentSubscriptionProps {
  tenantId: number
}

const CurrentSubscription: FC<CurrentSubscriptionProps> = ({
  tenantId,
}) => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isServiceOpen, onToggle: onServiceToggle } = useDisclosure();
  const { reportToGlobal } = useApiErrorHandler();

  const {data, error, isLoading} = useQuery<account.GetTenantCurrentSubscriptionResponse, Error>(
    [account.tenantCurrentSubscriptionQueryKey, { tenantId: tenantId }],
    async () => {
      return await account.getTenantCurrentSubscription(createGlobalApiAclRequest())({
        tenantId,
      });
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (!data?.subscription || !data?.subscription.id) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load the current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <>
      <UiStack spacing={4} px={8}>
        <UiStack spacing={4}>
          <UiHStack spacing={6}>
            <UiText variant={'title'}>Current subscription plan</UiText>
            {data.subscription && data.subscription.model && data.subscription.state !== SubscriptionState.Active && (
              <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add plan</BaseButtonIconText>
            )}
            {data.subscription && data.subscription.model && isServicePackEnabled(data.subscription.model) && (
              <BaseButtonIconText Icon={UiIconTimer} onClick={onServiceToggle}>Update service hours</BaseButtonIconText>
            )}
          </UiHStack>
          {!!data.subscription.model && (
            <PlanInformation subscription={data.subscription.model}/>
          )}
        </UiStack>
      </UiStack>
      {!!data.subscription.model && (
        <EditSubscriptionDrawer
          isOpen={isOpen}
          onClose={onToggle}
          subscription={data.subscription.model}
          tenantId={tenantId}
        />
      )}
      {!!data.subscription.model && (
        <ServiceHoursForm
          tenantId={tenantId}
          isOpen={isServiceOpen}
          onClose={onServiceToggle}
          subscription={data.subscription.model}
          onSaveSuccess={() => {}}
        />
      )}
    </>
  );
};

export default CurrentSubscription;
