import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  // UiBox,
  // UiHStack,
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
  // UiGrid,
} from '@/lib/ui';
import CodeForm from './CodeForm';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewCodeProps extends UiHStackProps {}

const NewCode: FC<NewCodeProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add discount code</BaseButtonIconText>
      <CodeForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewCode;
