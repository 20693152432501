import { type FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { generatePageUrl } from '@/app/pages';
import { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import PlanEditor from '@/account/component/Subscription/PlanEditor';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import PlanSummary from './PlanSummary';
import { SubscriptionEditor, useSubscriptionPlanEditor } from '@/account/hook/useSubscriptionPlanEditor';
import { UiStack, UiText } from '@/lib/ui';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { account } from '@/api';
import {
  SubscriptionState,
} from '@/api/constant/subscription';
import dayjs from 'dayjs';

export interface PlanProps {
  tenant: account.TenantData
  subscription: account.Subscription
}

const Plan: FC<PlanProps> = ({
  tenant,
  subscription,
}) => {
  const subscriptionEditor = useSubscriptionPlanEditor(subscription);
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Subscription' },
        { label: 'Plan', url: generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant?.code ?? '' }) },
        { label: 'Manage your plan' }
      ];
    },
    [tenant?.code]
  );

  if (subscriptionEditor.subscription) {
    // console.log('00000000000rrender again', subscriptionEditor);
    return (
      <>
        <BaseLayout
          smartWindow={(<SmartWindow />)}
          // disableTopNav={true}
          appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'} />)}
          MainTransition={null}
        >
          <BreadcrumbBody
            breadcrumbNodes={breadcrumbNodes}
          >
            <PlanEditor subscriptionEditor={subscriptionEditor} />
            <UiStack height={{ base: '300px', lg: '192px' }} />
          </BreadcrumbBody>
        </BaseLayout>
        <PlanSummary subscription={subscriptionEditor.subscription} />
      </>
    );
  }
};

export default Plan;
