import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiHStack,
  UiStack,
  UiText
} from '@/lib/ui';
import { useState, type FC } from 'react';
// import Filter from './Filter';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import SmartWindow from '@/base/Layout/SmartWindow';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import EventList from './EventList';
import BaseSearchBar from '@/base/SearchBar';

const Event: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const [searchKey, setSearchKey] = useState<string>('');
  const [name, setName] = useState('');
  const searchName = () => {
    setSearchKey(name);
  };

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['manage', 'events']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack spacing={4} justifyContent={'flex-start'}>
            {/* <NewEventRow/> */}
            <UiText variant={'title'}>Events</UiText>
            {/* Do not forget to remove the search bar once we enable pagination ofr the events. */}
            <UiHStack flexGrow={1}>
              <BaseSearchBar
                placeHolder={'Search name'}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                enableOnClear={false}
                onSearch={searchName}
                flexGrow={1}
              />
            </UiHStack>
            <EventList queryParams={{}} searchKey={searchKey} />
            {/* <RecentEvents queryParams={{tenantCode,}}/> */}
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
      {/* <Filter
        isOpen={showFilter}
        onClose={() => {setShowFilter(false)}}
      /> */}
    </>
  );
};

export default Event;
