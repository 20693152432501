import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiText,
  UiHStack
} from '@/lib/ui';
import CategoryForm from './CategoryForm';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewTypeProps extends UiHStackProps {}

const NewType: FC<NewTypeProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add attendee group</BaseButtonIconText>
      <CategoryForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewType;
