import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from './PageRow';
import ManageTemplatesPageForm from './ManageTemplatesForm';

export interface ManageTemplatesProps extends UiHStackProps {
}

const ManageTemplatesPage: FC<ManageTemplatesProps> = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Manage finance templates'}
        description={'Manage the ThinReports templates used for generating invoices and receipts.'}
        onEdit={onToggle}
        {...props}
      />
      <ManageTemplatesPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default ManageTemplatesPage;
