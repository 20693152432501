import { type FC, type ReactNode } from 'react';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import AccessDenied from './AccessDenied';
import { AdminUserType } from '@/api/constant/adminUser';

// import Filter from './Filter';

export interface AAAAccessProps {
  children: ReactNode
  accessDenied?: ReactNode
}

const AAAAccess: FC<AAAAccessProps> = ({
  children,
  accessDenied = (<AccessDenied />)
}) => {
  const { adminAuth } = useAdminAuth();
  // Only AAA admin users have access to the AAA pages.
  if (adminAuth.accessToken && adminAuth.user?.id && adminAuth.user.type === AdminUserType.AAA) {
    return children;
  }
  return accessDenied;
};

export default AAAAccess;
