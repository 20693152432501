import {
  UiCenter,
  UiFormControl,
  UiFormErrorMessage,
  UiFormHelperText,
  UiFormLabel,
  UiHStack,
  UiLink,
  uiStyles,
  UiText,
  UiVStack,
  type UiBoxProps
} from '@/lib/ui';
import { Field, type FieldProps } from 'formik';
import { useCallback, useState, type FC } from 'react';
import { useDropzone } from 'react-dropzone';

export interface UploaderProps extends UiBoxProps {
  label: string
  name: string
  helperText?: string
  defaultTemplateTitle?: string
  defaultTemplateLink?: string | null
  isRequired?: boolean
  isMultiple?: boolean
  acceptFiles?: any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  defaultValue?: any
}

const Uploader: FC<UploaderProps> = ({
  label,
  name,
  isMultiple = false,
  helperText = undefined,
  defaultTemplateTitle = undefined,
  defaultTemplateLink = undefined,
  isRequired = true,
  acceptFiles = { },
  setFieldValue,
  defaultValue
}) => {
  const [files, setFile] = useState<File[] | undefined>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles);

    if (!isMultiple) {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.onloadend = () => {
        setFieldValue(name, reader.result);
        setFieldValue(`${name}OriginalName`, file.name);
      };
      reader.readAsDataURL(file);
    } else {
      setFieldValue(name, acceptedFiles);
    }
  }, [isMultiple, name, setFieldValue]);
  const { getRootProps, getInputProps } = useDropzone({ accept: acceptFiles, onDrop, multiple: isMultiple });

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1}>
            {isRequired ? (
              <UiHStack alignItems={'flex-start'} spacing={0}>
                <UiFormLabel>{label}</UiFormLabel>
                <UiText color={'gray.600'} variant='title'>*</UiText>
              </UiHStack>
            ) : (
              <UiFormLabel>{label}</UiFormLabel>
            )}
            <UiVStack alignItems={'stretch'}>
              <UiCenter
                {...getRootProps()}
                p={4}
                border='2px'
                _focus={{ borderColor: 'blue.500' }}
                borderRadius={uiStyles.formElementBorderRadius}
                borderColor='gray.300'
                borderStyle="dashed"
                bgColor="white">
                <input {...getInputProps()} />
                <UiText color="gray">Drag &apos;n&apos; drop some files here, or click to select files</UiText>
              </UiCenter>
              <UiVStack>
                Files: { files?.map((file: File, index: number) => { return <UiText key={index}>{file.name}</UiText>; }) }
                {defaultValue && files?.length === 0 && (
                  <UiLink href={defaultValue} color={'blue'}>
                    {defaultValue.split('/').at(-1)}
                  </UiLink>
                )}
              </UiVStack>
              {!!helperText && (
                <UiFormHelperText>
                  {helperText}
                </UiFormHelperText>
              )}
              {/* Add download template link */}
              {defaultTemplateLink && (
                <UiLink href={defaultTemplateLink}
                  color={'primary.500'}
                  target="_blank"
                  style={{ fontSize: '14px' }}
                  rel="noopener noreferrer"
                  media="print"
                  download>
                  {defaultTemplateTitle ?? `Download default ${label}`}
                </UiLink>
              )}
              {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
            </UiVStack>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default Uploader;
