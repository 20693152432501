import { useCallback, type FC } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiButton,
  UiTag,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import AttendeeCategoriesForm from '@/registration/buildEvent/AdditionalInfoForm/AttendeeCategoriesForm';
import { useDisclosure } from '@chakra-ui/react';
import { InfoFormType, type InfoForm, type InfoFormDeleteRequest } from '@/api/registration';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import DeletionConfirmation from '@/registration/buildEvent/DiscountCodes/DeletionConfirmation';
import BaseAclContainer from '@/account/component/AclContainer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
// import { text } from '@/util';

export interface FormCardProps extends UiHStackProps {
  infoForm: InfoForm
}

const FormCard: FC<FormCardProps> = ({ infoForm }) => {
  const { tenantCode, eventId, tenant } = useRegisterRoute();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate: deleteMutate, isLoading: isDeleteMutationLoading } = useMutation<ApiResponseSingle<{}>, Error, InfoFormDeleteRequest>({
    mutationFn: async (params: InfoFormDeleteRequest) => {
      return await registration.deleteInfoForm(createTenantAdminApiRequest)(params);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.ADDITIONAL }] });
      // NOTE: handle error
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponseSingle<{}>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneInfoForm(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: () => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    deleteMutate({ id: infoForm.id });
  }, [infoForm.id, deleteMutate]);

  const onConfirm = useCallback(() => {
    mutateClone(infoForm.id);
  }, [infoForm, mutateClone]);

  return (
    <>
      <UiStack>
        <UiHStack
          flexGrow={1}
          // p={8}
          p={6}
          borderRadius={uiStyles.borderRadius}
          bgColor={'#fff'}
          spacing={6}
          alignItems={'stretch'}
          shadow={uiStyles.cardShadow}
        >
          <UiHStack
            spacing={0}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            flexGrow={1}
          >
            <UiStack
              p={6}
              borderLeftRadius={uiStyles.borderRadius}
              borderWidth={'1px'}
              borderColor={'gray.100'}
              borderStyle={'solid'}
              alignItems={'stretch'}
            >
              <UiText variant={'body2'} color={'text.secondary'}>Fields</UiText>
              <UiText variant={'title'}>{infoForm.config.totalFields}</UiText>
            </UiStack>
            <UiHStack
              flexGrow={1}
              alignItems={'stretch'}
              spacing={4}
              p={6}
              // px={6}
              borderRightRadius={uiStyles.borderRadius}
              borderWidth={'1px'}
              borderColor={'gray.100'}
              borderStyle={'solid'}
              borderLeftWidth={0}
            >
              <UiStack flexGrow={1}>
                <UiText variant={'body2'} color={'text.secondary'}>Attendee groups</UiText>
                <UiHStack flexWrap={'wrap'}>
                  {infoForm.attendeeCategories.map(attendeeCategory => (
                    <UiTag key={attendeeCategory.id} p={4} py={2}>{attendeeCategory.name}</UiTag>
                  ))}
                </UiHStack>
              </UiStack>
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <UiStack alignItems={'stretch'}>
                  <BaseButtonPlainIconEdit onClick={onToggle}/>
                </UiStack>
              </BaseAclContainer>
            </UiHStack>
          </UiHStack>
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiStack
              alignItems={'stretch'}
              spacing={4}
            >
              <BaseRouterLink
                to={generatePageUrl('RegistrationBuildEventBuildAdditionalInfoForm', { tenantCode, eventId }, { id: infoForm.id })}
              >
                <BaseButtonPlainIconEdit onClick={undefined}/>
              </BaseRouterLink>
              <BaseButtonPlainIconClone onClick={onToggleConfirmClone}/>
              <BaseButtonPlainIconDelete onClick={onToggleConfirmDeletion} isLoading={isDeleteMutationLoading}/>
            </UiStack>
          </BaseAclContainer>
        </UiHStack>
        <AttendeeCategoriesForm
          onSaveSuccess={() => {}}
          isVisible={isOpen}
          onClose={onToggle}
          infoForm={infoForm}
        />
      </UiStack>
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this additional information form?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'primary'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={"this additional information form"} />
    </>
  );
};

export default FormCard;
