import { type UseToastOptions, useToast } from '@chakra-ui/react';

interface useToastProps extends UseToastOptions {
  margin?: string,
}

export const useGlobalToast = () => {
  const toast = useToast();

  const showToastOption = (options: useToastProps) => {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      containerStyle: {
        margin: options.margin || '70px 30px auto auto'
      },
      ...options,
    });
  };

  const createShowToast = (status: 'success' | 'error' | 'warning' | 'info') => {
    return (message: string, options?: useToastProps) => {
      showToastOption({
        status,
        description: message,
        ...options,
      });
    };
  };

  const showToast = {
    success: createShowToast('success'),
    error: createShowToast('error'),
    warning: createShowToast('warning'),
    info: createShowToast('info'),
  };

  return { showToast };
};
