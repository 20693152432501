import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  UiText,
  UiStack,
} from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import SharedStockForm from './SharedStockForm';
import { type TicketType } from '@/api/constant/ticket';

export interface NewSharedStockProps extends UiHStackProps {
  ticketType: TicketType
}

const NewSharedStock: FC<NewSharedStockProps> = ({ ticketType }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack spacing={2}>
        <BaseButtonIconText
          Icon={UiIconPlusCircle}
          onClick={onToggle}
        >
          Create shared capacity
        </BaseButtonIconText>
        <UiText px={1} variant={'body2'} color={'text.secondary'}>If you have tickets to share the capacity, you can create a shared capacity and then choose it when adding/editing the tickets.</UiText>
      </UiStack>
      <SharedStockForm
        isVisible={isOpen}
        onClose={onToggle}
        onSaveSuccess={() => {}}
        ticketType={ticketType}
      />
    </>
  );
};

export default NewSharedStock;
