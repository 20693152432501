import { type FC } from 'react';
import {
  EnvelopeSimple
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconEnvelopeSimpleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconEnvelopeSimple: FC<UiIconEnvelopeSimpleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={EnvelopeSimple}
      {...props}
    />
  );
};
