import { type FC, type ReactElement } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';

export interface StandardProps extends UiHStackProps {
  icon: ReactElement
  text: ReactElement
  button?: ReactElement
}

export const Standard: FC<StandardProps> = ({
  icon,
  text,
  textColor = 'text.primary',
  button = undefined,
  ...props
}) => {
  return (
    <UiHStack
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      justifyContent={'space-between'}
      p={8}
      py={4}
      {...props}
    >
      <UiHStack>
        {icon}
        {text}
      </UiHStack>
      <UiHStack justifyContent={'flex-end'}>
        {!!button && button}
      </UiHStack>
    </UiHStack>
  );
};

export default Standard;
