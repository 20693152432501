import { type FC, type PropsWithChildren, createContext, useContext } from 'react';
import { type AppConfig, getAppConfig } from '@/app/config';

export interface ProviderConfigProps extends PropsWithChildren {
  config: AppConfig
}

export interface ConfigContextType extends AppConfig {
};

const ConfigContext = createContext<ConfigContextType>(getAppConfig());

export const ProviderConfig: FC<ProviderConfigProps> = ({
  config,
  ...props
}) => {
  return (
    <ConfigContext.Provider value={config} {...props}/>
  );
};

export function useConfig () {
  const context = useContext(ConfigContext) as Required<ConfigContextType>;

  return {
    ...context
  };
}
