import {
  type ApiRequest,
  type ApiResponse,
  properCallApi
} from '@/api/tenantClient';

export const registrationBadgeQueryKey = 'registration.registrationBadge';

export interface RegistrationBadgeData {
  id: number
  title: string
}

export function getRegistrationBadges(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string): Promise<ApiResponse<RegistrationBadgeData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/delegate_types/registration_badges';
    request.endpoint.query = { eventId };
    return await properCallApi<ApiResponse<RegistrationBadgeData>>(request);
  };
}
