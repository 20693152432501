/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode, useRef } from 'react';
import {
  type UiAlertDialogProps,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerCloseButton,
  UiDrawerFooter,
  UiDrawerBody
} from '@/lib/ui';

export interface InstructionDrawerProps {
  isOpen: UiAlertDialogProps['isOpen']
  onClose: UiAlertDialogProps['onClose']
  children: ReactNode
}

const InstructionDrawer: FC<InstructionDrawerProps> = ({
  isOpen,
  onClose,
  children
}) => {
  const cancelRef = useRef(null);
  return (
    <UiDrawer placement={'right'} size={'lg'} isOpen={isOpen} onClose={onClose}>
      <UiDrawerOverlay />
      <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
      <UiDrawerContent>
        <UiDrawerBody py={16}>
          <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
          {children}
        </UiDrawerBody>
        {/* <UiDrawerFooter> */}
        {/* </UiDrawerFooter> */}
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default InstructionDrawer;
