import { type FC, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton, UiHStack, UiIconEye, UiIconEyeSlash, uiStyles, UiText, UiIconPassword, UiIconEnvelopeSimple, UiBox,
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';
import { ApiError } from '@/api/error';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type AdminAuth, useAdminAuth } from '@/app/ProviderAdminAuth';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { resetPassword } from '@/api/account';
import { useDisclosure } from '@chakra-ui/react';

export interface ResetPasswordFormProps extends UiHStackProps {
  passwordResetToken: string
  email: string
  onResetDone: () => void
}

interface FormData {
  password: string
}

interface Errors {
  password?: string
}

const initFormData = {
  password: '',
};

const formSchema = Yup.object().shape({
  password: Yup.string().required('Password is empty.'),
});

// const inputWidth = '420px';

/**
 * @todo Incomplete
 */
const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  passwordResetToken,
  email,
  onResetDone,
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { setAccessToken } = useAdminAuth();
  const { isOpen: isPasswordVisible, onToggle: onTogglePasswordVisibility } = useDisclosure();

  const { mutate, isLoading } = useMutation<account.AdminUserResetPasswordResponse, Error, account.AdminUserResetPasswordRequest>({
    mutationFn: async (data: account.AdminUserResetPasswordRequest) => {
      return await account.resetPassword(createGlobalApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        setAccessToken(result.accessToken);
        onResetDone();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to reset the password.']);
    }
  });

  const submitForm = async (values: FormData) => {
    void mutate({
      passwordResetToken,
      password: values.password,
    });
  };

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <Form
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <UiStack alignItems={'stretch'} spacing={4} flexGrow={1}>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} pb={2}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <UiStack
            pt={200}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={4}
          >
            <UiStack
              minW={600}
              maxW={800}
              bgColor={'#fff'}
              borderRadius={uiStyles.borderRadius}
              spacing={8}
              py={8}
              alignItems={'stretch'}
            >
              <UiStack px={8} spacing={0}>
                <UiHStack>
                  <UiText variant={'title'}>Reset the password</UiText>
                </UiHStack>
                <BaseDividerHorizontal height={8}/>
                <UiHStack
                  px={8}
                  py={6}
                  bgColor={'blackAlpha.50'}
                  borderRadius={uiStyles.borderRadius}
                >
                  <UiIconEnvelopeSimple />
                  <UiText>{email}</UiText>
                </UiHStack>
              </UiStack>
              <UiStack px={8}>
                <BaseFormFieldGroup>
                  <UiHStack spacing={4} alignItems={'center'}>
                    <BaseFormInputField
                      name="password"
                      label="New password"
                      layout="stack"
                      type={isPasswordVisible ? 'text' : 'password'}
                      rightElement={(
                        <UiBox {...uiStyles.hover} onClick={onTogglePasswordVisibility}>
                          {isPasswordVisible ? (
                            <UiIconEye color={'primary.500'} />
                          ) : (
                            <UiIconEyeSlash color={'primary.500'} />
                          )}
                        </UiBox>
                      )}
                    />
                  </UiHStack>
                </BaseFormFieldGroup>
              </UiStack>
              <UiStack px={8}>
                <UiHStack justifyContent={'flex-end'}>
                  {isLoading
                    ? (
                      <UiButton px={8} size={'lg'} colorScheme={'green'}>
                        Saving...
                      </UiButton>
                    ) : (
                      <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'}>
                        Save
                      </UiButton>
                    )}
                </UiHStack>
              </UiStack>
            </UiStack>
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
