import { type FC } from 'react';
import {
  Flag
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconFlagProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconFlag: FC<UiIconFlagProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Flag}
      {...props}
    />
  );
};
