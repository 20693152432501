export enum AppEnv {
  Production = 'prod',
  Development = 'dev',
}

export interface AppConfig {
  env: AppEnv
  versionTag: string
  host: string
  registerHost: string
  api: {
    tenantHost: string
    globalHost: string
  }
}

const appConfig: AppConfig = {
  env: process.env.REACT_APP_ENV as AppEnv,
  versionTag: process.env.REACT_APP_VERSION_TAG!,
  host: process.env.REACT_APP_HOST!,
  registerHost: process.env.REACT_APP_REGISTER_HOST!,
  api: {
    tenantHost: process.env.REACT_APP_API_TENANT_HOST!,
    globalHost: process.env.REACT_APP_API_HOST!,
  },
};

export const getAppConfig: () => AppConfig = () => {
  return appConfig;
};

export function isEnv(env: AppEnv): boolean {
  return appConfig.env === env;
}
