import { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { type FieldMetadataDescriptionBlock, type FieldMetadataInput } from './Field';
import BaseFormSelectField from '@/base/Form/SelectField';
import BaseFormTextareaField from '@/base/Form/TextareaField';

export interface FieldDescriptionBlockFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataDescriptionBlock) => void
  fieldMetadata?: FieldMetadataDescriptionBlock
}

interface FieldAttributes {
  // label: string
  // isRequired: boolean
  // placeholder?: string
  // helpText?: string
  description: string
}

interface Errors {
  // label?: string
  // length?: string
  // helpText?: string
  // isRequired?: string
  description?: string
}

const FieldDescriptionBlockForm: FC<FieldDescriptionBlockFormProps> = ({
  fieldMetadata = undefined,
  onSave
}) => {
  const initValues: FieldAttributes = {
    description: fieldMetadata?.description ?? '',
  };

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        if (!values.description) {
          errors.description = 'The description is required';
        } else if (values.description.length > 500) {
          errors.description = 'The description can not be more than 500 charactors.';
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<FieldAttributes>
      ) => {
        if (fieldMetadata) {
          onSave({
            ...fieldMetadata,
            ...{
              type: 'descriptionBlock',
              label: 'Description block',
              description: values.description,
              canDelete: true,
              canEdit: true
            }
          });
        } else {
          onSave({
            id: uuidv4(),
            label: 'Description block',
            type: 'descriptionBlock',
            description: values.description,
            canDelete: true,
            canEdit: true
          });
        }
      }}
    >
      <Form
        style={{
          display: 'flex',
          marginTop: 0
        }}
      >
        <UiStack flexGrow={1}>
          <BaseFormFieldGroup>
            <BaseFormTextareaField
              name={'description'}
              label={'Description'}
              height={'200px'}
              placeholder={''}
              maxLength={500}
              helperText={'Put some thing you want to show as a description or instruction here. Limit 500 characters.'}
            />
          </BaseFormFieldGroup>
          <UiStack flexDirection={'row-reverse'} pt={12}>
            <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
              Save
            </UiButton>
          </UiStack>
        </UiStack>
      </Form>
    </Formik>
  );
};

export default FieldDescriptionBlockForm;
