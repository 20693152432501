import { type FC, useCallback, useState } from 'react';
import { UiButton, UiHStack, UiIconCalculator, UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import { getAddonsEnabled } from '@/account/subscription/utils';
import { subscriptionPackageLabels, SubscriptionState } from '@/api/constant/subscription';
import ReviewDrawer from '@/account/subscription/PlanManagement/ReviewDrawer';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import Status from '@/account/subscription/Plan/Status';
import dayjs from 'dayjs';
import UpdateSubscriptionMessage from '@/account/subscription/Plan/UpdateSubscriptionMessage';
import { tenantCurrentSubscriptionQueryKey } from '@/api/account';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';

export interface PriceSummaryProps {
  subscription: account.Subscription
}

const PlanSummary: FC<PriceSummaryProps> = ({
  subscription
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { tenant } = useTenantRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();
  const navigate = useNavigate();

  const toPlan = useCallback(() => {
    navigate(generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);

  const { mutate, isLoading } = useMutation<account.SubscriptionSaveResponse, Error, account.SubscriptionSaveRequest>({
    mutationFn: async (data: account.SubscriptionSaveRequest) => {
      return account.saveSubscription(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        // onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({queryKey: [account.subscriptionQueryKey]});
        void queryClient.invalidateQueries({queryKey: [account.subscriptionNextTermQueryKey]});
        void queryClient.invalidateQueries({queryKey: [account.tenantCurrentSubscriptionQueryKey]});
        toPlan(); // This is required to 'reset' the 'subscriptionEditor' to update the plan editor.
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to save the subscription.']);
    }
  });

  const onSave = useCallback(
    () => {
      mutate({
        tenantId: tenant?.id ?? 0,
        subscription,
      });
    },
    [subscription]
  );

  return (
    <>
      <UiStack
        position={'fixed'}
        bottom={0}
        left={0}
        // height={'120px'}
        bgColor={'#fff'}
        zIndex={99}
        alignItems={'stretch'}
        width={'100%'}
        borderTopColor={'gray.300'}
        borderTopWidth={'1px'}
        borderTopStyle={'solid'}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} px={8} pt={8}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        {subscription.plan.state === SubscriptionState.Active && (
          <UiStack p={8} pb={0}>
            <UpdateSubscriptionMessage/>
          </UiStack>
        )}
        <UiSimpleGrid columns={{ base: 2, lg: 4 }} gap={8} p={8} py={6}>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Package
            </UiText>
            <UiHStack spacing={4}>
              <UiText variant={'title'}>
                {subscriptionPackageLabels[subscription.plan.package]}
                {/* <PriceDisplay price={subscriptionPrice.package}/> (12 months) */}
              </UiText>
              <Status subscriptionState={subscription.plan.state}/>
            </UiHStack>
          </UiStack>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Add-ons
            </UiText>
            <UiText variant={'title'}>
              {getAddonsEnabled(subscription).length ?? 0}
              {/* <PriceDisplay price={subscriptionPrice.addon} /> (12 months) */}
            </UiText>
          </UiStack>
          <UiStack spacing={1}>
            <UiText color={'text.secondary'}>
              Total price
            </UiText>
            <UiHStack
              justifyContent={'flex-start'}
              alignItems={'center'}
              onClick={onToggle}
              {...uiStyles.hover}
            >
              <UiIconCalculator color={'primary.500'} size={'2xl'} weight={'bold'} />
              <UiText
                size={'md'}
                color={'primary.500'}
                variant={'title'}
              >
                Calculate
              </UiText>
            </UiHStack>
          </UiStack>
          {subscription.plan.state === SubscriptionState.Pending && (
            <UiStack spacing={1} alignItems={'flex-end'} justifyContent={'flex-end'}>
              <UiStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                {isLoading ? (
                  <UiButton size={'md'} colorScheme={'green'} py={2}>
                    Enabling...
                  </UiButton>
                ) : (
                  <UiButton size={'md'} colorScheme={'primary'} py={2} onClick={onSave}>
                    Enable the plan
                  </UiButton>
                )}
              </UiStack>
            </UiStack>
          )}
          {subscription.plan.state === SubscriptionState.Active && (
            <UiStack spacing={1} alignItems={'flex-end'} justifyContent={'flex-end'}>
              <UiStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                {isLoading ? (
                  <UiButton size={'md'} colorScheme={'green'} py={2}>
                    Updating...
                  </UiButton>
                ) : (
                  <UiButton size={'md'} colorScheme={'primary'} py={2} onClick={onSave}>
                    Update this plan
                  </UiButton>
                )}
              </UiStack>
            </UiStack>
          )}
        </UiSimpleGrid>
      </UiStack>
      <ReviewDrawer
        subscription={subscription}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default PlanSummary;
