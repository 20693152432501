import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { loadRegistrationsForTable, registrationTableQueryKey, type RegistrationTableRow } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { UiSpinner, uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect, useState } from 'react';
import Datatable from '../Registration/Datatable';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface SerializedRegistrationTableRow extends Omit<RegistrationTableRow, 'createdAt' | 'confirmDelegateTypeAt'> {
  createdAt: Date
  confirmDelegateTypeAt: Date | null
}

interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
}

const useGetRegistrations = ({ filters, sorting, pagination, search }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  return useQuery<ApiResponse<RegistrationTableRow>, Error>({
    queryKey: [registrationTableQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<RegistrationTableRow>> => {
      return await loadRegistrationsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        type: 'all',
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: !isTenantLoading,
  });
};

const RegistrationTable = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, isError, error, isFetching, refetch } = useGetRegistrations({ filters, sorting, pagination, search, setPagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <Datatable
        data={data.items.map((registration) => {
          return {
            ...registration,
            paid: registration.paid ? 'Yes' : 'No',
            complete: registration.complete ? 'Yes' : 'No',
            createdAt: new Date(registration.createdAt),
            confirmDelegateTypeAt: registration.confirmDelegateTypeAt === null ? null : new Date(registration.confirmDelegateTypeAt)
          };
        })}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={data.pagination!.total}
        delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
        booleanSelectOptions={data.selectOptions!.booleanSelectOptions}
        statusSelectOptions={data.selectOptions!.statusSelectOptions}
        tourTicketsSelectOptions={data.selectOptions!.tourTicketsSelectOptions}
        workshopTicketsSelectOptions={data.selectOptions!.workshopTicketsSelectOptions}
        functionTicketsSelectOptions={data.selectOptions!.functionTicketsSelectOptions}
        attendeeCategorySelectOptions={data.selectOptions!.attendeeCategorySelectOptions}
        paymentSelectOptions={data.selectOptions!.paymentOptions}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No registrations
      </BaseMessageBarInfo>
    );
  }
};

export default RegistrationTable;
