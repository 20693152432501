import { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import {
  UiButton,
  type UiHStackProps, UiStack
} from '@/lib/ui';
import { formBuilderSelectOptionTypeOptions, FormBuilderSelectOptionType } from '@/api/constant/formBuilder';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormNumberField from '@/base/Form/NumberField';
import BaseFormSelectField from '@/base/Form/SelectField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import { type FieldMetadataSelect } from './Field';
import { validateLabelFields } from '@/lib/util';

export interface FieldSelectFormProps extends UiHStackProps {
  onSave: (fieldMetadata: FieldMetadataSelect) => void
  fieldMetadata?: FieldMetadataSelect
}

export interface FieldAttributes {
  label: string
  optionType: FormBuilderSelectOptionType
  options: string
  isMultiple: boolean
  maximumOptionsSelectable?: number
  helpText?: string
  isRequired: boolean
}

interface Errors {
  label?: string
  optionType?: string
  options?: string
  isMultiple?: boolean
  maximumOptionsSelectable?: number
  helpText?: string
  isRequired?: boolean
}

const optionTypeHelpTexts = {
  [FormBuilderSelectOptionType.Custom]: 'Create your own options.',
  [FormBuilderSelectOptionType.Country]: 'A built-in full list of all the countries. No need to create your own.'
};

const FieldSelectForm: FC<FieldSelectFormProps> = ({
  onSave,
  fieldMetadata = undefined
}) => {
  const initValues: FieldAttributes = {
    label: fieldMetadata?.label ?? '',
    optionType: fieldMetadata?.optionType ?? FormBuilderSelectOptionType.Custom,
    isMultiple: fieldMetadata?.isMultiple ?? false,
    maximumOptionsSelectable: fieldMetadata?.maximumOptionsSelectable ?? 0,
    options: fieldMetadata?.options ?? '',
    helpText: fieldMetadata?.helpText ?? '',
    isRequired: fieldMetadata?.isRequired ?? true
  };

  return (
    <Formik
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: FieldAttributes): Errors => {
        const errors: Errors = {};
        const labelError = validateLabelFields(values.label);
        if (labelError) {
          errors.label = labelError;
        }
        if (values.optionType === FormBuilderSelectOptionType.Custom) {
          if (!values.options) {
            errors.options = 'Options can not be empty.';
          } else {
            const optionsArray = values.options.split('\n').map(opt => { return opt.trim(); });
            const uniqueOptions = new Set(optionsArray);
            if (uniqueOptions.size !== optionsArray.length) {
              errors.options = 'Each option must be unique.';
            }
          }
        }
        return errors;
      }}
      onSubmit={async (
        values: FieldAttributes
        // { setSubmitting }: FormikHelpers<Values>
      ) => {
        // setSubmitting(false);
        onSave({
          ...fieldMetadata,
          id: fieldMetadata?.id ?? uuidv4(),
          type: 'select',
          label: values.label,
          options: values.options,
          optionType: values.optionType,
          isMultiple: values.isMultiple,
          maximumOptionsSelectable: values.maximumOptionsSelectable,
          helpText: values.helpText,
          isRequired: values.isRequired,
          canEdit: true,
          canDelete: true
        });
      }}
    >
      {({ values }) => {
        return (
          <Form
            style={{
              display: 'flex',
              marginTop: 0
            }}
          >
            <UiStack flexGrow={1}>
              <BaseFormFieldGroup>
                <BaseFormInputField
                  name={'label'}
                  label={'Field label'}
                />
                <BaseFormSelectField
                  name={'optionType'}
                  label={'Option type'}
                  defaultValue={formBuilderSelectOptionTypeOptions[0]}
                  options={formBuilderSelectOptionTypeOptions}
                  helperText={optionTypeHelpTexts[values.optionType] ?? ''}
                />
                {values.optionType === FormBuilderSelectOptionType.Custom && (
                  <BaseFormTextareaField
                    name={'options'}
                    label={'Options'}
                    height={'200px'}
                    placeholder={'Sample option 1\nSample option 2'}
                    helperText={'Add each option on a new line.'}
                  />
                )}
                <BaseFormSelectField
                  name={'isMultiple'}
                  label={'Is this a multiple select?'}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                />
                {
                  values.isMultiple && (
                    <BaseFormNumberField
                      name={'maximumOptionsSelectable'}
                      min={0}
                      label={'Maximum number of options selectable'}
                      isRequired={false}
                      helperText={'This is the maximum number of options a user can select. If answer is 0 or blank, the user can select all available options.'}
                    />
                  )}
                <BaseFormInputField
                  name={'helpText'}
                  label={'Help text'}
                  isRequired={false}
                  helperText={'This the text you want to show underneath the input field to provide some context or examples.'}
                />
                <BaseFormSelectField
                  name={'isRequired'}
                  label={'Is this field mandatory?'}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                />
              </BaseFormFieldGroup>
              <UiStack flexDirection={'row-reverse'} pt={12}>
                <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'}>
                  Save
                </UiButton>
              </UiStack>
            </UiStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FieldSelectForm;
