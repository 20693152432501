import { type FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiDivider,
  UiHStack,
  UiStack,
  UiText,
  uiStyles,
  UiVStack,
  UiIconDotsNine
} from '@/lib/ui';
import { RouteParams } from '@/app/pages';
// import Filter from './Filter';
import SmartWindow from '@/base/Layout/SmartWindow';
import SideNav from '@/account/component/Layout/SideNav';
import SwitchApp from '@/account/component/Layout/SwitchApp';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import ManagePassword from './ManagePassword';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

const AccountSettings: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<SideNav activeNodes={['setup', 'accountSettings']} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          {/*<UiText variant={'h6'}>My account</UiText>*/}
          {/*<UiStack height={4}/>*/}
          {/*<BaseDividerHorizontal height={4}/>*/}
          <ManagePassword/>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default AccountSettings;
