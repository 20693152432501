import { type FC } from 'react';
import {
  UiIconInfo
} from '@/lib/ui';
import Base, { type BaseProps } from './Base';

export type MessageBarInfoProps = Omit<BaseProps, 'icon'>;

export const MessageBarInfo: FC<MessageBarInfoProps> = (props) => {
  return (
    <Base
      textProps={{
        variant: 'body2'
      }}
      icon={<UiIconInfo size={'2xl'} color={'text.primary'}/>}
      bgColor={'yellow.50'}
      {...props}
    />
  );
};

export default MessageBarInfo;
