import { forwardRef, useCallback, useState } from 'react';
import type { ForwardedRef, KeyboardEvent, SyntheticEvent } from 'react';
import { Wrap, WrapItem, type WrapItemProps, type WrapProps, type InputProps, type TagProps, type TagLabelProps, type TagCloseButtonProps } from '@chakra-ui/react';
import { tagCall, type TagFunc } from './tagFunc';
import * as Yup from 'yup';

import {
  UiBox,
  UiContainer,
  UiHStack,
  UiIconEye,
  UiIconEyeSlash, UiIconPencilSimple,
  UiIconPlus,
  UiIconUsersFour,
  UiInput, UiInputGroup, UiInputRightElement,
  uiStyles,
} from '@/lib/ui';
import ChakraTagInputTag from './ChakraTagInputTag';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import BaseButtonGhost from '@/base/Button/Ghost';

type MaybeIsInputProps<P> = TagFunc<[isInput: boolean, index?: number], P>;
type MaybeTagProps<P> = TagFunc<[tag: string, index?: number], P>;

export type ChakraTagInputProps = InputProps & {
  tags: string[]
  errors: string[]
  setErrors: (errors: string[]) => void
  onTagsChange?: (event: SyntheticEvent, tags: string[]) => void
  onTagAdd?: (event: SyntheticEvent, value: string) => void
  onTagRemove?: (event: SyntheticEvent, index: number) => void
  children?: React.ReactNode

  vertical?: boolean
  addKeys?: string[]

  wrapProps?: WrapProps
  wrapItemProps?: MaybeIsInputProps<WrapItemProps>
  tagProps?: MaybeTagProps<TagProps>
  tagLabelProps?: MaybeTagProps<TagLabelProps>
  tagCloseButtonProps?: MaybeTagProps<TagCloseButtonProps>
};

const emailSchema = Yup.string()
  .email('Invalid email.')
  .required('Email is required.');

const validateEmail: (name: string) => Promise<{ isValid: boolean, error?: string }> = async (email: string) => {
  try {
    await emailSchema.validate(email);
    return {
      isValid: true,
    };
  } catch (error: any) {
    return {
      isValid: false,
      error: error?.message ?? 'Invalid email',
    };
  }
};

/**
 * @deprecated Do not use this component since the design has flaws.
 */
export default forwardRef(function ChakraTagInput({
  tags = [],
  errors = [],
  setErrors,
  onTagsChange,
  onTagAdd,
  onTagRemove,
  vertical = false,
  addKeys = ['Enter'],
  wrapProps,
  wrapItemProps,
  tagProps,
  tagLabelProps,
  tagCloseButtonProps,
  ...props
}: ChakraTagInputProps, ref: ForwardedRef<HTMLInputElement>) {
  const [currentTag, setCurrentTag] = useState('');
  const addTag = useCallback(
    async (event: SyntheticEvent, tag: string) => {
      onTagAdd?.(event, tag);
      if (event.isDefaultPrevented()) {
        return;
      }
      const emailValidation = await validateEmail(tag);
      if (emailValidation?.error) {
        setErrors([...errors, emailValidation?.error]);
        return;
      }
      setErrors([]);
      onTagsChange?.(event, tags.concat([tag]));
    },
    [tags, onTagsChange, onTagAdd]
  );
  const removeTag = useCallback(
    (event: SyntheticEvent, index: number) => {
      onTagRemove?.(event, index);
      if (event.isDefaultPrevented()) return;

      onTagsChange?.(event, [...tags.slice(0, index), ...tags.slice(index + 1)]);
    },
    [tags, onTagsChange, onTagRemove]
  );
  const handleRemoveTag = useCallback(
    (index: number) => {
      return (event: SyntheticEvent) => {
        removeTag(event, index);
      };
    },
    [removeTag]
  );
  const onKeyDown = props.onKeyDown;
  const handleKeyDown = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event);

      if (event.isDefaultPrevented()) return;
      if (event.isPropagationStopped()) return;

      const { currentTarget, key } = event;
      const { selectionStart, selectionEnd } = currentTarget;
      if (addKeys.includes(key) && currentTarget.value) {
        await addTag(event, currentTarget.value);
        if (!event.isDefaultPrevented()) {
          currentTarget.value = '';
        }
        event.preventDefault();
      } else if (key === 'Backspace' && tags.length > 0 && selectionStart === 0 && selectionEnd === 0) {
        removeTag(event, tags.length - 1);
      }
    },
    [addKeys, tags.length, addTag, removeTag, onKeyDown]
  );

  return (
    <UiContainer width='100%' p={0}>
      <Wrap>
        {tags.map((tag, index) => {
          return (
            <WrapItem {...tagCall(wrapItemProps, false, index)} key={index}>
              <ChakraTagInputTag
                onRemove={handleRemoveTag(index)}
                tagLabelProps={tagCall(tagLabelProps, tag, index)}
                tagCloseButtonProps={tagCall(tagCloseButtonProps, tag, index)}
                colorScheme={props.colorScheme}
                size={props.size}
                {...tagCall(tagProps, tag, index)}
              >
                {tag}
              </ChakraTagInputTag>
            </WrapItem>
          );
        })}
      </Wrap>
      <UiContainer width="100%" flexGrow={1} mt={5} p={0}>
        <UiHStack spacing={4}>
          <UiInput
            {...props}
            onKeyDown={handleKeyDown}
            ref={ref}
            value={currentTag}
            onChange={(event) => {
              setCurrentTag(event.target.value);
            }}
          />
          <BaseButtonGhost
            onClick={async (event) => {
              await addTag(event, currentTag);
              setCurrentTag('');
            }}
          >
            Add
          </BaseButtonGhost>
          {/*<BaseButtonPlainIcon*/}
          {/*  label={'Add'}*/}
          {/*  Icon={UiIconPlus}*/}
          {/*  color={'primary.500'}*/}
          {/*  onClick={async (event) => {*/}
          {/*    await addTag(event, currentTag);*/}
          {/*    setCurrentTag('');*/}
          {/*  }}*/}
          {/*/>*/}
        </UiHStack>
      </UiContainer>
    </UiContainer>
  );
});
