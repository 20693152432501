import { type FC, useCallback, useMemo, useState } from 'react';
import { Formik, type FormikHelpers, Form } from 'formik';
import { type Options } from 'react-select';
import {
  UiButton,
  type UiHStackProps,
  UiStack,
  UiText,
  uiStyles
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { generatePageUrl } from '@/app/pages';
import { TeamState, teamStateOptions } from '@/api/constant/team';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import type { AttendeeCategoryData, AttendeeCategorySaveRequest } from '@/api/registration';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface TeamFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  team?: account.TeamData
}

interface FormData {
  state: number
  name: string
  description: string
}

interface Errors {
  name?: string
  description?: string
  members?: string
}

const teamOptions: Option[] = teamStateOptions;
const initFormData = {
  state: TeamState.Active,
  name: '',
  description: '',
};

const formSchema = Yup.object().shape({
  state: Yup.string()
    .required('State is required.'),
  name: Yup.string()
    .required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 charactors.')
    .nullable(),
});

const TeamForm: FC<TeamFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  team = undefined,
}) => {
  const { tenant } = useTenantRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.SaveTeamResponse, Error, account.SaveTeamRequest>({
    mutationFn: async (data: account.SaveTeamRequest) => {
      return await account.saveTeam(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [account.teamSearchQueryKey, {}], exact: false });
        if (team?.id) {
          void queryClient.invalidateQueries({ queryKey: [account.teamQueryKey, {id: team.id}] });
        }
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to create the team.']);
    }
  });

  const submitForm = useCallback(
    async (values: FormData) => {
      if (tenant?.id) {
        void mutate({
          tenantId: tenant?.id,
          team: {
            id: team?.id ?? undefined,
            state: values.state,
            name: values.name,
            description: values.description ?? '',
          },
        });
      }
    },
    [tenant?.id]
  );

  const _initFormData = useMemo(
    () => {
      if (team && team.id) {
        return {
          ...initFormData,
          name: team.name,
          description: team.description ?? '',
          state: team.state,
        };
      }
      return initFormData;
    },
    [team]
  );

  return (
    <Formik
      initialValues={_initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Create new team'}
        size={'lg'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'state'}
            label={'State'}
            options={teamOptions}
            // helper={(
            //   <BaseButtonRouterLink
            //     to={generatePageUrl('AccountAccessAdminUser')}
            //     description={'You will be redirected to members page'}
            //   >
            //     Manage members
            //   </BaseButtonRouterLink>
            // )}
            // isRequired={false}
          />
          <BaseFormInputField
            name={'name'}
            label={'Name'}
          />
          <BaseFormInputField
            name={'description'}
            label={'Description'}
            isRequired={false}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default TeamForm;
