import { type FC } from 'react';
import {
  Circle
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCircleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCircle: FC<UiIconCircleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Circle}
      {...props}
    />
  );
};
