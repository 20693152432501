import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { UiSpinner, uiStyles, UiTable, UiTableContainer, UiTbody, UiTd, UiText, UiTh, UiThead, UiTr } from '@/lib/ui';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { type IInsightRow, insightQueryKey, loadInsightForTable } from '@/api/registration/insight';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const thStyles = {
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6,
};

export interface SerializedInsightTableRow extends IInsightRow { }

interface Params {
  eventId: number
  attendeeCategoryIds: number[]
}

const useGetInsightTable = ({ eventId, attendeeCategoryIds }: Params) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();

  return useQuery<ApiResponseSingle<IInsightRow>, Error>({
    queryKey: [insightQueryKey, {
      eventId,
      attendeeCategoryIds
    }],
    queryFn: async (): Promise<ApiResponseSingle<IInsightRow>> => {
      const response = await loadInsightForTable(createTenantAdminApiRequest)({
        eventId,
        attendeeCategoryIds
      });
      return response;
    },
    enabled: !isTenantLoading && !!eventId && Boolean(attendeeCategoryIds.length),
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const InsightTable = ({ eventId, categoryIds }: { eventId: number, categoryIds: number[] }) => {
  const { reportToGlobal } = useApiErrorHandler();

  const { data, isLoading, error, isError, isFetching, refetch } = useGetInsightTable({ eventId, attendeeCategoryIds: categoryIds });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }

  if (data.item && Array(data.item)) {
    return (
      <UiTableContainer shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius}>
        <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
          <UiThead>
            <UiTr>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textAlign={'center'} textTransform="none" >Attendee group</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textAlign={'center'} textTransform="none" >Total registrations</UiText></UiTh>
              <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'} textAlign={'center'} textTransform="none" >Total sales value</UiText></UiTh>
            </UiTr>
          </UiThead>
          <UiTbody>
            {
              [data?.item ?? {}].map(({ name, count, salesValue }, index) => {
                return <UiTr key={index}>
                  <UiTd {...tdStyles} textAlign={'center'} whiteSpace="normal" wordBreak="break-word">{name}</UiTd>
                  <UiTd {...tdStyles} textAlign={'center'}>{count}</UiTd>
                  <UiTd {...tdStyles} textAlign={'center'}>{salesValue}</UiTd>
                </UiTr>;
              })
            }
          </UiTbody>
        </UiTable>
      </UiTableContainer>
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No reports
      </BaseMessageBarInfo>
    );
  }
};

export default InsightTable;
