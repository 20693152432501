import { type FC } from 'react';
import {
  PlusCircle
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconPlusCircleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconPlusCircle: FC<UiIconPlusCircleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={PlusCircle}
      {...props}
    />
  );
};
