import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiHStack,
  type UiHStackProps,
  UiVStack,
  UiStack,
  uiStyles,
  UiText,
  UiSwitch
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface TicketCardProps extends UiHStackProps {}

const TicketCard: FC<TicketCardProps> = () => {
  const { isOpen: isSkipped, onToggle: onSkipToggle } = useDisclosure();

  return (
    <UiVStack
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      // alignItems={'flex-start'}
      // justifyContent={'flex-start'}
      minWidth={'300px'}
      // bgColor={'gray.50'}
      // p={8}
      bgColor={'#fff'}
      // divider={(<BaseDividerHorizontal/>)}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiStack flexGrow={1}>
        <UiText variant={'title'}>Data privacy</UiText>
        <UiText>
          The attendee will see the privacy policy page. The page contents are provided by us.
        </UiText>
      </UiStack>
      <BaseDividerHorizontal height={2} />
      <UiHStack flexGrow={1}>
        {isSkipped
          ? (
            <UiText variant={'title'} color={'text.secondary'}>
              Step skipped
            </UiText>
          ) : (
            <UiText variant={'title'} color={'primary.500'}>
              Step included
            </UiText>
          )}
        <UiSwitch colorScheme={'primary'} onChange={onSkipToggle} isChecked={!isSkipped} size={'md'} />
      </UiHStack>
    </UiVStack>
  );
};

export default TicketCard;
