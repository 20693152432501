import { type FC } from 'react';
import { type ActionMeta, type GroupBase, type OnChangeValue, type OptionBase, Select, type SelectComponent } from 'chakra-react-select';
import {
  UiCheckbox,
  UiFlex,
  uiStyles,
  UiText
} from '@/lib/ui';

export interface Option extends OptionBase {
  value: string | number | boolean
  label: string
}

export interface SelectElementProps extends Partial<SelectComponent> {
  onChange: (newValue: OnChangeValue<Option, boolean>, actionMeta: ActionMeta<Option>) => void
  optionValue: Option['value']
  options: Option[]
  placeholder?: string
  isLoading?: boolean
  onScrollToBottom?: (event: WheelEvent | TouchEvent) => void
  value: Option[]
}

const CustomOption = (props: any) => {
  const { data, isSelected, innerRef, innerProps } = props;

  return (
    <UiFlex
      {...innerProps}
      ref={innerRef}
      px={2}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        innerProps.onClick?.(e);
      }}>
      <UiCheckbox isChecked={isSelected}>
        <UiText fontSize={'medium'}>{data.label}</UiText>
      </UiCheckbox>
    </UiFlex>
  );
};

const SimpleMultiSelectElement: FC<SelectElementProps> = ({
  onChange,
  options,
  placeholder = '',
  onScrollToBottom,
  isLoading = false,
  value,
  ...props
}) => {
  return (
    <Select<Option, true, GroupBase<Option>>
      onChange={onChange}
      options={options}
      size={'md'}
      focusBorderColor={'primary.500'}
      hideSelectedOptions={false}
      onMenuScrollToBottom={onScrollToBottom}
      isLoading={isLoading}
      value={value}
      chakraStyles={{
        container: (provided) => { return { ...provided, borderColor: 'gray.300', bgColor: '#fff', borderRadius: uiStyles.formElementBorderRadius }; },
        control: (provided) => { return { ...provided, borderRadius: uiStyles.formElementBorderRadius, flexWrap: 'nowrap' }; },
        menu: (provided) => { return { ...provided, zIndex: 999999 }; },
        menuList: (provided) => { return { ...provided, borderColor: 'gray.500', borderWidth: '1px', borderStyle: 'solid', borderRadius: uiStyles.formElementBorderRadius }; },
        dropdownIndicator: (provided) => { return { ...provided, ...uiStyles.hover }; }
      }}
      placeholder={placeholder}
      {...props}
      components={{ Option: CustomOption }}
      isMulti={true}
      closeMenuOnSelect={false}
    />
  );
};

export default SimpleMultiSelectElement;
