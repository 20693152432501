import { type FC } from 'react';
import {
  UiStack,
  UiText,
  UiHStack,
  type UiStackProps,
  UiIconPencilSimple,
  uiStyles
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
// import FieldInputForm from './FieldInputForm';

export interface FieldSectionBreakProps extends UiStackProps {
  label: string
  description?: string
}

const FieldSectionBreak: FC<FieldSectionBreakProps> = ({
  label,
  description = undefined,
  ...props
}) => {
  return (
    <UiStack h={'full'} {...uiStyles.hover} {...props}>
      <UiHStack justifyContent={'space-between'}>
        <UiStack spacing={0} flexGrow={1}>
          <UiText variant={'title'} noOfLines={1} textOverflow={'ellipsis'}>
            {label}
          </UiText>
          {!!description && (
            <UiText variant={'body2'} color={'text.secondary'} noOfLines={1} textOverflow={'ellipsis'}>
              {description}
            </UiText>
          )}
        </UiStack>
        {/* <UiIconPencilSimple size={'2xl'} color={'primary.400'}/> */}
      </UiHStack>
      <BaseDividerHorizontal height={2}/>
    </UiStack>
  );
};

export default FieldSectionBreak;
