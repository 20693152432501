import { type FC } from 'react';
import {
  UiText,
  UiSimpleGrid,
  UiIconStackSimple
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface TourTicketsProps {
  isActive?: boolean
}

const TourTickets: FC<TourTicketsProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconStackSimple}
      description={'Tour tickets'}
      isActive={isActive}
      // stepNumber={7}
      navLink={generatePageUrl('RegistrationBuildEventTourTickets', { tenantCode, eventId })}
      isSet={eventSettings?.tourTicket?.isSet}
      // isSkipped={!eventSettings?.tourTicket?.isEnabled}
    >
      <UiSimpleGrid flexGrow={1} columns={2} gap={4}>
        <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.tourTicket.total ?? 0} total</UiText>
      </UiSimpleGrid>
    </Step>
  );
};

export default TourTickets;
