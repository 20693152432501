import { type FC, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDisclosure } from '@chakra-ui/react';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack,
  UiButton, UiHStack, UiIconEye, UiIconEyeSlash, UiBox, uiStyles,
} from '@/lib/ui';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';
import { ApiError } from '@/api/error';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import ForgotPasswordForm from './ForgotPasswordForm';
import BaseMessageBarInfo from '@/base/MessageBar/Info';

export interface LoginEmailFormProps extends UiHStackProps {
  onLoggedIn: (accessToken: string) => void
  email?: string
}

interface FormData {
  email: string
  password: string
}

const formSchema = Yup.object().shape({
  email: Yup.string().required('Email is empty.'),
  password: Yup.string().required('Password is empty.'),
});

const inputWidth = '420px';

const LoginEmailForm: FC<LoginEmailFormProps> = ({
  onLoggedIn,
  email = ''
}) => {
  const [currentEmail, setCurrentEmail] = useState(email);
  const [errors, setErrors] = useState<string[]>([]);
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isPasswordVisible, onToggle: onTogglePasswordVisibility } = useDisclosure();

  const { mutate: loginEmailPassword, isLoading } = useMutation<account.AdminUserLoginEmailPasswordResponse, Error, account.AdminUserLoginEmailPasswordRequest>({
    mutationFn: async (request: account.AdminUserLoginEmailPasswordRequest) => {
      return await account.loginEmailPassword(createGlobalApiRequest)(request);
    },
    onSuccess: (result: account.AdminUserLoginEmailPasswordResponse) => {
      if (result.errors && result.errors.length > 0) {
        setErrors(result.errors);
      } else {
        onLoggedIn(result?.accessToken ?? '');
        setErrors([]);
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors(['Login failed (connection error).']);
    }
  });

  const submitForm = async (values: FormData) => {
    if (values.email) {
      setCurrentEmail(values.email);
      loginEmailPassword({
        email: values.email,
        password: values.password,
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: currentEmail,
          password: '',
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={formSchema}
        onSubmit={async (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
          setSubmitting(true);
          await submitForm(values);
          setSubmitting(false);
        }}
      >
        <Form
          style={{
            display: 'flex'
          }}
        >
          <UiStack alignItems={'flex-start'} spacing={4}>
            {errors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} pb={2}>
                {errors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index} width={inputWidth}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup minW={inputWidth} maxW={inputWidth}>
              <BaseFormInputField
                name="email"
                label="Email"
                layout="stack"
              />
            </BaseFormFieldGroup>
            <BaseFormFieldGroup minW={inputWidth} maxW={inputWidth}>
              <BaseFormInputField
                name="password"
                label="Password"
                type={isPasswordVisible ? 'text' : 'password'}
                layout="stack"
                rightElement={(
                  <UiBox {...uiStyles.hover} onClick={onTogglePasswordVisibility}>
                    {isPasswordVisible ? (
                      <UiIconEye color={'primary.500'}/>
                    ) : (
                      <UiIconEyeSlash color={'primary.500'}/>
                    )}
                  </UiBox>
                )}
              />
            </BaseFormFieldGroup>
            <UiStack py={4} spacing={4}>
              {isLoading
                ? (
                  <UiButton px={8} size={'lg'} colorScheme={'green'} width={inputWidth}>
                    Signing in...
                  </UiButton>
                ) : (
                  <UiButton px={8} size={'lg'} colorScheme={'primary'} type={'submit'} width={inputWidth}>
                    Sign in
                  </UiButton>
                )}
              <UiStack justifyContent={'center'} alignItems={'center'}>
                <UiButton variant={'ghost'} onClick={onToggle}>Forgot password?</UiButton>
              </UiStack>
            </UiStack>
          </UiStack>
        </Form>
      </Formik>
      <ForgotPasswordForm isVisible={isOpen} onClose={onToggle} onSaveSuccess={() => {}}/>
    </>
  );
};

export default LoginEmailForm;
