import { type FC, type ReactNode } from 'react';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import AccessDenied from './AccessDenied';
// import Filter from './Filter';

export interface AdminAccessProps {
  children: ReactNode
  accessDenied?: ReactNode
}

const AdminAccess: FC<AdminAccessProps> = ({
  children,
  accessDenied = (<AccessDenied />)
}) => {
  const { adminAuth } = useAdminAuth();
  if (adminAuth.accessToken && adminAuth.user?.id) {
    return children;
  }
  return accessDenied;
};

export default AdminAccess;
