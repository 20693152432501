import { forwardRef, MenuItem, type MenuItemProps } from '@chakra-ui/react';
import { ElementType } from 'react';

type As = ElementType;

// export type UiMenuItemProps = MenuItemProps;
export interface UiMenuItemProps extends MenuItemProps {}

export const UiMenuItem = forwardRef<UiMenuItemProps, As>((props, ref) => {
  return (
    <MenuItem
      ref={ref}
      transform={'opacity'}
      transitionDuration={'0.4s'}
      transitionTimingFunction={'ease-in-out'}
      opacity={1}
      _hover={{
        opacity: 0.5,
        cursor: 'pointer',
        transform: 'opacity',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease-in-out'
      }}
      {...props}
    />
  );
});
