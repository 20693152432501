export enum FormBuilderSelectOptionType {
  Custom = 'custom',
  Country = 'country',
}

export const formBuilderSelectOptionTypeLabels: Record<FormBuilderSelectOptionType, string> = {
  [FormBuilderSelectOptionType.Custom]: 'Custom',
  [FormBuilderSelectOptionType.Country]: 'Country'
};

export const formBuilderSelectOptionTypeOptions: Array<{ value: string, label: string }> = [
  { value: FormBuilderSelectOptionType.Custom, label: formBuilderSelectOptionTypeLabels[FormBuilderSelectOptionType.Custom] },
  { value: FormBuilderSelectOptionType.Country, label: formBuilderSelectOptionTypeLabels[FormBuilderSelectOptionType.Country] }
];
