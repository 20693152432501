import { useCallback, useEffect, useState } from 'react';

// Pintura Image Editor
import '@pqina/pintura/pintura.css';
import { type PinturaImageState, openDefaultEditor, type ImageSource } from '@pqina/pintura';
import { UiBox, UiImage, UiCenter, UiButtonIcon } from '@/lib/ui';
import { SelectionPlus } from '@phosphor-icons/react';

export type FileOrUrl = File | string;

interface ImageSelectItemProps {
  file: FileOrUrl
  onUpdate: (file: File) => void
};

function ImageSelectItem({ file, onUpdate }: ImageSelectItemProps) {
  const [preview, setPreview] = useState<string>();
  const [pintura, setPinture] = useState<{ file: ImageSource, state: PinturaImageState }>();

  useEffect(() => {
    if (!preview && typeof file === 'string') setPreview(file); // is URL
    if (!preview && file instanceof File)setPreview(URL.createObjectURL(file)); // is File
    return () => { preview && URL.revokeObjectURL(preview); };
  }, [file, preview]);

  // This function is called when the user taps the edit button.
  // It opens the editor and returns the modified file when done
  const editImage = useCallback((image: FileOrUrl, done: (dest: File) => void) => {
    const imageFile = pintura?.file ?? image;
    const imageState = pintura?.state ?? {};

    const editor = openDefaultEditor({
      src: imageFile,
      imageState
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    editor.on('process', ({ dest, imageState }: { dest: File, imageState: PinturaImageState }) => {
      setPreview(URL.createObjectURL(dest));
      setPinture({ file: imageFile, state: imageState });
      done(dest);
    });
  }, [pintura?.file, pintura?.state]);

  return (
    <UiBox maxH={24} minW={12} position="relative">
      {preview && <UiImage h={24} src={preview} />}
      <UiCenter
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgColor="#ffffff80"
        opacity={0}
        _hover={{ opacity: 1 }}>
        <UiButtonIcon
          colorScheme='blue'
          aria-label='Edit'
          pointerEvents="initial"
          icon={<SelectionPlus size={32} />}
          onClick={(e) => {
            e.stopPropagation();
            editImage(file, onUpdate);
          }}
        />
      </UiCenter>
    </UiBox>
  );
}

export default ImageSelectItem;
