import { useCallback, useEffect, useState } from 'react';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import {
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps,
  UiStack,
  UiText,
  uiStyles,
  UiDrawerFooter,
  UiButton
} from '@/lib/ui';
import ReorderList from '@/base/ReorderList';
import BaseMessageBarError from '@/base/MessageBar/Error';

interface ItemInterface {
  id: string | number
  name: string
  description?: string
}

export interface OrderChangeDrawerProps<T extends ItemInterface> extends Omit<UiDrawerProps, 'children'> {
  items: T[]
  onSubmit: (orderIds: Array<string | number>) => Promise<any>
  isSubmitting: boolean
}

function OrderChangeDrawer<T extends ItemInterface>({
  items: defaultItems,
  onSubmit,
  isSubmitting,
  ...props
}: OrderChangeDrawerProps<T>) {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [items, setItems] = useState<T[]>(defaultItems);
  useEffect(() => {
    setItems(defaultItems); // update item list after add new ticket
  }, [defaultItems]);

  const onSave = useCallback(async () => {
    try {
      await onSubmit(items.map(item => item.id));
    } catch (error) {
      if (Array.isArray(error)) {
        setSaveErrors((error as string[]));
      } else if ((error as Error)?.message) {
        setSaveErrors([(error as Error)?.message ?? 'something went wrong']);
      }
    }
  }, [items, onSubmit]);

  return (
    <UiDrawer placement={'right'} size={'xl'} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} fontWeight={'bold'} color={'primary.500'} />
        <UiDrawerBody py={16} px={8} alignItems={'stretch'} bgColor={'gray.100'}>
          <UiStack flexGrow={1} spacing={1}>
            <UiText variant={'h6'}>Tickets Order</UiText>
            <UiText variant={'body1'}>This changes the order of the tickets attendees will see.</UiText>
          </UiStack>
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error) => (
                <BaseMessageBarError key={error}>
                  {error}
                </BaseMessageBarError>
              ))}
            </UiStack>
          )}
          <BaseDividerHorizontal height={8} />
          <UiStack pt={1}>
            <ReorderList<T>
              data={items}
              onChange={setItems}
            >
              {({ item }) => (
                <UiStack bgColor={'#fff'} p={2} spacing={0} borderRadius={uiStyles.borderRadius}>
                  <UiText>{item.name}</UiText>
                  {!!item.description && (
                    <UiText variant={'body2'} color={'text.secondary'}>{item.description}</UiText>
                  )}
                </UiStack>
              )}
            </ReorderList>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            {isSubmitting ? (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'gray'}>
                Saving...
              </UiButton>
            ) : (
              <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} onClick={onSave}>
                Save
              </UiButton>
            )}
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default OrderChangeDrawer;
