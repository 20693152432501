import {
  forwardRef,
  Button,
  type ButtonProps,
} from '@chakra-ui/react';
import { uiStyles } from '@/lib/ui/styles';
import { ElementType, useMemo } from 'react';

export type UiButtonProps = ButtonProps;
type As = ElementType;

export const UiButton = forwardRef<ButtonProps, As>((props, ref) => {
  const defaultProps: Partial<ButtonProps> = useMemo(
    () => {
      if (props.variant == 'ghost') {
        return {
          px: 0,
          py: 0,
          borderRadius: 0,
          minW: 'auto',
          height: 'auto',
        };
      }
      if (props.variant == 'unstyled') {
        return {
          px: 0,
          borderRadius: 0,
        };
      }
      return {
        px: 6,
        borderRadius: uiStyles.buttonRadius,
      };
    },
    [props.variant]
  );

  return (
    <Button
      // display={{ base: 'none', md: 'inline-flex' }}
      display={'inline-flex'}
      fontSize={'md'}
      fontWeight={400}
      // px={px}
      // py={py}
      // borderRadius={uiStyles.buttonRadius}
      colorScheme={'primary'}
      {...defaultProps}
      ref={ref}
      {...uiStyles.hover}
      {...props}
    />
  );
});
