import { type FC, useMemo } from 'react';
import { type UiBoxProps, UiHStack, UiTag, UiText } from '@/lib/ui';
import { SubscriptionState, subscriptionStateLabels } from '@/api/constant/subscription';

export interface StatusProps extends UiBoxProps {
  subscriptionState: SubscriptionState;
}

const Status: FC<StatusProps> = ({
  subscriptionState,
}) => {
  const color = useMemo(
    () => {
      if (subscriptionState === SubscriptionState.Active) {
        return 'green';
      }
      if (subscriptionState === SubscriptionState.Pending) {
        return 'yellow';
      }
      if (subscriptionState === SubscriptionState.Obsolete) {
        return 'gray';
      }
      if (subscriptionState === SubscriptionState.Cancelled) {
        return 'orange';
      }
      return 'text.primary';
    },
    [subscriptionState]
  );

  return (
    <UiHStack justifyContent={'flex-start'}>
      <UiTag colorScheme={color} size={'md'}>
        {subscriptionStateLabels[subscriptionState]}
      </UiTag>
    </UiHStack>
  );

  // return (
  //   <UiHStack justifyContent={'flex-start'}>
  //     <UiText variant={'title'} color={color}>
  //       {subscriptionStateLabels[subscriptionState]}
  //     </UiText>
  //   </UiHStack>
  // );
};

export default Status;
