import { type FC } from 'react';
import {
  XCircle
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconXCircleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconXCircle: FC<UiIconXCircleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={XCircle}
      {...props}
    />
  );
};
