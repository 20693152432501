import { useQuery } from '@tanstack/react-query';
import { type Event, eventQueryKey } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';

export function useEventQuery(eventId: string) {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } =
    useTenantApi();
  return useQuery<Event, Error>({
    queryKey: [eventQueryKey, { eventId }],
    queryFn: async () => {
      const result = await registration.loadEvent(createTenantAdminApiRequest)({
        eventId,
      });
      return result.items?.[0];
    },
    enabled: !isApiPreparing,
    refetchInterval: (event) => {
      return (event?.cloneStatus === 'in_progress' || event?.built === false) ? 1000 : false;
    }
  });
}
