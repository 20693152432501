import { type FC, useCallback } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton
} from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import Invoice from './Invoice';
import Online from './Online';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseBlockList from '@/base/BlockList';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import SurchargeSetting from './SurchargeSetting';

const DiscountCodes: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { mutate, isLoading } = useIsSetMutation();
  const { toNextStep } = useBuildEventSteps();

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'paymentMethod', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const paymentOptionQuery = useQuery(
    [registration.paymentOptionQueryKey, { eventId }],
    async () => {
      const result = await registration.loadPaymentOptions(createTenantAdminApiRequest)({ eventId });
      return result.items;
    },
    { enabled: !isApiPreparing }
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'paymentMethods'}
          />
        )}
        message={'Please provide your payment methods'}
      >
        <UiStack
          // pt={4}
          spacing={8}
          flexGrow={1}
          p={8}
          enableDivider={true}
        >
          <BaseBlockList
            bgColor={'#fff'}
            enableDivider={true}
          >
            <SurchargeSetting />
          </BaseBlockList>
          <QueryContainer query={paymentOptionQuery}>
            {(paymentOptions) => {
              return (
                <BaseBlockList>
                  <Online paymentOptions={paymentOptions} />
                  <Invoice paymentOptions={paymentOptions} />
                </BaseBlockList>
              );
            }}
          </QueryContainer>
          {/* </UiGrid> */}
        </UiStack>
        <UiHStack
          p={8}
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiButton
              size={'lg'}
              colorScheme={'primary'}
              type={'submit'}
              fontSize={'lg'}
              onClick={onNext}
              isLoading={isLoading}
            >
              Next
            </UiButton>
          </BaseAclContainer>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default DiscountCodes;
