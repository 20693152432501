import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiHStackProps,
  uiStyles,
  UiText,
  UiHStack,
  UiIconListNumbers, UiIconPlusCircle,
} from '@/lib/ui';
import OrderChangeDrawer from './OrderChangeDrawer';
import BaseButtonIconText from '@/base/Button/IconText';

export interface OrderChangeProps extends UiHStackProps {
}

const OrderChange: FC<OrderChangeProps> = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText Icon={UiIconListNumbers} onClick={onToggle}>Reorder</BaseButtonIconText>
      <OrderChangeDrawer isOpen={isOpen} onClose={onToggle} />
    </>
  );
};

export default OrderChange;
