import { type FC } from 'react';
import { UiHStack, UiStack, UiTag, UiText } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseBlockList from '@/base/BlockList';
import ShareStockListItem from './ShareStockListItem';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useSharedStocksQuery } from '@/registration/hook/useSharedStocksQuery';

export interface SharedStockListProps {
  ticketType: TicketType
}

const SharedStockList: FC<SharedStockListProps> = ({ ticketType }) => {
  const { eventId } = useRegisterRoute();
  const { tenant } = useTenantRoute();

  const sharedStocksQuery = useSharedStocksQuery(eventId, ticketType);

  return (
    <UiStack
      spacing={4}
      alignItems={'stretch'}
      // flexGrow={1}
    >
      <QueryContainer query={sharedStocksQuery}>
        {(sharedStocks) => {
          return (
            <>
              {sharedStocks.length > 0 ? (
                <BaseBlockList>
                  {sharedStocks.map((sharedStock) => {
                    return (
                      <ShareStockListItem
                        eventId={eventId}
                        tenant={tenant}
                        key={sharedStock.id}
                        sharedStockItem={sharedStock}
                        ticketType={ticketType}
                      />
                    );
                  })}
                </BaseBlockList>
              ) : (
                <UiHStack>
                  <UiTag colorScheme={'yellow'} px={4} py={2}>
                  No shared capacities.
                  </UiTag>
                </UiHStack>
              )}
            </>
          );
        }}
      </QueryContainer>
    </UiStack>
  );
};

export default SharedStockList;
