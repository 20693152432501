import * as React from 'react';
import Bugsnag, { type BreadcrumbType, type NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AppErrorBoundary } from '@/base/ErrorBoundary/ErrorBoundary';
import { AppEnv, isEnv } from './app/config';

const errorsWhitelist: string[] = [
];

interface IBugsnagConfig {
  isEnabled: boolean
}

export interface IBugsnagService {
  start: () => void
  setUser: (id?: string, email?: string, username?: string) => void
  setContext: (context: string) => void
  ErrorBoundary: () => React.ElementType
  notify: typeof Bugsnag.notify
  leaveBreadcrumb: (message: string, data: Record<string, any>, type?: BreadcrumbType) => void
}

const createBugsnag = (config: IBugsnagConfig): IBugsnagService => {
  const start = (): void => {
    if (config.isEnabled) {
      const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
      if (apiKey) {
        Bugsnag.start({
          apiKey,
          plugins: [new BugsnagPluginReact()],
        });
      }
    }
  };

  const setUser = (id?: string, email?: string, username?: string): void => {
    if (config.isEnabled) {
      Bugsnag.setUser(id, email, username);
    }
  };

  const setContext = (context: string): void => {
    if (config.isEnabled) {
      Bugsnag.setContext(context);
    }
  };

  const notify = (error: NotifiableError) => {
    if (config.isEnabled) {
      const errorMessage =
        (error as Error | { name: string, message: string })?.message ??
        (error as { errorClass: string, errorMessage: string })?.errorMessage ??
        (error as string);
      if (!errorsWhitelist.includes(errorMessage)) {
        Bugsnag.notify(error);
      }
    }
  };

  const leaveBreadcrumb = (message: string, data: Record<string, any>, type?: BreadcrumbType) => {
    if (config.isEnabled) {
      Bugsnag.leaveBreadcrumb(message, data, type);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ErrorBoundary = () => { return config.isEnabled ? Bugsnag.getPlugin('react')!.createErrorBoundary(React) : AppErrorBoundary; };

  return {
    start,
    setUser,
    setContext,
    notify,
    ErrorBoundary,
    leaveBreadcrumb,
  };
};

export default createBugsnag({
  isEnabled: isEnv(AppEnv.Production),
});
