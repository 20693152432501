import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiFlex, UiHStack, UiStack, UiText } from '@/lib/ui';
import FormResponseTable from './FormResponseTable';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const FormResponse: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['report', 'form-responses']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
        // breadcrumbNodes={breadcrumbNodes}
      >
        <UiHStack justifyContent={'space-between'} spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Form responses</UiText>
          <UiFlex flexGrow={1} justifyContent={'flex-end'}>
            <SubscribeButton reportName={reportSubscriptionName.formResponsesReport} />
          </UiFlex>
        </UiHStack>
        <UiStack height={4} />
        <FormResponseTable />
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default FormResponse;
