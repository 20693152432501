import { type FC, ReactNode } from 'react';
import {
  UiHStack,
  UiStack,
  uiStyles,
} from '@/lib/ui';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';

export interface AddonCardProps {
  AddonCard: ReactNode
  children: ReactNode
  price: number
}

const AddonCalculator: FC<AddonCardProps> = ({
  AddonCard,
  children,
  price,
}) => {
  return (
    <UiStack
      spacing={4}
      flexGrow={1}
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiHStack>
        {AddonCard}
        <PriceDisplay price={price}/>
      </UiHStack>
      {children}
    </UiStack>
  );
};

export default AddonCalculator;
