import { type ApiRequest, type ApiResponse, callApi } from '@/api/tenantClient';
import { TicketType } from '../constant';
import { type TicketData } from './ticket';

export const ticketListQueryKey = 'registration.ticketList';

// export type TicketListItemData = Pick<Ticket, 'id'|'name'|'state'|'description'|'logo'|'geo'>;
export type TicketListItemData = TicketData;

export type TicketListData = TicketListItemData[];

export interface TicketListQueryRequest {
  eventId: string
  ticketType: TicketType
}

export function loadTicketList(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: TicketListQueryRequest
  ): Promise<ApiResponse<TicketData>> => {
    const path = params.ticketType === TicketType.Main ? '/api/v1/admin/delegate_types' : '/api/v1/admin/sessions_pricings';
    request.method = 'GET';
    request.endpoint.path = path;
    request.endpoint.query = {
      eventId: params.eventId,
      ticketType: params.ticketType.toString()
    };
    return await callApi<TicketData>(request);
  };
}

export interface TicketListByAttendeeCategoryQueryRequest {
  eventId: string
  ticketType: TicketType
  attendeeCategoryId: number
  registrationId: number
}

export function loadTicketListByAttendeeCategory(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: TicketListByAttendeeCategoryQueryRequest
  ): Promise<ApiResponse<TicketData>> => {
    const path = params.ticketType === TicketType.Main ? '/api/v1/admin/delegate_types/by_attendee_category' : '/api/v1/admin/sessions_pricings/by_attendee_category';
    request.method = 'GET';
    request.endpoint.path = path;
    request.endpoint.query = {
      eventId: params.eventId,
      ticketType: params.ticketType.toString(),
      attendeeCategoryId: params.attendeeCategoryId.toString(),
      registrationId: params.registrationId.toString()
    };
    return await callApi<TicketData>(request);
  };
}
