import { type FC, useMemo } from 'react';
import {
  UiHStack, UiIconSignIn,
  UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';

export interface ApiErrorUnauthorizedProps {
}

const ApiErrorUnauthorized: FC<ApiErrorUnauthorizedProps> = () => {
  const loginUrl = useMemo(() => generatePageUrl('AccountLoginLoginEmail'), []);

  return (
    <UiHStack spacing={2} flexGrow={1} justifyContent={'space-between'}>
      <UiText color={'red.500'}>Operation denied. Sign in required.</UiText>
      <BaseRouterLink to={loginUrl} {...uiStyles.hover}>
        <UiHStack>
          <UiText color={'primary.500'} fontWeight={600}>Go to sign in</UiText>
          <UiIconSignIn size={'2xl'} color={'primary.500'} weight={'bold'}/>
        </UiHStack>
      </BaseRouterLink>
    </UiHStack>
  );
};

export default ApiErrorUnauthorized;
