import { useMemo, type FC } from "react";
import { UiSimpleGrid, UiStack, uiStyles, UiText } from "@/lib/ui";
import { type OrderById } from "@/api/registration";
import dayjs from "dayjs";

export interface RefundsSectionProps {
  order: OrderById;
}

export interface RefundTicketProps {
  refund: OrderById["refunds"][0];
}

const RefundsSection: FC<RefundsSectionProps> = ({ order }) => {
  return (
    <UiStack spacing={4}>
      <UiText variant={"title"}>Previous Refunds</UiText>
      <UiSimpleGrid columns={3} gap={8}>
        {order.refunds.map((refund) => {
          return (
            <RefundTicket
              key={refund.id}
              refund={refund}
            />
          );
        })}
      </UiSimpleGrid>
    </UiStack>
  );
};

const RefundTicket: FC<RefundTicketProps> = ({ refund }) => {

  return (
    <UiStack
      p={8}
      py={6}
      borderRadius={uiStyles.borderRadius}
      bgColor={"#fff"}
      flexGrow={1}
      enableDivider={true}
      spacing={4}
    >
      <UiSimpleGrid columns={2} gap={8}>
        <UiStack>
          <UiText color={"text.secondary"}>Amount</UiText>
          <UiText>{refund.refundAmountFormat}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={"text.secondary"}>Refund Note</UiText>
          <UiText>{refund.refundNote}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={"text.secondary"}>Refund By</UiText>
          <UiText>{refund.refundedBy}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={"text.secondary"}>CreatedAt</UiText>
          <UiText>
            {dayjs(refund.createdAt).format("Do MMMM YYYY HH:mm:ss")}
          </UiText>
        </UiStack>
      </UiSimpleGrid>
    </UiStack>
  );
};

export default RefundsSection;
