import { type EventLocale, exportOrderStatistics, type EventListData, type OrderReport } from '@/api/registration';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import BarChart from '@/base/Chart/BarChart';
import {
  UiHStack, UiIconExport,
  UiIconMagnifyingGlassMinus, UiSimpleGrid,
  UiSpinner,
  UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import { dynamicColor } from '@/lib/util';
import { createFormatPrice } from '@/lib/util/locale';
import { type ChartDataset } from 'chart.js';
import { useMemo, type ReactNode } from 'react';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type useGetLocaleEventType, type useGetOrderStatisticsType } from '@/registration/report/Order';
import { defaultColors } from '@/api/constant/colors';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import type { OnChangeValue } from 'chakra-react-select';
import { type ReportSubscriptionName } from '@/api/reports/reportSubscriptions';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

interface TotalCompleteRegistrationsChartProps {
  selectOptions: Array<{
    label: string
    value: string
  }>
  eventList: EventListData | undefined
  useGetOrderStatistics: useGetOrderStatisticsType
  useGetLocaleEvent?: useGetLocaleEventType
  title: string
  type: string
  icon?: ReactNode
  reportName?: ReportSubscriptionName
  eventOptions?: SelectElementOption[]
  handleScrollToBottom?: (event: WheelEvent | TouchEvent) => void
}

const OrderChartReport: React.FC<TotalCompleteRegistrationsChartProps> = ({
  selectOptions,
  useGetOrderStatistics,
  useGetLocaleEvent,
  title,
  type,
  reportName,
  eventOptions = [],
  icon = undefined,
  handleScrollToBottom = () => {}
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [labels, setLabels] = useState<string[] | undefined>([]);
  const [datasets, setDatasets] = useState<Array<ChartDataset<'bar', number[]>>>([]);
  const [locale, setLocale] = useState<EventLocale>({currencyCode: 'AUD', localeCode: 'en-AU' });
  const [eventId, setEventId] = useState<string>('');
  const { data, isLoading } = useGetOrderStatistics({ eventId, type });
  const { adminAuth } = useAdminAuth();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();
  const localeQuery = useGetLocaleEvent ? useGetLocaleEvent({ eventId }) : { data: undefined, isFetched: false };
  const { data: localeData, isFetched: localeIsFetched } = localeQuery;

  const { mutate: callExportOrderStatistics, isLoading: isExportOrderStatisTicsLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await exportOrderStatistics(createTenantAdminApiRequest)({
        type,
        email: adminAuth.user!.email
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportOrderStatistics = useCallback(() => { return callExportOrderStatistics(); }, [callExportOrderStatistics]);

  useEffect(() => {
    if (data && !data?.errors && selectedGroup) {
      let colors: string[] = [...defaultColors];

      const fetchLabel: string[] = data?.item[selectedGroup as keyof OrderReport]?.map((label: { category?: string, ticketType?: string, country?: string, name?: string }) => {
        switch (selectedGroup) {
          case 'byAttendeeCategory':
            return label.category ?? '';
          case 'byMainTicketType':
            return label.ticketType ?? '';
          case 'byCountry':
            return label.country ?? '';
          case 'byDiscountCode':
            return label.name ?? '';
          default:
            return label.name ?? '';
        }
      }) ?? [];
      setLabels(fetchLabel);

      const randomColors: string[] = [];
      const fetchDataset = data?.item[selectedGroup as keyof OrderReport]?.map((label: { totalPrice?: number, count?: number }) => {
        const color = dynamicColor(colors);
        randomColors.push(color);
        colors = colors.filter((value) => { return value !== color; });

        return label.totalPrice ?? label.count ?? 0;
      }) ?? [];
      if (fetchDataset) {
        setDatasets([{
          data: fetchDataset,
          label: title,
          backgroundColor: randomColors
        }]);
      }
    }
  }, [data, selectedGroup, title]);

  useEffect(() => {
    if (localeData?.item?.localeCode && localeData?.item?.currencyCode && localeIsFetched) {
      setLocale(pre => ({ ...pre, localeCode: localeData?.item.localeCode, currencyCode: localeData?.item.currencyCode }))
    }
  }, [localeIsFetched, localeData]);

  const handleChange = (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedGroup(option?.value ? `${option?.value}` : '');
  };

  const handleChangeEvent = (option: OnChangeValue<SelectElementOption, false>) => {
    setEventId(option?.value ? `${option?.value}` : '');
  };

  const formatPrice = useMemo(
    () => { return useGetLocaleEvent ? createFormatPrice({ locale: locale.localeCode, currency: locale.currencyCode }) : undefined },
    [locale, eventId, useGetLocaleEvent]
  );

  return (
    <UiStack
      alignItems={'stretch'}
      spacing={4}
      borderWidth={'1px'}
      borderColor={'blackAlpha.100'}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiHStack spacing={4}>
        <UiHStack spacing={2}>
          {!!icon && icon}
          <UiText variant={'body1'}>{title}</UiText>
        </UiHStack>
        <UiHStack flexGrow={1} justifyContent={'flex-end'} spacing={4}>
          <BasePlainIcon
            label={'Email CSV'}
            Icon={UiIconExport}
            color={'primary.500'}
            onClick={onExportOrderStatistics}
            isLoading={isExportOrderStatisTicsLoading}
          />
          {!!reportName && <SubscribeButton reportName={reportName} />}
        </UiHStack>
      </UiHStack>
      <UiSimpleGrid columns={2} spacing={4} flexGrow={1}>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={eventId}
            onChange={handleChangeEvent}
            options={eventOptions}
            onScrollToBottom={handleScrollToBottom}
            placeholder={'Select event'}
          />
        </UiStack>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={selectedGroup}
            onChange={handleChange}
            options={selectOptions}
            placeholder={'Select group'}
          />
        </UiStack>
      </UiSimpleGrid>
      {labels?.length === 0 && (
        <UiHStack py={2}>
          <UiIconMagnifyingGlassMinus color={'text.secondary'} />
          <UiText color={'text.secondary'} variant={'body2'}>
            No results
          </UiText>
        </UiHStack>
      )}
      {labels && labels.length > 0 && (localeIsFetched || !useGetLocaleEvent) && (
        <UiStack py={2}>
          <UiStack shadow={'base'} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} p={6}>
            <BarChart datasets={datasets} labels={labels} title={''} formatLabel={formatPrice} />
          </UiStack>
        </UiStack>
      )}
      {isLoading && !!eventId && (
        <UiStack>
          <BaseLoadingSpinner/>
        </UiStack>
      )}

    </UiStack>
  );
};

export default OrderChartReport;
