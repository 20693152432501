/* eslint-disable @typescript-eslint/no-shadow */
import { type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  UiHStack,
  UiSpinner,
  UiStack, UiText,
} from '@/lib/ui';
import LoginSmartWindow from '@/account/component/Layout/LoginSmartWindow';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import Password from './Password';

const SetPassword: FC = () => {
  // const { tenant, isLoading: isTenantLoading } = useTenantRoute();

  return (
    <BaseLayout
      smartWindow={(<LoginSmartWindow />)}
      sideMenu={null}
      smartButton={(<UiStack />)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        <Password/>
      </LoginBody>
    </BaseLayout>
  );
};

export default SetPassword;
