import {
  callApi,
  type ApiRequest,
  type ApiResponse
} from '@/api/tenantClient';

export const paymentOptionQueryKey = 'registration.paymentOption';

export type paymentOptionType = 'stripe' | 'eWay' | 'invoice' | 'secure_pay' | 'nab_transact';

export interface PaymentOption {
  id: number
  name: paymentOptionType
  enabled?: boolean
  createdAt: string
  updatedAt: string
  config: Record<string, string>
  default?: boolean
  tenantCode?: string
}

export interface PaymentOptionRequest {
  eventId: string
}

export function loadPaymentOptions(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: PaymentOptionRequest
  ) => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/payment_options/index_admin';
    request.endpoint.query = { eventId: params.eventId };
    return await callApi<PaymentOption>(request);
  };
}

export interface CreatePaymentRequest {
  id?: number
  paymentOptionType: paymentOptionType
  isEnabled?: boolean
  config: Record<string, string>
  eventId: string
  tenantCode?: string
  default?: boolean
}

export function addPaymentOptions(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CreatePaymentRequest
  ): Promise<ApiResponse<PaymentOption>> => {
    request.method = params.id ? 'PATCH' : 'POST';
    request.endpoint.path = `/api/v1/payment_options/${params.id ?? ''}`;
    request.payload = {
      paymentOptionType: params.paymentOptionType,
      enabled: params.isEnabled,
      config: JSON.stringify(params.config),
      eventId: params.eventId,
      tenantCode: params.tenantCode ?? '',
      default: params.default ?? false
    };
    return await callApi<PaymentOption>(request);
  };
}

export function testConnection(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: CreatePaymentRequest
  ) => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/payment_options/test_connection';
    request.payload = {
      paymentOption: {
        paymentOptionType: params.paymentOptionType,
        config: JSON.stringify(params.config)
      }
    };
    return await callApi<{}>(request);
  };
}

export interface ClonePaymentOptionRequest {
  id: number
  toEvent: string | number
}

export function clonePaymentOption(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: ClonePaymentOptionRequest
  ) => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/payment_options/clone';
    request.payload = {
      id: params.id,
      toEvent: params.toEvent
    };
    return await callApi<{}>(request);
  };
}
