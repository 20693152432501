import { type ApiRequest, callApi } from '@/api/globalClient';
import { TeamState, TeamType } from '@/api/constant/team';
import { PermissionMap } from '@/api/account/adminUserSession';
import { AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';

export const teamSearchQueryKey = 'account.teamSearch';
export const teamQueryKey = 'account.team';

export interface SearchTeamsResponseTeam {
  id: number
  state: TeamState
  name: string
  description: string
  createdAt: number
  updatedAt: number
}
export interface SearchTeamsRequest {
  tenantId?: number,
  state?: TeamState
  type?: TeamType
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}
export interface SearchTeamsResponse {
  teamList: SearchTeamsResponseTeam[]
  errors: string[]
}
// Currently the API will return all the admin users for this team. We should consider admin user pagination in the future.
export function searchTeams(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: SearchTeamsRequest): Promise<SearchTeamsResponse> {
    apiRequest.endpoint.path = '/api/acl/search-teams';
    params.type = params.type ?? TeamType.Custom; // Default to exclude built-in teams.
    apiRequest.payload = params;
    const response = await callApi<SearchTeamsResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface LoadTeamRequest {
  id: number
}
export interface LoadTeamResponseUser {
  id: number
  type: AdminUserType
  state: AdminUserState
  email: string
  createdAt: number
  updatedAt: number
}
export interface LoadTeamResponseTeam {
  id: number
  state: TeamState
  name: string
  description: string
  createdAt: number
  updatedAt: number
  permissions: PermissionMap
  adminUsers: LoadTeamResponseUser[]
}
export interface LoadTeamResponse {
  team: LoadTeamResponseTeam
  errors: string[]
}
export function loadTeam(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: LoadTeamRequest): Promise<LoadTeamResponse> {
    apiRequest.endpoint.path = '/api/acl/load-team';
    apiRequest.payload = params;
    const response = await callApi<LoadTeamResponse>(apiRequest);
    response.errors = response.errors ?? [];
    response.team.permissions = response.team.permissions ?? [];
    response.team.adminUsers = response.team.adminUsers ?? [];
    return response;
  }
}

export interface TeamData {
  id?: number
  name: string
  state: number
  description?: string
}

export interface SaveTeamRequest {
  tenantId: number
  team: TeamData,
}
export interface SaveTeamResponse {
  teamId: number
  errors: string[]
}
export function saveTeam(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: SaveTeamRequest): Promise<SaveTeamResponse> {
    apiRequest.endpoint.path = '/api/acl/save-team';
    apiRequest.payload = params;
    const response = await callApi<SaveTeamResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface UpdateTeamPermissionRequest {
  teamId: number
  permissionMap: PermissionMap
}
export interface UpdateTeamPermissionResponse {
  errors: string[];
}
export function updateTeamPermissionMap(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: UpdateTeamPermissionRequest): Promise<UpdateTeamPermissionResponse> {
    apiRequest.endpoint.path = '/api/acl/update-team-permission-map';
    apiRequest.payload = params;
    const response = await callApi<SaveTeamResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface UpdateTeamDomainActionsRequest {
  teamId: number
  domain: PermissionDomain
  actions: PermissionAction[]
}
export interface UpdateTeamDomainActionsResponse {
  errors: string[];
}
export function updateTeamDomainActions(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: UpdateTeamDomainActionsRequest): Promise<UpdateTeamDomainActionsResponse> {
    apiRequest.endpoint.path = '/api/acl/update-team-domain-actions';
    apiRequest.payload = params;
    const response = await callApi<UpdateTeamDomainActionsResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface RemoveTeamAdminUsersRequest {
  teamId: number
  adminUserIds: number[]
}
export interface RemoveTeamAdminUsersResponse {
  errors: string[];
}
export function removeTeamAdminUsers(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: RemoveTeamAdminUsersRequest): Promise<RemoveTeamAdminUsersResponse> {
    apiRequest.endpoint.path = '/api/acl/remove-team-admin-users';
    apiRequest.payload = params;
    const response = await callApi<RemoveTeamAdminUsersResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AddTeamAdminUsersRequest {
  teamId: number
  adminUserIds: number[]
}
export interface AddTeamAdminUsersResponse {
  errors: string[];
}
export function addTeamAdminUsers(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: AddTeamAdminUsersRequest): Promise<AddTeamAdminUsersResponse> {
    apiRequest.endpoint.path = '/api/acl/add-team-admin-users';
    apiRequest.payload = params;
    const response = await callApi<AddTeamAdminUsersResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface DeleteTeamRequest {
  teamId: number
}
export interface DeleteTeamResponse {
  errors: string[]
}
export function deleteTeam(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: DeleteTeamRequest): Promise<DeleteTeamResponse> {
    apiRequest.endpoint.path = '/api/acl/delete-team';
    apiRequest.payload = params;
    const response = await callApi<SaveTeamResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}
