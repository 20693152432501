import { type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
  UiInputGroup,
  uiStyles,
  UiIconEye,
  UiIconEyeSlash,
  UiBox,
  UiInputRightElement,
} from '@/lib/ui';
import FieldContainer from './FieldContainer';
import { InputFieldProps } from '@/base/Form/InputField';
import { useDisclosure } from '@chakra-ui/react';
import { Permission } from '@/app/ProviderAdminAuth';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

export interface SecretField extends Omit<InputFieldProps, 'type'> {
  tenantId?: number
  permissionsRequired?: Permission[]
}

const SecretField: FC<SecretField> = ({
  label,
  name,
  min,
  max,
  maxLength,
  tenantId,
  helperText = undefined,
  isRequired = true,
  placeholder = '',
  layout = 'inline',
  disabled = false,
  hidden = false,
  permissionsRequired = [
    [PermissionDomain.Account, PermissionAction.AdminUserAdmin], // By default, the user needs to be the account owner to reveal the secret.
  ],
}) => {
  const { isOpen: isPasswordVisible, onToggle: onTogglePasswordVisibility } = useDisclosure();

  return (
    <Field name={name} >
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} style={{ display: hidden ? 'none' : 'block' }}>
            <FieldContainer
              layout={layout}
            >
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'} spacing={0}>
                <UiInputGroup size='md'>
                  <UiInput
                    {...field}
                    placeholder={placeholder}
                    type={isPasswordVisible ? 'text' : 'password'}
                    disabled={disabled}
                    pr="2.5rem"
                    min={min}
                    max={max}
                    maxLength={maxLength}
                  />
                  <BaseAclContainer
                    tenantId={tenantId ?? 0}
                    permissionsRequired={permissionsRequired}
                    noPermissionPlaceholder={(
                      <UiInputRightElement width='2.5rem'>
                        <UiBox>
                          <UiIconEyeSlash color={'gray.500'}/>
                        </UiBox>
                      </UiInputRightElement>
                    )}
                  >
                    <UiInputRightElement width='2.5rem'>
                      <UiBox {...uiStyles.hover} onClick={onTogglePasswordVisibility}>
                        {isPasswordVisible ? (
                          <UiIconEye color={'primary.500'}/>
                        ) : (
                          <UiIconEyeSlash color={'primary.500'}/>
                        )}
                      </UiBox>
                    </UiInputRightElement>
                  </BaseAclContainer>
                </UiInputGroup>
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default SecretField;
