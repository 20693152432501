import { type FC, useCallback, useState } from 'react';
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerOverlay,
  type UiDrawerProps,
  UiHStack,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { account } from '@/api';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface RevokeConfirmation {
  adminUser: account.SearchAdminUsersResponseAdminUser,
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
}

const RevokeConfirmation: FC<RevokeConfirmation> = ({
  adminUser,
  isOpen,
  onClose,
}) => {
  const { tenant } = useTenantRoute();
  const [errors, setErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<account.AdminUserUpdateStateResponse, Error, account.AdminUserUpdateStateRequest>({
    mutationFn: async (data: account.AdminUserUpdateStateRequest) => {
      return await account.updateState(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        void queryClient.invalidateQueries({queryKey: [account.adminUserSearchQueryKey, {tenantId: tenant?.id, types: [AdminUserType.Generic]}]});
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to revoke the user.']);
    }
  });

  const resend = useCallback(
    () => {
      if (adminUser.id && tenant?.id) {
        void mutate({
          adminUserId: adminUser.id,
          state: AdminUserState.Inactive,
        });
      }
    },
    [adminUser.id]
  );

  return (
    <UiDrawer
      placement={'right'}
      size={'md'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} py={12} bgColor={'gray.100'}>
          <UiStack py={8} spacing={4} flexGrow={1}>
            {errors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {errors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <UiText variant={'title'}>Revoke user</UiText>
            <UiStack bgColor={'#fff'} p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={2}>
              <UiText>{adminUser.firstName} {adminUser.lastName}</UiText>
              <UiText color={'text.secondary'} variant={'body2'}>{adminUser.email}</UiText>
            </UiStack>
            <UiText color={'text.secondary'}>This user won't be able to access the platform once revoked</UiText>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={8}>
          <UiHStack justifyContent={'flex-end'}>
            {isLoading ? (
              <UiButton colorScheme={'green'}>Revoking...</UiButton>
            ) : (
              <UiButton onClick={resend}>Revoke</UiButton>
            )}
          </UiHStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default RevokeConfirmation;
