// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDotglftLUGSesWy-1twsvhFgJxtAW2IWs",
  authDomain: "evexus-a6a42.firebaseapp.com",
  projectId: "evexus-a6a42",
  storageBucket: "evexus-a6a42.appspot.com",
  messagingSenderId: "215667116007",
  appId: "1:215667116007:web:ad2bdc536d0c1b5ee0df42",
  measurementId: "G-ZSWWBRK6H4"
};

// Initialize Firebase
export default initializeApp(firebaseConfig);