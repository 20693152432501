import { type FC, type ReactNode, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiHStack, UiIconArrowCounterClockwise, UiIconArrowsClockwise, UiIconCaretCircleDoubleRight, UiIconArrowRight, UiLink,
  UiStack,
  type UiStackProps, uiStyles, UiTag, UiText,
} from '@/lib/ui';
import { account } from '@/api';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import TenantSwitchDrawer from '@/base/App/TenantSwitchDrawer';
import { generatePageUrl } from '@/app/pages';
import { useQuery } from '@tanstack/react-query';
import { TenantState } from '@/api/constant';
import { useGlobalApi } from '@/account/hook/useGlobalApi';

export interface TenantSwitchProps {
}

const TenantSwitch: FC<TenantSwitchProps> = ({}) => {
  const { tenant } = useTenantRoute();
  const { adminAuth } = useAdminAuth();
  const { isOpen, onToggle } = useDisclosure();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const getTenantLink = useCallback(
    (tenantCode: string) => {
      // navigate(generatePageUrl('AccountDashboardModules', {tenantCode,}));
      return generatePageUrl('AccountDashboardModules', {tenantCode,});
    },
    []
  );
  const { data, isLoading, error } = useQuery<account.TenantSearchResponse, Error>(
    [account.tenantListQueryKey, {page: 1, orderByField: 'id', isOrderedAsc: true, limit: 1}],
    async () => {
      return await account.searchTenants(createGlobalApiAclRequest())({
        state: TenantState.Active,
        page: 1,
        orderByField: 'name',
        isOrderedAsc: true,
        limit: 20,
      });
    },
    {
      enabled: !!adminAuth?.user?.id,
    }
  );

  if (data?.total && data?.total > 1) {
    return (
      <>
        <UiHStack
          // bgColor={'whiteAlpha.600'}
          // borderColor={'primary.300'}
          // borderWidth={'2px'}
          // borderStyle={'solid'}
          // p={6}
          py={4}
          px={6}
          borderRadius={uiStyles.buttonRadius}
          spacing={4}
          justifyContent={'space-between'}
          onClick={onToggle}
          {...uiStyles.hover}
        >
          <UiText variant={'body1'} color={'primary.500'} fontWeight={600}>{tenant?.name ?? 'Go to workspace'}</UiText>
          <UiIconArrowsClockwise size={'xl'} color={'primary.500'} weight={'bold'}/>
        </UiHStack>
        <TenantSwitchDrawer
          currentTenant={tenant}
          isOpen={isOpen}
          onClose={onToggle}
        />
      </>
    );
  } else {
    // Only one tenant is available. Make it clickable.
    return (
      <>
        <UiLink href={(data?.tenantList[0] && data?.tenantList[0]?.code) ? getTenantLink(data?.tenantList[0].code) : "#"}>
          <UiHStack
            // bgColor={'whiteAlpha.600'}
            // borderColor={'primary.300'}
            // borderWidth={'2px'}
            // borderStyle={'solid'}
            // p={6}
            py={4}
            px={6}
            borderRadius={uiStyles.buttonRadius}
            spacing={4}
            justifyContent={'space-between'}
            {...uiStyles.hover}
          >
            <UiText color={'primary.500'} variant={'body1'} fontWeight={600}>{tenant?.name ?? `Go to workspace (${data?.tenantList[0]?.name})`}</UiText>
          </UiHStack>
        </UiLink>
      </>
    );
  }
};

export default TenantSwitch;
