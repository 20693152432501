import { type FC, useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import dayjs, { type Dayjs } from 'dayjs';
import { UiButton, UiHStack, UiIconArrowRight, uiStyles, UiTag } from '@/lib/ui';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { type SerializedRegistrationTableRow } from './GroupRegistrationTable';
import { type DatatableSelectOptions } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

dayjs.extend(advancedFormat);

interface DatatableProps {
  data: SerializedRegistrationTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: any
  setFilters: (filters: any) => void
  sorting: any
  setSorting: (sorting: any) => void
  search: string
  setSearch: (searchText: string) => void
  pagination: any
  setPagination: (pagination: any) => void
  rowCount: number
  delegateTypeSelectOptions: DatatableSelectOptions
  booleanSelectOptions: DatatableSelectOptions
  statusSelectOptions: DatatableSelectOptions
  workshopTicketsSelectOptions: DatatableSelectOptions
  functionTicketsSelectOptions: DatatableSelectOptions
  tourTicketsSelectOptions: DatatableSelectOptions
  attendeeCategorySelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount, booleanSelectOptions, statusSelectOptions, attendeeCategorySelectOptions
}) => {
  const navigate = useNavigate();
  const { tenantCode, tenant } = useRegisterRoute();
  const dataTableOptions = useDataTableOptions<SerializedRegistrationTableRow>();

  const columns = useMemo<Array<MRT_ColumnDef<SerializedRegistrationTableRow>>>(
    () => {
      return [
        {
          accessorKey: 'eventName',
          header: 'Event'
        },
        {
          accessorKey: 'registrantName',
          header: 'Name'
        },
        {
          accessorKey: 'registrantEmail',
          header: 'Email'
        },
        {
          accessorKey: 'status',
          header: 'Status',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: statusSelectOptions
          }
        },
        {
          accessorKey: 'attendeeCategoryName',
          header: 'Attendee group',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: attendeeCategorySelectOptions
          }
        },
        {
          accessorKey: 'abandonedCart',
          header: 'Abandoned cart',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          },
          Cell: ({ cell }) => {
            if (cell.getValue() === null) return;

            if (cell.getValue()) {
              return (
                <UiTag colorScheme='red'>
                  Yes
                </UiTag>
              );
            } else if (!cell.getValue()) {
              return (
                <UiTag colorScheme='gray'>
                  No
                </UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at date',
          id: 'createdAtDate',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('Do MMMM') : ''; },
          filterVariant: 'date-range'
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at time',
          id: 'createdAtTime',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Z') : ''; },
          enableColumnFilter: false,
          enableSorting: false,
        },
      ];
    },
    []
  );

  const toGroupRegistrationManageInfoPage = (groupRegistrationId: number) => {
    navigate(generatePageUrl('GroupRegistrationManageInfo', { tenantCode, groupRegistrationId: groupRegistrationId.toString() }));
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: false,
      columnVisibility: {
        id: false,
        uuid: false,
        'delegateType.name': false,
        'ticket.function': false,
        'ticket.workshop': false,
        'ticket.tour': false,
        paid: false,
        complete: false,
        createdAt: false,
        confirmDelegateTypeAt: false,
        'attendeeCategory.name': false
      }
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
        </UiHStack>
      );
    },
    renderRowActions: ({ row }) => {
      return (
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack
            px={0}
            justifyContent={'flex-start'}
            onClick={() => { return toGroupRegistrationManageInfoPage(row.original.id); }}
            {...uiStyles.hover}
          >
            <UiIconArrowRight size={'2xl'} color={'primary.500'} />
          </UiHStack>
        </BaseAclContainer>
      );
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;
