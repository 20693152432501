import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconChartLine } from '@/lib/ui';

export interface DataInsightCardProps {
  isSelected?: boolean
  navLink?: string
  isEnabled?: boolean
}

const DataInsightCard: FC<DataInsightCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false
}) => {
  return (
    <AppCard
      icon={(<UiIconChartLine size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      name={'Data insights'}
      description={'Access event data charts, reports and analysis'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default DataInsightCard;
