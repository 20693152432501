import { type RegistrationMode } from '@/api/constant/registration';
import { type EventSettingConfirmValidate } from '@/api/registration';
import {
  UiBadge,
  UiButton,
  UiHStack, UiIconDot,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalHeader,
  UiModalOverlay,
  UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import { useCallback, type FC } from 'react';

export interface ValidateProps {
  isOpen: boolean
  data: EventSettingConfirmValidate | undefined
  isLoading: boolean
  onClose: () => void
  onUpdateEvent: (value?: RegistrationMode) => void
}

const ValidateEventSettingsModal: FC<ValidateProps> = ({
  isOpen,
  data,
  isLoading,
  onClose,
  onUpdateEvent
}) => {
  const requestChangeMode = useCallback(() => {
    onUpdateEvent(data?.mode);
    onClose();
  }, [data?.mode, onClose, onUpdateEvent]);

  return (
    <>
      <UiModal
        onClose={onClose}
        size={'xl'}
        closeOnEsc={true}
        motionPreset={'scale'}
        scrollBehavior={'inside'}
        isOpen={isOpen}
      >
        <UiModalOverlay/>
        <UiModalContent borderRadius={uiStyles.borderRadius} p={2}>
          <UiModalHeader fontSize={'title'}>Status update validation</UiModalHeader>
          <UiHStack>
            <UiModalCloseButton alignSelf={'flex-end'} p={4} size={'lg'} />
          </UiHStack>
          <UiModalBody mt={{ base: 4, md: 0 }}>
            {data?.warnings && data.warnings.length > 0 && (
              <UiStack spacing={2} flexGrow={1}>
                <UiText variant={'body1'}>
                  Warning:
                </UiText>
                <UiStack spacing={4}>
                  {data.warnings.map((warning, index) => {
                    return (
                      <UiHStack key={index} spacing={2} alignItems={'flex-start'}>
                        <UiText color={'orange.500'}>•</UiText>
                        <UiText color={'orange.500'}>
                          {warning}
                        </UiText>
                      </UiHStack>
                    );
                  })}
                </UiStack>
              </UiStack>
            )}
            {data?.errors && data.errors.length > 0 && (
              <UiStack spacing={2} flexGrow={1}>
                {/*<UiText>*/}
                {/*  Errors:*/}
                {/*</UiText>*/}
                <UiStack spacing={4}>
                  {data.errors.map((error, index) => {
                    return (
                      <UiHStack key={index} spacing={2} alignItems={'flex-start'}>
                        <UiText color={'red.500'}>•</UiText>
                        <UiText color={'red.500'}>
                          {error}
                        </UiText>
                      </UiHStack>
                    );
                  })}
                </UiStack>
              </UiStack>
            )}
          </UiModalBody>
          <UiModalFooter>
            <UiButton isDisabled={isLoading || (data?.errors && data?.errors.length > 0)} onClick={requestChangeMode}>Proceed</UiButton>
          </UiModalFooter>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default ValidateEventSettingsModal;
