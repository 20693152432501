import { type FC } from 'react';
import { locale } from '@/lib/util';

const { createFormatPrice } = locale;
const formatPrice = createFormatPrice({ locale: 'en-AU', currency: 'AUD' });

export interface PriceDisplayProps {
  price: number // Dollars.
  suffix?: string
  format?: (amount: number) => string
}

const PriceDisplay: FC<PriceDisplayProps> = ({
  price,
  suffix = '',
  format = formatPrice,
}) => {
  return `${format(price ?? 0)}${suffix}`;
};

export default PriceDisplay;
