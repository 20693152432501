import { create } from 'zustand';

export interface EventStoreType {
  isCloneAvailable: boolean
  setCloneAvailable: (value: boolean) => void
};

export const useBuildEventStore = create<EventStoreType>((set) => {
  return {
    isCloneAvailable: true,
    setCloneAvailable: (value: boolean) => {
      return set(() => {
        return { isCloneAvailable: value };
      });
    },
  };
});
