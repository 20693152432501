import { type AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import { type ApiRequest, callApi } from '@/api/globalClient';

export const adminUserQueryKey = 'account.adminUser';
export const adminUserAccountOwnerQueryKey = 'account.adminUserAccountOwner';
export const adminUserSearchQueryKey = 'account.adminUserSearch';

export interface AdminUserDataTenant {
  id: number
  state: number
  name: string
  code: string
  apiEndpoint: string
  createdAt: string
  updatedAt: string
}

export interface AdminUserData {
  id: number
  state: AdminUserState
  type: AdminUserType
  uid: string
  email: string
  firstName: string
  lastName: string
  tenants: AdminUserDataTenant[]
  createdAt: string | null
  updatedAt: string | null
}

export const adminUserDefaultData: AdminUserData = {
  id: 0,
  state: 2,
  type: AdminUserType.Generic,
  uid: '',
  email: '',
  firstName: '',
  lastName: '',
  tenants: [],
  createdAt: '',
  updatedAt: ''
};

// export interface AdminUserLoadByEmailRequest {
//   email: string // Admin user email.
// }
//
// export interface AdminUserLoadByEmailResponse {
//   item: AdminUserData
//   errors: string[]
// }
//
// /**
//  * Always return an admin user data.
//  * If the email is empty, an empty admin user data will be returned.
//  */
// export async function loadAdminUserByEmail(
//   params: AdminUserLoadByEmailRequest
// ): Promise<AdminUserLoadByEmailResponse> {
//   if (params.email) {
//     const request: ApiRequest = {
//       method: 'POST',
//       endpoint: {
//         path: '/api/tenant/load-admin-user-by-email'
//       },
//       payload: {
//         email: params.email
//       }
//     };
//     const response = await callApi<AdminUserLoadByEmailResponse>(request);
//     return {
//       item: response.item,
//       errors: response.errors
//     };
//   }
//   return {
//     item: adminUserDefaultData,
//     errors: []
//   };
// }

export interface InviteAdminUserRequest {
  email: string
  firstName?: string
  lastName?: string
  tenantId: number
}

export interface InviteAdminUserResponse {
  errors: string[]
}

export function inviteAdminUser(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: InviteAdminUserRequest): Promise<InviteAdminUserResponse> {
    apiRequest.endpoint.path = '/api/auth/invite-admin-user'
    apiRequest.payload = params
    const response = await callApi<InviteAdminUserResponse>(apiRequest);
    return {
      errors: response.errors
    };
  }
}

export interface InviteAccountOwnerRequest {
  email: string
  firstName?: string
  lastName?: string
  tenantId: number
}

export interface InviteAccountOwnerResponse {
  errors: string[]
}

export function inviteAccountOwner(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: InviteAccountOwnerRequest): Promise<InviteAccountOwnerResponse> {
    apiRequest.endpoint.path = '/api/auth/invite-account-owner'
    apiRequest.payload = params
    const response = await callApi<InviteAccountOwnerResponse>(apiRequest);
    return {
      errors: response.errors
    };
  }
}

export interface SearchAdminUsersRequest {
  state?: AdminUserState
  type?: AdminUserType
  types?: AdminUserType[]
  tenantId?: number
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}
export type SearchAdminUsersResponseAdminUser = Omit<AdminUserData, 'tenants'>;
export interface SearchAdminUsersResponse {
  adminUserList: SearchAdminUsersResponseAdminUser[]
  errors: string[]
}
// Currently the API will return all the admin users for this team. We should consider admin user pagination in the future.
export function searchAdminUsers(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: SearchAdminUsersRequest): Promise<SearchAdminUsersResponse> {
    apiRequest.endpoint.path = '/api/tenant/search-admin-users';
    apiRequest.payload = params;
    const response = await callApi<SearchAdminUsersResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserAcceptInviteRequest {
  inviteToken: string
}

export interface AdminUserAcceptInviteResponse {
  accessToken: string
  errors: string[]
}

export function acceptAdminUserInvite(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function(params: AdminUserAcceptInviteRequest): Promise<AdminUserAcceptInviteResponse> {
    apiRequest.endpoint.path = '/api/auth/accept-admin-user-invite'
    apiRequest.payload = params
    const response = await callApi<AdminUserAcceptInviteResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserUpdatePasswordRequest {
  password: string
}

export interface AdminUserUpdatePasswordResponse {
  errors: string[]
}

export function updatePassword(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: AdminUserUpdatePasswordRequest): Promise<AdminUserUpdatePasswordResponse> {
    apiRequest.endpoint.path = '/api/auth/update-admin-user-password'
    apiRequest.payload = params
    const response = await callApi<AdminUserUpdatePasswordResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserSendPasswordResetEmailRequest {
  email: string
}

export interface AdminUserSendPasswordResetEmailResponse {
  errors: string[]
}

export function sendPasswordResetEmail(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function(params: AdminUserSendPasswordResetEmailRequest): Promise<AdminUserSendPasswordResetEmailResponse> {
    apiRequest.endpoint.path = '/api/auth/send-password-reset-email'
    apiRequest.payload = params
    const response = await callApi<AdminUserResetPasswordResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserVerifyPasswordRestRequestRequest {
  passwordResetToken: string
}

export interface AdminUserVerifyPasswordRestRequestResponse {
  email: string
  errors: string[]
}

export function verifyPasswordResetRequest(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function(params: AdminUserVerifyPasswordRestRequestRequest): Promise<AdminUserVerifyPasswordRestRequestResponse> {
    apiRequest.endpoint.path = '/api/auth/verify-password-reset-request'
    apiRequest.payload = params
    const response = await callApi<AdminUserVerifyPasswordRestRequestResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserResetPasswordRequest {
  passwordResetToken: string
  password: string
}

export interface AdminUserResetPasswordResponse {
  accessToken: string
  errors: string[]
}

export function resetPassword(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function(params: AdminUserResetPasswordRequest): Promise<AdminUserResetPasswordResponse> {
    apiRequest.endpoint.path = '/api/auth/reset-admin-user-password'
    apiRequest.payload = params
    const response = await callApi<AdminUserResetPasswordResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface AdminUserUpdateStateRequest {
  adminUserId: number
  state: AdminUserState
}

export interface AdminUserUpdateStateResponse {
  errors: string[]
}

export function updateState(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: AdminUserUpdateStateRequest): Promise<AdminUserUpdateStateResponse> {
    apiRequest.endpoint.path = '/api/tenant/update-admin-user-state'
    apiRequest.payload = params
    const response = await callApi<AdminUserUpdateStateResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}

export interface LoadAccountOwnerRequest {
  tenantId: number
}

export interface LoadAccountOwnerResponse {
  adminUser: AdminUserData
  errors: string[]
}

export function loadAccountOwner(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function(params: LoadAccountOwnerRequest): Promise<LoadAccountOwnerResponse> {
    apiRequest.endpoint.path = '/api/tenant/load-tenant-account-owner'
    apiRequest.payload = params
    const response = await callApi<LoadAccountOwnerResponse>(apiRequest);
    response.errors = response.errors ?? [];
    return response;
  }
}
