import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiStack,
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import SideNav from '@/account/component/Layout/SideNav';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import NewTeam from './NewTeam';
import TeamList from './TeamList';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const Team: FC = () => {
  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow/>)}
        sideMenu={(<SideNav activeNodes={['userAccess', 'team']}/>)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppAccountCard showDescription={false}/>} currentAppName={'account'}/>)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack flexGrow={1} spacing={4} alignItems={'stretch'}>
            <NewTeam/>
            <TeamList/>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Team;
