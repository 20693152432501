import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  // UiBox,
  // UiHStack,
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
  // UiGrid,
} from '@/lib/ui';
import TicketForm from './TicketForm';
import { ticketTypeButtonLabels, type TicketType } from '@/api/constant';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewTicketProps extends UiHStackProps {
  ticketType: TicketType
}

const NewTicket: FC<NewTicketProps> = ({
  ticketType,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      {/* <UiHStack */}
      {/*  // bgColor={'gray.50'} */}
      {/*  borderRadius={'100%'} */}
      {/*  alignItems={'center'} */}
      {/*  justifyContent={'flex-start'} */}
      {/*  // flexGrow={1} */}
      {/*  onClick={onToggle} */}
      {/*  {...uiStyles.hover} */}
      {/*  // width={'64px'} */}
      {/*  // flexGrow={} */}
      {/*  p={0} */}
      {/* > */}
      {/*  <UiIconPlusCircle */}
      {/*    size={'3xl'} */}
      {/*    color={'primary.600'} */}
      {/*    // width={64} */}
      {/*    // weight={'light'} */}
      {/*  /> */}
      {/*  <UiText variant={'body1'} color={'primary.500'}>Add {ticketTypeButtonLabels[ticketType].toLowerCase()}</UiText> */}
      {/* </UiHStack> */}
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add {ticketTypeButtonLabels[ticketType]?.toLowerCase()}</BaseButtonIconText>
      <TicketForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        ticketType={ticketType}
      />
    </>
  );
};

export default NewTicket;
