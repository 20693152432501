import { type FC } from 'react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewFormProps extends UiHStackProps {}

const NewForm: FC<NewFormProps> = () => {
  const { tenantCode, eventId } = useRegisterRoute();

  return (
    <>
      <BaseRouterLink
        to={generatePageUrl('RegistrationBuildEventBuildAdditionalInfoForm', { tenantCode, eventId })}
      >
        <BaseButtonIconText Icon={UiIconPlusCircle}>Add additional information form</BaseButtonIconText>
      </BaseRouterLink>
    </>
  );
};

export default NewForm;
