/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import {
  generatePath,
  createSearchParams
} from 'react-router-dom';
import _ from 'lodash';
import { type AAARouteParams, adminRoutes, type AAARouteName } from '@/aaa/pages';
import { type AccountRouteParams, accountRoutes, type AccountRouteName } from '@/account/pages';
import { type RegistrationRouteParams, registrationRoutes, type RegistrationRouteName, type RegisterRouteParams, type BuildEventRouteParams, buildEventRoutes } from '@/registration/pages';
import { type AbstractRouteParams, abstractRoutes, type AbstractRouteName } from '@/abstract/pages';
import { type ProductWebsiteRouteParams, productWebsiteRoutes, type ProductWebsiteRouteName } from '@/productWebsite/pages';
import { type MobileAppRouteParams, mobileAppRoutes, type MobileAppRouteName } from '@/mobileApp/pages';
import { type PresentationRouteParams, presentationRoutes, type PresentationRouteName } from '@/presentation/pages';
import { type EventWebsiteRouteParams, eventWebsiteRoutes, type EventWebsiteRouteName } from '@/eventWebsite/pages';

// Need to extend the type here so that when reading params back via useParam, we can use proper generic types for each of the pages here.
export interface RouteParams extends AAARouteParams, AccountRouteParams, RegistrationRouteParams, AbstractRouteParams, ProductWebsiteRouteParams, MobileAppRouteParams, PresentationRouteParams, EventWebsiteRouteParams {}

type RouteName = AAARouteName | AccountRouteName | RegistrationRouteName | AbstractRouteName | ProductWebsiteRouteName | MobileAppRouteName | PresentationRouteName | EventWebsiteRouteName;

// Merging routes here will trigger error "Cannot access 'accountRoutes' before initialization" since routeObjects are only accessible inside the page provider.
// const routes = {...accountRoutes, ...registrationRoutes};
// Instead, we will need to merge routes inside a function.
const getRoutePath = (routeName: RouteName): string => {
  if (adminRoutes[routeName as AAARouteName]) {
    return adminRoutes[routeName as AAARouteName].path ?? '';
  }
  if (accountRoutes[routeName as AccountRouteName]) {
    return accountRoutes[routeName as AccountRouteName].path ?? '';
  }
  if (buildEventRoutes[routeName as keyof BuildEventRouteParams]) {
    return `/registration/:tenantCode/build-event/${buildEventRoutes[routeName as keyof BuildEventRouteParams].path}`;
  }
  if (registrationRoutes[routeName as RegistrationRouteName]) {
    return `/registration/:tenantCode/${registrationRoutes[routeName as RegistrationRouteName].path}`;
  }
  if (abstractRoutes[routeName as AbstractRouteName]) {
    return abstractRoutes[routeName as AbstractRouteName].path ?? '';
  }
  if (productWebsiteRoutes[routeName as ProductWebsiteRouteName]) {
    return productWebsiteRoutes[routeName as ProductWebsiteRouteName].path ?? '';
  }
  if (mobileAppRoutes[routeName as MobileAppRouteName]) {
    return mobileAppRoutes[routeName as MobileAppRouteName].path ?? '';
  }
  if (presentationRoutes[routeName as PresentationRouteName]) {
    return presentationRoutes[routeName as PresentationRouteName].path ?? '';
  }
  if (eventWebsiteRoutes[routeName as EventWebsiteRouteName]) {
    return eventWebsiteRoutes[routeName as EventWebsiteRouteName].path ?? '';
  }
  return '';
};

// The generic tyupe PageRouteName here is to avoid a union type of all the params from RouteParams[RouteName].
export function generatePageUrl<PageRouteName extends RouteName>(...args: [
  page: PageRouteName,
  params?: RouteParams[PageRouteName],
  query?: {},
]): string {
  const [page, params = undefined, query = {}] = args;
  const _url = generatePath(getRoutePath(page), params);
  if (_.isEmpty(query)) return _url;

  return `${_url}?${createSearchParams(query).toString()}`;
}
