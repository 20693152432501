import { type FC } from 'react';
import {
  MagnifyingGlass
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconMagnifyingGlassProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconMagnifyingGlass: FC<UiIconMagnifyingGlassProps> = (props) => {
  return (
    <UiIcon
      IconComponent={MagnifyingGlass}
      {...props}
    />
  );
};
