import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import FunctionTicketPageForm from './FunctionTicketPageForm';
import PageRow from './PageRow';

export interface ManageLabelsProps extends UiHStackProps {
}

const FunctionTicketPage: FC<ManageLabelsProps> = ({...props}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <PageRow
        title={'Function tickets page'}
        description={'Manage labels and descriptions on the page where we list the function tickets.'}
        onEdit={onToggle}
        {...props}
      />
      <FunctionTicketPageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default FunctionTicketPage;
