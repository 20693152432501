import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { TenantState, tenantStateOptions } from '@/api/constant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export interface TenantFormProps extends UiHStackProps {
  tenant?: account.TenantData
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const isValidTime = (value: string) => { return dayjs(value, 'HH:mm').isValid(); };
const defaultTimezone = { value: 'Melbourne', label: 'Melbourne' };

const formSchema = Yup.object().shape({
  name: Yup.string()
  .required('Name is required.'),
  code: Yup.string()
  .required('Code is required.')
  .matches(/^[a-z0-9\-]{3,20}$/, 'Code should contain only lowercase letters, numbers and dash (-) with the length between 3 and 20.'),
  state: Yup.number()
  .required('State is required.'),
  apiEndpoint: Yup.string()
  .required('Api endpoint is required.')
  .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Invalid url.'),
});

interface FormData {
  name: string
  code: string
  state: number
  apiEndpoint: string
}

const stateOptions: Option[] = tenantStateOptions;

const defaultFormData: FormData = {
  name: '',
  code: '',
  state: TenantState.Active,
  apiEndpoint: '',
};

const TenantForm: FC<TenantFormProps> = ({
  tenant,
  onClose,
  isVisible
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<account.TenantSaveResponse, Error, account.TenantSaveRequest>({
    mutationFn: async (data: account.TenantSaveRequest) => {
      return await account.saveTenant(createGlobalApiAclRequest())(data);
    },
    onSuccess: async (result) => {
      if (result?.errors && result.errors?.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        // We want to invalidate all the related tenant search keys.
        await queryClient.invalidateQueries({ queryKey: [account.tenantSearchQueryKey], exact: false });
        if (tenant?.id) {
          await queryClient.invalidateQueries({ queryKey: [account.tenantQueryKey, { id: tenant.id }] });
        }
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to save the tenant.']);
    }
  });

  const initValues: FormData = useMemo(
    () => {
      if (tenant) {
        return {
          name: tenant.name,
          code: tenant.code,
          state: tenant.state,
          apiEndpoint: tenant.apiEndpoint,
        };
      }
      return {
        name: '',
        code: '',
        state: TenantState.Active,
        apiEndpoint: '',
      };
    },
    [tenant]
  );

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    const tenantData = {
      id: tenant?.id ?? 0,
      name: values.name,
      code: values.code,
      state: values.state,
      apiEndpoint: values.apiEndpoint,
    };
    mutate({
      tenant: tenantData,
    });
    resetForm({ values: initValues }); // update form init values
    setSubmitting(false);
  }, [mutate]);

  return (
    <Formik<FormData>
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Workspace'}
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'name'}
            label={'Name'}
          />
          <BaseFormInputField
            name={'code'}
            label={'Code'}
            disabled={!!tenant?.id}
            helperText={
              !!tenant?.id
                ? 'Code change is not allowed. Please contact engineers if you have to do it.'
                : 'You will not be able to change the code once the workspace is created. Code is normally the abbreviation of the tenant (association, organisation, corporate, etc). It should contain only lowercase letters and numbers with the length between 3 and 20. e.g. racs'
            }
          />
          <BaseFormSelectField
            name={'state'}
            label={'State'}
            options={tenantStateOptions}
          />
          <BaseFormInputField
            name={'apiEndpoint'}
            label={'Api endpoint'}
            helperText={'Api endpoint must be a valid url starting with http. It is normally the base url of the event website. e.g. https://penpineappleapplepen.evexus.net'}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default TenantForm;
