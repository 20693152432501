import { type FC } from 'react';
import {
  User
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconUserProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconUser: FC<UiIconUserProps> = (props) => {
  return (
    <UiIcon
      IconComponent={User}
      {...props}
    />
  );
};
