/* eslint-disable react/no-unescaped-entities */
import { useCallback, type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import useFields from '@/base/FormBuilder/useFields';
import { type InfoForm, type InfoFormSaveRequest, InfoFormType, initPersonalFieldsLayout, initPersonalFieldsMetadata } from '@/api/registration';
import {
  UiHStack,
  UiIconArrowSquareOut,
  UiIconCaretLeft,
  UiLink,
  UiSpinner,
  UiStack,
  UiText,
  UiVStack,
  uiStyles,
  UiIconX, UiIconEye,
} from '@/lib/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { type Layout } from 'react-grid-layout';
import BaseFormBuilder from '@/base/FormBuilder';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseMessageBarWarning from '@/base/MessageBar/Warning';
import FormPreview from '@/base/FormGenerator/FormPreview';
import BaseFloatingContainerTop from '@/base/FloatingContainer/Top';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const PersonalInfoForm: FC = () => {
  const { tenantCode, eventId } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createTenantAdminApiRequest } = useTenantApi();
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get('id'));
  const infoFormQuery = useQuery<InfoForm, Error>(
    [registration.infoFormQueryKey, { id }],
    async () => {
      const result = await registration.loadInfoForm(createTenantAdminApiRequest)(id);
      return result.item;
    },
    { enabled: !!id, keepPreviousData: false }
  );

  const {
    fieldsLayout,
    fieldsMetadata,
    addField,
    setFieldsLayout,
    deleteField,
    updateFieldMetadata
  } = useFields(
    infoFormQuery?.data?.config.fieldsLayout ?? initPersonalFieldsLayout,
    infoFormQuery?.data?.config?.fieldsMetadata ?? initPersonalFieldsMetadata
  );

  const onFieldAdd = (newFieldsMetadata: FieldMetadata) => {
    let layout: Layout = { x: 0, y: Infinity, w: 1, h: 1, maxH: 1, i: newFieldsMetadata.id };
    if (newFieldsMetadata.type === 'sectionBreak') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    if (newFieldsMetadata.type === 'descriptionBlock') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    addField(layout, newFieldsMetadata);
  };

  const { mutate } = useMutation<InfoForm, Error, InfoFormSaveRequest>({
    mutationFn: async (data: InfoFormSaveRequest) => {
      const response = await registration.saveInfoForm(createTenantAdminApiRequest)(data);
      return response.item;
    },
    onSuccess: (result: any) => {
      void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.PERSONAL }] });
      void (!!id && queryClient.invalidateQueries({ queryKey: [registration.infoFormQueryKey, { id }] }));
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee group.']);
    }
  });

  const onSaveAndGoBack = useCallback(() => {
    mutate({
      infoForm: {
        ...(id ? { id } : {}),
        formType: InfoFormType.PERSONAL,
        eventId,
        config: JSON.stringify({
          totalFields: fieldsLayout.length,
          fieldsLayout,
          fieldsMetadata
        })
      }
    });
  }, [eventId, fieldsLayout, fieldsMetadata, id, mutate]);

  return (
    <UiStack
      // width={1200}
      bgColor={'gray.100'}
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      minHeight={'100vh'}
      flexGrow={1}
      pb={12}
    >
      <BaseFloatingContainerTop heightOffset={1}>
        <UiStack
          spacing={0}
          // borderRadius={uiStyles.bodyRadius}
          bgColor={'#fff'}
        >
          <UiHStack
            px={8}
            py={6}
            alignItems={'stretch'}
            justifyContent={'space-between'}
          >
            <BaseRouterLink to={generatePageUrl('RegistrationBuildEventPersonalInfoForm', { tenantCode, eventId })}>
              <UiHStack {...uiStyles.hover} onClick={onSaveAndGoBack}>
                <UiIconCaretLeft color={'primary.500'}/>
                <UiText variant={'body1'} color={'primary.500'}>{eventQuery.isLoading ? 'Go back' : 'Save & go back'}</UiText>
              </UiHStack>
            </BaseRouterLink>
            <BaseRouterLink to={generatePageUrl('RegistrationBuildEventPersonalInfoForm', { tenantCode, eventId })}>
              <UiHStack {...uiStyles.hover}>
                <UiIconX color={'red.500'} size={'2xl'}/>
              </UiHStack>
            </BaseRouterLink>
          </UiHStack>
        </UiStack>
      </BaseFloatingContainerTop>
      <UiStack
        spacing={4}
        flexGrow={1}
        alignItems={'center'}
        pt={32}
      >
        <UiHStack {...uiStyles.hover} onClick={onOpen} width={1080} justifyContent={'flex-end'}>
          <UiIconEye color={'primary.500'}/>
          <UiText variant={'body1'} color={'primary.500'}>Preview</UiText>
        </UiHStack>
        <UiVStack>
          <BaseMessageBarInfo borderRadius={uiStyles.borderRadius} bgColor={'red.100'} width={1080}>
            Do not include the 'Email' field in the form. We will always collect the attendee's email at the beginning of the registration.
          </BaseMessageBarInfo>
          <BaseMessageBarWarning borderRadius={uiStyles.borderRadius} bgColor={'yellow.100'} width={1080}>
            To change the order of form fields, drag and drop a field. To resize the form field (from half-page width to full-page width, or vice versa), click and drag the bottom-right corner of the form field.
          </BaseMessageBarWarning>
        </UiVStack>
        {
          infoFormQuery.isFetching && id ? (
            <UiHStack p={8} justifyContent={'flex-start'}>
              <BaseLoadingSpinner/>
            </UiHStack>
          ) : (
            <UiStack
              width={1080}
              alignItems={'stretch'}
            >
              <BaseFormBuilder
                onLayoutChange={setFieldsLayout}
                onFieldUpdate={updateFieldMetadata}
                fieldsLayout={fieldsLayout}
                fieldsMetadata={fieldsMetadata}
                onFieldAdd={onFieldAdd}
                onFieldDelete={deleteField}
              />
              <FullScreen isOpen={isOpen} onClose={onClose}>
                <FormPreview fieldsLayout={fieldsLayout} fieldsMetadata={fieldsMetadata} />
              </FullScreen>
            </UiStack>
          )
        }
      </UiStack>
    </UiStack>
  );
};

export default PersonalInfoForm;
