import { type FC } from 'react';
import {
  UiIconWarningDiamond
} from '@/lib/ui';
import Base, { type BaseProps } from './Base';

export type MessageBarWarningProps = Omit<BaseProps, 'icon'>;

export const BaseMessageBarWarning: FC<MessageBarWarningProps> = (props) => {
  return (
    <Base
      textProps={{
        variant: 'body1'
      }}
      icon={<UiIconWarningDiamond size={'3xl'} color={'text.primary'} />}
      bgColor={'#FFCC00'}
      {...props}
    />
  );
};

export default BaseMessageBarWarning;
