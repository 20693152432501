import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { UiButton, UiHStack, UiIconPlusCircle, UiStack, uiStyles, UiTag, UiText } from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { account } from '@/api';
import RevokeConfirmation from './RevokeConfirmation';

dayjs.extend(utc);

export interface RevokeProps {
  adminUser: account.SearchAdminUsersResponseAdminUser,
}

const Revoke: FC<RevokeProps> = ({
  adminUser,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiButton variant={'ghost'} py={0} onClick={onToggle}>Revoke</UiButton>
      <RevokeConfirmation
        isOpen={isOpen}
        onClose={onToggle}
        adminUser={adminUser}
      />
    </>
  );
};

export default Revoke;
