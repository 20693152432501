import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';

interface RouteParams extends Record<string, string | undefined> {
  tenantCode: string
  eventId: string | undefined
}

export function useRegisterRoute() {
  const params = useParams<RouteParams>();
  const eventId = useMemo<string>(
    () => params.eventId ?? '0',
    [params.eventId]
  );
  const tenantCode = params.tenantCode ?? '';
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, error } = useQuery<account.TenantLoadByCodeResponse, Error>(
    [account.tenantQueryKey],
    async () => {
      return await account.loadTenantByCode(createGlobalApiRequest)({ code: tenantCode });
    },
    {
      enabled: !!tenantCode,
      cacheTime: Infinity
    }
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  return {
    ...params,
    tenantCode: params.tenantCode ?? 'dev',
    eventId,
    tenant: data?.tenant,
    isTenantLoading: isLoading,
  };
}
