import { type FC, type PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';

export interface ProviderGlobalMessagesProps extends PropsWithChildren {
  // config: AppGlobalMessage
}

export interface GlobalMessage {
  body: string
  type: string
}

// type GlobalMessages = string[];

interface GlobalMessageContextValue {
  globalMessages: GlobalMessage[]
  setGlobalMessages: (globalMessages: GlobalMessage[]) => void
}

const GlobalMessageContext = createContext<GlobalMessageContextValue>({
  globalMessages: [],
  setGlobalMessages: (globalMessages: GlobalMessage[]) => {},
});

export const ProviderGlobalMessages: FC<ProviderGlobalMessagesProps> = ({
  ...props
}) => {
  const [globalMessages, setGlobalMessages] = useState<GlobalMessage[]>([]);

  return (
    <GlobalMessageContext.Provider
      value={{
        globalMessages,
        setGlobalMessages
      }}
      {...props}
    />
  );
};

export function useGlobalMessages() {
  const { globalMessages, setGlobalMessages } = useContext(GlobalMessageContext) as Required<GlobalMessageContextValue>;

  const pushMessages = (errors: GlobalMessage[]) => {
    setGlobalMessages([...globalMessages, ...errors]);
  }

  const popMessages = (): GlobalMessage[] => {
    const errors = globalMessages;
    setGlobalMessages([]);
    return errors;
  }

  return {
    globalMessages,
    pushMessages,
    popMessages,
  };
}
