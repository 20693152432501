import {
  type RouteObject
} from 'react-router-dom';
import AdminAccess from '@/account/component/AdminAccess';
import PresentationGeneralLandingPage from '@/presentation/general/Landing';

// All the route names should start with Presentation to avoid conflicts with other modules (e.g. registration).
export interface PresentationRouteParams {
  PresentationGeneralLanding: {
    tenantCode: string
  }
}

export type PresentationRouteName = keyof PresentationRouteParams;

export const presentationRoutes: Record<PresentationRouteName, RouteObject> = {
  PresentationGeneralLanding: {
    path: '/presentation-management/:tenantCode/general/landing',
    element: (<AdminAccess><PresentationGeneralLandingPage /></AdminAccess>)
  },
};
