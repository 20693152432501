import { type FC } from 'react';
import {
  ClosedCaptioning
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconClosedCaptioningProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconClosedCaptioning: FC<UiIconClosedCaptioningProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ClosedCaptioning}
      {...props}
    />
  );
};
