import { type FC, type ElementType } from 'react';
import {
  uiStyles,
  UiButton,
  UiButtonProps, UiStack, UiSpinner,
} from '@/lib/ui';
import { UiTooltip } from '@/lib/ui/Tooltip';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface PlainIconProps extends UiButtonProps {
  label: string
  Icon: ElementType
  color?: UiButtonProps['color']
  isLoading?: boolean
}

const PlainIcon: FC<PlainIconProps> = ({
  label,
  Icon,
  color = 'primary.500',
  isLoading = false,
  ...props
}) => {

  if (isLoading) {
    return (
      <BaseLoadingSpinner/>
    );
  }
  return (
    <UiTooltip label={label} borderRadius={uiStyles.borderRadius} py={1} px={3}>
      <UiButton
        variant={'ghost'}
        {...uiStyles.hover}
        {...props}
      >
        <Icon size={'2xl'} color={color}/>
      </UiButton>
    </UiTooltip>
  );
};

export default PlainIcon;
