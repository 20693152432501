import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useCallback } from 'react';
import { AdminUserType } from '@/api/constant/adminUser';

type AdminUser = account.AdminUserData | account.SearchAdminUsersResponseAdminUser | account.AdminUserSessionUser;

export function useAdminUserTypeCheck(tenantId: number) {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const {data, error, isLoading} = useQuery<account.LoadAccountOwnerResponse, Error>(
    [account.adminUserAccountOwnerQueryKey, {tenantId,}],
    async () => {
      return await account.loadAccountOwner(createGlobalApiAclRequest())({
        tenantId,
      });
    },
  );

  const isAccountOwner = useCallback(
    (adminUser: AdminUser): boolean => {
      if (error) {
        return false;
      }
      return adminUser.id === data?.adminUser?.id;
    },
    [data]
  );

  const isAAAUser = (adminUser: AdminUser) => {
    return adminUser.type === AdminUserType.AAA;
  }

  return {
    isAccountOwner,
    isAAAUser,
    isLoading,
    error,
  };
}
