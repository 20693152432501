import { type FC, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ApiResponse } from '@/api/tenantClient';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseFormSelectField from '@/base/Form/SelectField';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  displayCapacities: boolean
  displayRemainingTime: boolean
}

const formSchema = Yup.object().shape({
  displayCapacities: Yup.boolean(),
  displayRemainingTime: Yup.boolean()
});

const TicketDisplayForm: FC<LabelsFormProps> = ({
  onClose,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the ticket display settings.']);
    }
  });

  const submitForm = async (values: FormData) => {
    await mutateEventSettings({
      eventId,
      config: {
        ticketDisplay: {
          ...eventSettingQuery.data!.ticketDisplay,
          displayCapacities: values.displayCapacities,
          displayRemainingTime: values.displayRemainingTime
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => {

        return (
          <Formik
            initialValues={{
              displayCapacities: eventSetting.ticketDisplay?.displayCapacities ?? true,
              displayRemainingTime: eventSetting.ticketDisplay?.displayRemainingTime ?? true
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={async (
              values: FormData,
              { setSubmitting }: FormikHelpers<FormData>
            ) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
            }}
          >
            <BaseFormDrawer
              isOpen={isVisible}
              onClose={onClose}
              title={'Ticket display settings'}
              size={'xl'}
              isLoading={isLoading}
            >
              {saveErrors.length > 0 && (
                <UiStack spacing={4} flexGrow={1} py={4}>
                  {saveErrors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <BaseFormSelectField
                name={'displayCapacities'}
                label={'Display capacities?'}
                isMultiple={false}
                helperText={'This controls whether the attendee can see the remaining capacity for a given ticket on the ticket details page.'}
                options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
              />
              <BaseFormSelectField
                name={'displayRemainingTime'}
                label={'Display remaining time?'}
                isMultiple={false}
                helperText={'This controls whether the attendee can see the remaining time that the ticket is available on the ticket cards.'}
                options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
              />
            </BaseFormDrawer>
          </Formik>
        );
      }}
    </QueryContainer>

  );
};

export default TicketDisplayForm;
