/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  UiButton,
  UiIconWarningCircle,
  UiSpinner,
  UiStack, UiText,
} from '@/lib/ui';
import PasswordResetForm from './PasswordResetForm';
import { account } from '@/api';
import BaseMessageBarStandard from '@/base/MessageBar/Standard';
import { generatePageUrl } from '@/app/pages';
import { useMutation } from '@tanstack/react-query';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface PasswordResetProps {
}

const PasswordReset: FC<PasswordResetProps> = ({}) => {
  const [email, setEmail] = useState('');
  const [searchParams] = useSearchParams();
  const passwordResetToken = searchParams.get('t');
  const navigate = useNavigate();
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const [errors, setErrors] = useState<string[]>([]);

  const toLogin = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginLoginEmail'));
    },
    [navigate]
  );

  const { mutate, isLoading } = useMutation<account.AdminUserVerifyPasswordRestRequestResponse, Error, account.AdminUserVerifyPasswordRestRequestRequest>({
    mutationFn: async (data: account.AdminUserVerifyPasswordRestRequestRequest) => {
      // console.log('----accept request: ', data);
      return await account.verifyPasswordResetRequest(createGlobalApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setEmail(result.email);
        setErrors([]);
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to invite the user.']);
    }
  });

  const verifyPasswordResetRequest = useCallback(
    () => {
      if (passwordResetToken) {
        mutate({
          passwordResetToken,
        });
      } else {
        setErrors(['Token invalid']);
      }
    },
    [passwordResetToken]
  );

  useEffect(() => {
    if (passwordResetToken) {
      verifyPasswordResetRequest();
    }
  }, [passwordResetToken]);

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (errors.length > 0) {
    return (
      <UiStack p={8}>
        <BaseMessageBarStandard
          icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
          text={(
            <UiText color={'red.500'}>
              {errors[0]}
            </UiText>
          )}
          button={(<UiButton onClick={toLogin} variant={'ghost'}>Back to login</UiButton>)}
        />
      </UiStack>
    );
  }

  return (
    <>
      <PasswordResetForm
        passwordResetToken={passwordResetToken ?? ''}
        email={email}
        // passwordResetToken={passwordResetToken ?? ''}
        onResetDone={toLogin}
      />
    </>
  );
};

export default PasswordReset;
