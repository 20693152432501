/* eslint-disable react/no-unescaped-entities */
import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  type UiBoxProps,
  UiStack,
  UiSpinner, UiButton, UiText,
} from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { account } from '@/api';
import {
  SubscriptionState,
} from '@/api/constant/subscription';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import ManageNextSubscriptionTerm from './ManageNextSubscriptionTerm';
import ManageSubscription from './ManageSubscription';
import PlanInformation from '@/account/component/Subscription/PlanInformation';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface AppListProps extends UiBoxProps {
}

const PlanSummary: FC<AppListProps> = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { data, isLoading } = useQuery(
    [account.subscriptionQueryKey, {}],
    async () => {
      return await account.loadSubscription(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (!data?.subscription) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load your current subscription.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <UiStack spacing={0}>
      {data.subscription.plan.state === SubscriptionState.Active && (
        <>
          <UiStack spacing={0} enableDivider={true}>
            <ManageNextSubscriptionTerm/>
          </UiStack>
          <BaseDividerHorizontal height={8} />
        </>
      )}
      {data.subscription.plan.state !== SubscriptionState.Active && (
        <>
          <UiStack spacing={0} enableDivider={true}>
            <ManageSubscription/>
          </UiStack>
          <BaseDividerHorizontal height={8} />
        </>
      )}
      <UiStack spacing={4}>
        <UiText variant={'title'}>Current plan</UiText>
        <PlanInformation subscription={data.subscription}/>
      </UiStack>
    </UiStack>
  );
};

export default PlanSummary;
