import { UiButton, UiHStack, UiInput, UiStack } from '@/lib/ui';
import {
  type BaseSyntheticEvent,
  useEffect,
  useRef,
  useState,
  type KeyboardEvent,
  type ChangeEvent,
  type FC,
  useCallback
} from 'react';
import { useTimer } from 'use-timer';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface PasscodeInputProps {
  isVerifying?: boolean
  onSendPasscode: () => void
  onVerifyPasscode: (code: number) => void
  // errors: string[]
  // setErrors: (errors: string[]) => void
}

const PasscodeInput: FC<PasscodeInputProps> = ({
  isVerifying,
  onSendPasscode,
  onVerifyPasscode,
  // errors,
  // setErrors,
}) => {
  const [arrayValue, setArrayValue] = useState<Array<number | ''>>(['', '', '', '', '', '']);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(0);
  const inputRefs = useRef<HTMLInputElement[] | []>([]);
  const [ submittedCode, setSubmittedCode ] = useState(0);

  const { time, start, status } = useTimer({
    initialTime: 30,
    endTime: 0,
    timerType: 'DECREMENTAL'
  });

  const onKeyDown = (e: KeyboardEvent, index: number) => {
    const keyCode = parseInt(e.key);
    if (keyCode >= 0 && keyCode <= 9) {
      setArrayValue((preValue: Array<number | ''>) => {
        const newArray = [...preValue];
        newArray[index] = keyCode;
        return newArray;
      });
    } else if (e.key === 'Backspace') {
      setArrayValue((preValue: Array<number | ''>) => {
        const newArray = [...preValue];
        newArray[index] = '';
        return newArray;
      });
    } else if (!(e.metaKey && e.key === 'v')) {
      e.preventDefault();
    }
  };

  const handleRequestPasscode = useCallback(async () => {
    start();
    setArrayValue(['', '', '', '', '', '']);
    onSendPasscode();
  }, [onSendPasscode, start]);

  const onSubmit = useCallback(() => {
    const code = parseInt(arrayValue.join(''), 10);
    if (code !== submittedCode) {
      onVerifyPasscode(code);
      setSubmittedCode(code);
    }
  }, [arrayValue, onVerifyPasscode, submittedCode]);

  useEffect(() => {
    start();
    onSendPasscode();
  }, []);

  useEffect(() => {
    if (arrayValue.filter(value => value !== '').length === 6) {
      onSubmit();
    }
  }, [arrayValue, onSubmit]);

  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setArrayValue((preValue: Array<number | ''>) => {
      const newArray = [...preValue];
      newArray[index] = e.target.value !== '' ? parseInt(e.target.value) : '';
      return newArray;
    });
  };

  const onKeyUp = (e: KeyboardEvent, index: number) => {
    if (e.key === 'Backspace') {
      if (index === 0) {
        setCurrentFocusedIndex(0);
      } else {
        setCurrentFocusedIndex(index - 1);
        if (inputRefs?.current && index === currentFocusedIndex) {
          inputRefs.current[index - 1].focus();
        }
      }
    } else {
      if (e.key !== '' && index < arrayValue.length - 1) {
        setCurrentFocusedIndex(index + 1);
        if (inputRefs?.current && index === currentFocusedIndex) {
          inputRefs.current[index + 1].focus();
        }
      }
    }
  };

  const onFocus = (e: BaseSyntheticEvent, index: number) => {
    setCurrentFocusedIndex(index);
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    const pasteFunction = async () => {
      try {
        const pastePermission = await navigator.permissions.query({
          name: 'clipboard-read' as PermissionName
        });

        if (pastePermission.state === 'denied') {
          throw new Error('Not allowed to read clipboard');
        }

        const clipboardContent = await navigator.clipboard.readText();
        const newArray = clipboardContent.split('')
        .map((item) => isNaN(Number(item)) ? '' : Number(item)) as Array<number | ''>;

        const lastIndex = arrayValue.length - 1;
        if (currentFocusedIndex > 0) {
          const remainingPlaces = lastIndex - currentFocusedIndex;
          const partialArray = newArray.slice(0, remainingPlaces + 1);
          setArrayValue([
            ...arrayValue.slice(0, currentFocusedIndex),
            ...partialArray
          ].slice(0, 6));
        } else {
          setArrayValue([
            ...newArray,
            ...arrayValue.slice(newArray.length - 1, lastIndex)
          ].slice(0, 6));
        }

        if (newArray.length < arrayValue.length && currentFocusedIndex === 0) {
          setCurrentFocusedIndex(newArray.length - 1);
          inputRefs.current[newArray.length - 1].focus();
        } else {
          setCurrentFocusedIndex(arrayValue.length - 1);
          inputRefs.current[arrayValue.length - 1].focus();
        }
      } catch (err) {
        // setErrors([(err as Error).message]);
      }
    };
    document.addEventListener('paste', pasteFunction);

    return () => {
      document.removeEventListener('paste', pasteFunction);
    };
  }, [arrayValue, currentFocusedIndex]);

  // const renderErrors = () => {
  //   return errors.map((error, index) => (
  //     <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
  //   ));
  // };

  return (
      <UiHStack alignItems={'center'} justifyContent={'space-between'}>
        <UiStack alignItems={'stretch'}>
          <UiHStack>
            {arrayValue.map((value: number | '', index: number) => (
              <UiInput
                key={index}
                textAlign="center"
                width={12}
                height={12}
                ref={(el) => el && (inputRefs.current[index] = el)}
                maxLength={1}
                value={String(value)}
                inputMode="numeric"
                type="text"
                onChange={(e) => onChange(e, index)}
                onKeyDown={(e) => onKeyDown(e, index)}
                onKeyUp={(e) => onKeyUp(e, index)}
                onFocus={(e) => onFocus(e, index)}
              />
            ))}
          </UiHStack>
        </UiStack>
        <UiHStack>
          {isVerifying ? (
            <BaseLoadingSpinner/>
          ) : (
            <UiButton
              // px={8}
              size={'lg'}
              variant={'ghost'}
              // shadow={'base'}
              colorScheme={'primary'}
              onClick={handleRequestPasscode}
              isDisabled={status === 'RUNNING'}
            >
              Send passcode again {status !== 'STOPPED' ? `(${time}s)` : ''}
            </UiButton>
          )}
        </UiHStack>
      </UiHStack>
  );
};

export default PasscodeInput;
