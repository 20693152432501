export enum PermissionDomain {
  Account = 'Account',
  Registration = 'Registration',
  EventWebsite = 'EventWebsite',
  DigitalPoster = 'DigitalPoster',
  LiveStream = 'LiveStream',
  SponsorExhibitor = 'SponsorExhibitor',
  Webinar = 'Webinar',
  AbstractManagement = 'AbstractManagement',
  EventApp = 'EventApp',
  Integration = 'Integration',
  ContentHub = 'ContentHub',
}

export enum PermissionAction {
  Read = 'Read',
  Write = 'Write',
  BillAdmin = 'BillAdmin',
  BillRead = 'BillRead',
  AdminUserAdmin = 'AdminUserAdmin',
  AdminUserRead = 'AdminUserRead',
  Any = '*',
  DEV_NOOneHasThisAction = '---NoOneHasThisAction---', // This is purely for developing purposes.
}
