import { type FC } from 'react';
import {
  Invoice
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconInvoiceProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconInvoice: FC<UiIconInvoiceProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Invoice}
      {...props}
    />
  );
};
