import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconScreencast } from '@/lib/ui';

export interface LiveStreamingCardProps {
  isSelected?: boolean
  isEnabled?: boolean
  navLink?: string
}

const LiveStreamingCard: FC<LiveStreamingCardProps> = ({
  isSelected = false,
  navLink = '',
  isEnabled = false
}) => {
  return (
    <AppCard
      icon={(<UiIconScreencast size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'} />)}
      name={'Live streaming'}
      description={'Online event access and live engagement for remote attendees'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default LiveStreamingCard;
