import { type FC, useState, useCallback, useMemo } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { ApiError } from '@/api/error';
import { TenantState } from '@/api/constant';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

export interface AccountOwnerInviteFormProps extends UiHStackProps {
  tenant: account.TenantData
  adminUser?: account.AdminUserData
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const formSchema = Yup.object().shape({
  email: Yup.string()
  .required('Email is required.'),
  firstName: Yup.string()
  .required('First name is required.'),
  lastName: Yup.string()
  .required('Last name is required.'),
});

interface FormData {
  email: string
  firstName: string
  lastName: string
}

const defaultFormData: FormData = {
  email: '',
  firstName: '',
  lastName: '',
};

const AccountOwnerInviteForm: FC<AccountOwnerInviteFormProps> = ({
  tenant,
  onClose,
  isVisible,
  adminUser = undefined,
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation<account.InviteAccountOwnerResponse, Error, account.InviteAccountOwnerRequest>({
    mutationFn: async (data: account.InviteAccountOwnerRequest) => {
      return await account.inviteAccountOwner(createGlobalApiAclRequest())(data);
    },
    onSuccess: async (result) => {
      if (result?.errors && result.errors?.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        // We want to invalidate all the related tenant search keys.
        await queryClient.invalidateQueries({ queryKey: [account.adminUserAccountOwnerQueryKey, { tenantId: tenant.id, }] });
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to invite the account owner.']);
    }
  });

  const initValues: FormData = useMemo(
    () => {
      if (adminUser) {
        return {
          email: adminUser.email,
          firstName: adminUser.firstName,
          lastName: adminUser.lastName,
        };
      }
      return defaultFormData;
    },
    [adminUser]
  );

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    try {
      await mutateAsync({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        tenantId: tenant.id,
      });
      resetForm({ values: initValues }); // update form init values
    } catch (e) {
      reportToGlobal(e as ApiError);
      setSaveErrors([(e as Error).message ?? 'Failed to invite the account owner.']);
    }
    setSubmitting(false);
  }, [mutateAsync]);

  return (
    <Formik<FormData>
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Invite account owner'}
        size={'xl'}
        buttonText={'Invite'}
        buttonLoadingText={'Inviting...'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormInputField
            name={'email'}
            label={'Email'}
          />
          <BaseFormInputField
            name={'firstName'}
            label={'First name'}
          />
          <BaseFormInputField
            name={'lastName'}
            label={'Last name'}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default AccountOwnerInviteForm;
