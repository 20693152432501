import { type FC } from 'react';
import {
  FileArrowUp
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconFileArrowUpProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconFileArrowUp: FC<UiIconFileArrowUpProps> = (props) => {
  return (
    <UiIcon
      IconComponent={FileArrowUp}
      {...props}
    />
  );
};
