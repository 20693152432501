import { getAppConfig } from '@/app/config';
import { ApiErrorCode, createApiError } from '@/api/error';
import bugsnag from '@/bugsnag';

const appConfigApiHost = getAppConfig().api.tenantHost;

type BaseIncludedType = Array<{
  attributes: Record<string, any>
  id: string
  type: string
}>;

type IncludedType = Record<string, Record<string, any>>;

export interface RequestEndpoint {
  host?: string
  path: string
  query?: Record<string, any>
}

// @todo remove locale=au as tmp fix for API calls.
export function getRequestUrl(requestEndpoint: RequestEndpoint): string {
  let _queryString = '';
  if (requestEndpoint.query) {
    _queryString = '?' + new URLSearchParams(requestEndpoint.query).toString();
  }
  const _host = requestEndpoint.host ?? appConfigApiHost;
  return `${_host}${requestEndpoint.path}${_queryString}`;
}

export interface ApiRequest {
  // url: string;
  endpoint: RequestEndpoint
  payload?: {}
  headers?: HeadersInit
  method?: string
  // transformData?: <Data>(data: object) => Data|Error;
}

const defaultFetchOptions: RequestInit = {
  method: 'POST',
  mode: 'cors',
  // credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'X-Key-Inflection': 'camel',
  },
  // body: ''
};

export type DatatableSelectOptions = Array<{ value: string, label: string }>;

export interface ApiResponse<T> {
  items: T[]
  errors?: string[]
  pagination?: {
    page: number
    total: number
    totalPages: number
  }
  selectOptions?: Record<string, DatatableSelectOptions>
  infoFormQuestions?: {
    personal: string[]
    additional: string[]
  }
}

export interface ApiResponseSingle<T> {
  item: T
  errors?: string[]
}

export async function callApi<ApiData>(
  request: ApiRequest
): Promise<ApiResponse<ApiData>> {
  // Merge all the custom headers.
  const options = {
    ...defaultFetchOptions,
    ...(request.method ? { method: request.method } : {}),
    headers: {
      ...defaultFetchOptions.headers,
      ...(request.headers ?? {}),
    },
    ...(request.payload ? { body: JSON.stringify(request.payload) } : {}),
  };
  // Leave a breadcrumb for the API call
  bugsnag.leaveBreadcrumb('API Call', {
    requestEndpoint: request.endpoint,
    Headers: request.headers,
    method: request.method,
    payload: request.payload,
  });
  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 201) {
    // Received the successful response from the backend.
    const jsonData = await fetchResponse.json();

    // Transform the data
    /**
     * @notice This is NOT the best way to transform backend API data.
     *  We should NOT limit how the data should look like. Not all queries are about CRUD though.
     *  We are not writing Rails here.
     *  Mapping what's returned from the backend to the object in js should happen in each of the API calls (functions under src/api).
     *  If we have a lot APIs that return common data structure. We should create transform methods and again call them in each of the api functions.
     *  This is shared by all the API calls (CRUD or any other business logic). So it should ONLY convert the response to json and that's it.
     */
    if (jsonData?.items?.length > 1 && jsonData?.items[0]?.included) {
      const included: IncludedType = {}
      ;(jsonData.items[0].included as BaseIncludedType).forEach((item) => {
        if (!included[item.type]) included[item.type] = {};
        included[item.type][item.id] = { ...item.attributes, id: item.id };
      });
      jsonData.items[0].included = included;
    }
    return jsonData as ApiResponse<ApiData>;
  } else {
    if (fetchResponse.status === 401) {
      throw new Error('Access denied.');
    } else {
      throw new Error('Failed API call.');
    }
  }
}

/**
 * @notice This is not a good design.
 *  All API calls should use callApi. Since all the APIs are rest calls (json payload request / json payload response).
 *  We are not writing Rails here.
 *  Please do not use this. Talk to Derek to see how to do this properly.
 */
export async function deleteAPI(request: ApiRequest) {
  // Merge all the custom headers.
  const options = {
    ...defaultFetchOptions,
    ...(request.method ? { method: request.method } : {}),
    headers: {
      ...defaultFetchOptions.headers,
      ...(request.headers ?? {}),
    },
    ...(request.payload ? { body: JSON.stringify(request.payload) } : {}),
  };
  // Leave a breadcrumb for the API call
  bugsnag.leaveBreadcrumb('API Call', {
    requestEndpoint: request.endpoint,
    Headers: request.headers,
    method: request.method,
    payload: request.payload,
  });
  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 204) {
    return undefined;
  } else {
    if (fetchResponse.status === 401) {
      throw new Error('Access denied.');
    } else {
      throw new Error('Failed API call.');
    }
  }
}

export async function properCallApi<ApiData>(
  request: ApiRequest
): Promise<ApiData> {
  // Merge all the custom headers.
  const options = {
    ...(request.method ? { method: request.method } : {}),
    headers: {
      ...defaultFetchOptions.headers,
      ...(request.headers ?? {}),
    },
    ...(request.payload ? { body: JSON.stringify(request.payload) } : {}),
  };
  // Leave a breadcrumb for the API call
  bugsnag.leaveBreadcrumb('API Call', {
    requestEndpoint: request.endpoint,
    Headers: request.headers,
    method: request.method,
    payload: request.payload,
  });
  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 201) {
    // Recieved the successful reponse from the backend.
    const jsonData = await fetchResponse.json();
    return jsonData as ApiData;
  } else {
    // Handle errors.
    switch (fetchResponse.status) {
      case 400: {
        throw createApiError('Bad request.', ApiErrorCode.BadRequest);
      }
      case 401: {
        throw createApiError('Login required.', ApiErrorCode.Unauthorized);
      }
      case 403: {
        throw createApiError('Access denied.', ApiErrorCode.AccessDenied);
      }
      case 404: {
        throw createApiError('Api not found.', ApiErrorCode.PageNotFound);
      }
      default: {
        throw createApiError('API call failed.', ApiErrorCode.ServerError);
      }
    }
  }
}

export async function formDataApi<ApiData>(
  request: ApiRequest,
  formData: FormData
): Promise<ApiData> {
  const options = {
    ...(request.method ? { method: request.method } : {}),
    headers: {
      ...(request.headers ?? {}),
      'X-Key-Inflection': 'camel',
      // 'Content-Type': 'multipart/form-data',
    },
    body: formData,
  };
  // Leave a breadcrumb for the API call
  bugsnag.leaveBreadcrumb('API Call', {
    requestEndpoint: request.endpoint,
    Headers: request.headers,
    method: request.method,
    payload: request.payload,
  });

  const fetchResponse = await fetch(getRequestUrl(request.endpoint), options);

  if (fetchResponse.status === 200 || fetchResponse.status === 201) {
    const jsonData = await fetchResponse.json();
    return jsonData as ApiData;
  } else {
    if (fetchResponse.status === 401) {
      throw new Error('Access denied.');
    } else {
      throw new Error('Failed API call.');
    }
  }
}
