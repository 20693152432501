import { type Dispatch, type ReactNode, type SetStateAction, useCallback, useState } from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ListItem from './ListItem';

export interface Item {
  id: number | string
}

export interface ReorderListProps<T> {
  data: T[]
  onChange: Dispatch<SetStateAction<T[]>>
  children: (props: { item: T }) => ReactNode
}

function ReorderList<T extends Item>({
  data,
  children,
  onChange
}: ReorderListProps<T>): ReactNode {
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    onChange((prevCards: T[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {data.map((item, index) => (
        <ListItem
          key={item.id}
          id={item.id}
          index={index}
          moveCard={moveCard}
        >
          {children({ item })}
        </ListItem>
      ))}
    </DndProvider>
  );
}

export default ReorderList;
