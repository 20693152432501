import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { UiHStack, UiIconPlusCircle, uiStyles, UiText } from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { account } from '@/api';
import { AdminUserType } from '@/api/constant/adminUser';
import TeamMember from '@/account/access/TeamInfo/TeamMember';
import AddAdminUsersForm from '@/account/access/TeamInfo/AddAdminUsersForm';
import BaseBlockList from '@/base/BlockList';

dayjs.extend(utc);

export interface TeamMemberListProps {
  team: account.LoadTeamResponseTeam
  // members: account.LoadTeamResponseUser[]
}

const TeamMemberList: FC<TeamMemberListProps> = ({
  team,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack spacing={4}>
        <UiText variant={'title'}>Users</UiText>
        <UiHStack spacing={1} {...uiStyles.hover} onClick={onToggle}>
          <UiIconPlusCircle color={'primary.500'}/>
          <UiText color={'primary.500'} variant={'body1'}>Add to this team</UiText>
        </UiHStack>
      </UiHStack>
      {team.adminUsers && Array.isArray(team.adminUsers) && team.adminUsers.length > 0 && (
        <BaseBlockList>
          {team.adminUsers.map((member) => {
            if (member.type !== AdminUserType.AAA) {
              return (
                <TeamMember member={member} teamId={team.id} key={member.id}/>
              );
            }
            // return (
            //   <UiHStack key={member.id} p={8} py={4} justifyContent={'space-between'}>
            //     <UiStack>
            //       <UiText>{member.email}</UiText>
            //     </UiStack>
            //     <UiStack>
            //       <UiButton variant={'ghost'} py={0}>Remove</UiButton>
            //     </UiStack>
            //     <UiHStack minW={360} justifyContent={'space-between'}>
            //       {member.state === AdminUserState.Invited && (
            //         <>
            //           <UiTag colorScheme={'yellow'}>Invited</UiTag>
            //           <UiButton variant={'ghost'} py={0}>Resend invite</UiButton>
            //         </>
            //       )}
            //       {member.state === AdminUserState.Active && (
            //         <>
            //           <UiTag colorScheme={'green'}>Active</UiTag>
            //           <UiButton variant={'ghost'} py={0}>Revoke</UiButton>
            //         </>
            //       )}
            //       {member.state === AdminUserState.Suspended && (
            //         <>
            //           <UiTag colorScheme={'red'}>Suspended</UiTag>
            //           <UiButton variant={'ghost'} py={0}>Invoke</UiButton>
            //         </>
            //       )}
            //       {member.state === AdminUserState.Inactive && (
            //         <>
            //           <UiTag colorScheme={'red'}>Inactive</UiTag>
            //           <UiButton variant={'ghost'} py={0}>Invoke</UiButton>
            //         </>
            //       )}
            //     </UiHStack>
            //   </UiHStack>
            // );
          })}
        </BaseBlockList>
      )}
      <AddAdminUsersForm team={team} onClose={onToggle} onSaveSuccess={() => {}} isVisible={isOpen} />
    </>
  );
};

export default TeamMemberList;
