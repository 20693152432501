
import { type FC, useCallback, useEffect } from 'react';
import {
  UiHStack, UiIconArrowSquareOut, UiIconEye, UiIconLink,
  UiIconPencilSimple, UiLink,
  UiSimpleGrid, UiSpinner,
  UiStack,
  uiStyles, UiTag,
  UiText,
} from '@/lib/ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import { useDisclosure } from '@chakra-ui/react';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import dayjs from 'dayjs';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { TenantState, tenantStateLabels } from '@/api/constant';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import TenantForm from '@/aaa/manage/Tenant/TenantForm';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseBlockList from '@/base/BlockList';
import AccountOwner from '@/aaa/manage/TenantInfo/AccountOwner';
import BasePlainIcon from '@/base/Button/PlainIcon';
import PlainIcon from '@/base/Button/PlainIcon';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface TenantProps {
  tenantId: number
}

const Tenant: FC<TenantProps> = ({
  tenantId,
}) => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { isOpen, onToggle } = useDisclosure();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const {data, error, isLoading} = useQuery<account.TenantLoadResponse, Error>(
    [account.tenantQueryKey, { id: tenantId }],
    async () => {
      return await account.loadTenant(createGlobalApiAclRequest())({
        id: tenantId,
      });
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  const clearCache = useCallback(
    () => {
      queryClient.invalidateQueries({ queryKey: [account.tenantQueryKey, { id: tenantId }] });
    },
    [tenantId]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  if (!data?.tenant || !data?.tenant.id) {
    return (
      <UiStack p={8}>
        <BaseMessageBarError>
          Failed to load the workspace information.
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <>
      <UiStack spacing={4} px={8}>
        <UiHStack spacing={4}>
          <UiText variant={'title'}>Details</UiText>
        </UiHStack>
        <BaseBlockList
          bgColor={'#fff'}
          enableDivider={true}
        >
          <UiHStack
            p={8}
            py={6}
            borderRadius={uiStyles.borderRadius}
            // bgColor={'#fff'}
            flexGrow={1}
            alignItems={'stretch'}
          >
            <UiSimpleGrid columns={4} gap={6} flexGrow={1}>
              <UiStack>
                <UiText color={'text.secondary'}>Status</UiText>
                <UiHStack spacing={4}>
                  {data.tenant.state === TenantState.Active && (
                    <UiTag colorScheme={'green'}>{tenantStateLabels[data.tenant.state]}</UiTag>
                  )}
                  {data.tenant.state === TenantState.Inactive && (
                    <UiTag colorScheme={'red'}>{tenantStateLabels[data.tenant.state]}</UiTag>
                  )}
                </UiHStack>
              </UiStack>
              <UiStack>
                <UiText color={'text.secondary'}>Name</UiText>
                <UiHStack spacing={4}>
                  <UiText>{data.tenant.name}</UiText>
                </UiHStack>
              </UiStack>
              <UiStack>
                <UiText color={'text.secondary'}>Code</UiText>
                <UiText>{data.tenant.code}</UiText>
              </UiStack>
              <UiStack>
                <UiText color={'text.secondary'}>Api endpoint</UiText>
                <UiHStack>
                  <UiText>{data.tenant.apiEndpoint}</UiText>
                  <UiLink href={data.tenant.apiEndpoint} target={'_blank'}>
                    <PlainIcon
                      label={'Open the link'}
                      Icon={UiIconArrowSquareOut}
                    />
                  </UiLink>
                </UiHStack>
              </UiStack>
              <UiStack>
                <UiText color={'text.secondary'}>Created at</UiText>
                <UiText>{data.tenant.createdAt ? dayjs(data.tenant.createdAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
              </UiStack>
              <UiStack>
                <UiText color={'text.secondary'}>Updated At</UiText>
                <UiText>{data.tenant.updatedAt ? dayjs(data.tenant.updatedAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
              </UiStack>
            </UiSimpleGrid>
            <UiStack>
              <BaseButtonPlainIconEdit onClick={onToggle}/>
            </UiStack>
          </UiHStack>
          <UiStack>
            <AccountOwner tenant={data.tenant}/>
          </UiStack>
        </BaseBlockList>
      </UiStack>
      <TenantForm
        tenant={data.tenant}
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={clearCache}
      />
    </>
  );
};

export default Tenant;
