import { type FC } from 'react';
import {
  ListChecks
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconListChecksProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconListChecks: FC<UiIconListChecksProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ListChecks}
      {...props}
    />
  );
};
