import { type FC } from 'react';
import {
  Presentation
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconPresentationProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconPresentation: FC<UiIconPresentationProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Presentation}
      {...props}
    />
  );
};
