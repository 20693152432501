import { type FC } from 'react';
import {
  Question
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconQuestionProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconQuestion: FC<UiIconQuestionProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Question}
      {...props}
    />
  );
};
