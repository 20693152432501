import { type FC } from 'react';
import {
  UiDivider,
  type UiDividerProps,
  UiStack,
  type UiStackProps
} from '@/lib/ui';

export interface HorizontalProps {
  height?: UiStackProps['p']
  widthOffset?: UiStackProps['p']
  color?: UiDividerProps['borderColor']
}

const Horizontal: FC<HorizontalProps> = ({
  height = 0,
  widthOffset = 0,
  color = 'gray.300'
}) => {
  return (
    <UiStack py={height} px={widthOffset}>
      <UiDivider borderColor={color}/>
    </UiStack>
  );
};

export default Horizontal;
