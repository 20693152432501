import { useMemo } from 'react';
import { useLayout } from '@/base/Layout/LayoutProvider';
import { useWindowSize } from '@/lib/hook';
import { menuWidth } from '@/base/Layout/viewport';

export function useBodySize() {
  const { isFullScreen } = useLayout();
  const { clientWidth: windowClientWidth } = useWindowSize();
  const bodyWidth = useMemo(
    () => {
      return isFullScreen ? windowClientWidth : windowClientWidth - menuWidth;
    },
    [
      isFullScreen,
      windowClientWidth,
    ]
  );

  return {
    bodyWidth,
  };
}
