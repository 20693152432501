import { type FC, type ReactNode } from 'react';
import {
  UiGrid,
  UiStack
} from '@/lib/ui';

export type Layout = 'stack' | 'inline';

export interface FieldContainerProps {
  layout?: Layout
  children: ReactNode
}

const FieldContainer: FC<FieldContainerProps> = ({
  layout = 'inline',
  children
}) => {
  if (layout === 'inline') {
    return (
      <UiGrid
        // templateColumns={'1fr 3fr'}
        templateColumns={'1fr 3fr'}
        gap={4}
      >
        {children}
      </UiGrid>
    );
  } else {
    return (
      <UiStack spacing={0} flexGrow={1}>
        {children}
      </UiStack>
    );
  }
};

export default FieldContainer;
