import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiGrid
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import BaseBlockList from '@/base/BlockList';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

const Privacy: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      mutate({ eventId, key: 'privacy', bool: true }, {
        onSuccess: () => {
          toNextStep();
        }
      });
    },
    [mutate, eventId, toNextStep]
  );

  // Mark the step as set if both the urls are provided.
  useEffect(
    () => {
      return () => {
        if (eventSettings?.privacy.privacyPolicyUrl &&
          eventSettings?.privacy.termsAndConditionsUrl
        ) {
          mutate({ eventId, key: 'privacy', bool: true });
        }
      };
    },
    [
      eventSettings?.privacy.privacyPolicyUrl,
      eventSettings?.privacy.termsAndConditionsUrl
    ]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <ManageEventLayoutBody
        steps={(
          <ManageEventSteps
            eventId={eventId}
            activeStep={'privacy'}
          />
        )}
        message={'Please design your badge.'}
      >
        <UiStack p={8}>
          <BaseBlockList
            bgColor={'#fff'}
            enableDivider={true}
          >
            <PrivacyPolicy />
            <TermsAndConditions />
          </BaseBlockList>
        </UiStack>
        <UiHStack
          p={8}
          justifyContent={'space-between'}
          // flexDirection={'row-reverse'}
        >
          <UiStack flexGrow={1} />
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiButton size={'lg'} colorScheme={'primary'} type={'submit'} fontSize={'lg'} onClick={onNext} isLoading={isLoading}>
              Next
            </UiButton>
          </BaseAclContainer>
        </UiHStack>
      </ManageEventLayoutBody>
    </BaseLayout>
  );
};

export default Privacy;
