import { Tag, type TagProps } from '@chakra-ui/react';
import { type FC } from 'react';
import { uiStyles } from '@/lib/ui';

export type UiTagProps = TagProps;

export const UiTag: FC<UiTagProps> = (props) => {
  return (
    <Tag
      borderRadius={uiStyles.formElementBorderRadius}
      {...props}
    />
  );
};
