// Do NOT change values here since they are referenced directly in backend.
export enum SubscriptionBillState {
  Issued = 1,
  PendingPayment = 2,
  Overdue = 3,
  Paid = 9,
}

export const subscriptionBillStateLabels: Record<SubscriptionBillState, string> = {
  [SubscriptionBillState.Issued]: 'Issued',
  [SubscriptionBillState.PendingPayment]: 'Pending payment',
  [SubscriptionBillState.Overdue]: 'Overdue',
  [SubscriptionBillState.Paid]: 'Paid',
};

export enum SubscriptionBillType {
  Package = 1,
  Variables = 2,
}

export const SubscriptionBillTypeLabels: Record<SubscriptionBillType, string> = {
  [SubscriptionBillType.Package]: 'Package',
  [SubscriptionBillType.Variables]: 'Quarterly usage',
};
