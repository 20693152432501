import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { account, registration } from '@/api';
import { type CreatePaymentRequest, type PaymentOption } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseFormSecretField from '@/base/Form/SecretField';

export interface InvoiceFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  invoicePayment?: PaymentOption
  tenant?: account.TenantData
}

interface FormData {
  accountName: string
  bsb: string
  accountNumber: string
  isEnabled?: boolean
  iban?: string
  swift?: string
  bic?: string
  paymentInstructions?: string
}

const formSchema = Yup.object().shape({
  isEnabled: Yup.boolean().required('Must be enabled or disabled.'),
  accountName: Yup.string().when('isEnabled', {
    is: true,
    then: (schema) => { return schema.required('Account name is required.'); }
  }),
  bsb: Yup.string().when('isEnabled', {
    is: true,
    then: (schema) => { return schema.required('BSB is required.'); }
  }),
  accountNumber: Yup.string().when('isEnabled', {
    is: true,
    then: (schema) => { return schema.required('Account number is required.'); }
  }),
  iban: Yup.string(),
  swift: Yup.string(),
  bic: Yup.string(),
  paymentInstructions: Yup.string(),
});

const isEnabledOptions: Option[] = [
  { value: true, label: 'Enable' },
  { value: false, label: 'Disable' }
];

const InvoiceForm: FC<InvoiceFormProps> = ({
  // tenantCode,
  onClose,
  onSaveSuccess,
  isVisible,
  invoicePayment,
  tenant,
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { eventId } = useRegisterRoute();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<PaymentOption>, Error, CreatePaymentRequest>({
    mutationFn: async (data: CreatePaymentRequest) => {
      return await registration.addPaymentOptions(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [registration.paymentOptionQueryKey, { eventId }] });
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save payment method']);
    }
  });

  const submitForm = useCallback(async (values: FormData) => {
    const { isEnabled, ...config } = values;
    void mutateAsync({
      id: invoicePayment?.id,
      paymentOptionType: 'invoice',
      isEnabled,
      config,
      eventId
    });
  }, [mutateAsync, invoicePayment?.id, eventId]);

  const initFormData = useMemo<FormData>(() => {
    return {
      accountName: invoicePayment?.config.accountName ?? '',
      bsb: invoicePayment?.config.bsb ?? '',
      accountNumber: invoicePayment?.config.accountNumber ?? '',
      iban: invoicePayment?.config.iban ?? '',
      swift: invoicePayment?.config.swift ?? '',
      bic: invoicePayment?.config.bic ?? '',
      paymentInstructions: invoicePayment?.config.paymentInstructions ?? '',
      isEnabled: invoicePayment?.enabled ?? true,
      default: invoicePayment?.default ?? false
    };
  }, [invoicePayment?.config.accountName, invoicePayment?.config.accountNumber, invoicePayment?.config.bic, invoicePayment?.config.bsb, invoicePayment?.config.iban, invoicePayment?.config.paymentInstructions, invoicePayment?.config.swift, invoicePayment?.default, invoicePayment?.enabled]);

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Set your bank account'}
        size={'xl'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormSelectField
            name={'isEnabled'}
            label={'Enable this method?'}
            options={isEnabledOptions}
          />
          <BaseFormSecretField
            name={'accountName'}
            label={'Account name'}
            tenantId={tenant?.id}
          />
          <BaseFormSecretField
            name={'bsb'}
            label={'BSB'}
            tenantId={tenant?.id}
          />
          <BaseFormSecretField
            name={'accountNumber'}
            label={'Account number'}
            tenantId={tenant?.id}
          />
          <BaseFormSecretField
            name={'iban'}
            label={'IBAN code'}
            isRequired={false}
            tenantId={tenant?.id}
          />
          <BaseFormSecretField
            name={'swift'}
            label={'SWIFT code'}
            isRequired={false}
            tenantId={tenant?.id}
          />
          <BaseFormSecretField
            name={'bic'}
            label={'BIC code'}
            isRequired={false}
            tenantId={tenant?.id}
          />
          <BaseFormInputField
            name={'paymentInstructions'}
            label={'Payment instructions'}
            helperText={'The text entered in this field will be added on the bottom of the invoice.'}
            isRequired={false}
          />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default InvoiceForm;
