import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconNavigationArrow } from '@/lib/ui';

export interface AppWayfinderProps {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const AppWayfinderCard: FC<AppWayfinderProps> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false,
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconNavigationArrow size={'3xl'} color={isEnabled ? 'green.500' : 'gray.800'}/>)}
      name={'App way-finder'}
      isSelected={isSelected}
    />
  );
};
export default AppWayfinderCard;
