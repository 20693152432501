import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconPresentation } from '@/lib/ui';

export interface SpeakerToolsCardProps {
  isSelected?: boolean
  navLink?: string
}

const SpeakerToolsCard: FC<SpeakerToolsCardProps> = ({
  isSelected = false,
  navLink = ''
}) => {
  return (
    <AppCard
      // iconUrl={'/image/app/speaker.jpeg'}
      icon={(<UiIconPresentation size={'3xl'} color={'gray.800'} />)}
      name={'Presentation management'}
      description={'Provide production services'}
      isSelected={isSelected}
      navLink={navLink}
    />
  );
};
export default SpeakerToolsCard;
