import { type FieldMetadata } from '@/base/FormBuilder/Field';

export type FieldsValues = Record<FieldMetadata['id'], string | number | boolean | null>;
export interface Utils {
  getFieldMetadata: (id: string) => FieldMetadata | null
  getFieldsValues: (defaultValues?: FieldsValues) => FieldsValues
}

export function createUtils(fieldsMetadata: FieldMetadata[]): Utils {
  const getFieldMetadata = (fieldId: string): FieldMetadata | null => {
    for (const metadata of fieldsMetadata) {
      if (metadata.label.toLowerCase() === fieldId) {
        return metadata;
      }
    }
    return null;
  };

  /**
   * @todo Currently the default values are all set to '' (empty string).
   *       We will need to allow admin users to set the default value for a field.
   *       Then store that in FieldMetadata. This requires quite some work. So we are good for now.
   */
  const getFieldsValues = (defaultValues?: FieldsValues): FieldsValues => {
    const values: FieldsValues = {};
    for (const metadata of fieldsMetadata) {
      if (metadata.type !== 'sectionBreak' && metadata.type !== 'descriptionBlock') {
        values[metadata.label.toLowerCase()] = defaultValues?.[metadata.label.toLowerCase()] ?? '';
      }
    }
    return values;
  };

  return {
    getFieldMetadata,
    getFieldsValues
  };
}
