import { type FC } from 'react';
import {
  Calculator
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCalculatorProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCalculator: FC<UiIconCalculatorProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Calculator}
      {...props}
    />
  );
};
