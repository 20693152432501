// import { FC } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = RouterLinkProps;

const Link = RouterLink;

// const Link: FC<LinkProps> = ({
//   ...props
// }) => {
//   return (
//     <UiVStack
//       alignItems={'flex-start'}
//       flexGrow={1}
//       spacing={0}
//       {...props}
//     />
//   );
// };

export default Link;
