import {
  type ApiRequest,
  callApi,
} from '@/api/globalClient';
import { signInByEmailPassword } from '@/api/firebase/auth';

export const adminUserLoginQueryKey = 'tenant.adminUserLogin';

export interface AdminUserLoginEmailPasswordRequest {
  email: string
  password: string
}

export interface AdminUserLoginEmailPasswordResponse {
  accessToken: string
  errors: string[]
}

export function loginEmailPassword(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async (request: AdminUserLoginEmailPasswordRequest): Promise<AdminUserLoginEmailPasswordResponse> => {
    const loginResult = await signInByEmailPassword({
      email: request.email,
      password: request.password,
    });
    if (loginResult.error) {
      // throw createApiError('Login required.', ApiErrorCode.Unauthorized);
      // return {
      //   accessToken: '',
      //   errors: [loginResult.error],
      // };
      return {
        accessToken: '',
        errors: ['Sign-in failed. Invalid email address/password combination.'],
      };
    } else {
      if (loginResult.user) {
        const idToken = await loginResult.user.getIdToken(true);
        // Load the admin user from the backend using the id token and the email address.
        apiRequest.endpoint.path = '/api/auth/sign-in-by-fb-id-token';
        apiRequest.payload = {idToken};
        const apiData = await callApi<AdminUserLoginEmailPasswordResponse>(apiRequest);
        apiData.errors = apiData.errors ?? [];
        return apiData;
      } else {
        return {
          accessToken: '',
          errors: ["The user is not found."],
        };
      }
    }
  }
}
