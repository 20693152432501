import { type SyntheticEvent, useCallback, type FC, useState } from 'react';

import { UiButton, UiHStack, UiStack, uiStyles, UiText } from '@/lib/ui';
import InputTag from '@/base/InputTag';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useMutation } from '@tanstack/react-query';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { requestConfirmationEmail, type RequestConfirmationEmailRequest } from '@/api/registration';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseMessageBarInfo from '@/base/MessageBar/Info';

interface ConfirmCardProps { orderId: number, onClose: () => void, title: string, body: string, buttonLabel: string }

/**
 * @deprecated Please do not use this component since there are some design flaws.
 */
const ConfirmCard: FC<ConfirmCardProps> = ({ onClose, title, body, buttonLabel, orderId }) => {
  const [errorsMessage, setErrorsMessage] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();
  const [forwardEmails, setForwardEmails] = useState<string[]>([]);

  const handleTagsChange = useCallback((event: SyntheticEvent, selectedTags: string[]) => {
    setForwardEmails(selectedTags);
  }, []);

  const handleTagAdd = useCallback((event: SyntheticEvent, tag: string) => {
    setForwardEmails([...forwardEmails, tag]);
  }, []);

  const { mutate: requestConfirmationMutate, isLoading: isConfirmationMutationLoading } = useMutation<ApiResponseSingle<void>, Error, RequestConfirmationEmailRequest>({
    mutationFn: async (request: RequestConfirmationEmailRequest) => {
      setErrorsMessage([]);
      return await requestConfirmationEmail(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data: ApiResponseSingle<void>) => {
      if (data?.errors && (data?.errors?.length ?? 0) > 0) {
        setErrorsMessage(data.errors);
      } else {
        onClose();
      }
    },
    onError: (error: Error) => {
      reportToGlobal(error);
      setErrorsMessage([error.message]);
    }
  });

  const handleRequestConfirmation = () => {
    void requestConfirmationMutate({ orderId, forward_emails: [...forwardEmails] });
  };
  return (
    <UiStack
      alignItems={'stretch'}
      justifyContent={'space-between'}
      p={8}
      bgColor={'#fff'}
      spacing={4}
      borderRadius={uiStyles.borderRadius}
    >
      <UiStack spacing={4} justifyContent={'space-between'} flexGrow={1}>
        <UiText variant={'title'}>{title}</UiText>
        {!!body && (
          <UiText variant={'body1'}>{body}</UiText>
        )}
      </UiStack>
      <BaseDividerHorizontal height={0}/>
      <UiStack spacing={8}>
        <InputTag
          vertical
          tags={forwardEmails}
          onTagsChange={handleTagsChange}
          onTagAdd={handleTagAdd}
          errors={errorsMessage}
          setErrors={setErrorsMessage}
        />
        {errorsMessage.length > 0 && (
          <UiStack spacing={2} flexGrow={1} py={2}>
            {errorsMessage.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
          Before clicking the 'Send button', please remember to click the 'Add' button to add any emails you also want to send it to.
        </BaseMessageBarInfo>
        <UiButton
          onClick={handleRequestConfirmation}
          alignSelf='flex-end'
          // isDisabled={forwardEmails.length === 0}
          isLoading={isConfirmationMutationLoading}
        >
          {buttonLabel}
        </UiButton>
      </UiStack>
    </UiStack>
  );
};

export default ConfirmCard;
