import { type FC, useState, useCallback } from 'react';
import { UiButton, UiIconBell, UiIconBellSimpleSlash, UiIconPlusCircle, UiIconX, UiText } from '@/lib/ui';
import SubscribeModal from './SubscribeModal';
import { getSubscriptionStatus, unsubscribeFromReport, type ReportSubscriptionName, type ReportSubscriptionStatus } from '@/api/reports/reportSubscriptions';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseButtonIconText from '@/base/Button/IconText';
import BasePlainIcon from '@/base/Button/PlainIcon';

export interface SubscribeButtonProps {
  reportName: ReportSubscriptionName
}

const SubscribeButton: FC<SubscribeButtonProps> = ({ reportName }) => {
  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const [unsubscribeError, setUnsubscribeError] = useState<string[]>([]);
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { data: subscriptionStatus } = useQuery<ApiResponseSingle<ReportSubscriptionStatus>>(
    ['subscriptionStatus', reportName],
    async () => {
      return await getSubscriptionStatus(createTenantAdminApiRequest)({ reportName });
    },
    { enabled: !!reportName && isLoaded }
  );

  const isSubscribed: boolean = subscriptionStatus?.item?.subscribed ?? false;

  const { mutateAsync: unsubscribe, isLoading: isUnsubscribing } = useMutation<ApiResponseSingle<null>, Error>({
    mutationFn: async () => {
      return await unsubscribeFromReport(createTenantAdminApiRequest)({ reportName });
    },
    onSuccess: async (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setIsSubscribeOpen(true);
        setUnsubscribeError(result?.errors);
      } else {
        setUnsubscribeError([]);
        await queryClient.invalidateQueries({ queryKey: ['subscriptionStatus', reportName] });
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setUnsubscribeError([error.message ?? 'Failed to unsubscribe from the report.']);
      setIsSubscribeOpen(true);
    }
  });

  const handleClick = useCallback(async () => {
    if (!isSubscribed) {
      setIsSubscribeOpen(true);
    } else {
      try {
        await unsubscribe();
      } catch (error) {
        reportToGlobal(error as Error);
      }
    }
  }, [isSubscribed]);

  const onCloseSubscribe = () => {
    setIsSubscribeOpen(false);
    setUnsubscribeError([]);
  };

  const onConfirmSubscribe = () => {
    setIsSubscribeOpen(false);
    void queryClient.invalidateQueries({ queryKey: ['subscriptionStatus', reportName] });
  };

  return (
    <>
      {/*<BaseButtonIconText*/}
      {/*  Icon={isSubscribed ? UiIconBellSimpleSlash : UiIconBell}*/}
      {/*  onClick={handleClick}*/}
      {/*>*/}
      {/*  {isSubscribed ? 'Unsubscribe' : 'Subscribe'}*/}
      {/*</BaseButtonIconText>*/}
      {isSubscribed ? (
        <BasePlainIcon
          label={'Unsubscribe'}
          Icon={UiIconBell}
          color={'green.500'}
          onClick={handleClick}
        />
      ) : (
        <BasePlainIcon
          label={'Subscribe'}
          Icon={UiIconBellSimpleSlash}
          color={'primary.500'}
          onClick={handleClick}
        />
      )}
      <SubscribeModal
        reportName={reportName}
        isOpen={isSubscribeOpen}
        onClose={onCloseSubscribe}
        onConfirm={onConfirmSubscribe}
        isSubscribed={isSubscribed}
        unsubscribeError={unsubscribeError}
      />
    </>
  );
};

export default SubscribeButton;
