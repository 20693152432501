import { type FC } from 'react';
import { Wrap, type WrapProps } from '@chakra-ui/react';

export interface UiWarpProps extends WrapProps {
}

export const UiWarp: FC<UiWarpProps> = (props) => {
  return (
    <Wrap
      align='center'
      justify='center'
      {...props}
    />
  );
};
