/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

// Pintura Image Editor
import '@pqina/pintura/pintura.css';
import { UiText, UiCenter, UiWarp, uiStyles } from '@/lib/ui';
import ImageSelectItem, { type FileOrUrl } from './ImageSelectItem';

interface ImageSelectProps {
  value: FileOrUrl[]
  name: string
  isMultiple?: boolean
  onChange: (value: FileOrUrl[]) => void
  disabled?: boolean
}

const ImageSelect: FC<ImageSelectProps> = ({ value: files, onChange, isMultiple, disabled = false }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, [onChange]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    multiple: isMultiple,
    onDrop
  });
  return (
    <UiCenter
      {...getRootProps()}
      p={4}
      border='2px'
      _focus={{ borderColor: 'blue.500' }}
      borderRadius={uiStyles.formElementBorderRadius}
      borderColor='gray.300'
      borderStyle="dashed"
      bgColor="white">
      <input {...getInputProps()} />
      {
        files?.length ? (
          <UiWarp mt={4} spacing={2}>
            {
              files.map(
                (file, index) =>
                  <ImageSelectItem
                    key={file instanceof File ? file.name : file}
                    file={file}
                    onUpdate={(output) => {
                      const updatedFiles = [...files];
                      updatedFiles[index] = output;
                      onChange(updatedFiles);
                    }}
                  />
              )
            }
          </UiWarp>
        ) : (
          <UiText color="gray">Drag 'n' drop some files here, or click to select files</UiText>
        )
      }
    </UiCenter>
  );
};

export default ImageSelect;
