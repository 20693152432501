import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles,
  UiIconInfo
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import NewCode from './NewCode';
import CodeList from './CodeList';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { UiUnorderedList } from '@/lib/ui/Text/UiUnorderedList';
import { UiListItem } from '@/lib/ui/Text/UiListItem';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

const DiscountCodes: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'discountCode', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set regardless.
  useEffect(
    () => {
      return () => {
        if (eventSettings && !eventSettings.discountCode.isSet) {
          mutate({ eventId, key: 'discountCode', bool: true });
        }
      };
    },
    [eventSettings?.discountCode.isSet]
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'discountCodes'}
            />
          )}
          message={'Please design your badge.'}
        >
          <UiStack
            // pt={4}
            spacing={4}
            flexGrow={1}
            // pt={4}
            p={8}
          >
            <UiHStack>
              <StepInstruction label={'Instructions'}>
                <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                  <UiText variant="h5">
                    Discount codes
                  </UiText>
                  <UiText variant="body1">
                    'Discount codes' enable administrators to create customised prices to groups of attendees that meet certain conditions/criteria.
                  </UiText>
                  <UiText variant="body1">
                    Examples of discount codes are: Student discounts, Sponsor/Exhibitor discounts, Developing Country discounts
                  </UiText>
                  <UiText variant="body1">
                    You can choose to apply verify whether an attendee is eligible for a discount by requesting them to provide an ID photocopy or ID info. For example:
                  </UiText>
                  <UiUnorderedList>
                    <UiListItem>
                      You can ask a student to upload a copy of a student ID card in an 'ID photocopy' field
                    </UiListItem>
                    <UiListItem>
                      You can ask an association member to upload their membership number in an 'ID info' field
                    </UiListItem>
                  </UiUnorderedList>
                  <UiText variant="body1">
                    Discount codes are created in the 'Discount codes' step, but applied in the Ticket steps. You can set different discount amounts and percentages for individual tickets in these Ticket steps.
                  </UiText>
                  <UiText variant="body1">
                    You can change the label for 'Discount codes' in the 'Other settings, customisations' step if you want to call them eg. Promo codes, Coupon codes, etc.
                  </UiText>
                </UiStack>
              </StepInstruction>
            </UiHStack>
            <BaseDividerHorizontal height={4} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <NewCode />
            </BaseAclContainer>
            <CodeList />
          </UiStack>
          <UiHStack
            // pt={8}
            p={8}
            justifyContent={'space-between'}
            // flexDirection={'row-reverse'}
          >
            <UiStack flexGrow={1} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <UiButton
                size={'lg'}
                colorScheme={'primary'}
                type={'submit'}
                fontSize={'lg'}
                onClick={onNext}
                isLoading={isLoading}
              >
                Next
              </UiButton>
            </BaseAclContainer>
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default DiscountCodes;
