import { type FC, type ReactElement, useMemo } from 'react';
import {
  UiStack,
  UiImage, UiHStack, UiIconDotsNine, uiStyles,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export interface BrandProps {
}

const Brand: FC<BrandProps> = () => {
  const { tenant } = useTenantRoute();

  const url = useMemo(
    () => {
      if (tenant?.code) {
        return generatePageUrl('AccountDashboardModules', {tenantCode: tenant?.code});
      }
      return null;
    },
    [tenant?.code]
  );

  if (url) {
    return (
      <UiStack>
        <BaseRouterLink to={url} >
          <UiHStack spacing={4}>
            <UiStack
              p={1}
              borderRadius={'50%'}
              bgColor={'brand.100'}
              borderWidth={'2px'}
              borderStyle={'solid'}
              borderColor={'brand.300'}
              {...uiStyles.hoverShadow}
              // shadow={'base'}
            >
              <UiIconDotsNine size={'18px'} color={'brand.800'} weight={'bold'}/>
            </UiStack>
            <UiImage
              src={'/image/brand/logo.svg'}
              minHeight={'24px'}
              maxHeight={'24px'}
            />
          </UiHStack>
        </BaseRouterLink>
      </UiStack>
    );
  } else {
    return (
      <UiStack>
        <UiImage
          src={'/image/brand/logo.svg'}
          minHeight={'30px'}
          maxHeight={'30px'}
        />
      </UiStack>
    );
  }
};

export default Brand;
