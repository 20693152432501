import { type FC, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText,
  UiButton,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
  UiGrid,
  UiTag
} from '@/lib/ui';
import { type DiscountCodeDeleteRequest, type DiscountCode } from '@/api/registration';
import { useDisclosure } from '@chakra-ui/react';
import CodeForm from './CodeForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { account, registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DeletionConfirmation from './DeletionConfirmation';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

dayjs.extend(utc);

export interface CodeCardProps extends UiHStackProps {
  discountCode: DiscountCode
  tenant: account.TenantData
}

const CodeCard: FC<CodeCardProps> = ({ discountCode, tenant, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { data: event } = useEventQuery(eventId);

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate, isLoading } = useMutation<void, Error, DiscountCodeDeleteRequest>({
    mutationFn: async (request: DiscountCodeDeleteRequest) => {
      await registration.deleteDiscountCode(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
      void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<DiscountCode>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneDiscountCode(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId: discountCode.eventId.toString() }] });
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId: discountCode.eventId.toString() }] });
      void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    mutate({ id: discountCode.id });
  }, [discountCode.id, mutate]);

  const onConfirm = useCallback(() => {
    mutateClone(discountCode.id);
  }, [discountCode, mutateClone]);

  const getValidDateFormat = useCallback(
    (date: string) => {
      if (event?.tzInfoName) {
        return dayjs(date).tz(event?.tzInfoName).format('DD MMM YYYY');
      }
      return dayjs(date).format('DD MMM YYYY');
    }
    , [event?.tzInfoName]);

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={8}
        p={6}
        bgColor={'#fff'}
        // borderRadius={uiStyles.borderRadius}
        {...props}
      >
        <UiGrid
          templateColumns={{ base: '2fr 1fr 1fr' }}
          gap={8}
          flexGrow={1}
        >
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body1'} fontWeight={600}>{discountCode.name}</UiText>
            <UiText variant={'body2'} color={'text.secondary'}>{discountCode.description}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Code</UiText>
            <UiText variant='body1'>{discountCode.discountCode}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiHStack>
              <UiText variant={'body2'} color={'text.secondary'}>Valid from/to</UiText>
              {dayjs(discountCode.endDate).isBefore(dayjs()) && <UiTag colorScheme={'red'}>Expired</UiTag>}
            </UiHStack>
            <UiHStack>
              <UiText variant='body1'>
                {discountCode.startDate
                  ? `${getValidDateFormat(discountCode.startDate)} - ${getValidDateFormat(discountCode.endDate)}`
                  : 'All times'}
              </UiText>
            </UiHStack>
          </UiStack>
        </UiGrid>
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack spacing={6} justifyContent={'space-between'}>
            <BaseButtonPlainIconEdit onClick={onToggle} />
            <BaseButtonPlainIconClone onClick={onToggleConfirmClone} />
            <BaseButtonPlainIconDelete onClick={onToggleConfirmDeletion} isLoading={isLoading} />
          </UiHStack>
        </BaseAclContainer>
      </UiHStack>
      <CodeForm
        onClose={onToggle}
        isVisible={isOpen}
        discountCode={discountCode}
        onSaveSuccess={() => {}}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this discount code?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'primary'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={discountCode.name} />
    </>
  );
};

export default CodeCard;
