import { type FC } from 'react';
import {
  ArrowSquareOut
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconArrowSquareOutProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconArrowSquareOut: FC<UiIconArrowSquareOutProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ArrowSquareOut}
      {...props}
    />
  );
};
