import { type FC } from 'react';
import {
  type UiStackProps,
  UiStack,
  uiStyles
} from '@/lib/ui';

export interface FieldGroupProps extends UiStackProps {
  width?: UiStackProps['width']
  title?: string
}

const FieldGroup: FC<FieldGroupProps> = ({
  // width = '720px',
  title = null,
  children,
  ...props
}) => {
  return (
    <UiStack
      spacing={8}
      // maxWidth={width}
      // minWidth={width}
      flexGrow={1}
      // bgColor={'blackAlpha.50'}
      borderRadius={uiStyles.borderRadius}
      // borderWidth={'1px'}
      // borderColor={'gray.200'}
      borderStyle={'solid'}
      // p={8}
      // py={8}
      // borderRadius={uiStyles.borderRadius}
      {...props}
      // divider={<UiDivider borderColor={'gray.300'}/>}
    >
      {children}
    </UiStack>
  );
};

export default FieldGroup;
