import { type FC, type ReactNode } from 'react';
import {
  UiStack,
  uiStyles
} from '@/lib/ui';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';

export interface LoginBodyProps {
  children: ReactNode
  basePadding?: number
}

const LoginBody: FC<LoginBodyProps> = ({
  children,
  basePadding = 0
}) => {
  return (
    <UiStack
      alignItems={'stretch'}
      flexGrow={1}
    >
      <BaseTransitionSlideLeft>
        <UiStack
          // borderRadius={uiStyles.borderRadius}
          spacing={0}
          alignItems={'stretch'}
          flexGrow={1}
          p={basePadding}
          {...uiStyles.glass}
          borderRadius={uiStyles.bodyRadius}
        >
          {children}
        </UiStack>
      </BaseTransitionSlideLeft>
    </UiStack>
  );
};

export default LoginBody;
