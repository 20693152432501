import {
  type ApiRequest,
  type ApiResponse,
  properCallApi
} from '@/api/tenantClient';

export const recommendedDelegateTypesQueryKey = 'registration.recommendedDelegateTypes';

export interface RecommendedDelegateTypeData {
  id: number
  name: string
}

export function getRecommendedDelegateTypes(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string, delegateTypeId: string): Promise<ApiResponse<RecommendedDelegateTypeData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/delegate_types/recommend_delegate_types';
    request.endpoint.query = { eventId, delegateTypeId };
    return await properCallApi<ApiResponse<RecommendedDelegateTypeData>>(request);
  };
}
