import { type FC } from 'react';
import {
  type UiBoxProps
} from '@/lib/ui';
import BaseAppSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import ModuleCard, { type ModuleCardProps } from './ModuleCard';

export interface SponsorExhibitorProps extends Omit<ModuleCardProps, 'children'> {
}

const SponsorExhibitor: FC<SponsorExhibitorProps> = ({
  ...props
}) => {
  return (
    <ModuleCard
      {...props}
    >
      <BaseAppSponsorExhibitorCard />
    </ModuleCard>
  );
};

export default SponsorExhibitor;
