import { type FC, useMemo, useState } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiHStack, UiIconPaperPlaneTilt, UiStack, uiStyles, UiText } from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type ManageRouteParams } from '@/registration/pages';
import { useMutation, useQuery } from '@tanstack/react-query';
import { registrationIdQueryKey, requestDetailsChange, type RequestDetailsChangeRequest } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import RegistrationSummary from './RegistrationSummary';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import RegistrationFormResponse from './RegistrationInfoResponse';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseButtonGhost from '@/base/Button/Ghost';
import { type IGroupRegistrationInfo, loadGroupRegistrationById } from '@/api/registration/groupRegistration';

const GroupRegistrationInfo: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams<ManageRouteParams['GroupRegistrationManageInfo']>();
  const { reportToGlobal } = useApiErrorHandler();
  const groupRegistrationId = useMemo<number | null>(
    () => { return params.groupRegistrationId ? parseInt(params.groupRegistrationId, 10) : null; },
    [params.groupRegistrationId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Group Registrations', url: generatePageUrl('GroupRegistrationManageTable', { tenantCode }) },
        { label: `Group Registration information (${groupRegistrationId ?? ''})` }
      ];
    },
    [groupRegistrationId, tenantCode]
  );

  const groupRegistrationQuery = useQuery<IGroupRegistrationInfo, Error>({
    queryKey: [registrationIdQueryKey, { id: groupRegistrationId }],
    queryFn: async (): Promise<IGroupRegistrationInfo> => {
      const response = await loadGroupRegistrationById(createTenantAdminApiRequest)({ id: groupRegistrationId! });
      return response.item;
    },
    enabled: !!groupRegistrationId && isLoaded
  });

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate: requestDetailsChangeMutate, isLoading: isRequestDetailsChangeMutationLoading } = useMutation<void, Error, RequestDetailsChangeRequest>({
    mutationFn: async (request: RequestDetailsChangeRequest) => {
      setSuccessMessage('');
      setErrorMessage('');
      await requestDetailsChange(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      setSuccessMessage('An email has been sent to the attendee. Please tell the attendee to follow the link in the email to change the registration details.');
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrorMessage(`Failed to send the email (${error.message ?? ''}).`);
    }
  });

  const handleRequestDetailsChange = () => {
    void requestDetailsChangeMutate({ registrationId: groupRegistrationId! });
  };

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody
        breadcrumbNodes={breadcrumbNodes}
      >
        <QueryContainer query={groupRegistrationQuery}>
          {(registration) => {
            return (
              <UiStack p={8} spacing={4}>
                <UiHStack justifyContent={'flex-start'} spacing={4} alignItems={'center'}>
                  <UiText variant={'title'}>Summary</UiText>
                  {registration.summary.status === 'completed' && (
                    <BaseButtonGhost
                      LeftIcon={UiIconPaperPlaneTilt}
                      onClick={handleRequestDetailsChange}
                      isLoading={isRequestDetailsChangeMutationLoading}
                    >
                      Send details change email to attendee
                    </BaseButtonGhost>
                  )}
                </UiHStack>
                {!!errorMessage && (
                  <UiStack>
                    <BaseMessageBarError borderRadius={uiStyles.borderRadius}>
                      {errorMessage}
                    </BaseMessageBarError>
                  </UiStack>
                )}
                {!!successMessage && (
                  <UiStack>
                    <BaseMessageBarInfo bgColor={'green.50'} borderRadius={uiStyles.borderRadius}>
                      {successMessage}
                    </BaseMessageBarInfo>
                  </UiStack>
                )}
                <RegistrationSummary registrationSummary={registration.summary} />

                {registration?.registrations && (
                  <UiStack pt={4}>
                    <RegistrationFormResponse
                      title="Registrations"
                      registrationsInfo={registration.registrations}
                    />
                  </UiStack>
                )}
              </UiStack>
            );
          }}
        </QueryContainer>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default GroupRegistrationInfo;
