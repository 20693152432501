import { useParams, type Params } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useEffect } from 'react';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { Permission, useAdminAuth } from '@/app/ProviderAdminAuth';
import { AdminUserType } from '@/api/constant/adminUser';

interface TenantAclParams {
  tenantId: number
  userType?: AdminUserType
}

export function useTenantAcl(params: TenantAclParams) {
  const { adminAuth, hasPermissions } = useAdminAuth();
  const { tenantId, userType } = params;

  const hasTenantPermissions = (permissions: Permission[]) => {
    if (userType != undefined && adminAuth.user?.type !== userType) {
      return false;
    }
    return permissions.length === 0 || hasPermissions(tenantId, permissions);
  }

  return {
    hasTenantPermissions,
  };
}
