import { type FC, type PropsWithChildren, createContext, useContext, useState, useEffect, useCallback } from 'react';

export interface LayoutProviderProps extends PropsWithChildren {
}

interface LayoutContextValue {
  isFullScreen: boolean
  setIsFullScreen: (isFullScreen: boolean) => void,
}

const GlobalMessageContext = createContext<LayoutContextValue>({
  isFullScreen: false,
  setIsFullScreen: (isFullScreen: boolean) => {},
});

export const LayoutProvider: FC<LayoutProviderProps> = ({
  ...props
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <GlobalMessageContext.Provider
      value={{
        isFullScreen,
        setIsFullScreen,
      }}
      {...props}
    />
  );
};

export function useLayout() {
  const { isFullScreen, setIsFullScreen } = useContext(GlobalMessageContext) as Required<LayoutContextValue>;

  const toggleFullScreen = useCallback(
    () => {
      setIsFullScreen(!isFullScreen);
    },
    [isFullScreen, setIsFullScreen]
  );

  return {
    isFullScreen,
    toggleFullScreen,
  };
}
