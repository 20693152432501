import { type FC, type ReactElement, useState } from 'react';
import {
  UiVStack,
  UiStack,
  UiHStack,
  UiText,
  UiBox,
  UiImage,
  uiStyles,
  UiButton,
  UiIconGear,
  UiIconArrowRight
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import { smartWindowVisibleWidth } from '@/base/Layout/viewport';

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export interface LoginSmartWindowProps {
}

const LoginSmartWindow: FC<LoginSmartWindowProps> = () => {
  const [accountHeight, setAccountHeight] = useState('64px');

  return (
    <UiStack
      // enableShadow={false}
      // flexGrow={1}
      // alignItems={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      // minWidth={`${smartWindowVisibleWidth}px`}
      // maxWidth={`${smartWindowVisibleWidth}px`}
      flexGrow={1}
      // bgColor={'#fff'}
      py={12}
      borderRadius={uiStyles.borderRadius}
      // px={8}
      // flexDirection={'column'}
      spacing={0}
      bgColor={'gray.100'}
    >
      <UiStack
        enableDivider={true}
        bgColor={'#fff'}
        borderRadius={uiStyles.borderRadius}
        // p={4}
        spacing={0}
      >
        <UiHStack
          p={6}
          // py={4}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          {...uiStyles.hover}
        >
          <UiText flexGrow={1}>
            Request a demo?
          </UiText>
          <UiIconArrowRight color={'primary.500'} />
        </UiHStack>
        <UiHStack
          p={6}
          // py={4}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          {...uiStyles.hover}
        >
          <UiText flexGrow={1}>
            Having trouble with login?
          </UiText>
          <UiIconArrowRight color={'primary.500'} />
        </UiHStack>
      </UiStack>
    </UiStack>
  );
};

export default LoginSmartWindow;
