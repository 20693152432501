import { type FC } from 'react';
import {
  type UiBoxProps,
  UiHStack,
  UiStack,
  uiStyles, UiText
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import { isServicePackEnabled } from '@/account/subscription/utils';
import ServicePackCard from '@/base/App/ServicePackCard';

export interface ServicePackProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
  hours: number
  usedHours: number
}

const ServicePack: FC<ServicePackProps> = ({
  onToggle = undefined,
  subscription = undefined,
  hours = 0,
  usedHours = 0,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isServicePackEnabled(subscription): false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <ServicePackCard/>
          {/*{subscription && (*/}
          {/*  <PriceDisplay price={subscription.products[SubscriptionProduct.ServicePack].yearlyPrice} suffix={' / year'} />*/}
          {/*)}*/}
        </UiHStack>
        <BaseDividerHorizontal height={4} />
        {hours && hours > 0 && (
          <UiText variant='body1' color={'text.secondary'}>
            {hours - usedHours} / {hours} hours
          </UiText>
        )}
      </UiStack>
    </AddonCard>
  );
};

export default ServicePack;
