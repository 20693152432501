import { type FC, type ReactNode, useEffect, useState } from 'react';
import { GlobalMessage, useGlobalMessages } from '@/app/ProviderGlobalMessages';
import MessagesDisplay from '@/base/GlobalMessages/MessagesDisplay';
// import Filter from './Filter';

export interface GlobalMessagesProps {
}

const GlobalMessages: FC<GlobalMessagesProps> = () => {
  const { globalMessages, popMessages } = useGlobalMessages();
  const [messages, setMessages] = useState<GlobalMessage[]>([]);
  useEffect(
    () => {
      if ( globalMessages.length > 0 ) {
        setMessages(popMessages());
      }
    },
    [globalMessages]
  );

  return (
    <MessagesDisplay messages={messages}/>
  )
};

export default GlobalMessages;
