import { type FC } from 'react';
import {
  UiHStack,
  UiText,
  uiStyles,
  UiBox,
  UiIconCurrencyCircleDollar, type UiHStackProps
} from '@/lib/ui';

export interface CurrencyTagProps extends UiHStackProps {
  currency: string
}

const CurrencyTag: FC<CurrencyTagProps> = ({
  currency,
  ...props
}) => {
  return (
    <UiHStack
      px={6}
      py={4}
      pl={5}
      bgColor={'#fff'}
      // bgColor={'green.100'}
      alignItems={'center'}
      borderRadius={uiStyles.borderRadius}
      spacing={2}
      {...props}
    >
      <UiIconCurrencyCircleDollar />
      <UiText variant={'body1'}>
        {currency}
      </UiText>
    </UiHStack>
  );
};

export default CurrencyTag;
