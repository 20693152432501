import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import {
  UiHStack, UiSpinner,
  UiStack, uiStyles,
  UiSwitch, UiTag,
  UiText,
} from '@/lib/ui';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, type FC } from 'react';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const TaxInclusivePrices: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const eventQuery = useEventSettingsQuery(eventId);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { mutate: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee group.']);
    }
  });

  const onToggle = useCallback(() => {
    mutateEventSettings({
      eventId,
      config: {
        paymentMethod: {
          isSet: eventQuery.data?.paymentMethod?.isSet ?? false,
          displayTaxInclusivePrices: !(eventQuery.data?.paymentMethod?.displayTaxInclusivePrices ?? false),
        }
      }
    });
  }, [eventId, eventQuery.data?.paymentMethod?.displayTaxInclusivePrices, eventQuery.data?.paymentMethod?.isSet, mutateEventSettings]);

  return (
    <QueryContainer
      errorMessage="Failed to load payment methods."
      query={eventQuery}>
      {(eventSettings) => {
        return (
          <UiHStack p={6} spacing={4} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} justifyContent={'space-between'}>
            <UiStack spacing={1}>
              <UiText variant={'body1'}>Tax setting</UiText>
              <UiText color={'green.500'} variant={'body2'}>This setting will be applied to all the other ticket too.</UiText>
            </UiStack>
            <UiHStack spacing={6}>
              {eventSettings.paymentMethod?.displayTaxInclusivePrices ? (
                <UiTag colorScheme={'green'} py={2} px={4}>Display ticket prices inclusive of tax</UiTag>
              ) : (
                <UiTag colorScheme={'yellow'} py={2} px={4}>Display ticket prices exclusive of tax</UiTag>
              )}
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                {isLoading ? (
                  <BaseLoadingSpinner/>
                ) : (
                  <UiSwitch colorScheme={'primary'} onChange={onToggle} isChecked={eventSettings.paymentMethod?.displayTaxInclusivePrices} size={'md'} />
                )}
              </BaseAclContainer>
            </UiHStack>
          </UiHStack>
        );
      }}
    </QueryContainer>
  );
};

export default TaxInclusivePrices;
