import { type FC, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiStack,
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseLayoutBody from '@/base/Layout/BreadcrumbBody';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import EventDetails from './EventDetails';

const EventInfo: FC = () => {
  const { eventId } = useRegisterRoute();
  const { tenant } = useTenantRoute();

  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Registration setup', url: generatePageUrl('RegistrationManageEvent', { tenantCode: tenant?.code ?? '' }) },
        { label: 'Event' }
      ];
    },
    [tenant?.code]
  );

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      // // disableTopNav={true}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
      MainTransition={null}
    >
      <BaseLayoutBody
        breadcrumbNodes={breadcrumbNodes}
      >
        <UiStack flexGrow={1} spacing={4} alignItems={'stretch'} p={8}>
          <EventDetails eventId={eventId} />
        </UiStack>
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default EventInfo;
