import {
  callApi,
  properCallApi,
  type ApiRequest,
  type ApiResponseSingle
} from '@/api/tenantClient';

export const reportSubscriptionQueryKey = 'reports.reportSubscription';

export const reportSubscriptionName = {
  orderReport: 'order_report',
  registrationReport: 'registration_report',
  cancellationReport: 'cancellation_report',
  refundReport: 'refund_report',
  formResponsesReport: 'form_responses_report',
  orderTotalSingleSaleReport: 'order_total_single_sale_report',
  orderTotalSingleSaleInDollarReport: 'order_total_single_sale_in_dollar_report',
  registrationCompletedReport: 'registration_completed_report',
  registrationCancelledReport: 'registration_cancelled_report',
  registrationAbandonedCartReport: 'registration_aborted_cart_report',
  registrationsActivatedReport: 'registrations_activated_report'
} as const;

export type ReportSubscriptionName = (typeof reportSubscriptionName)[keyof typeof reportSubscriptionName];

export interface ReportSubscription {
  id: string
  userId: number
  reportName: string
  createdAt: string
  updatedAt: string
}

export interface ReportSubscriptionRequest {
  reportName: string
}

export interface ReportSubscriptionStatus {
  subscribed: boolean
}

export function getSubscriptionStatus(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: ReportSubscriptionRequest
  ): Promise<ApiResponseSingle<ReportSubscriptionStatus>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/report_subscriptions/status';
    request.endpoint.query = {
      report_name: params.reportName
    };
    return await properCallApi<ApiResponseSingle<ReportSubscriptionStatus>>(request);
  };
}

export function subscribeToReport(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: ReportSubscriptionRequest
  ): Promise<ApiResponseSingle<ReportSubscription>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/report_subscriptions/subscribed';
    request.payload = {
      report_subscription: params
    };
    return await properCallApi<ApiResponseSingle<ReportSubscription>>(request);
  };
}

export function unsubscribeFromReport(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: ReportSubscriptionRequest
  ): Promise<ApiResponseSingle<null>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/report_subscriptions/unsubscribed';
    request.payload = {
      report_subscription: params
    };
    return await properCallApi<ApiResponseSingle<null>>(request);
  };
}
