import {
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const daysQueryKey = 'registration.days';

export interface DayData {
  id: number
  date: string
}

export function getDays(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string): Promise<ApiResponse<DayData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/delegate_types/days';
    request.endpoint.query = { eventId };
    return await properCallApi<ApiResponse<DayData>>(request);
  };
}
