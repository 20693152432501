import { type FC } from 'react';
import {
  UiStack,
  UiIconStackSimple,
  UiText
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface FunctionTicketsProps {
  isActive?: boolean
}

const FunctionTickets: FC<FunctionTicketsProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconStackSimple}
      description={'Function tickets'}
      isActive={isActive}
      // stepNumber={5}
      // isSkipped={!eventSettings?.functionTicket?.isEnabled}
      navLink={generatePageUrl('RegistrationBuildEventFunctionTickets', { tenantCode, eventId })}
      isSet={eventSettings?.functionTicket?.isSet}
    >
      <UiStack>
        <UiStack spacing={0}>
          <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.functionTicket.total ?? 0} total</UiText>
        </UiStack>
      </UiStack>
    </Step>
  );
};

export default FunctionTickets;
