import { FC } from 'react';
import {
  Ticket,
} from "@phosphor-icons/react";
import { UiIcon, UiIconProps } from "./UiIcon";

export type UiIconTicketProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconTicket: FC<UiIconTicketProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Ticket}
      {...props}
    />
  );
}
