import { type FC } from 'react';
import {
  ArrowRight
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconArrowRightProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconArrowRight: FC<UiIconArrowRightProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ArrowRight}
      {...props}
    />
  );
};
