import { type ApiRequest, type ApiResponseSingle, properCallApi, type ApiResponse } from '../tenantClient';

export interface AdminNote {
  id?: number
  profileableType: string
  profileableId: number | null
  content: string
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
}

export interface LoadNotesByProfileableTypeRequest {
  profileableType: string
  profileableId: number | null
}

export const loadNotes = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: LoadNotesByProfileableTypeRequest): Promise<ApiResponse<AdminNote>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/notes/index_by_profile';
    request.endpoint.query = {
      profileable_type: params.profileableType,
      profileable_id: params.profileableId
    };
    return await properCallApi<ApiResponse<AdminNote>>(request);
  };
};

export interface SaveNoteRequest {
  note: AdminNote
}

export const createNote = (createTenantAdminRequest: () => ApiRequest, profileableType: string, profileableId: number | null) => {
  const request = createTenantAdminRequest();
  return async (params: SaveNoteRequest): Promise<ApiResponseSingle<AdminNote>> => {
    if (params.note.id) {
      request.method = 'PUT';
      request.endpoint.path = `/api/v1/notes/${params.note.id}`;
      request.endpoint.query = {
        profileable_type: profileableType,
        profileable_id: profileableId
      };
      request.payload = params;
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/notes';
      request.endpoint.query = {
        profileable_type: profileableType,
        profileable_id: profileableId
      };
      request.payload = params;
    }
    return await properCallApi<ApiResponseSingle<AdminNote>>(request);
  };
};

export interface RemoveNoteRequest {
  profileableType: string
  profileableId: number | null
  note: AdminNote
}

export const removeNote = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: RemoveNoteRequest): Promise<ApiResponseSingle<AdminNote>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/notes/${params.note.id}`;
    request.endpoint.query = {
      profileable_type: params.profileableType,
      profileable_id: params.profileableId
    };
    return await properCallApi<ApiResponseSingle<AdminNote>>(request);
  };
};
