import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiHStack,
  type UiHStackProps,
  UiIconGear,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import SurchargePageForm from './SurchargeForm';

export interface ManageLabelsProps extends UiHStackProps {
}

const SurchargeSetting: FC<ManageLabelsProps> = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiHStack p={6} spacing={4} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} justifyContent={'space-between'}>
        <UiStack spacing={1}>
          <UiText variant={'body1'}>Surcharge setting</UiText>
          <UiText variant={'body2'} color={'text.secondary'}>Set the surcharge for payment methods.</UiText>
        </UiStack>
        <UiHStack {...uiStyles.hover} onClick={onToggle}>
          <UiIconGear size={'2xl'} color={'primary.500'} />
        </UiHStack>
      </UiHStack>
      <SurchargePageForm
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default SurchargeSetting;
