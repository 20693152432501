import { useDisclosure } from '@chakra-ui/react';
import { UiButton, UiStack, UiText } from '@/lib/ui';
import { type FC } from 'react';
import dayjs from 'dayjs';

import {
  UiHStack,
  type UiHStackProps,
  uiStyles
} from '@/lib/ui';
import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';

import NoteForm from './NoteForm';
import RemoveNoteConfirmation from './RemoveNoteConfirmation';
import { type AdminNote } from '@/api/admin/note';
import { useQueryClient } from '@tanstack/react-query';

export interface NoteRowProps extends UiHStackProps {
  profileableType: string
  profileableId: number | null
  note: AdminNote
  onSaveSuccess: () => void
}

const NoteRow: FC<NoteRowProps> = ({
  profileableType,
  profileableId,
  note,
  onSaveSuccess
}) => {
  const { isOpen: isFormOpen, onToggle: onToggleEdit } = useDisclosure();
  const { isOpen: isRemoveOpen, onToggle: onToggleRemoveRaw } = useDisclosure();

  const queryClient = useQueryClient();

  const onToggleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    onToggleRemoveRaw();
  };

  const onConfirm = () => {
    void queryClient.invalidateQueries({ queryKey: [{ profileableType, profileableId }] });
  };

  return (
    <>
      <UiHStack
        p={4}
        px={8}
        flexGrow={1}
        spacing={4}
        justifyContent={'space-between'}
      >
        <UiStack>
          <UiText variant='body1'>{note.content}</UiText>
          <UiText variant='body2' color={'text.secondary'}>
            Created at: {dayjs(note.createdAt).format('DD MMM YYYY')} by {note.createdBy}
          </UiText>
          {note.updatedAt && note.updatedBy && (
            <UiText variant='body2' color={'text.secondary'}>
              Updated at: {dayjs(note.updatedAt).format('DD MMM YYYY')} by {note.updatedBy}
            </UiText>
          )}
        </UiStack>
        <UiHStack spacing={4}>
          <UiButton variant={'ghost'} onClick={onToggleEdit} px={0}>Edit</UiButton>
          <UiButton variant={'ghost'} onClick={onToggleRemove} px={0}>Remove</UiButton>
        </UiHStack>
      </UiHStack>
      <NoteForm
        profileableType={profileableType}
        profileableId={profileableId}
        note={note}
        isVisible={isFormOpen}
        onClose={onToggleEdit}
        onSaveSuccess={onSaveSuccess}
      />
      <RemoveNoteConfirmation
        profileableType={profileableType}
        profileableId={profileableId}
        note={note}
        isOpen={isRemoveOpen}
        onClose={onToggleRemoveRaw}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default NoteRow;
