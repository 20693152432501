import { ApiError, ApiErrorCode, ApiErrorName } from '@/api/error';
import { GlobalMessage, useGlobalMessages } from '@/app/ProviderGlobalMessages';

const createGlobalMessageTypeFromError = (error: Error): string => {
  if (error.name && error.name === ApiErrorName) {
    return createGlobalMessageType((error as ApiError).code ?? ApiErrorCode.ServerError);
  }
  return '_';
}

const createGlobalMessageType = (errorCode: ApiErrorCode): string => {
  return `${ApiErrorName}.${errorCode}`;
}

export const isMessageApiError = (message: GlobalMessage, code: ApiErrorCode): boolean => {
  return (message.type !== '' && message.type == createGlobalMessageType(code));
}

export function useApiErrorHandler() {
  const { pushMessages } = useGlobalMessages();

  const reportToGlobal = (error: Error): boolean => {
    if (error.name && error.name === ApiErrorName) {
      // error as ApiError
      pushMessages([{
        body: error.message ?? 'Server error.',
        type: createGlobalMessageTypeFromError(error),
      }]);
      return true;
    }
    return false;
  };

  return {
    reportToGlobal,
  };
}
