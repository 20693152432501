import { type FC } from 'react';
import {
  NavigationArrow
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconNavigationArrowProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconNavigationArrow: FC<UiIconNavigationArrowProps> = (props) => {
  return (
    <UiIcon
      IconComponent={NavigationArrow}
      {...props}
    />
  );
};
