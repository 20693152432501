/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UiButton, UiHStack,
  UiIconWarningCircle,
  UiSpinner,
  UiStack, UiText,
} from '@/lib/ui';
import LoginSmartWindow from '@/account/component/Layout/LoginSmartWindow';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseMessageBarStandard from '@/base/MessageBar/Standard';
import { generatePageUrl } from '@/app/pages';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

const ResetPassword: FC = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const navigate = useNavigate();
  const toDashboardModules = useCallback(
    () => {
      navigate(generatePageUrl('AccountDashboardModules', { tenantCode: tenant?.code ?? '' }));
    },
    [navigate, tenant?.code]
  );
  
  return (
    <BaseLayout
      smartWindow={(<LoginSmartWindow />)}
      sideMenu={null}
      smartButton={(<UiStack />)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        {isTenantLoading ? (
          <UiStack p={8}>
            <BaseLoadingSpinner/>
          </UiStack>
        ) : (
          <UiStack p={8}>
            <BaseMessageBarStandard
              icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
              text={(
                <UiText color={'red.500'}>
                  Failed to redirect to the module. Please try it again.
                </UiText>
              )}
              button={(<UiButton onClick={toDashboardModules} variant={'ghost'}>Back to modules</UiButton>)}
            />
          </UiStack>
        )}
      </LoginBody>
    </BaseLayout>
  );
};

export default ResetPassword;
