import { type FC } from 'react';
import {
  ShoppingCart
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconShoppingCartProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconShoppingCart: FC<UiIconShoppingCartProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ShoppingCart}
      {...props}
    />
  );
};
