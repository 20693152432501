export enum ApiErrorCode {
  BadRequest = 1,
  Unauthorized = 2,
  AccessDenied = 3,
  PageNotFound = 4,
  ServerError = 99,
}

export interface ApiError extends  Error{
  code: ApiErrorCode
}

export const ApiErrorName = '__API_ERROR__';

export function createApiError(message: string, code: ApiErrorCode): ApiError {
  return {
    name: ApiErrorName,
    message,
    code,
  };
}
