import { type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { UiButton, UiHStack, UiIconPlusCircle, UiSpinner, UiStack, uiStyles, UiTag, UiText } from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { account } from '@/api';
import { AdminUserState, AdminUserType, adminUserTypeLabels } from '@/api/constant/adminUser';
import ResendInvite from './ResendInvite';
import Revoke from './Revoke';
import Invoke from './Invoke';
import { useAdminUserTypeCheck } from '@/account/hook/useAdminUserTypeCheck';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

dayjs.extend(utc);

export interface AdminUserProps {
  tenant: account.TenantData
  adminUser: account.SearchAdminUsersResponseAdminUser
  isCurrentUser?: boolean
}

const AdminUser: FC<AdminUserProps> = ({
  tenant,
  adminUser,
  isCurrentUser = false,
  ...props
}) => {
  const {isAccountOwner, isAAAUser, isLoading} = useAdminUserTypeCheck(tenant.id ?? 0);

  if (isLoading) {
    return (
      <UiStack p={8}>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }

  return (
    <UiHStack p={8} py={6} justifyContent={'space-between'} bgColor={'#fff'} {...props}>
      <UiHStack spacing={4}>
        <UiStack minW={400} maxW={400} spacing={1}>
          <UiHStack>
            <UiText fontWeight={600}>
              {adminUser.firstName} {adminUser.lastName}
            </UiText>
            {isCurrentUser && (<UiTag>You</UiTag>)}
          </UiHStack>
          <UiText variant={'body2'} color={'text.secondary'}>
            {adminUser.email}
          </UiText>
        </UiStack>
        <UiStack minW={300} maxW={300} justifyContent={'flex-start'} alignItems={'flex-start'} spacing={1}>
          <UiHStack spacing={4}>
            {isAAAUser(adminUser) && (
              <UiTag colorScheme={'purple'}>{adminUserTypeLabels[adminUser.type]}</UiTag>
            )}
            {isAccountOwner(adminUser) && (
              <UiTag colorScheme={'brand'}>{adminUserTypeLabels[adminUser.type]}</UiTag>
            )}
            {adminUser.state === AdminUserState.Invited && (
              <UiTag colorScheme={'yellow'}>Invited</UiTag>
            )}
            {adminUser.state === AdminUserState.Active && (
              <UiTag colorScheme={'green'}>Active</UiTag>
            )}
            {adminUser.state === AdminUserState.Suspended && (
              <UiTag colorScheme={'red'}>Suspended</UiTag>
            )}
            {adminUser.state === AdminUserState.Inactive && (
              <UiTag colorScheme={'red'}>Inactive</UiTag>
            )}
          </UiHStack>
          <UiText variant={'body2'} color={'text.secondary'}>Last updated {dayjs(adminUser.updatedAt).format('DD-MM-YYYY H:mm:ss')}</UiText>
        </UiStack>
      </UiHStack>
      {!isCurrentUser && (
        <>
          <UiHStack justifyContent={'flex-end'} spacing={8}>
            {adminUser.state === AdminUserState.Invited && (
              <ResendInvite adminUser={adminUser}/>
            )}
            {adminUser.state === AdminUserState.Active && (
              <Revoke adminUser={adminUser}/>
            )}
            {adminUser.state === AdminUserState.Suspended && (
              <Invoke adminUser={adminUser}/>
            )}
            {adminUser.state === AdminUserState.Inactive && (
              <Invoke adminUser={adminUser}/>
            )}
          </UiHStack>
        </>
      )}
    </UiHStack>
  );
};

export default AdminUser;
