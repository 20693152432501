import { type ElementType, type FC } from 'react';
import {
  uiStyles,
  UiStack,
  type UiStackProps,
  UiHStack,
  UiText,
  UiIconProhibit,
  UiIconCheckCircle,
  UiSpinner
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface StepProps extends UiStackProps {
  Icon: ElementType
  description: string
  isActive?: boolean
  isSkipped?: boolean
  navLink?: string
  stepNumber?: number
  isSet?: boolean
  isFirst?: boolean
  isLast?: boolean
}

const Step: FC<StepProps> = ({
  Icon,
  description,
  isActive = false,
  isSkipped = false,
  navLink = '',
  children,
  stepNumber = undefined,
  isSet = false,
  isFirst = false,
  isLast = false,
  ...props
}) => {
  const { eventId } = useRegisterRoute();
  const { isLoading: isEventSettingsLoading } = useEventSettingsQuery(eventId);
  return (
    <BaseRouterLink to={`${navLink}#auto-scroll`} relative={'route'}>
      <UiStack
        alignItems={'stretch'}
        spacing={0}
        minW={260}
        maxW={260}
        minH={140}
        maxH={140}
        bgColor={'#fff'}
        // borderStyle={'solid'}
        // borderWidth={'1px'}
        // borderColor={isActive ? 'primary.200' : 'transparent'}
        borderRadius={uiStyles.borderRadius}
        // transform={`scale(${isActive ? 1.1 : 1})`}
        transition={'all .2s ease-in-out'}
        {...uiStyles.lift({
          isEnabled: isActive,
        })}
        // shadow={isActive ? 'md' : 'none'}
        // borderLeftRadius={isFirst ? uiStyles.borderRadius : 0}
        // borderRightRadius={isLast ? uiStyles.borderRadius : 0}
        {...props}
      >
        <UiHStack
          p={4}
          // bgColor={'blackAlpha.100'}
          borderTopRadius={uiStyles.borderRadius}
          // borderBottomStyle={'solid'}
          // borderBottomWidth={'1px'}
          // borderBottomColor={isActive ? 'primary.100' : 'gray.300'}
          spacing={2}
          pb={2}
        >
          {/* <Icon/> */}
          {!!stepNumber && (
            <UiText variant='body1'>{stepNumber}.</UiText>
          )}
          <UiText variant={'body1'} flexGrow={1}>{description}</UiText>
          {isSet && (
            <UiIconCheckCircle color={'green.500'} size={'2xl'} />
          )}
        </UiHStack>
        {/* {isSkipped && (
          <UiHStack flexGrow={1} alignItems={'flex-start'} px={8}>
            <BaseMessageBarError borderRadius={uiStyles.borderRadius} flexGrow={1}>Skipped</BaseMessageBarError>
          </UiHStack>
        )} */}
        {isSkipped && !isEventSettingsLoading &&  (
          <UiHStack flexGrow={1} alignItems={'flex-start'} p={4} pt={0}>
            <UiIconProhibit color={'red.600'} size={'xl'} />
            <UiText color={'red.600'} variant={'body2'}>Skipped</UiText>
          </UiHStack>
        )}
        <UiStack p={4} pt={0} alignItems={'stretch'} spacing={0} flexGrow={1}>
          {isEventSettingsLoading ? (
            <UiStack>
              <BaseLoadingSpinner/>
            </UiStack>
          ) : (
            children)}
        </UiStack>
        {/* {isSet && ( */}
        {/*  <UiHStack p={4} pt={0} justifyContent={'flex-end'}> */}
        {/*    /!* <UiTag colorScheme={'green'} flexGrow={1} p={4} py={2}>Checked</UiTag> *!/ */}
        {/*    <UiIconCheckCircle color={'green.400'} size={'3xl'} weight={'duotone'}/> */}
        {/*  </UiHStack> */}
        {/* )} */}
      </UiStack>
    </BaseRouterLink>
  );
};

export default Step;
