import { type FC } from 'react';
import {
  MagnifyingGlassMinus
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconMagnifyingGlassMinusProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconMagnifyingGlassMinus: FC<UiIconMagnifyingGlassMinusProps> = (props) => {
  return (
    <UiIcon
      IconComponent={MagnifyingGlassMinus}
      {...props}
    />
  );
};
