import { type FC } from 'react';
import AppCard from './AppCard';
import { UiIconHeadset, UiIconTicket } from '@/lib/ui';

export interface ServicePackCardProps {
  navLink?: string
  isSelected?: boolean
  isEnabled?: boolean
}

const ServicePackCard: FC<ServicePackCardProps> = ({
  navLink = undefined,
  isSelected = false,
  isEnabled = false
}) => {
  return (
    <AppCard
      navLink={navLink}
      icon={(<UiIconHeadset size={'3xl'} color={'gray.800'}/>)}
      // iconUrl={'/image/app/registration.jpeg'}
      name={'Service pack'}
      description={'Customer & operations service'}
      isSelected={isSelected}
      bgColor={isEnabled ? '#fff' : 'transparent'}
    />
  );
};
export default ServicePackCard;
