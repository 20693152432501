import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import ImporterButton from './ImporterButton';
import ImportTable from './ImportTable';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';

export interface ImporterPageProps {
  modelType: string
}

const ImporterPage: FC<ImporterPageProps> = ({ modelType }) => {
  const { tenantCode } = useRegisterRoute();
  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['importers', 'registration']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody isStandalone={false}>
        <UiHStack justifyContent={'flex-start'} spacing={4}>
          <BaseLayoutFullScreenToggle/>
          <ImporterButton modelType={modelType} />
        </UiHStack>
        <UiStack height={4} />
        <ImportTable modelType={modelType} />
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default ImporterPage;
