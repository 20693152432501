import { type FC } from 'react';
import {
  type UiBoxProps, UiHStack, UiIconHardDrives,
  UiStack,
  uiStyles, UiText
} from '@/lib/ui';
import AddonCard, { type AddonCardProps } from './AddonCard';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type account } from '@/api';
import { getAddonsEnabled, isAddonEnabled } from '@/account/subscription/utils';
import { SubscriptionProduct } from '@/api/constant/subscription';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import IntegrationCard from '@/base/App/IntegrationCard';

export interface IntegrationProps extends UiBoxProps {
  subscription?: account.Subscription
  onToggle?: AddonCardProps['onToggle']
}

const Integration: FC<IntegrationProps> = ({
  onToggle = undefined,
  subscription = undefined,
  ...props
}) => {
  return (
    <AddonCard
      onToggle={onToggle}
      isEnabled={subscription ? isAddonEnabled(subscription, SubscriptionProduct.Integration) : false}
      {...props}
    >
      <UiStack spacing={0} borderRadius={uiStyles.borderRadius}>
        <UiHStack justifyContent={'space-between'}>
          <IntegrationCard />
          {subscription && (
            <PriceDisplay price={subscription.products[SubscriptionProduct.Integration].monthlyPrice * 3} suffix={' / 3 months'} />
          )}
        </UiHStack>
        <BaseDividerHorizontal height={4} />
        <UiText variant='body1' color={'text.secondary'}>4 integrations included in the price</UiText>
      </UiStack>
    </AddonCard>
  );
};

export default Integration;
