import { type FC, type ElementType } from 'react';
import {
  type IconProps
} from '@phosphor-icons/react';
import { useToken } from '@chakra-ui/react';

export interface UiIconProps extends Omit<IconProps, 'color' | 'size'> {
  IconComponent: ElementType
  color?: string | number | Array<string | number>
  size?: string | number | Array<string | number>
  onClick?: () => void
  weight?: IconProps['weight']
}

export const UiIcon: FC<UiIconProps> = ({
  IconComponent,
  color = '#000',
  size = 24,
  ...props
}) => {
  const colorToken = useToken('colors', color);
  const sizeToken = useToken('fontSizes', size);
  return (
    <IconComponent size={sizeToken} color={colorToken} {...props}/>
  );
};
