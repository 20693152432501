import { type FC, ReactNode } from 'react';
import {
  UiHStack, UiInput,
  UiStack,
  uiStyles, UiText,
} from '@/lib/ui';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';

export interface AddonCardProps {
  label: string
  defaultValue: string | number
  price: number
  onValueChange: (value: number) => void
}

const UnitCostCalculator: FC<AddonCardProps> = ({
  label,
  defaultValue,
  price,
  onValueChange,
}) => {
  return (
    <UiStack spacing={4} p={8} borderRadius={uiStyles.borderRadius} bgColor={'#fff'}>
      <UiHStack alignItems={'flex-start'} justifyContent={'space-between'}>
        <UiText variant={'body1'}>{label}</UiText>
        <UiText variant={'body1'}>
          <PriceDisplay price={price ?? 0}/>
        </UiText>
      </UiHStack>
      <UiStack>
        <UiInput
          borderColor={'gray.500'}
          py={5}
          type={'number'}
          minW={240}
          maxW={240}
          defaultValue={defaultValue}
          // value={numOfRegistrations}
          onBlur={(val) => {
            let value = parseInt(val.target.value, 10);
            value = Number.isNaN(value) ? 0 : value;
            onValueChange(value >= 0 ? value : 0);
          }}
        />
      </UiStack>
    </UiStack>
  );
};

export default UnitCostCalculator;
