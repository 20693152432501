import { type FC } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';
import { uiStyles } from '@/lib/ui/styles';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';

export interface NumberFieldProps extends Omit<FieldContainerProps, 'children'> {
  label: string
  name: string
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  disabled?: boolean
  hidden?: boolean
  value?: number | null
  onChange?: (valueAsString: string, valueAsNumber: number) => void
  min?: number
  max?: number
}

const NumberField: FC<NumberFieldProps> = ({
  label,
  name,
  helperText = undefined,
  isRequired = true,
  placeholder = '',
  layout = 'inline',
  disabled = false,
  hidden = false,
  min,
  max
}) => {
  return (
    <Field name={name} >
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} style={{ display: hidden ? 'none' : 'block' }}>
            <FieldContainer
              layout={layout}
            >
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'}>
                {
                  <NumberInput
                    value={field.value === null ? undefined : field.value }
                    id={name}
                    onChange={async (_, valueAsNumber) => { return await form.setFieldValue(name, isNaN(valueAsNumber) ? '' : valueAsNumber); }}
                    min={min}
                    max={max}
                    size='md'
                    borderRadius={uiStyles.formElementBorderRadius}
                  >
                    <NumberInputField
                      {...field}
                      placeholder={placeholder}
                      disabled={disabled}
                      pr="2.5rem"
                      borderColor={'gray.300'}
                      bgColor={'#fff'}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                }
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default NumberField;
