import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { orderIdQueryKey, orderTableQueryKey, type OrderById } from '@/api/registration';
import { type InvoicePaymentRequest } from '@/api/registration/payment';
import { type ApiResponseSingle } from '@/api/tenantClient';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  UiHStack,
  UiStack,
  type UiDrawerProps
} from '@/lib/ui';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikHelpers } from 'formik';
import { useCallback, type FC } from 'react';

export interface OrderStatusDrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  orderId: number
  currentStatus: string
}

export interface FormData {
  orderId: number
  actionType: string
}

const options: CheckboxOption[] = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
  { label: 'Resend', value: 'resend' }
];

const OrderStatusDrawer: FC<OrderStatusDrawerProps> = ({
  isOpen,
  onClose,
  orderId,
  currentStatus,
}) => {
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();

  const { mutateAsync: updateInvoice, isLoading } = useMutation<ApiResponseSingle<OrderById>, Error, InvoicePaymentRequest>({
    mutationFn: async (request) => {
      return await registration.updateInvoice(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data) => {
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors.join(', '));
      } else {
        void queryClient.invalidateQueries({ queryKey: [orderIdQueryKey, { id: orderId }] });
        void queryClient.invalidateQueries({ queryKey: [orderTableQueryKey] });
        onClose();
      }
    },
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    await updateInvoice({ orderId, actionType: values.actionType });
    setSubmitting(false);
  }, [updateInvoice, orderId]);

  return (
    <UiDrawer
      placement={'right'}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerBody p={8} py={16} bgColor={'gray.100'}>
          <Formik<FormData>
            initialValues={{
              orderId,
              actionType: currentStatus
            }}
            validateOnChange={false}
            validateOnBlur={false}
            // validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <UiStack flexGrow={1} spacing={8}>
                <BaseFormCheckboxListField
                  label={'Order status'}
                  name={'actionType'}
                  options={options}
                  isMultiple={false}
                  layout={'stack'}
                // helperText={'@todo This field might be hidden since admin users can choose to send attendee group specific urls to delegates.'}
                />
                <UiHStack justifyContent={'flex-end'}>
                  <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={'primary'} type={'submit'} isLoading={isLoading}>
                    Save
                  </UiButton>
                </UiHStack>
              </UiStack>
            </Form>
          </Formik>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default OrderStatusDrawer;
