import { type FC, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { UiStack } from '@/lib/ui';
// import Filter from './Filter';
import { type BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

const Overview: FC = () => {
  const { tenantCode } = useRegisterRoute();

  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        // {label: 'Dashboard'},
      ];
    },
    []
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['dashboard', 'overview']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack />
        </BaseLayoutBody>
      </BaseLayout>
      {/* <Filter
        isOpen={showFilter}
        onClose={() => {setShowFilter(false)}}
      /> */}
    </>
  );
};

export default Overview;
