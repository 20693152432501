import { type FC, useMemo } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import { UiHStack, UiStack } from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type AAARouteParams } from '@/aaa/pages';
import Tenant from './Tenant';
import CurrentSubscription from './CurrentSubscription';
import ToTenant from '@/aaa/component/Layout/ToTenant';

const TenantInfo: FC = () => {
  const params = useParams<AAARouteParams['AAAManageTenantInfo']>();
  const tenantId = useMemo<number | null>(
    () => {
      return params.tenantId ? parseInt(params.tenantId, 10) : null;
    },
    [params.tenantId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Workspaces', url: generatePageUrl('AAAManageTenant') },
        /**
         * @todo tenantId here should be the order serial number, not the database table row id.
         */
        { label: `Workspace (${tenantId ?? ''})` }
      ];
    },
    [tenantId]
  );

  return (
    <BaseLayout
      // brand={(<ToTenant/>)}
      smartWindow={(<SmartWindow/>)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
      sideMenu={null}
      MainTransition={null}
    >
      <BreadcrumbBody breadcrumbNodes={breadcrumbNodes}>
        {!!tenantId && (
          <UiStack spacing={8} py={8}>
            <Tenant tenantId={tenantId}/>
            <CurrentSubscription tenantId={tenantId}/>
          </UiStack>
        )}
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default TenantInfo;
