import { type FC } from 'react';
import {
  UiHStack,
  UiLink,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText
} from '@/lib/ui';
import { HStack } from '@chakra-ui/react';
import { type IGroupRegistrationSummary } from '@/api/registration/groupRegistration';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';

export interface RegistrationSummaryProps {
  registrationSummary: IGroupRegistrationSummary
}

const RegistrationSummary: FC<RegistrationSummaryProps> = ({ registrationSummary }) => {
  const { tenantCode } = useRegisterRoute();

  return (
    <>
      <UiStack p={8} py={8} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} shadow={uiStyles.cardShadow}>
        <UiSimpleGrid columns={3} gap={8}>
          <UiStack>
            <UiText color={'text.secondary'}>Email</UiText>
            <UiHStack spacing={4}>
              <UiText>{registrationSummary.email}</UiText>
            </UiHStack>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Status</UiText>
            <HStack>
              <UiText>{registrationSummary.status}</UiText>
            </HStack>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Attendee Category</UiText>
            <UiText>{registrationSummary.attendeeCategoryName}</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Event</UiText>
            <UiText>{registrationSummary.eventName}</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>CreatedAt</UiText>
            <UiText>{registrationSummary.createdAt}</UiText>
          </UiStack>
        </UiSimpleGrid>
        {registrationSummary.orderId && (
          <UiLink href={generatePageUrl('RegistrationManageOrderInfo', { tenantCode, orderId: registrationSummary.orderId.toString() })} color="primary.500" mt={4}>
            View order
          </UiLink>
        )}
      </UiStack>
    </>
  );
};

export default RegistrationSummary;
