import { type FC } from 'react';
import { type TicketType } from '@/api/constant';
import NewSharedStock from '@/registration/component/BuildEvent/SharedStock/NewSharedStock';
import SharedStockList from '@/registration/component/BuildEvent/SharedStock/SharedStockList';

export interface SharedStockProps {
  ticketType: TicketType
}

const SharedStock: FC<SharedStockProps> = ({ ticketType }) => {
  return (
    <>
      <NewSharedStock ticketType={ticketType} />
      <SharedStockList ticketType={ticketType} />
    </>
  );
};

export default SharedStock;
