import { type FC } from 'react';
import {
  Browser
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconBrowserProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconBrowser: FC<UiIconBrowserProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Browser}
      {...props}
    />
  );
};
