import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI
} from '@/api/tenantClient';

export const ticketDiscountQueryKey = 'registration.ticketDiscountList';
export const ticketDiscountOptionQueryKey = 'registration.ticketDiscountOptionList';

export interface ActiveTicketDiscount {
  id: string
  discountId: number
  ticketType: string
  ticketId: number
  discountType: number
  amount: number
  active: true
  discount: {
    name: string
    description: string
  }
}

export interface InactiveTicketDiscount {
  id: string
  discountId: number
  ticketType: string
  ticketId: number
  discountType: number
  amount: number
  active: false
  discount?: {
    name: string
    description: string
  }
}

export type TicketDiscount = ActiveTicketDiscount | InactiveTicketDiscount;

export interface TicketDiscountOption extends Omit<Partial<TicketDiscount>, 'discountId'> {
  discountId: number
  discount: {
    name: string
    description: string
  }
}

export interface TicketDiscountQueryRequest {
  ticketId: number
  eventId: string
  ticketType: string
}

export function loadTicketDiscountOptions(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: TicketDiscountQueryRequest
  ): Promise<ApiResponse<TicketDiscountOption[]>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/ticket_discounts/options';
    request.endpoint.query = {
      ticketId: params.ticketId.toString(),
      ticketType: params.ticketType.toString(),
      eventId: params.eventId
    };
    return await callApi<TicketDiscountOption[]>(request);
  };
}

export interface TicketDiscountSaveRequestItem extends Omit<TicketDiscount, 'discountId'> {
  discountId: number | string
}

export interface TicketDiscountSaveRequest {
  eventId: number | string
  ticketDiscounts: TicketDiscountSaveRequestItem[]
}

export function saveTicketDiscounts(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: TicketDiscountSaveRequest
  ): Promise<ApiResponse<TicketDiscount[]>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/ticket_discounts/create_options';
    request.endpoint.query = {
      eventId: params.eventId
    };
    request.payload = { ticketDiscounts: params.ticketDiscounts };
    return await callApi<TicketDiscount[]>(request);
  };
}

export interface TicketDiscountDeleteRequest {
  id: string
}

export function deleteTicketDiscount(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: TicketDiscountDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/ticket_discounts/' + params.id;
    await deleteAPI(request);
  };
}
