import { useCallback, useState } from 'react';

export function useModal<T>(defaultIsOpen?: boolean) {
  const [isOpen, setOpen] = useState<boolean>(defaultIsOpen ?? false);
  const [selectedData, setSelectedData] = useState<T>();

  const onOpen = useCallback((data?: T) => {
    setSelectedData(data);
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setSelectedData(undefined);
    setOpen(false);
  }, []);

  return { selectedData, isOpen, onOpen, onClose };
}
