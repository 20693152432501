import {
  type RouteObject
} from 'react-router-dom';
import AdminAccess from '@/account/component/AdminAccess';
import MobileAppGeneralLandingPage from '@/mobileApp/general/Landing';

// All the route names should start with MobileApp to avoid conflicts with other modules (e.g. registration).
export interface MobileAppRouteParams {
  MobileAppGeneralLanding: {
    tenantCode: string
  }
}

export type MobileAppRouteName = keyof MobileAppRouteParams;

export const mobileAppRoutes: Record<MobileAppRouteName, RouteObject> = {
  MobileAppGeneralLanding: {
    path: '/mobile-event-app/:tenantCode/general/landing',
    element: (<AdminAccess><MobileAppGeneralLandingPage /></AdminAccess>)
  },
};
