import { type FC } from 'react';
import {
  ClipboardText
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconClipboardTextProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconClipboardText: FC<UiIconClipboardTextProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ClipboardText}
      {...props}
    />
  );
};
