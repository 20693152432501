import { type FC } from 'react';
import {
  UiStack,
  uiStyles,
  UiText,
  type UiStackProps,
  UiHStack,
  UiIconCaretDown,
  UiIconPencilSimple
} from '@/lib/ui';

export interface FieldInputProps extends UiStackProps {
  label: string
  onClick: () => void
  isMultiple?: boolean
  isRequired?: boolean
}

const FieldInput: FC<FieldInputProps> = ({
  label,
  onClick,
  isMultiple = false,
  isRequired = false,
  ...props
}) => {
  return (
    <UiStack
      borderRadius={uiStyles.borderRadius}
      spacing={2}
      onClick={onClick}
      {...uiStyles.hover}
      {...props}
    >
      <UiHStack justifyContent={'space-between'}>
        <UiText color={'text.secondary'}>{label}{isRequired ? ' *' : ''}</UiText>
        {/* <UiIconPencilSimple size={'2xl'} color={'primary.400'}/> */}
      </UiHStack>
      <UiHStack
        height={'40px'}
        // width={'320px'}
        borderRadius={uiStyles.formElementBorderRadius}
        borderWidth={'1px'}
        borderStyle={'solid'}
        borderColor={'gray.400'}
        flexDirection={'row-reverse'}
        bgColor={'indigo.50'}
        px={4}
      >
        <UiIconCaretDown size={'2xl'} color={'gray.400'}/>
      </UiHStack>
    </UiStack>
  );
};

export default FieldInput;
