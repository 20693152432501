import {
  callApi,
  properCallApi,
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle
} from '@/api/tenantClient';
import { type Order, type OrderById } from './order';

export const paymentIntentQueryKey = 'registration.paymentIntent';
export const paymentConfirmQueryKey = 'registration.paymentConfirm';
export const paymentInvoice = 'registration.invoicde';

export type Gateway = 'stripe' | 'securepay' | 'invoice';

export interface PaymentIntent {
  id: string
  clientSecret: string
  publishableKey: string
  order: Order
}

export interface PaymentRequest {
  locale: string
  gateway: Gateway
}

export interface PreparePaymentRequest extends PaymentRequest {
  cartId: number
}

export interface InvoicePaymentRequest {
  orderId: number
  actionType: string
}

export function preparePayment(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: PreparePaymentRequest
  ): Promise<ApiResponse<PaymentIntent>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/payments/prepare_payment';
    request.payload = {
      payment: params
    };
    return await callApi<PaymentIntent>(request);
  };
}

export function updateInvoice(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: InvoicePaymentRequest
  ): Promise<ApiResponseSingle<OrderById>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/payments/invoice';
    request.payload = {
      payment: params
    };

    return await properCallApi<ApiResponseSingle<OrderById>>(request);
  };
}
