// Constants here should be identical to AdminUserSessionState in the backend.
export enum AdminUserSessionState {
  Active = 1,
  Inactive = 2,
  PendingMfa = 3,
}

export const adminUserStateLabels: Record<AdminUserSessionState, string> = {
  [AdminUserSessionState.Active]: 'Active',
  [AdminUserSessionState.Inactive]: 'Inactive',
  [AdminUserSessionState.PendingMfa]: 'Pending MFA',
};
