import { forwardRef } from '@chakra-ui/react';
import {
  UiBox,
  type UiBoxProps,
  UiStack,
  // UiStackProps,
  uiStyles,
  UiText
} from '@/lib/ui';
import { type FieldMetadataSelect, type FieldMetadata } from '@/base/FormBuilder/Field';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormPhoneField from '@/base/Form/PhoneField';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import countries from '@/app/countries.json';

const countryOptions = countries.map((countryName) => { return { value: countryName, label: countryName }; });

export interface GridItemProps extends UiBoxProps {
  fieldMetadata: FieldMetadata
}

const createSelectOptions = (fieldMetadata: FieldMetadataSelect): Option[] => {
  const options: Option[] = [];
  if (fieldMetadata.options) {
    for (const opt of fieldMetadata.options.split('\n')) {
      options.push({ value: opt, label: opt });
    }
  }
  return options;
};

const GridItem = forwardRef<GridItemProps, 'div'>(({
  fieldMetadata,
  // children,
  ...props
}, ref) => {
  return (
    <>
      <UiBox
        ref={ref}
        p={4}
        px={8}
        bgColor={'transparent'}
        borderRadius={uiStyles.borderRadius}
        // {...uiStyles.hover}
        _hover={{
          cursor: 'pointer'
        }}
        {...props}
      >
        {/* {children} */}
        {fieldMetadata.type === 'input' && (
          <BaseFormInputField
            name={fieldMetadata.label.toLowerCase()}
            label={fieldMetadata.label}
            helperText={fieldMetadata.helpText}
            isRequired={fieldMetadata.isRequired}
            layout={'stack'}
            maxLength={fieldMetadata.length}
          />
        )}
        {fieldMetadata.type === 'select' && fieldMetadata.optionType === 'country' && (
          <BaseFormSelectField
            name={fieldMetadata.label.toLowerCase()}
            label={fieldMetadata.label}
            helperText={fieldMetadata.helpText}
            options={countryOptions}
            layout={'stack'}
          />
        )}
        {fieldMetadata.type === 'select' && fieldMetadata.optionType === 'custom' && (
          <BaseFormSelectField
            name={fieldMetadata.label.toLowerCase()}
            label={fieldMetadata.label}
            helperText={fieldMetadata.helpText}
            isMultiple={fieldMetadata.isMultiple}
            maximumOptionsSelectable={fieldMetadata.maximumOptionsSelectable}
            options={createSelectOptions(fieldMetadata)}
            layout={'stack'}
          />
        )}
        {fieldMetadata.type === 'descriptionBlock' && (
          <UiStack p={4} px={{ base: 4, lg: 6 }} bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
            <UiText>{fieldMetadata.description}</UiText>
          </UiStack>
        )}
        {fieldMetadata.type === 'date' && (
          <BaseFormInputField
            name={fieldMetadata.label.toLowerCase()}
            label={fieldMetadata.label}
            type="date"
            helperText={fieldMetadata.helpText}
            isRequired={fieldMetadata.isRequired}
            layout={'stack'}
          />
        )}

        {fieldMetadata.type === 'phone' && (
          <BaseFormPhoneField
            name={fieldMetadata.label.toLowerCase()}
            label={fieldMetadata.label}
            helperText={fieldMetadata.helpText}
            isRequired={fieldMetadata.isRequired}
            layout={'stack'}
          />
        )}
        {/* {fieldMetadata.type === 'descriptionBlock' && ( */}
        {/*  <UiStack */}
        {/*    px={0} */}
        {/*    py={6} */}
        {/*    // bgColor={'#fff'} */}
        {/*    borderTopColor={'gray.300'} */}
        {/*    borderBottomColor={'gray.300'} */}
        {/*    borderTopWidth={'1px'} */}
        {/*    borderBottomWidth={'1px'} */}
        {/*    borderStyle={'solid'} */}
        {/*  > */}
        {/*    <UiText variant={'body1'}>{fieldMetadata.description}</UiText> */}
        {/*  </UiStack> */}
        {/* )} */}

        {/* {fieldMetadata.type === 'input' && (
          <UiStack spacing={0}>
            <UiText variant={'h6'}>{fieldMetadata.label}</UiText>
            <UiText variant={'body1'} color={'text.secondary'}>Input</UiText>
          </UiStack>
        )}
        {fieldMetadata.type === 'select' && (
          <UiStack spacing={0}>
            <UiText variant={'h6'}>{fieldMetadata.label}</UiText>
            <UiText variant={'body1'} color={'text.secondary'}>Select</UiText>
          </UiStack>
        )} */}
        {fieldMetadata.type === 'sectionBreak' && (
          <UiStack
            spacing={0}
            borderBottomWidth={1}
            borderBottomColor={'gray.300'}
            borderBottomStyle={'solid'}
            pb={4}
          >
            <UiText variant={'h6'}>{fieldMetadata.label}</UiText>
            {!!fieldMetadata.description && (
              <UiText variant={'body1'} color={'text.secondary'}>{fieldMetadata.description}</UiText>
            )}
          </UiStack>
        )}
      </UiBox>
    </>
  );
});
export default GridItem;
