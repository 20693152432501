import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI
} from '@/api/tenantClient';

export const discountCodeQueryKey = 'registration.discountCodeList';

export enum IsRequiredType {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  NOT_REQUIRED = 'not_required'
}

export interface DiscountCode {
  id: number
  name: string
  description?: string
  discountCode: string
  startDate: string
  endDate: string
  active: boolean
  eventId: string
  idPhotocopy: IsRequiredType
  idInfo: IsRequiredType
}

export interface DiscountCodeQueryRequest {
  eventId: string
}

export function loadDiscountCodes(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: DiscountCodeQueryRequest
  ): Promise<ApiResponse<DiscountCode[]>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/discounts';
    request.endpoint.query = {
      eventId: params.eventId.toString()
    };
    return await callApi<DiscountCode[]>(request);
  };
}

export interface DiscountCodeSaveRequest extends Omit<DiscountCode, 'id'> {
  id?: number
}

export function saveDiscountCode(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: DiscountCodeSaveRequest
  ): Promise<ApiResponse<DiscountCode[]>> => {
    if (params.id) {
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/discounts/' + params.id;
      request.endpoint.query = { eventId: params.eventId };
      request.payload = {
        discount: params
      };
    } else {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/discounts';
      request.endpoint.query = { eventId: params.eventId };
      request.payload = {
        discount: params,
      };
    }

    return await callApi<DiscountCode[]>(request);
  };
}

export interface DiscountCodeDeleteRequest {
  id: number
}

export function deleteDiscountCode(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: DiscountCodeDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/discounts/' + params.id;
    await deleteAPI(request);
  };
}

export interface DiscountCodeCheckRequest {
  discountCode: string
  eventId: string
}

export function checkDiscountCode(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: DiscountCodeCheckRequest) => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/discounts/check_code';
    request.endpoint.query = {
      eventId: params.eventId.toString(),
      discountCode: params.discountCode
    };
    return await callApi<DiscountCode>(request);
  };
}

export function cloneDiscountCode(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (discountCodeId: number): Promise<ApiResponse<DiscountCode>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/discounts/clone';
    request.endpoint.query = { id: discountCodeId.toString() };
    return await callApi<DiscountCode>(request);
  };
}
