import { type FC } from 'react';
import {
  ChartLine
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconChartLineProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconChartLine: FC<UiIconChartLineProps> = (props) => {
  return (
    <UiIcon
      IconComponent={ChartLine}
      {...props}
    />
  );
};
