import {
  type RouteObject
} from 'react-router-dom';
import ProductWebsiteGeneralHomePage from 'src/productWebsite/general/Home';

// All the route names should start with ProductWebsite to avoid conflicts with other modules (e.g. registration).
export interface ProductWebsiteRouteParams {
  ProductWebsiteGeneralHome: {}
}

export type ProductWebsiteRouteName = keyof ProductWebsiteRouteParams;

export const productWebsiteRoutes: Record<ProductWebsiteRouteName, RouteObject> = {
  ProductWebsiteGeneralHome: {
    path: '/',
    index: true,
    element: (<ProductWebsiteGeneralHomePage />)
  },
};
