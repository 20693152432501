// Do NOT change values here since they are referenced directly in backend.
// SubscriptionBillItemCodePackage                      SubscriptionBillItemCode = 1
// SubscriptionBillItemCodeModuleRegistration           SubscriptionBillItemCode = 2
// SubscriptionBillItemCodeModuleAbstractManagement     SubscriptionBillItemCode = 3
// SubscriptionBillItemCodeModuleEventWebsite           SubscriptionBillItemCode = 4
// SubscriptionBillItemCodeModuleMobileEventApp         SubscriptionBillItemCode = 5
// SubscriptionBillItemCodeModuleDigitalPoster          SubscriptionBillItemCode = 6
// SubscriptionBillItemCodeModuleLiveStreaming          SubscriptionBillItemCode = 7
// SubscriptionBillItemCodeModuleSponsorExhibitor       SubscriptionBillItemCode = 8
// SubscriptionBillItemCodeModuleWebinar                SubscriptionBillItemCode = 9
// SubscriptionBillItemCodeModuleDataInsight            SubscriptionBillItemCode = 10
// SubscriptionBillItemCodeModuleMultiLingualSupport    SubscriptionBillItemCode = 11
// SubscriptionBillItemCodeModuleLiveTranslation        SubscriptionBillItemCode = 12
// SubscriptionBillItemCodeModuleAILiveCaptioning       SubscriptionBillItemCode = 13
// SubscriptionBillItemCodeModuleAppWayfinder           SubscriptionBillItemCode = 14
// SubscriptionBillItemCodeModuleContentHub             SubscriptionBillItemCode = 15
// SubscriptionBillItemCodeServicePack                  SubscriptionBillItemCode = 99
// SubscriptionBillItemCodeModuleRegistrationUnit       SubscriptionBillItemCode = 100
// SubscriptionBillItemCodeModuleAbstractManagementUnit SubscriptionBillItemCode = 101
// SubscriptionBillItemCodeModuleDigitalPosterUnit      SubscriptionBillItemCode = 102

export enum SubscriptionBillItemCode {
  Package = 1,
  ModuleRegistration = 2,
  ModuleAbstractManagement = 3,
  ModuleEventWebsite = 4,
  ModuleMobileEventApp = 5,
  ModuleDigitalPoster = 6,
  ModuleLiveStreaming = 7,
  ModuleSponsorExhibitor = 8,
  ModuleWebinar = 9,
  ModuleDataInsight = 10,
  ModuleMultiLingualSupport = 11,
  ModuleLiveTranslation = 12,
  ModuleAILiveCaptioning = 13,
  ModuleAppWayfinder = 14,
  ModuleContentHub = 15,
  Integration = 16,
  ModuleServicePack = 99,
  UnitRegistration = 100,
  UnitAbstractManagement = 101,
  UnitModuleDigitalPoster = 102,
}

export const subscriptionBillItemCodeLabels: Record<SubscriptionBillItemCode, string> = {
  [SubscriptionBillItemCode.Package]: 'Package',
  [SubscriptionBillItemCode.ModuleRegistration]: 'Registration',
  [SubscriptionBillItemCode.ModuleAbstractManagement]: 'Abstract management',
  [SubscriptionBillItemCode.ModuleEventWebsite]: 'Event website',
  [SubscriptionBillItemCode.ModuleMobileEventApp]: 'Event app',
  [SubscriptionBillItemCode.ModuleDigitalPoster]: 'Digital poster',
  [SubscriptionBillItemCode.ModuleLiveStreaming]: 'Live straming',
  [SubscriptionBillItemCode.ModuleSponsorExhibitor]: 'Sponsors & Exhibitors',
  [SubscriptionBillItemCode.ModuleWebinar]: 'Webinar',
  [SubscriptionBillItemCode.ModuleDataInsight]: 'Data insights',
  [SubscriptionBillItemCode.ModuleMultiLingualSupport]: 'Multilingual support',
  [SubscriptionBillItemCode.ModuleLiveTranslation]: 'Live translation',
  [SubscriptionBillItemCode.ModuleAILiveCaptioning]: 'AI live captioning',
  [SubscriptionBillItemCode.ModuleAppWayfinder]: 'App wayfinder',
  [SubscriptionBillItemCode.ModuleContentHub]: 'Content hub',
  [SubscriptionBillItemCode.Integration]: 'Integration',
  [SubscriptionBillItemCode.ModuleServicePack]: 'Service pack',
  [SubscriptionBillItemCode.UnitRegistration]: 'Registration fee',
  [SubscriptionBillItemCode.UnitAbstractManagement]: 'Abstract submission fee',
  [SubscriptionBillItemCode.UnitModuleDigitalPoster]: 'Digital poster creation fee',
};
