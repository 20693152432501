import { type FC } from 'react';
import {
  CheckCircle
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCheckCircleProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCheckCircle: FC<UiIconCheckCircleProps> = (props) => {
  return (
    <UiIcon
      IconComponent={CheckCircle}
      {...props}
    />
  );
};
