import { useMemo, type FC } from 'react';
import { type Layout } from 'react-grid-layout';
import {
  UiGrid,
  UiGridItem,
  UiStack,
  type UiStackProps,
  uiStyles
} from '@/lib/ui';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import GridItem from './GridItem';

export interface FormGeneratorProps extends UiStackProps {
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
}

const FormGenerator: FC<FormGeneratorProps> = ({
  fieldsLayout = [],
  fieldsMetadata = [],
  ...props
}) => {
  const fieldsData = useMemo(() => {
    return fieldsMetadata
      .map((metadata) => {
        return {
          ...metadata,
          layout: fieldsLayout.find(layout => { return layout.i === metadata.id; })
        };
      })
      .filter((metadata) => { return !!metadata.layout; })
      .sort((a, b) => { return (a.layout!.y === b.layout!.y) ? a.layout!.x - b.layout!.x : a.layout!.y - b.layout!.y; })
      .map(({ layout, ...metadata }) => { return metadata as FieldMetadata; });
  }
  , [fieldsLayout, fieldsMetadata]);
  return (
    <UiStack
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      {...props}
    >
      <UiGrid
        templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
        rowGap={'24px'}
        columnGap={'36px'}
      >
        {fieldsData.map((fieldMetadata) => {
          const fieldLayout = fieldsLayout.findIndex((layout: Layout) => {
            return layout.i === fieldMetadata.id;
          });
          return (
            <UiGridItem
              key={`ui-grid-item-${fieldMetadata.id}`}
              colSpan={{ base: 'auto', lg: fieldsLayout[fieldLayout].w }}>
              <GridItem
                key={`grid-item-${fieldMetadata.id}`}
                fieldMetadata={fieldMetadata}
              />
            </UiGridItem>
          );
        })}
      </UiGrid>
    </UiStack>
  );
};

export default FormGenerator;
