import { type FC } from 'react';
import PlainIcon, { PlainIconProps } from '@/base/Button/PlainIcon';
import { UiIconX } from '@/lib/ui';

export interface PlainIconDeleteProps extends Omit<PlainIconProps, 'Icon' | 'label'> {
  label?: string
}

const PlainIconDelete: FC<PlainIconDeleteProps> = ({
  label = 'Delete',
  color = 'red.500',
  ...props
}) => {
  return (
    <PlainIcon
      label={label}
      color={color}
      Icon={UiIconX}
      {...props}
    />
  );
};

export default PlainIconDelete;
