import { type FC } from 'react';
import {
  Screencast
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconScreencastProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconScreencast: FC<UiIconScreencastProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Screencast}
      {...props}
    />
  );
};
