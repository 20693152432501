import { type FC, useMemo, useCallback, useState } from 'react';
import { Formik, Form, type FormikHelpers } from 'formik';
import { type Layout } from 'react-grid-layout';
import LayoutFooter from '@/registration/component/Register/Layout/Footer';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import {
  UiButton, UiHStack,
  UiIconCheck,
  UiStack,
} from '@/lib/ui';
import FormGenerator from '@/base/FormGenerator';
import { createUtils, type FieldsValues } from '@/base/FormGenerator/utils';
import React from 'react';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { useWindowSize } from '@/lib/hook';

type FormData = FieldsValues;
type FieldName = keyof FieldsValues;

type Errors = Record<FieldName, string>;

export interface FormPreviewProps {
  fieldsLayout: Layout[]
  fieldsMetadata: FieldMetadata[]
}

const FormPreview: FC<FormPreviewProps> = ({ fieldsLayout, fieldsMetadata }) => {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const utils = useMemo(() => { return createUtils(fieldsMetadata ?? []); }, [fieldsMetadata]);
  const { clientWidth: windowClientWidth } = useWindowSize();
  const bodyWidth = useMemo(
    () => {
      return Math.min((windowClientWidth - 64), 1440);
    },
    [windowClientWidth]
  );

  const submitForm = useCallback(async (values: FormData) => {
    setIsFakeLoading(true);
    setIsButtonClicked(false);
    setTimeout(() => {
      setIsFakeLoading(false);
      setIsButtonClicked(true);
    }, 1500);
  }, []);
  return (
    <UiHStack
      flexGrow={1}
      justifyContent={'space-around'}
      alignItems={'flex-start'}
      p={8}
      bgColor={'gray.100'}
    >
      <UiStack
        maxW={bodyWidth}
        flexGrow={1}
        overflowX={'hidden'}
      >
        <Formik
          initialValues={utils.getFieldsValues()}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values: FormData) => {
            const errors: Errors = {};
            // This is an example to check 'required' fields. We might validator others in the future.
            for (const fieldId in values) {
              const fieldMetadata = utils.getFieldMetadata(fieldId);
              if (fieldMetadata) {
                // If a field contains exmpty string, we will treat it as empty. Currently we don't consider number or boolean.
                if ('isRequired' in fieldMetadata && fieldMetadata.isRequired && values[fieldId] === '') {
                  errors[fieldId] = 'The field is required';
                }
              }
            }
            return errors;
          }}
          onSubmit={async (
            values,
            { setSubmitting }: FormikHelpers<FormData>
          ) => {
            setSubmitting(true);
            await submitForm(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <BaseTransitionSlideLeft>
              <UiStack flexGrow={1}>
                <FormGenerator
                  fieldsLayout={fieldsLayout}
                  fieldsMetadata={fieldsMetadata}
                />
              </UiStack>
            </BaseTransitionSlideLeft>
            <UiStack height={'400px'} />
            <LayoutFooter>
              <UiHStack justifyContent={'flex-end'} flexGrow={1}>
                <UiButton px={8} size={'lg'} shadow={'base'} colorScheme={isButtonClicked && !isFakeLoading ? 'green' : 'primary'} type={'submit'} isLoading={isFakeLoading}>
                  {isButtonClicked && !isFakeLoading ? <UiIconCheck color={'white'} size={'3xl'} /> : 'Test'}
                </UiButton>
              </UiHStack>
            </LayoutFooter>
          </Form>
        </Formik>
      </UiStack>
    </UiHStack>
  );
};

export default React.memo(FormPreview);
