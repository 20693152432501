import { type FC } from 'react';
import { UiHStack, type UiHStackProps, UiStack } from '@/lib/ui';
import BrandFooter from '@/registration/component/Register/Layout/BrandFooter';

export interface FooterProps extends UiHStackProps {

}

const Footer: FC<FooterProps> = ({
  children,
  ...props
}) => {
  return (
    <UiStack
      position={'fixed'}
      left={0}
      right={0}
      bottom={0}
      bgColor={'#fff'}
      zIndex={999}
      borderTopWidth={'1px'}
      borderTopStyle={'solid'}
      borderTopColor={'gray.300'}
      alignItems={'stretch'}
      spacing={0}
      {...props}
    >
      {
        children && (
          <UiHStack
            px={8}
            py={4}
            flexGrow={1}
          >
            {children}
          </UiHStack>
        )
      }
      <BrandFooter />
    </UiStack>
  );
};

export default Footer;
