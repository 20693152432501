import {
  UiButton, UiModal, UiModalBody, UiModalContent, UiModalOverlay, UiStack, UiText,
  UiHStack,
} from '@/lib/ui';
import { useMutation } from '@tanstack/react-query';
import { type FC, useState, useCallback, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import BaseMessageBarError from '@/base/MessageBar/Error';

import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type ReportSubscriptionRequest, subscribeToReport, type ReportSubscription } from '@/api/reports/reportSubscriptions';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SubscribeModalProps {
  reportName: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isSubscribed: boolean
  unsubscribeError: string[]
}

const SubscribeModal: FC<SubscribeModalProps> = ({
  reportName,
  isOpen,
  onClose,
  onConfirm,
  isSubscribed,
  unsubscribeError
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();

  const deliveryTime = useMemo(() => {
    return dayjs.utc().set('hour', 1).set('minute', 0).tz(dayjs.tz.guess()).format('h:mm A');
  }, []);

  const { mutate: subscribeReport, isLoading } = useMutation<ApiResponseSingle<ReportSubscription>, Error, ReportSubscriptionRequest>({
    mutationFn: async (data: ReportSubscriptionRequest) => {
      return await subscribeToReport(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        onConfirm();
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to subscribe to the report.']);
    }
  });

  useEffect(() => {
    if (!isOpen) {
      setErrors([]);
    }
  }, [isOpen]);

  const onYes = useCallback(
    () => {
      subscribeReport({
        reportName,
      });
    },
    [reportName]
  );

  const modalContent = isSubscribed ? null : `Do you confirm to subscribe to this scheduled CSV export? It will be delivered to your email each day at ${deliveryTime}.`;

  return (
    <>
      <UiModal
        onClose={onClose}
        size={'xs'}
        isOpen={isOpen}
        motionPreset={'scale'}
        isCentered={true}
      >
        <UiModalOverlay />
        <UiModalContent minW={480}>
          <UiModalBody>
            <UiStack
              px={2}
              py={6}
              flexGrow={1}
              alignItems={'stretch'}
              spacing={4}
            >
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              {isSubscribed && (
                unsubscribeError.length > 0 && (
                  <UiStack spacing={4} flexGrow={1}>
                    {unsubscribeError.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                    <UiButton onClick={onClose} pl={0} py={0}>Close</UiButton>
                  </UiStack>
                )
              )}
              <UiText>{modalContent}</UiText>
              {!isSubscribed && (
                <UiHStack flexGrow={1} justifyContent={'space-between'}>
                  <UiButton variant={'ghost'} color={'red.500'} pl={0} py={0} onClick={onClose}>No</UiButton>
                  <UiButton isLoading={isLoading} onClick={onYes}>Yes</UiButton>
                </UiHStack>
              )}
            </UiStack>
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default SubscribeModal;
