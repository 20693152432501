import {
  type ApiRequest,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const financeTemplateQueryKey = 'registration.financeTemplate';

interface FinanceTemplateQueryRequest {

}

export interface FinanceTemplate {
  id: number
  createdAt: Date
  updatedAt: Date
  customInvoiceTemplate: string
  customRefundReceiptTemplate: string
}

export function loadFinanceTemplate(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: FinanceTemplateQueryRequest): Promise<ApiResponseSingle<FinanceTemplate>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/tenants/load_finance_template';

    return await properCallApi<ApiResponseSingle<FinanceTemplate>>(request);
  };
}

export interface FinanceTemplateParams {
  customInvoiceTemplate: string | Blob
  customRefundReceiptTemplate: string | Blob
  invoiceTemplateName: string
  refundReceiptName: string
}

export function updateFinanceTemplate(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: FinanceTemplateParams): Promise<ApiResponseSingle<FinanceTemplate>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/admin/tenants/update_finance_template';

    request.payload = {
      tenant: params
    };

    return await properCallApi<ApiResponseSingle<FinanceTemplate>>(request);
  };
}
