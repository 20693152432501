import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import TicketCard from './TicketCard';
import { UiHStack, UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type TicketType } from '@/api/constant';
import { Skeleton } from '@chakra-ui/react';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import NewTicket from './NewTicket';
import OrderChange from './OrderChange';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

export interface TicketListProps {
  ticketType: TicketType
}

const TicketList: FC<TicketListProps> = ({ ticketType }) => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const ticketQuery = useQuery(
    [registration.ticketListQueryKey, { eventId, ticketType }],
    async () => {
      const result = await registration.loadTicketList(createTenantAdminApiRequest)({ eventId, ticketType });
      return result.items;
    },
    {
      enabled: !isApiPreparing
    }
  );

  return (
    <QueryContainer
      query={ticketQuery}
      loadingComponent={<Skeleton height='80px' />}
      errorMessage='Failed to load tickets.'
      enableLoadingEffectOnFetching={true}
    >
      {(data) => {
        return (
          <>
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <UiHStack justifyContent={'space-between'}>
                <NewTicket ticketType={ticketType} />
                <OrderChange tickets={data} ticketType={ticketType} />
              </UiHStack>
            </BaseAclContainer>
            {/* <UiGrid */}
            {/*  templateColumns={{ base: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr' }} */}
            {/*  gap={8} */}
            {/* > */}
            {/* <BaseBlockList bgColor={'#fff'} enableDivider={true}> */}
            {/*  {data.map((ticket) => { */}
            {/*    return ( */}
            {/*      <TicketCard key={ticket.id} ticket={ticket} ticketType={ticketType} /> */}
            {/*    ); */}
            {/*  })} */}
            {/* </BaseBlockList> */}
            <UiStack spacing={4}>
              {data.map((ticket) => {
                return (
                  <TicketCard key={ticket.id} ticket={ticket} ticketType={ticketType} />
                );
              })}
            </UiStack>
            {/* </UiGrid> */}
          </>
        );
      }}
    </QueryContainer>
  );
};

export default TicketList;
