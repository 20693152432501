import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { type EventSetting, type EventSettingsUpdateRequest } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import {
  UiStack,
  type UiHStackProps
} from '@/lib/ui';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import { useState, type FC } from 'react';
import * as Yup from 'yup';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
};

interface FormData {
  buildingPageMessage: string
};

const formSchema = Yup.object().shape({
  buildingPageMessage: Yup.string().nullable()
});

const BuildingPageForm: FC<LabelsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { mutate: mutateEventSettings, isLoading } = useMutation<ApiResponse<EventSetting>, Error, EventSettingsUpdateRequest>({
    mutationFn: async (data: EventSettingsUpdateRequest) => {
      return await registration.updateEventSettings(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result.errors && result.errors.length > 0) {
        setSaveErrors(result.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
        setSaveErrors([]);
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the message for building.']);
    }
  });

  const submitForm = async (values: FormData) => {
    mutateEventSettings({
      eventId,
      config: {
        pageMetadata: {
          ...eventSettingQuery.data!.pageMetadata,
          building: {
            message: values.buildingPageMessage
          }
        }
      }
    });
  };

  return (
    <QueryContainer query={eventSettingQuery} loadingComponent={<></>}>
      {(eventSetting) => (
        <Formik
          initialValues={{
            buildingPageMessage: eventSetting.pageMetadata?.building?.message ?? '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={formSchema}
          onSubmit={async (
            values: FormData,
            { setSubmitting }: FormikHelpers<FormData>
          ) => {
            setSubmitting(true);
            await submitForm(values);
            setSubmitting(false);
          }}
        >
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Building page'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                ))}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormTextareaField
                name={'buildingPageMessage'}
                label={'Message'}
                isRequired={false}
                maxLength={500}
                helperText={'This is the message that will display to an attendee visiting the registration pages when the registration mode is set to “Building”. Below this message is a field to collect an email address to be notified when registration goes live.'}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>
        </Formik>
      )}
    </QueryContainer>
  );
};

export default BuildingPageForm;
