import { type FC, type ReactNode } from 'react';
import { useAdminAuth, Permission } from '@/app/ProviderAdminAuth';
import { AdminUserType } from '@/api/constant/adminUser';
import { useTenantAcl } from '@/account/hook/useTenantAcl';

export interface AclContainerProps {
  children: ReactNode
  tenantId: number
  userType?: AdminUserType
  permissionsRequired?: Permission[]
  noPermissionPlaceholder?: ReactNode
}

const AclContainer: FC<AclContainerProps> = ({
  children,
  tenantId,
  userType = undefined,
  permissionsRequired = [],
  noPermissionPlaceholder = null,
}) => {
  const { hasTenantPermissions } = useTenantAcl({
    tenantId,
    userType,
  });
  if (!hasTenantPermissions(permissionsRequired)) {
    return noPermissionPlaceholder;
  }
  return children;
};

export default AclContainer;
