import { type FC } from 'react';
import {
  CaretDown
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCaretDownProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCaretDown: FC<UiIconCaretDownProps> = (props) => {
  return (
    <UiIcon
      IconComponent={CaretDown}
      {...props}
    />
  );
};
