// Constants here should be identical to TeamState in the backend.
export enum TeamState {
  Active = 1,
  Inactive = 2,
}

export const teamStateLabels: Record<TeamState, string> = {
  [TeamState.Active]: 'Active',
  [TeamState.Inactive]: 'Inactive',
};

export const teamStateOptions: Array<{ value: number, label: string }> = [
  { value: TeamState.Active, label: teamStateLabels[TeamState.Active] },
  { value: TeamState.Inactive, label: teamStateLabels[TeamState.Inactive] },
];

export enum TeamType {
  Custom = 1,
  BuiltIn = 2,
}

export enum TeamBuiltinName {
  AccountOwner = '__AccountOwner__',
  AAA = '__AAATeam__',
}
